import { createReducer } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/types/types-external';

import { Conversation, compareByLastMessage } from '@twilio/frontline-shared/models/Conversation';
import { indexExists } from '@twilio/frontline-shared/utils/array';
import { ConversationSid } from '@twilio/frontline-shared/types';
import { ValueOf } from '@twilio/frontline-shared/utils/typescript';
import {
    addConversation,
    removeConversation,
    saveUnreadMessagesCount,
    updateConversation,
    setIsAllPreviousMessagesFetched,
    saveUnreadSystemMessagesCount,
    addUserConversation,
    removeUserConversation,
    setUnreadMessagesCount,
    saveMarkedUnreadAt,
} from './actions';

export type ConversationsState = {
    allConversations: {
        [converastionSid: ConversationSid]: Conversation | undefined;
    };
    myConversations: ConversationSid[];
    userConversations: {
        [userSid: string]: ConversationSid[];
    };
    unreadMessagesCount: number;
};

export const initialState: ConversationsState = {
    allConversations: {},
    myConversations: [],
    userConversations: {},
    unreadMessagesCount: 0,
};

const pushConversationSid = (
    allConversations: WritableDraft<ConversationsState['allConversations']>,
    conversationSids:
        | ConversationsState['myConversations']
        | ValueOf<ConversationsState['userConversations']>,
    conversationSid: ConversationSid,
): ConversationSid[] => {
    if (conversationSids.includes(conversationSid)) {
        return conversationSids;
    }

    conversationSids.push(conversationSid);
    conversationSids.sort((conversationASid, conversationBSid) => {
        const conversationA = allConversations[conversationASid];
        const conversationB = allConversations[conversationBSid];
        return compareByLastMessage(conversationA, conversationB);
    });
    return conversationSids;
};

export const reduce = createReducer<ConversationsState>(initialState, (builder) => {
    builder
        .addCase(addConversation, (state, action) => {
            const { conversation } = action.payload;
            const conversationSid = conversation.sid;
            const isConversationNotExist = !state.allConversations[conversationSid];
            if (isConversationNotExist) {
                state.allConversations[conversationSid] = conversation;
            }

            pushConversationSid(state.allConversations, state.myConversations, conversationSid);
        })
        .addCase(addUserConversation, (state, action) => {
            const { conversation, conversationSid, userIdentity } = action.payload;

            const userConversations = state.userConversations[userIdentity] || [];

            const sid = conversationSid || conversation?.sid;
            if (sid) {
                state.userConversations[userIdentity] = pushConversationSid(
                    state.allConversations,
                    userConversations,
                    sid,
                );

                if (conversation) {
                    state.allConversations[sid] = conversation;
                }
            }
        })
        .addCase(removeConversation, (state, action) => {
            const { conversationSid } = action.payload;
            // TODO: How to handle "dead" converastion SID's in user conversations map?
            delete state.allConversations[conversationSid];
            const conversationIndex = state.myConversations.findIndex(
                (sid) => sid === conversationSid,
            );
            if (indexExists(conversationIndex)) {
                state.myConversations.splice(conversationIndex, 1);
            }
        })
        .addCase(removeUserConversation, (state, action) => {
            const { conversationSid, userIdentity } = action.payload;
            const conversationIndex = state.userConversations[userIdentity].findIndex(
                (sid) => sid === conversationSid,
            );
            if (indexExists(conversationIndex)) {
                state.userConversations[userIdentity].splice(conversationIndex, 1);
            }
        })
        .addCase(updateConversation, (state, action) => {
            const { conversation, conversationSid } = action.payload;
            const conversationExist = state.allConversations[conversationSid];
            if (conversationExist) {
                const {
                    unreadMessagesCount,
                    unreadSystemMessagesCount,
                    isAllPreviousMessagesFetched,
                    markedUnreadAt,
                    ...rest
                } = conversation;
                state.allConversations[conversationSid] = Object.assign(
                    {},
                    state.allConversations[conversationSid],
                    rest,
                );
                state.myConversations.sort((conversationASid, conversationBSid) => {
                    const conversationA = state.allConversations[conversationASid];
                    const conversationB = state.allConversations[conversationBSid];
                    return compareByLastMessage(conversationA, conversationB);
                });
            }
        })
        .addCase(saveUnreadMessagesCount, (state, action) => {
            const { conversationSid, count, lastConsumedMessageIndex } = action.payload;
            const conversation = state.allConversations[conversationSid];
            if (conversation) {
                conversation.unreadMessagesCount = count;
                conversation.lastConsumedMessageIndex =
                    lastConsumedMessageIndex ?? conversation.lastConsumedMessageIndex;
            }
        })
        .addCase(saveUnreadSystemMessagesCount, (state, action) => {
            const { conversationSid, count } = action.payload;
            const conversation = state.allConversations[conversationSid];
            if (conversation) {
                conversation.unreadSystemMessagesCount = count;
            }
        })
        .addCase(saveMarkedUnreadAt, (state, action) => {
            const { conversationSid, markedUnreadAt } = action.payload;
            const conversation = state.allConversations[conversationSid];
            if (conversation) {
                conversation.markedUnreadAt = markedUnreadAt;
            }
        })
        .addCase(setIsAllPreviousMessagesFetched, (state, action) => {
            const { conversationSid, fetched } = action.payload;
            const conversation = state.allConversations[conversationSid];
            if (conversation) {
                conversation.isAllPreviousMessagesFetched = fetched;
            }
        })
        .addCase(setUnreadMessagesCount, (state, action) => {
            const { unreadMessagesCount } = action.payload;
            state.unreadMessagesCount = unreadMessagesCount;
        });
});
