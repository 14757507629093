import { all, takeEvery } from '@twilio/frontline-shared/store/redux';

import {
    watchChannelLoadMessages,
    watchChannelReadAllMessages,
    watchConversationEventsAction,
    watchFetchUnreadMessagesCount,
    watchUserActions,
    watchNewMessage,
    watchMarkConversationUnread,
    watchReadConversation,
    watchHideMessage,
} from '@twilio/frontline-shared/store/rootSaga';
import { FrontlineSDK } from '@twilio/frontline-shared/sdk/FrontlineSDK';
import { newMedia, NewMediaAction } from '@twilio/frontline-shared/store/media/actions';
import {
    retrySendMessage,
    RetrySendMessage,
    sendMediaMessage,
    SendMediaMessage,
    sendMessage,
    SendMessage,
} from '@twilio/frontline-shared/store/messages/actions';
import {
    UpdateAppIconBadge,
    updateAppIconBadge,
} from '@twilio/frontline-shared/store/conversations/actions';

import {
    loadDrafts,
    LoadDrafts,
    saveDraft,
    SaveDraft,
} from '@twilio/frontline-shared/store/draft/actions';
import { receiveMediaSaga } from './media/sagas';
import { retrySendMessageSaga, sendMessageSaga } from './messages/sagas';
import { updateAppIconBadgeSaga } from './conversations/sagas';
import { saveDraftSaga, loadDraftsSaga } from './draft/sagas';

export function* watchSendMessage(frontlineSDK: typeof FrontlineSDK) {
    yield takeEvery<SendMessage | SendMediaMessage, typeof sendMessageSaga>(
        sendMessage.getType(),
        sendMessageSaga,
        frontlineSDK,
    );
    yield takeEvery<SendMessage | SendMediaMessage, typeof sendMessageSaga>(
        sendMediaMessage.getType(),
        sendMessageSaga,
        frontlineSDK,
    );
    yield takeEvery<RetrySendMessage, typeof retrySendMessageSaga>(
        retrySendMessage.getType(),
        retrySendMessageSaga,
        frontlineSDK,
    );
}

function* watchMedia() {
    yield takeEvery<NewMediaAction>(newMedia.getType(), receiveMediaSaga);
}

function* watchChannelUpdateAppIconBadge() {
    yield takeEvery<UpdateAppIconBadge>(updateAppIconBadge.type, updateAppIconBadgeSaga);
}

function* watchDraftActions() {
    yield takeEvery<LoadDrafts>(loadDrafts.type, loadDraftsSaga);
    yield takeEvery<SaveDraft>(saveDraft.type, saveDraftSaga);
}

export function* rootSaga(frontlineSDK: typeof FrontlineSDK) {
    yield all([
        watchMedia(),
        watchDraftActions(),
        watchHideMessage(frontlineSDK),
        // Shared
        watchNewMessage(),
        watchSendMessage(frontlineSDK),
        watchChannelReadAllMessages(frontlineSDK),
        watchChannelLoadMessages(frontlineSDK),
        watchConversationEventsAction(),
        watchFetchUnreadMessagesCount(frontlineSDK),
        watchUserActions(frontlineSDK),
        watchChannelUpdateAppIconBadge(),
        watchMarkConversationUnread(frontlineSDK),
        watchReadConversation(frontlineSDK),
    ]);
}
