import { Reducer } from 'redux';
import { useSelector } from 'react-redux';

import { VoiceActions, setCurrentlyPlaying } from './actions';
import { ExtendsChatState } from '../ChatState';

export type VoiceState = { currentlyPlaying: string };

export const initialState: VoiceState = { currentlyPlaying: '' };

export const reduce: Reducer<VoiceState, VoiceActions> = (state = initialState, action) => {
    switch (action.type) {
        case setCurrentlyPlaying.getType(): {
            return { currentlyPlaying: action.payload.sid };
        }
        default:
            return state;
    }
};

export const useCurrentlyPlaying = (): string => {
    return useSelector<ExtendsChatState, string>((state) => {
        return state.chat.voice.currentlyPlaying;
    });
};
