import React from 'react';
import { Linking, Platform, StyleSheet, View } from 'react-native';
import { ActionSheetProps } from '@expo/react-native-action-sheet';
import Communications from 'react-native-communications';
import { DefaultTheme } from '@twilio/frontline-shared/theme';
import ParsedText from '../ParsedText';
// @ts-ignore

import { IChatMessage } from './types';

const WWW_URL_PATTERN = /^www\./i;

const OPTION_TITLES = ['Call', 'Text', 'Cancel'];

type MessageTextProps = {
    currentMessage: IChatMessage;
    position: 'left' | 'right';
} & ActionSheetProps;

export class MessageText extends React.Component<MessageTextProps> {
    onUrlPress: (url: string) => void;

    onPhonePress: (phone: string) => void;

    onEmailPress: (email: string) => void;

    constructor(props: MessageTextProps) {
        super(props);

        this.onUrlPress = (url) => {
            // When someone sends a message that includes a website address beginning with "www." (omitting the scheme),
            // react-native-parsed-text recognizes it as a valid url, but Linking fails to open due to the missing scheme.
            if (WWW_URL_PATTERN.test(url)) {
                this.onUrlPress(`http://${url}`);
            } else {
                Linking.canOpenURL(url).then((supported) => {
                    if (!supported) {
                        console.error('No handler for URL:', url);
                    } else {
                        Linking.openURL(url);
                    }
                });
            }
        };

        this.onPhonePress = (phone) => {
            const cancelButtonIndex = OPTION_TITLES.length - 1;

            props.showActionSheetWithOptions(
                {
                    options: OPTION_TITLES,
                    cancelButtonIndex,
                },
                (buttonIndex?: number) => {
                    switch (buttonIndex) {
                        case 0:
                            Communications.phonecall(phone, true);
                            break;
                        case 1:
                            Communications.text(phone);
                            break;
                        default:
                            break;
                    }
                },
            );
        };

        this.onEmailPress = (email) => Communications.email([email], null, null, null, null);
    }

    shouldComponentUpdate(nextProps: MessageTextProps) {
        const { currentMessage } = this.props;
        return (
            !!currentMessage &&
            !!nextProps.currentMessage &&
            currentMessage.text !== nextProps.currentMessage.text
        );
    }

    render() {
        const { position, currentMessage } = this.props;
        const linkStyle = linkStyles[position];

        return (
            <View style={containerMargin.margin}>
                <ParsedText
                    testID="message-text"
                    style={textStyles[position]}
                    parse={[
                        { type: 'url', style: linkStyle, onPress: this.onUrlPress },
                        { type: 'phone', style: linkStyle, onPress: this.onPhonePress },
                        { type: 'email', style: linkStyle, onPress: this.onEmailPress },
                    ]}>
                    {currentMessage.text}
                </ParsedText>
            </View>
        );
    }
}

export const styles = StyleSheet.create({
    textMessageText: {
        fontSize: DefaultTheme.fontSizes.fontSize16,
        lineHeight: DefaultTheme.lineHeights.lineHeight10,
        marginTop: DefaultTheme.space.space20,
        marginBottom: DefaultTheme.space.space0,
        ...Platform.select({
            web: {
                marginTop: DefaultTheme.space.space7,
                wordBreak: 'break-word',
            },
        }),
    },
});

const containerMargin = StyleSheet.create({
    margin: {
        marginLeft: DefaultTheme.space.space10,
        marginRight: DefaultTheme.space.space10,
    },
});

const linkStyles = StyleSheet.create({
    left: {
        color: DefaultTheme.textColors.colorTextLink,
        textDecorationLine: 'underline',
    },
    right: {
        color: 'white',
        textDecorationLine: 'underline',
    },
});

const textStyles = {
    left: StyleSheet.flatten([
        styles.textMessageText,
        {
            color: DefaultTheme.textColors.colorText,
        },
    ]),
    right: StyleSheet.flatten([
        styles.textMessageText,
        {
            color: DefaultTheme.textColors.colorTextInverse,
        },
    ]),
};
