import { put, select, call } from '@redux-saga/core/effects';

import {
    ConversationMessage,
    isLocalMessage,
    LocalMessage,
    MessageStatus,
    MessageType,
} from '@twilio/frontline-shared/models/Message';
import { Participant } from '@twilio/frontline-shared/models/Participant';
import { FrontlineSDK } from '@twilio/frontline-shared/sdk/FrontlineSDK';
import { IMedia, MediaStatus } from '@twilio/frontline-shared/models/Media';
import { isActionOf } from '@twilio/frontline-shared/store/Action';
import { IUser } from '@twilio/frontline-shared/models/User';
import {
    sendMessageRequest,
    sendMessageFailure,
    sendMessage as sendMessageAction,
    SendMessage,
    SendMediaMessage,
    RetrySendMessage,
    hideMessage,
} from '@twilio/frontline-shared/store/messages/actions';
import { markMessagesAsRead } from '@twilio/frontline-shared/store/conversations/actions';
import { selectConversationParticipant } from '@twilio/frontline-shared/store/ParticipantsState';
import { selectCurrentUser } from '@twilio/frontline-shared/store/ChatSessionState';
import { randomId } from '@twilio/frontline-shared/utils/random';
import { SendMediaOptions } from '@twilio/frontline-shared/types';
import { sendMedia } from '@twilio/frontline-shared/store/media/actions';
import { Analytics } from '@twilio/frontline-web/src/analytics';
import { selectChannelMessage } from '@twilio/frontline-shared/store/messages/ChatMessagesState';
import { selectMedia } from '@twilio/frontline-shared/store/media/ChannelMediaState';

function* sendMessage(
    frontlineSDK: typeof FrontlineSDK,
    channelSid: string,
    message: LocalMessage,
    media?: IMedia,
) {
    let sendMessageParam: string | SendMediaOptions | FormData;
    let messageAttributes: { messageId: string; mediaId?: string };

    if (media) {
        const file: File = yield call(() =>
            fetch(media.path!)
                .then((r) => r.blob())
                .then(
                    (blob) =>
                        new File([blob], media.filename || '', {
                            type: media.contentType,
                        }),
                ),
        );
        sendMessageParam = new FormData();
        sendMessageParam.append('media', file);
        messageAttributes = { messageId: message.sid, mediaId: media.sid };
        yield put(sendMedia({ media }));
    } else {
        sendMessageParam = message.body || '';
        messageAttributes = { messageId: message.sid, ...message.attributes };
    }

    yield put(sendMessageRequest({ channelSid, message }));

    try {
        // Send Message
        const messageIndex: number = yield call(
            frontlineSDK.shared!.sendMessage,
            channelSid,
            sendMessageParam,
            messageAttributes,
        );
        // Mark it as "read" immediately
        yield put(markMessagesAsRead({ conversationSid: channelSid, messageIndex }));
    } catch (err: any) {
        Analytics.logError('FAILED_TO_SEND_A_MESSAGE', err);
        yield put(sendMessageFailure({ channelSid, id: message.sid }));
    }
}

export function* sendMessageSaga(
    frontlineSDK: typeof FrontlineSDK,
    action: SendMessage | SendMediaMessage,
) {
    const { channelSid } = action.payload;

    const user: IUser = yield select(selectCurrentUser);
    const participant: Participant = yield select(
        selectConversationParticipant,
        channelSid,
        user.identity,
    );

    if (isActionOf(sendMessageAction, action)) {
        const { body, attributes } = action.payload;
        const messageId = randomId();

        const message: LocalMessage = {
            sid: messageId,
            index: null,
            status: MessageStatus.Sending,
            messageType: MessageType.Text,
            body,
            channelSid,
            author: user.identity,
            attributes: attributes || {},
            createdAt: new Date(),
            participantSid: participant?.sid,
        };
        yield call(sendMessage, frontlineSDK, channelSid, message);
    } else {
        const draftMedia = action.payload.media;
        const messageId = randomId();
        const mediaId = randomId();

        const message: LocalMessage = {
            sid: messageId,
            index: null,
            status: MessageStatus.Sending,
            messageType: MessageType.Media,
            body: undefined,
            mediaSid: mediaId,
            channelSid,
            author: user.identity,
            attributes: {},
            createdAt: new Date(),
            participantSid: participant?.sid,
        };
        const mediaPath = draftMedia.path!;
        const media: IMedia = {
            ...draftMedia,
            sid: mediaId,
            path: mediaPath,
            state: MediaStatus.Ready,
        };
        yield call(sendMessage, frontlineSDK, channelSid, message, media);
    }
}

export function* retrySendMessageSaga(frontlineSDK: typeof FrontlineSDK, action: RetrySendMessage) {
    const { channelSid, messageSid } = action.payload;

    let message: ConversationMessage | undefined = yield select(
        selectChannelMessage,
        channelSid,
        messageSid,
    );

    if (!message) {
        return;
    }

    if (!isLocalMessage(message)) {
        const newLocalMessage: LocalMessage = {
            ...message,
            sid: randomId(),
            createdAt: new Date(),
            attributes: {},
            tempId: undefined,
            index: null,
            errorCode: undefined,
        };
        message = newLocalMessage;
    }

    const { mediaSid } = message;
    if (mediaSid) {
        const media: IMedia | undefined = yield select(selectMedia, mediaSid);
        if (media) {
            yield put(hideMessage({ messageSid, channelSid }));
            yield call(sendMessage, frontlineSDK, channelSid, message, media);
        }
        return;
    }

    yield put(hideMessage({ messageSid, channelSid }));
    yield call(sendMessage, frontlineSDK, channelSid, message);
}
