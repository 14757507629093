import { Heading } from '@twilio-paste/heading';
import { Flex } from '@twilio-paste/flex';
import { Text } from '@twilio-paste/text';
import { Card } from '@twilio-paste/card';

export function EmptyCustomerDetails() {
    return (
        <Card paddingX="space40" paddingY="space50">
            <Flex vertical width={400} hAlignContent="center">
                <Heading as="h4" variant="heading40" data-testid="customer-no-details-title">
                    No details to display
                </Heading>
                <Text as="span" textAlign="center" data-testid="customer-no-details-text">
                    This record has no details stored. Populate this on your CRM or contact your
                    support team.
                </Text>
            </Flex>
        </Card>
    );
}
