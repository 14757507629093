import { MutableRefObject } from 'react';
import { Box } from '@twilio-paste/box';
import { Text } from '@twilio-paste/text';
import { Spinner } from '@twilio-paste/spinner';

type LoadingMoreProps = {
    text?: string;
    loaderRef: MutableRefObject<null>;
};

export function LoadingMore({ loaderRef, text = 'Loading more' }: LoadingMoreProps) {
    return (
        <Box
            ref={loaderRef}
            display="flex"
            justifyContent="center"
            alignItems="center"
            height={60}
            width="100%"
            paddingLeft="space50"
            paddingRight="space50">
            <Spinner decorative={false} color="colorTextWeak" title={text} />
            <Text
                as="span"
                color="colorTextWeak"
                fontSize="fontSize30"
                fontWeight="fontWeightMedium"
                lineHeight="lineHeight20"
                marginLeft="space30">
                {text}
            </Text>
        </Box>
    );
}
