import { Analytics as SharedAnalytics, AnalyticsClass } from '@twilio/frontline-shared/analytics';
import { SENTRY_ID } from '@twilio/frontline-shared/analytics/sentry';
import { AnalyticEvent } from './type';
import { SentryService } from './sentry';
import { SegmentService } from './segment';

export const Analytics = SharedAnalytics as AnalyticsClass<AnalyticEvent>;

export function initializeAnalytics() {
    const analysticsInitArray = [
        { id: SENTRY_ID, service: new SentryService() },
        { id: 'segment', service: new SegmentService() },
    ];
    Analytics.init(analysticsInitArray);
}
