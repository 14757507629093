import { put, select, call } from '@redux-saga/core/effects';

import { Conversation } from '@twilio/frontline-shared/models/Conversation';
import {
    ConversationMessage,
    isLocalMessage,
    isMessageFromWhatsApp,
} from '@twilio/frontline-shared/models/Message';
import { isWhatsAppChannel } from '@twilio/frontline-shared/models/Participant';
import {
    markMessagesAsRead,
    saveUnreadMessagesCount,
    updateAppIconBadge,
} from '@twilio/frontline-shared/store/conversations/actions';
import { setWindowExpireTime } from '@twilio/frontline-shared/store/WhatsAppWindowState';
import { selectChannelType } from '@twilio/frontline-shared/store/ParticipantsState';
import { Analytics } from '@twilio/frontline-shared/analytics';
import { selectConversation } from '../conversations/selectors';
import { addMessages, HideMessage, NewMessage, removeMessage, sendMessageSuccess } from './actions';
import { FrontlineSDK } from '../../sdk/FrontlineSDK';
import { selectChannelMessage } from './ChatMessagesState';
import { Attributes } from '../../types';

export function* receiveMessageSaga(action: NewMessage) {
    const { channelSid, message } = action.payload;
    const { activeConversation, identity, channelType } = yield select((state) => ({
        activeConversation: state.ui.activeConversationSid,
        identity: state.chat.session.user?.identity,
        channelType: selectChannelType(state, channelSid),
    }));

    if (isWhatsAppChannel(channelType) && isMessageFromWhatsApp(message)) {
        yield put(setWindowExpireTime({ channelSid, lastMessageAt: message.createdAt }));
    }

    if ((activeConversation && activeConversation === channelSid) || message.author === identity) {
        yield put(markMessagesAsRead({ conversationSid: channelSid, messageIndex: message.index }));
    } else {
        const channel: Conversation = yield select(selectConversation, channelSid);
        yield put(
            saveUnreadMessagesCount({
                conversationSid: channelSid,
                count: (channel.unreadMessagesCount || 0) + 1,
            }),
        );
        yield put(updateAppIconBadge());
    }

    if (message.tempId) {
        yield put(sendMessageSuccess({ channelSid, message }));
    } else {
        yield put(addMessages({ channelSid, messages: [message] }));
    }
}

export function* hideMessageSaga(frontlineSDK: typeof FrontlineSDK, action: HideMessage) {
    const { channelSid, messageSid } = action.payload;

    const message: ConversationMessage | undefined = yield select(
        selectChannelMessage,
        channelSid,
        messageSid,
    );

    if (message === undefined) {
        return;
    }
    yield put(removeMessage({ channelSid, messageSid }));

    if (isLocalMessage(message)) {
        return;
    }

    try {
        const messageAttributes: Attributes = { ...message.attributes, isHidden: true };
        yield call(frontlineSDK.shared!.updateMessageAttributes, {
            conversationSid: channelSid,
            messageIdx: message.index,
            attributes: messageAttributes,
        });
    } catch (err: any) {
        Analytics.logError('HIDING_MESSAGE_FAILED', err);
        yield put(addMessages({ channelSid, messages: [message] }));
    }
}
