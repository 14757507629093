import { useCallback, useRef, useState, useEffect } from 'react';
import { AlertButton, AlertOptions } from 'react-native';
import { useDebouncedCallback } from 'use-debounce';

import { IDraftMedia } from '@twilio/frontline-shared/models/Media';
import { Conversation } from '@twilio/frontline-shared/models/Conversation';
import { Participant } from '@twilio/frontline-shared/models/Participant';
import { ChannelType } from '@twilio/frontline-shared/types/channel';
import { Attributes } from '@twilio/frontline-shared/types';
import { Chat } from '@twilio/frontline-shared/components/Chat/Chat';
import {
    IChatMessage,
    IChatSystemMessage,
    IChatUser,
} from '@twilio/frontline-shared/components/Chat/types';
import { Analytics } from '@twilio/frontline-web/src/analytics';

import { Alert, useAlert } from './Alert';
import { ParticipantAvatar } from './Chat/ParticipantAvatar';
import { MessageMediaContainer } from './Chat/Media/MessageMediaContainer';
import { InputToolbarContainer } from './Chat/InputToolbar/InputToolbarContainer';
import { Composer } from './Chat/InputToolbar/Composer';
import { SendButton } from './Chat/InputToolbar/SendButton';
import { TemplatesModalContainer } from '../pages/Templates/TemplatesModalContainer';
import { MediaConfirmationModal } from './Chat/MediaConfirmationModal';
import { TemplateOnlyComposer } from './Chat/InputToolbar/TemplateOnlyComposer';
import { is24Hour, dateFormatter } from '../utils/date';

export type SendMediaFn = (mediaAttributes: IDraftMedia) => void;

type ChatContainerProps = {
    user: IChatUser;
    customerParticipant?: Participant;
    onSend(message: string, attributes?: Attributes): void;
    onSendMedia: SendMediaFn;
    messages: Array<IChatMessage | IChatSystemMessage>;
    conversationFriendlyName: Conversation['friendlyName'];
    conversationSid: Conversation['sid'];
    channelType: ChannelType;
    draftMessage: string;
    onEndReached(): void;
    saveDraft: (message: string) => void;
    isOutOfWhatsAppWindow: boolean;
    hideSendAttachmentButton: boolean;
    hideInputToolbar?: boolean;
};

export function ChatContainer({
    user,
    customerParticipant,
    onSend,
    onSendMedia,
    messages,
    conversationFriendlyName,
    conversationSid,
    channelType,
    draftMessage,
    onEndReached,
    saveDraft,
    isOutOfWhatsAppWindow,
    hideSendAttachmentButton,
    hideInputToolbar = false,
}: ChatContainerProps) {
    const alert = useAlert();
    const gcRef = useRef<Chat>(null);
    const fileRef = useRef<HTMLInputElement>(null);
    const [isTemplatesModalOpen, setIsTemplatesModalOpen] = useState(false);
    const onSendMessage = (message: string) => {
        onSend(message);
        gcRef.current?.scrollToBottom(false);
        Analytics.logEvent('send_message');
    };

    const onSendMediaConfirmed = (mediaAttributes: IDraftMedia) => {
        onSendMedia(mediaAttributes);
        gcRef.current?.scrollToBottom(false);
        Analytics.logEvent('send_media_message');
    };

    const cacheLastText = useDebouncedCallback((text: string) => {
        saveDraft(text);
    }, 1000);

    const onInputTextChanged = (text: string) => {
        cacheLastText(text);
    };

    useEffect(() => {
        return () => {
            cacheLastText.flush();
        };
    }, [conversationSid]);

    const onTemplateSelected = (text: string) => {
        if (isOutOfWhatsAppWindow) {
            onSend(text, { whatsAppApprovedTemplate: true });
            gcRef.current?.scrollToBottom(false);
            //      Analytics.logEvent('send_whatsapp_template');
        } else {
            gcRef.current?.onInputTextChanged(text);
        }
        setIsTemplatesModalOpen(false);
    };

    const handleOpenTemplateModal = () => {
        // todo: ?
        setIsTemplatesModalOpen(true);
    };

    const onAttachmentsPress = () => {
        fileRef.current?.click();
        //   Analytics.logEvent('click_attachments');
    };

    const onPressActionButton = useCallback(() => {
        handleOpenTemplateModal();
        //    Analytics.logEvent('open_templates');
    }, []);

    const onAlert = (
        title: string,
        message?: string,
        buttons?: AlertButton[],
        options?: AlertOptions,
    ) => {
        alert.showAlert({ title, message, buttons, options });
    };

    return (
        <>
            <MediaConfirmationModal
                fileRef={fileRef}
                onSendMedia={onSendMediaConfirmed}
                channelType={channelType}
            />

            <TemplatesModalContainer
                isOpen={isTemplatesModalOpen}
                handleClose={() => setIsTemplatesModalOpen(false)}
                onTemplateSelected={onTemplateSelected}
                isOutOfWhatsAppWindow={isOutOfWhatsAppWindow}
                conversationSid={conversationSid}
                channelType={channelType}
            />

            <Chat
                ref={gcRef}
                text={draftMessage}
                messages={messages as IChatMessage[]}
                isOutOfWhatsAppWindow={isOutOfWhatsAppWindow}
                hideSendAttachmentButton={hideSendAttachmentButton}
                channelName={conversationFriendlyName}
                user={user}
                customerParticipant={customerParticipant}
                onEndReached={onEndReached}
                onSend={onSendMessage}
                onInputTextChanged={onInputTextChanged}
                onAttachmentsPress={onAttachmentsPress}
                onTemplatesPress={handleOpenTemplateModal}
                onPressActionButton={onPressActionButton}
                timeFormat={is24Hour() ? 'HH:mm' : 'hh:mm a'}
                dateFormatter={dateFormatter}
                hideInputToolbar={hideInputToolbar}
                ParticipantAvatar={ParticipantAvatar}
                MessageMedia={MessageMediaContainer}
                InputToolbarContainer={InputToolbarContainer}
                Composer={Composer}
                SendButton={SendButton}
                TemplateOnlyComposer={TemplateOnlyComposer}
                onAlert={onAlert}
            />
            <Alert {...alert} />
        </>
    );
}
