import { getImageExtension } from '@twilio/frontline-shared/utils/compression';
import { bytesToMb } from '@twilio/frontline-shared/utils/filesize';
import { createResizedImage } from './imageResizer';
import { createObjectURL } from './file';

export function hasImageExtension(name: string) {
    const extensions = ['jpg', 'jpeg', 'gif', 'png'];
    return extensions.includes(getImageExtension(name));
}

export const getImageDimensionsFromDataURL = (dataURL: string) =>
    new Promise<{ width: number; height: number }>((resolve) => {
        const img = new Image();
        img.onload = () => {
            resolve({
                width: img.width,
                height: img.height,
            });
        };
        img.src = dataURL;
    });

export const compressImage = (
    file: File,
    maxWidth: number,
    maxHeight: number,
    output: 'jpg' | 'jpeg' | 'png',
) => {
    const compressFormat = ['jpg', 'jpeg'].includes(output) ? 'JPEG' : 'PNG';
    return new Promise<{ image: string; size: number }>((res, rej) => {
        try {
            createResizedImage(
                file,
                maxWidth,
                maxHeight,
                compressFormat,
                compressFormat === 'JPEG' ? 80 : 100,
                (uri) => {
                    res({ image: createObjectURL(uri as File), size: (uri as File).size });
                },
                'file',
            );
        } catch (e) {
            rej(e);
        }
    });
};

export const compressImageInTwoStages = async (
    ref: File,
    scaledDimension: number,
    extension: 'png' | 'jpg',
    channelSizeLimit: number,
) => {
    let { image: result, size } = await compressImage(
        ref,
        scaledDimension,
        scaledDimension,
        extension,
    );
    const compressedSizeToMb = bytesToMb(size);
    if (compressedSizeToMb > channelSizeLimit) {
        const coefficientBuffer = 1.2;
        const resolutionRatio =
            Math.sqrt(compressedSizeToMb / channelSizeLimit) * coefficientBuffer;
        const updatedMaxDimension = Math.floor(scaledDimension / resolutionRatio);
        const combinedResult = await compressImage(
            ref,
            updatedMaxDimension,
            updatedMaxDimension,
            extension,
        );
        result = combinedResult.image;
        size = combinedResult.size;
    }
    return { result, size };
};
