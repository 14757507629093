import { useState, useEffect } from 'react';
import { Customer } from '@twilio/frontline-shared/types/customer';
import { getCustomerDetailsByCustomerId } from '@twilio/frontline-shared/core/Callbacks/CRMCallbacks';

export enum CustomerState {
    Pending = 'pending',
    Resolved = 'resolved',
    NoCustomer = 'noCustomer',
    Rejected = 'rejected',
}

export function useCustomer(customerId: string) {
    const [state, setState] = useState<CustomerState>(CustomerState.Pending);
    const [customer, setCustomer] = useState<Customer | null>(null);

    const requestArgs = { payload: { CustomerId: customerId } };

    const getCustomerById = () =>
        getCustomerDetailsByCustomerId(requestArgs)
            .then((customerData) => {
                if (customerData) {
                    setCustomer(customerData);
                    setState(CustomerState.Resolved);
                } else {
                    setCustomer(null);
                    setState(CustomerState.NoCustomer);
                }
            })
            .catch((e) => {
                console.log('Fetch customer details failed: ', e);
                setState(CustomerState.Rejected);
            });

    const onReload = () => {
        setState(CustomerState.Pending);
        getCustomerById();
    };

    useEffect(() => {
        getCustomerById();
    }, [customerId]);

    return { state, customer, onReload };
}
