export function createMailLink(
    email: string,
    subject: string,
    deviceInfo: { [key: string]: string },
) {
    let body = '\n\n\n\n\n\n';
    body += '============================\n';
    Object.keys(deviceInfo).forEach((k) => {
        body += `${[k]}: ${deviceInfo[k]}\n`;
    });
    body += '============================';
    return `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
        body,
    )}`;
}
