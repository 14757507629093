import { useHistory, useParams } from 'react-router-dom';

import { Box } from '@twilio-paste/box';
import { Flex } from '@twilio-paste/flex';
import { Text } from '@twilio-paste/text';

import { useConversationsUser } from '@twilio/frontline-shared/store/users/ConversationsUsersState';
import { useDispatch } from '@twilio/frontline-shared/store/redux';

import { useEffect } from 'react';
import { fromTwilioUser } from '@twilio/frontline-shared/models/User';
import { upsertConversationsUser } from '@twilio/frontline-shared/store/users/actions';
import { useQuery } from '../../hooks/useQuery';
import { useUserConversations } from '../../hooks/useUserConversations';
import { UserConversationsHeader } from './components/UserConversationsHeader';
import { UserConversationsList } from './components/UserConversationsList';
import { UserConversation } from './components/UserConversation';
import { useToasterContext } from '../../context/ToasterContext';
import { userNotFoundErrorToast } from './components/toasts';
import { PAGES } from '../../paths';
import { useSession } from '../../context/SessionContext';

const CONVERSATIONS_LIST_WIDTH = 320;

export function UserConversations() {
    const { identity } = useParams<{ identity: string }>();
    const navHistory = useHistory();
    const dispatch = useDispatch();
    const { session } = useSession();
    const toaster = useToasterContext();
    const user = useConversationsUser(identity);
    useEffect(() => {
        if (!user) {
            session?.frontlineSDK
                .getUser(identity)
                .then((twilioUser) =>
                    dispatch(upsertConversationsUser({ user: fromTwilioUser(twilioUser) })),
                )
                .catch((e) => {
                    if (e.status && e.status === 404) {
                        toaster.push(userNotFoundErrorToast(identity));
                        navHistory.replace(PAGES.DEFAULT);
                    }
                });
        }
    }, [identity]);
    const query = useQuery();
    const conversationId = query.get('conversation_id');
    const {
        state: userConversationsState,
        conversations,
        onReload,
    } = useUserConversations(identity);

    return (
        <Flex width="100%" height="100%">
            <Flex vertical minWidth={CONVERSATIONS_LIST_WIDTH} width="25%" height="100%">
                <Box
                    display="flex"
                    flexDirection="column"
                    width="100%"
                    height="100%"
                    alignItems="stretch"
                    borderRightWidth="borderWidth10"
                    borderRightStyle="solid"
                    borderRightColor="colorBorderWeak">
                    <UserConversationsHeader user={user} />

                    <UserConversationsList
                        conversations={conversations}
                        identity={identity}
                        selectedConversationId={conversationId}
                        userConversationsState={userConversationsState}
                        onUserConversationsReload={onReload}
                    />
                </Box>
            </Flex>
            <Flex width="75%" height="100%" vAlignContent="stretch" hAlignContent="center">
                {conversationId ? (
                    <UserConversation conversationSid={conversationId} userIdentity={identity} />
                ) : (
                    <Box width="100%" backgroundColor="colorBackground">
                        <Flex vAlignContent="center" hAlignContent="center" height="100%">
                            <Text color="colorTextWeak" as="p" textAlign="center">
                                Select a conversation
                            </Text>
                        </Flex>
                    </Box>
                )}
            </Flex>
        </Flex>
    );
}
