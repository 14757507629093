import { createStore as createReduxStore, applyMiddleware, Reducer } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware, { SagaMiddleware, Saga } from 'redux-saga';
import { sentryMiddleware } from '../analytics/sentryMiddleware';

import { FrontlineSDK } from '../sdk/FrontlineSDK';

import { Action } from './Action';
import { logout } from './ui/actions';

const rootReducer = (appReducer: Reducer) => (state: any | undefined, action: Action) => {
    // when a logout action is dispatched it will reset redux state
    if (action.type === logout.type) {
        state = undefined;
    }
    return appReducer(state, action);
};

export function createFrontlineStore<T extends Record<string, any>>({
    sagaMiddleware,
    appReducer,
    rootSaga,
    state,
    frontlineSDK,
    isDev,
}: {
    sagaMiddleware?: SagaMiddleware;
    appReducer: Reducer;
    rootSaga: Saga;
    state: T | undefined;
    frontlineSDK: typeof FrontlineSDK;
    isDev?: boolean;
}) {
    const _sagaMiddleware = sagaMiddleware || createSagaMiddleware();
    let middleware = applyMiddleware(sentryMiddleware, _sagaMiddleware);
    const reducer = rootReducer(appReducer);
    if (isDev) {
        middleware = composeWithDevTools(middleware);
    }
    const store = createReduxStore(reducer, state, middleware);
    _sagaMiddleware.run(rootSaga, frontlineSDK!);
    return store;
}

/* export function createFrontlineStore<T>(
    state: T | undefined,
    appReducer: Reducer,
    rootSaga: Saga,
    frontlineSDK?: typeof FrontlineSDK,
) {
    return _createFrontlineStore<T>(sagaMiddleware, appReducer, rootSaga, state, frontlineSDK);
}
 */
