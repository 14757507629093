import { Conversation } from '@twilio/frontline-shared/models/Conversation';
import { ExtendsChatState } from '@twilio/frontline-shared/store/ChatState';
import { ConversationSid, UserIdentity } from '@twilio/frontline-shared/types';

const selectAllConversations = (state: ExtendsChatState) => state.chat.channels.allConversations;

export const selectMyConversationSids = (state: ExtendsChatState): string[] => {
    return state.chat.channels.myConversations;
};

export const selectMyConversations = (state: ExtendsChatState): Conversation[] => {
    return selectMyConversationSids(state)
        .map((conversationSid) => state.chat.channels.allConversations[conversationSid])
        .filter((conversation): conversation is Conversation => !!conversation);
};

export const selectConversation = (
    state: ExtendsChatState,
    conversationSid: string,
): Conversation | undefined => selectAllConversations(state)[conversationSid];

export const selectUserConversationsSids = (
    state: ExtendsChatState,
    userIdentity: UserIdentity,
): ConversationSid[] => {
    return state.chat.channels.userConversations[userIdentity] || [];
};
