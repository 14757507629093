import { put, call } from '@redux-saga/core/effects';

import {
    LoadDrafts,
    initDrafts,
    SaveDraft,
    setDraft,
} from '@twilio/frontline-shared/store/draft/actions';
import { DraftMap, DraftStorageManager } from '../../core/DraftStorageManager';

export function* loadDraftsSaga(action: LoadDrafts) {
    try {
        const drafts: DraftMap = yield call(DraftStorageManager.getAll);
        yield put(initDrafts({ drafts }));
    } catch (err) {
        console.log('DraftManager getAll failed', err);
    }
}

export function* saveDraftSaga(action: SaveDraft) {
    const { channelSid, message } = action.payload;
    yield call(DraftStorageManager.saveDraft, channelSid, message);
    yield put(setDraft({ channelSid, message }));
}
