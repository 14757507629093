import { useState, useContext, createContext, ReactNode } from 'react';

import { Session } from '../core/Session';

export interface SessionContextProps {
    session?: Session;
    setSession: (session?: Session) => void;
}

export const SessionContext = createContext<SessionContextProps>({
    session: undefined,
    setSession: () => {},
});

export function SessionProvider({ session, children }: { session?: Session; children: ReactNode }) {
    const [sessionState, setSessionState] = useState<SessionContextProps>({
        session,
        setSession: (newSessionState?: Session) =>
            setSessionState((prevState) => ({ ...prevState, session: newSessionState })),
    });

    return <SessionContext.Provider value={sessionState}>{children}</SessionContext.Provider>;
}

export function useSession() {
    return useContext(SessionContext);
}
