import React, { RefObject } from 'react';
import { Platform, StyleSheet, View, FlatList, TextInput } from 'react-native';
import { ActionSheetProvider } from '@expo/react-native-action-sheet';
import { min } from 'ramda';

import { Participant } from '@twilio/frontline-shared/models/Participant';

import { COMPOSER_LINE_HEIGHT } from './Composer';
import { InputToolbar } from './InputToolbar';
import { MessagesList } from './MessagesList';
import { AudioRecorderContainerProps, IChatMessage, IChatUser } from './types';
import { ChatContext, IChatContext } from './ChatContext';
import { IDraftMedia } from '../../models/Media';

const MIN_COMPOSER_HEIGHT = 44;
const MAX_COMPOSER_HEIGHT = 84;

export type ChatProps = {
    /* Messages to display */
    messages?: IChatMessage[];
    /* Initial input text; default is undefined, will be overriden by internal state on input change */
    text?: string;
    /* Is WhatsApp 24H window is enabled or not */
    isOutOfWhatsAppWindow: boolean;
    /* Is sending attachment button shown or not */
    hideSendAttachmentButton: boolean;
    /* Conversation participant that is representing Customer */
    customerParticipant?: Participant;
    /* Name of a channel that conversation is happening on with a customer */
    channelName: string;
    /* User sending the messages */
    user?: IChatUser;
    /* Callback when sending a message */
    onSend?(message: string): void;
    onSendMedia?(media: IDraftMedia): void;
    /* Callback when scrolled to the end of messages list */
    onEndReached: () => void;
    /* Callback when attachments button clicked */
    onAttachmentsPress: () => void;
    /* Callback when templates button clicked */
    onTemplatesPress: () => void;
    /* Callback when the Action button is pressed (if set, the default actionSheet will not be used) */
    onPressActionButton?(): void;
    /* Callback when the input text changes */
    onInputTextChanged?(text: string): void;

    dateFormatter: (date: Date) => string;

    hideInputToolbar?: boolean;

    timeFormat: string;

    AudioRecorder?: React.FC<AudioRecorderContainerProps>;
} & IChatContext;

export interface ChatState {
    composerHeight?: number;
    typingDisabled: boolean;
    text?: string;
    messages: IChatMessage[];
}

export class Chat extends React.Component<ChatProps, ChatState> {
    static defaultProps = {
        messages: [],
        text: undefined,
        user: {},
        onSend: () => {},
        onPressActionButton: () => {},
        onInputTextChanged: null,
    };

    _isMounted = false;

    _messageContainerRef: RefObject<FlatList<IChatMessage>> = React.createRef();

    _isTextInputWasFocused = false;

    _textInputRef: RefObject<TextInput> = React.createRef();

    state = {
        composerHeight: MIN_COMPOSER_HEIGHT,
        typingDisabled: false,
        text: undefined,
        messages: [],
    };

    componentDidMount() {
        const { messages, text } = this.props;
        this.setIsMounted(true);
        this.setMessages(messages || []);
        this.setTextFromProp(text);
    }

    componentDidUpdate(prevProps: ChatProps) {
        const { messages } = this.props;

        if (messages !== prevProps.messages) {
            this.setMessages(messages || []);
        }
    }

    componentWillUnmount() {
        this.setIsMounted(false);
    }

    /**
     * Store text input focus status when keyboard hide to retrieve
     * it after wards if needed.
     * `onKeyboardWillHide` may be called twice in sequence so we
     * make a guard condition (eg. showing image picker)
     */
    handleTextInputFocusWhenKeyboardHide() {
        if (!this._isTextInputWasFocused) {
            this._isTextInputWasFocused = this._textInputRef?.current?.isFocused() || false;
        }
    }

    /**
     * Refocus the text input only if it was focused before showing keyboard.
     * This is needed in some cases (eg. showing image picker).
     */
    handleTextInputFocusWhenKeyboardShow() {
        if (this._isTextInputWasFocused && !this._textInputRef?.current?.isFocused()) {
            this.focusTextInput();
        }

        // Reset the indicator since the keyboard is shown
        this._isTextInputWasFocused = false;
    }

    setTextFromProp(textProp?: string) {
        // Text prop takes precedence over state.
        if (textProp !== undefined && textProp !== this.state.text) {
            this.setState({ text: textProp });
        }
    }

    setMessages(messages: IChatMessage[]) {
        this.setState({ messages });
    }

    getMessages() {
        return this.state.messages;
    }

    setIsTypingDisabled(value: boolean) {
        this.setState({
            typingDisabled: value,
        });
    }

    getIsTypingDisabled() {
        return this.state.typingDisabled;
    }

    setIsMounted(value: boolean) {
        this._isMounted = value;
    }

    getIsMounted() {
        return this._isMounted;
    }

    onKeyboardWillShow = () => {
        this.handleTextInputFocusWhenKeyboardShow();
        this.setIsTypingDisabled(true);
    };

    onKeyboardWillHide = () => {
        this.handleTextInputFocusWhenKeyboardHide();
        this.setIsTypingDisabled(true);
    };

    onKeyboardDidShow = () => {
        if (Platform.OS === 'android') {
            this.onKeyboardWillShow();
        }
        this.setIsTypingDisabled(false);
    };

    onKeyboardDidHide = () => {
        if (Platform.OS === 'android') {
            this.onKeyboardWillHide();
        }
        this.setIsTypingDisabled(false);
    };

    onSend = (message: string) => {
        this.setIsTypingDisabled(true);
        this.resetInputToolbar();

        if (this.props.onSend) {
            this.props.onSend(message);
        }

        setTimeout(() => {
            if (this.getIsMounted() === true) {
                this.setIsTypingDisabled(false);
            }
        }, 100);
    };

    onInputSizeChanged = (contentSize: { width: number; height: number }) => {
        const lines = Math.round(contentSize.height / COMPOSER_LINE_HEIGHT);
        const height = min(
            MIN_COMPOSER_HEIGHT + (lines - 1) * COMPOSER_LINE_HEIGHT,
            MAX_COMPOSER_HEIGHT,
        );

        this.setState({
            composerHeight: height,
        });
    };

    onInputTextChanged = (text: string) => {
        if (this.getIsTypingDisabled()) {
            return;
        }
        if (this.props.onInputTextChanged) {
            this.props.onInputTextChanged(text);
        }

        this.setState({ text });
    };

    resetInputToolbar() {
        this._textInputRef?.current?.clear();

        this.notifyInputTextReset();
        const newComposerHeight = MIN_COMPOSER_HEIGHT;

        this.setState({
            text: '',
            composerHeight: newComposerHeight,
        });
    }

    focusTextInput() {
        this._textInputRef?.current?.focus();
    }

    notifyInputTextReset() {
        if (this.props.onInputTextChanged) {
            this.props.onInputTextChanged('');
        }
    }

    // eslint-disable-next-line react/no-unused-class-component-methods
    scrollToBottom(animated = true) {
        if (this._messageContainerRef.current) {
            this._messageContainerRef.current.scrollToOffset({
                offset: 0,
                animated,
            });
        }
    }

    renderMessages() {
        return (
            <View style={styles.messagesContainer}>
                <MessagesList
                    forwardRef={this._messageContainerRef}
                    user={this.props.user}
                    messages={this.getMessages()}
                    customerParticipant={this.props.customerParticipant}
                    onKeyboardWillShow={this.onKeyboardWillShow}
                    onKeyboardWillHide={this.onKeyboardWillHide}
                    onKeyboardDidShow={this.onKeyboardDidShow}
                    onKeyboardDidHide={this.onKeyboardDidHide}
                    onEndReached={this.props.onEndReached}
                />
            </View>
        );
    }

    renderInputToolbar() {
        if (this.props.hideInputToolbar) {
            return null;
        }
        return (
            <InputToolbar
                inputRef={this._textInputRef}
                text={this.state.text || ''}
                channelName={this.props.channelName}
                isOutOfWhatsAppWindow={this.props.isOutOfWhatsAppWindow}
                hideSendAttachmentButton={this.props.hideSendAttachmentButton}
                editing={!!this.state.text}
                onSend={this.onSend}
                composerHeight={Math.max(MIN_COMPOSER_HEIGHT, this.state.composerHeight!)}
                maxLength={this.getIsTypingDisabled() ? 0 : undefined}
                onPressActionButton={this.props.onPressActionButton} // templates
                AudioRecorder={this.props.AudioRecorder}
                onAttachmentsPress={this.props.onAttachmentsPress}
                onTemplatesPress={this.props.onTemplatesPress} // WA only templates
                onInputSizeChanged={this.onInputSizeChanged}
                onTextChanged={this.onInputTextChanged}
            />
        );
    }

    render() {
        const {
            timeFormat,
            MessageMedia,
            ParticipantAvatar,
            InputToolbarContainer,
            Actions,
            Composer,
            SendButton,
            TemplateOnlyComposer,
            onAlert,
            dateFormatter,
            audioRecordingEnabled,
            onSendMedia,
            onShowRecorderAttempt,
            conversationSid,
        } = this.props;
        return (
            <ChatContext.Provider
                value={{
                    timeFormat,
                    MessageMedia,
                    ParticipantAvatar,
                    InputToolbarContainer,
                    Actions,
                    Composer,
                    SendButton,
                    TemplateOnlyComposer,
                    onAlert,
                    onSendMedia,
                    onShowRecorderAttempt,
                    dateFormatter,
                    audioRecordingEnabled,
                    conversationSid,
                }}>
                <View style={styles.safeArea}>
                    <ActionSheetProvider>
                        <View style={styles.container}>
                            {this.renderMessages()}
                            {this.renderInputToolbar()}
                        </View>
                    </ActionSheetProvider>
                </View>
            </ChatContext.Provider>
        );
    }
}

const styles = StyleSheet.create({
    messagesContainer: {
        flex: 1,
    },
    container: {
        flex: 1,
        flexDirection: 'column',
    },
    safeArea: {
        flex: 1,
    },
});
