import { Flex } from '@twilio-paste/flex';

import { DeveloperGuidance } from './DeveloperGuidance';
import { InfoBox } from '../../../components/InfoBox';

type EmptyCustomersListViewProps = {
    title: string;
    text: string;
    guidanceText?: string;
    offsetHeight?: number;
    onReload: () => void;
};

export function EmptyCustomersListView({
    title,
    text,
    guidanceText,
    offsetHeight = 0,
    onReload,
}: EmptyCustomersListViewProps) {
    return (
        <Flex vertical width="100%" height={`calc(100% - ${offsetHeight}px)`} padding="space50">
            <Flex vertical grow width="100%" vAlignContent="center" hAlignContent="center">
                <InfoBox title={title} text={text} showButton onClick={onReload} />
            </Flex>

            {guidanceText && (
                <DeveloperGuidance
                    text={guidanceText}
                    docUrl="https://www.twilio.com/docs/frontline/my-customers"
                />
            )}
        </Flex>
    );
}
