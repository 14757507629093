import { Reducer } from 'redux';
import { useSelector } from 'react-redux';
import { uniqBy, prop, lensPath, set, findIndex, propEq, sortBy } from 'ramda';

import { ConversationMessage, MessageStatus } from '@twilio/frontline-shared/models/Message';
import { indexExists } from '@twilio/frontline-shared/utils/array';
import { ExtendsChatState } from '../ChatState';
import {
    ChatMessagesActions,
    addMessages,
    sendMessageFailure,
    sendMessageRequest,
    sendMessageSuccess,
    updateMessageStatus,
    setMessageError,
    removeMessage,
} from './actions';

export type ChatMessagesState = { [key: string]: Array<ConversationMessage> };

export const initialState: ChatMessagesState = {};

export const reduce: Reducer<ChatMessagesState, ChatMessagesActions> = (
    state = initialState,
    action,
) => {
    switch (action.type) {
        case addMessages.getType(): {
            const { channelSid, messages: messagesToAdd } = action.payload;

            const existingMessages = state[channelSid] || [];
            const messages = uniqBy(prop('sid'), [...existingMessages, ...messagesToAdd]);
            const sortedMessages = sortBy(prop('createdAt'), messages);

            return { ...state, [channelSid]: sortedMessages };
        }
        case removeMessage.getType(): {
            const { channelSid, messageSid } = action.payload;

            const existingMessages = state[channelSid] || [];
            const filteredMessages = existingMessages.filter(
                (message) => message.sid !== messageSid,
            );

            return { ...state, [channelSid]: filteredMessages };
        }
        case sendMessageRequest.getType(): {
            const { channelSid, message } = action.payload;

            const newMessage = { ...message, status: MessageStatus.Sending };
            const messages = state[channelSid] || [];
            const messageIndex = findIndex(propEq('sid', newMessage.sid), messages);

            let newMessages: Array<ConversationMessage>;
            if (messageIndex >= 0) {
                const messageLens = lensPath([messageIndex]);
                newMessages = set(messageLens, newMessage, messages);
            } else {
                newMessages = messages.concat(newMessage);
            }

            return { ...state, [channelSid]: newMessages };
        }
        case sendMessageSuccess.getType(): {
            const { channelSid, message } = action.payload;

            const messages = state[channelSid];
            const messageIndex = findIndex(propEq('sid', message.tempId), messages);

            let newMessages: Array<ConversationMessage>;
            if (indexExists(messageIndex)) {
                const messageLens = lensPath([messageIndex]);
                newMessages = set(messageLens, message, messages);
            } else {
                newMessages = messages.concat(message);
            }

            return { ...state, [channelSid]: newMessages };
        }
        case sendMessageFailure.getType(): {
            const { channelSid, id } = action.payload;

            const messages = state[channelSid];
            const messageIndex = findIndex(propEq('sid', id), messages);

            if (indexExists(messageIndex)) {
                const messageStatusLens = lensPath([messageIndex, 'status']);
                const newMessages = set(messageStatusLens, MessageStatus.Failed, messages);

                return { ...state, [channelSid]: newMessages };
            }

            return state;
        }
        case updateMessageStatus.getType(): {
            const { channelSid, messageSid, status } = action.payload;

            const messages = state[channelSid];
            const messageIndex = findIndex(propEq('sid', messageSid), messages);

            if (indexExists(messageIndex)) {
                const messageStatusLens = lensPath([messageIndex, 'status']);
                const newMessages = set(messageStatusLens, status, messages);

                return { ...state, [channelSid]: newMessages };
            }

            return state;
        }
        case setMessageError.getType(): {
            const { channelSid, messageSid, status, errorCode } = action.payload;

            const messages = state[channelSid];
            const messageIndex = findIndex(propEq('sid', messageSid), messages);

            if (indexExists(messageIndex)) {
                const messageStatusLens = lensPath([messageIndex, 'status']);
                const errorCodeLens = lensPath([messageIndex, 'errorCode']);
                let newMessages = set(messageStatusLens, status, messages);
                newMessages = set(errorCodeLens, errorCode, newMessages);

                return { ...state, [channelSid]: newMessages };
            }

            return state;
        }
        default:
            return state;
    }
};

export const selectChannelMessages = (
    state: ExtendsChatState,
    channelSid: string,
): ConversationMessage[] => state.chat.messages[channelSid] || [];

export const selectChannelMessage = (
    state: ExtendsChatState,
    channelSid: string,
    messageSid: string,
): ConversationMessage | undefined =>
    selectChannelMessages(state, channelSid).find((message) => message.sid === messageSid);

export const useMessages = (): ChatMessagesState => {
    return useSelector<ExtendsChatState, ChatMessagesState>((state) => state.chat.messages);
};

export const useChannelMessages = (channelSid: string): ConversationMessage[] => {
    return useSelector<ExtendsChatState, ConversationMessage[]>((state) =>
        selectChannelMessages(state, channelSid),
    );
};
