import { useSelector } from 'react-redux';

import { createAction, createReducer } from '@reduxjs/toolkit';

export const internetConnectionStateChanged = createAction<boolean | null>(
    'INTERNET_CONNECTION_CHANGED',
);

export type InternetConnectionState = boolean | null;

type ExtendsInternetConnectionState = { connection: InternetConnectionState };

export const initialState: InternetConnectionState = true;

export const reduce = createReducer<InternetConnectionState>(initialState, (builder) => {
    builder.addCase(internetConnectionStateChanged, (state, action) => {
        return action.payload;
    });
});

const selectInternetConnection = (state: ExtendsInternetConnectionState) => state.connection;
export const useInternetConnection = () =>
    useSelector<ExtendsInternetConnectionState, boolean | null>(selectInternetConnection);
