import React, { PureComponent } from 'react';
import { Platform, Text, TextProps, TextStyle } from 'react-native';

export const DEFAULT_ICON_SIZE = 12;
export const DEFAULT_ICON_COLOR = 'black';

export type IconProps = TextProps & {
    name: string;
    size: number;
    color: string;
};

export default function createIconSetFromIcoMoon(
    config: any,
    fontFamilyArg?: string,
    fontFile?: string,
) {
    const glyphMap: { [key: string]: string } = {};
    config.icons.forEach((icon: any) => {
        icon.properties.name.split(/\s*,\s*/g).forEach((name: string) => {
            glyphMap[name] = icon.properties.code;
        });
    });

    const fontFamily = fontFamilyArg || config.preferences.fontPref.metadata.fontFamily;

    return createIconSet(glyphMap, fontFamily, fontFile || `${fontFamily}.ttf`);
}

function createIconSet(glyphMap: { [key: string]: string }, fontFamily: string, fontFile: string) {
    // Android doesn't care about actual fontFamily name, it will only look in fonts folder.
    const fontBasename = fontFile ? fontFile.replace(/\.(otf|ttf)$/, '') : fontFamily;

    const fontReference = Platform.select({
        windows: `/Assets/${fontFile}#${fontFamily}`,
        android: fontBasename,
        web: fontBasename,
        default: fontFamily,
    });

    class Icon extends PureComponent<IconProps> {
        render() {
            const { name, size, color, style, children, ...props } = this.props;

            let glyph = name ? glyphMap[name] || '?' : '';
            if (typeof glyph === 'number') {
                glyph = String.fromCodePoint(glyph);
            }

            const styleDefaults: TextStyle = {
                fontSize: size || DEFAULT_ICON_SIZE,
                color: color || DEFAULT_ICON_COLOR,
            };

            const styleOverrides: TextStyle = {
                fontFamily: fontReference,
                fontWeight: 'normal',
                fontStyle: 'normal',
            };

            return (
                <Text
                    {...props}
                    allowFontScaling={props.allowFontScaling || false}
                    style={[styleDefaults, style, styleOverrides]}>
                    {glyph}
                    {children}
                </Text>
            );
        }
    }

    return Icon;
}
