import { Button } from '@twilio-paste/button';

import { ActionsProps } from '@twilio/frontline-shared/components/Chat/types';
import { TwilIcon } from '@twilio/frontline-shared/components/TwilIcon';
import { DefaultTheme } from '@twilio/frontline-shared/theme';

export function Actions({ onPress }: ActionsProps) {
    return (
        <Button variant="primary_icon" size="reset" onClick={onPress}>
            <TwilIcon name="templates" size={20} color={DefaultTheme.colors.colorGray60} />
        </Button>
    );
}
