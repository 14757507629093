import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useDispatch } from '@twilio/frontline-shared/store/redux';
import {
    useChannelType,
    useConversationCustomer,
    useConversationParticipants,
    useUserParticipant,
} from '@twilio/frontline-shared/store/ParticipantsState';
import { isWhatsAppChannel } from '@twilio/frontline-shared/models/Participant';
import { useChatUser } from '@twilio/frontline-shared/store/ChatSessionState';
import { useChannelMessages } from '@twilio/frontline-shared/store/messages/ChatMessagesState';
import { useConversation } from '@twilio/frontline-shared/store/conversations/hooks';
import { useChannelDraft } from '@twilio/frontline-shared/store/draft/DraftState';
import {
    isWindowClosed,
    useWhatsAppWindowState,
} from '@twilio/frontline-shared/store/WhatsAppWindowState';
import { getLastChatReadTime } from '@twilio/frontline-shared/models/Message';
import {
    getLastReadMessageTime,
    shouldReadConversation,
} from '@twilio/frontline-shared/components/Chat/utils';
import { useConversationsUsers } from '@twilio/frontline-shared/store/users/ConversationsUsersState';
import { getUnreadMessagesCount } from '@twilio/frontline-shared/models/Conversation';
import { openConversationScreen } from '@twilio/frontline-shared/store/ui/actions';
import { useConsumptionHorizon } from '@twilio/frontline-shared/hooks/useConsumptionHorizon';
import { useForceUpdate } from '@twilio/frontline-shared/hooks/useForceUpdate';
import { useOnConversationRemoved } from '@twilio/frontline-shared/hooks/useOnConversationRemoved';
import { BETA_FEATURE_FLAGS } from '@twilio/frontline-shared/sdk/FrontlineSDK';
import { readConversation } from '@twilio/frontline-shared/store/conversations/actions';
import { isNumber } from '@twilio/frontline-shared/utils/isNumber';
import { ConversationViewVariant } from './types';

import { useSession } from '../../context/SessionContext';
import { useWindowFocus } from '../../hooks/useWindowFocus';
import { ConversationView } from './Conversation';
import { PAGES } from '../../paths';

const ONE_SECOND = 1000;

export type ConversationContainerProps = {
    conversationSid: string;
    hideInputToolbar?: boolean;
    onConversationHeaderClick?: () => void;
    fetchConversation?: () => Promise<void>;
} & (
    | { variant: ConversationViewVariant.MANAGER; participantIdentity: string }
    | { variant: ConversationViewVariant.USER; participantIdentity?: undefined }
);

export function ConversationContainer({
    conversationSid,
    hideInputToolbar = false,
    variant,
    participantIdentity,
    onConversationHeaderClick,
    fetchConversation,
}: ConversationContainerProps) {
    const [error, setError] = useState(false);
    const forceUpdate = useForceUpdate();
    const dispatch = useDispatch();
    const isFocused = useWindowFocus();
    const { session } = useSession();

    const hideSendAttachmentButton = session?.frontlineSDK.isBetaFlagEnabled(
        BETA_FEATURE_FLAGS.HIDE_SEND_ATTACHMENT_BUTTON,
    );
    const user = useChatUser();
    const history = useHistory();
    const conversation = useConversation(conversationSid);
    const channelType = useChannelType(conversationSid);
    const users = useConversationsUsers();
    const userParticipant = useUserParticipant(conversationSid);
    const messages = useChannelMessages(conversationSid);
    const customerParticipant = useConversationCustomer(conversationSid);
    const participants = useConversationParticipants(conversationSid);

    useEffect(() => {
        if (variant === ConversationViewVariant.MANAGER) {
            return;
        }
        if (isFocused && conversation) {
            dispatch(openConversationScreen({ channelSid: conversationSid }));
        } else {
            // For handling opening modals/customer details
            dispatch(openConversationScreen({ channelSid: undefined }));
        }
    }, [isFocused, conversationSid, !conversation, variant]);

    useEffect(() => {
        return () => {
            // For handling going back to customer details
            dispatch(openConversationScreen({ channelSid: undefined }));

            // For stopping the player when leaving the conversation
        };
    }, []);

    const unreadMessagesCount = getUnreadMessagesCount(conversation);
    const hasLastMessage = conversation?.lastMessage.index !== undefined;
    const hasMarkedUnreadAt = isNumber(conversation?.markedUnreadAt);

    useEffect(() => {
        if (variant === ConversationViewVariant.MANAGER) {
            return;
        }
        if (
            shouldReadConversation(
                hasLastMessage,
                hasMarkedUnreadAt,
                conversationSid,
                unreadMessagesCount,
                isFocused,
            )
        ) {
            dispatch(readConversation({ conversationSid }));
        }
    }, [conversationSid, unreadMessagesCount, isFocused, hasLastMessage, hasMarkedUnreadAt]);

    useOnConversationRemoved(conversationSid, session, () => {
        history.replace(PAGES.CONVERSATIONS);
    });

    const lastChatReadTime = getLastChatReadTime(participants, user, channelType);

    const lastReadMessageTime = getLastReadMessageTime(
        conversation?.lastConsumedMessageIndex,
        userParticipant?.lastReadTime || 0,
        messages,
    );
    const [consumptionHorizon, newMessagesCount] = useConsumptionHorizon(
        conversation?.sid,
        unreadMessagesCount,
        lastReadMessageTime,
        messages,
        user.identity,
        conversation?.events || [],
    );

    const conversationRef = useRef(conversation);
    conversationRef.current = conversation;

    const fetchConversationCallback = React.useCallback(() => {
        setError(false);
        if (fetchConversation) {
            fetchConversation().catch(() => {
                setError(true);
            });
        } else {
            session?.fetchMyConversation(conversationSid).catch(() => {
                setError(true);
            });
        }
    }, []);

    useEffect(() => {
        if (!conversationRef.current) {
            fetchConversationCallback();
        }
    }, [conversationSid]);

    const draftMessage = useChannelDraft(conversationSid);
    const whatsAppWindowWillCloseAt = useWhatsAppWindowState(conversationSid);

    useEffect(() => {
        let timeout: NodeJS.Timeout;
        if (whatsAppWindowWillCloseAt && !isWindowClosed(whatsAppWindowWillCloseAt)) {
            const duration = whatsAppWindowWillCloseAt.getTime() - Date.now() + ONE_SECOND;
            timeout = setTimeout(() => {
                forceUpdate();
            }, duration);
        }
        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [whatsAppWindowWillCloseAt]);

    const passedParticipantIdentity =
        variant === ConversationViewVariant.MANAGER ? participantIdentity! : user.identity;

    return (
        <ConversationView
            user={user}
            channelName={conversation?.friendlyName}
            channelSid={conversation?.sid}
            avatar={conversation?.avatar}
            messages={messages}
            events={conversation?.events || []}
            customerParticipant={customerParticipant}
            consumptionHorizon={consumptionHorizon || 0}
            newMessagesCount={newMessagesCount}
            draftMessage={draftMessage}
            hasOlderMessages={!conversation?.isAllPreviousMessagesFetched}
            state={!conversation ? (error ? 'error' : 'loading') : 'ready'}
            isOutOfWhatsAppWindow={
                isWhatsAppChannel(channelType) && isWindowClosed(whatsAppWindowWillCloseAt)
            }
            hideSendAttachmentButton={Boolean(hideSendAttachmentButton)}
            hideInputToolbar={hideInputToolbar}
            channelType={channelType}
            lastChatReadTime={lastChatReadTime}
            fetchConversation={fetchConversationCallback}
            users={users}
            variant={variant}
            participantIdentity={passedParticipantIdentity}
            onConversationHeaderClick={onConversationHeaderClick}
        />
    );
}
