import { ChannelType } from '@twilio/frontline-shared/types/channel';

export type CreateConversationRequestBody = {
    customer: {
        id: string;
        displayName: string;
        avatar?: string;
    };
    channel: {
        type: ChannelType;
        value: string;
    };
};

export type CreateConversationResponse = {
    sid: string;
};

export enum FrontlineRole {
    User = 'user',
    Manager = 'manager',
}

export type GetRoleResponse = {
    role: FrontlineRole;
};
