import { Stack } from '@twilio-paste/stack';
import { Spinner } from '@twilio-paste/spinner';
import { Text } from '@twilio-paste/text';
import { PrimitiveModalDialog } from './PrimitiveModalDialog';

type WaitingOverlayProps = {
    isOpen: boolean;
    message: string;
};

export function WaitingOverlay({ isOpen, message }: WaitingOverlayProps) {
    return (
        <PrimitiveModalDialog isOpen={isOpen}>
            <Stack orientation="horizontal" spacing="space30">
                <Spinner decorative={false} title="Loading" color="colorTextInverseWeak" />
                <Text as="span" color="colorTextInverse">
                    {message}
                </Text>
            </Stack>
        </PrimitiveModalDialog>
    );
}
