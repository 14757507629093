import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box } from '@twilio-paste/box';
import { Text } from '@twilio-paste/text';
import { Flex } from '@twilio-paste/flex';

import { trimSpaces } from '@twilio/frontline-shared/utils/stringUtils';

import { useCustomers, CustomersState } from '../../hooks/useCustomers';
import { useQuery } from '../../hooks/useQuery';
import { CustomerDetails } from '../CustomerDetails/CustomerDetails';
import { getCustomerDetailsPath } from '../../paths';
import { LoadingMore } from '../../components/LoadingMore';
import { useDebounce } from '../../hooks/useDebounce';

import { EmptyCustomersList } from './components/EmptyCustomersList';
import { MyCustomersListLoader } from './components/MyCustomersListLoader';
import { MyCustomerListItem } from './components/MyCustomerListItem';
import { SearchInput, SEARCH_INPUT_HEIGHT } from './components/SearchInput';
import { NoSearchResults } from './components/NoSearchResults';

export const CUSTOMERS_LIST_WIDTH = 320;
export const CUSTOMERS_LIST_HEADER_HEIGHT = 60;
export const LIST_HEADER_AND_SEARCH_HEIGHT = CUSTOMERS_LIST_HEADER_HEIGHT + SEARCH_INPUT_HEIGHT;

export function MyCustomers() {
    const [searchInputValue, setSearchInputValue] = useState<string>('');
    const history = useHistory();
    const query = useQuery();
    const customerId = query.get('customer_id');
    const searchValue = useDebounce(trimSpaces(searchInputValue), 500);
    const { state, customers, errorType, hasNext, loaderRef, onLoadData } =
        useCustomers(searchValue);

    const handleListItemClick = (currentCustomerId: string) => {
        const path = getCustomerDetailsPath(currentCustomerId);
        history.push(path);
    };

    const renderCustomersList = () => {
        if (state === CustomersState.Pending) {
            return (
                <Box height={`calc(100% - ${LIST_HEADER_AND_SEARCH_HEIGHT}px)`} overflowY="auto">
                    <MyCustomersListLoader />
                </Box>
            );
        }

        if (state === CustomersState.NoCustomers || state === CustomersState.Rejected) {
            return (
                <EmptyCustomersList
                    errorType={errorType}
                    emptyViewOffsetHeight={LIST_HEADER_AND_SEARCH_HEIGHT}
                    onReload={onLoadData}
                />
            );
        }

        if (state === CustomersState.NoSearchResults) {
            return <NoSearchResults searchValue={searchValue} />;
        }

        return (
            <Box
                height={`calc(100% - ${LIST_HEADER_AND_SEARCH_HEIGHT}px)`}
                overflowY="auto"
                data-testid="my-customers-list">
                {customers.map((customer, index) => (
                    <MyCustomerListItem
                        // eslint-disable-next-line react/no-array-index-key
                        key={`customer_${index}_${String(customer.customer_id)}`}
                        customer={customer}
                        selectedCustomerId={customerId}
                        onSelect={handleListItemClick}
                    />
                ))}

                {(state === CustomersState.Resolved || state === CustomersState.FetchingMore) &&
                    hasNext && <LoadingMore loaderRef={loaderRef} />}
            </Box>
        );
    };

    return (
        <Flex height="100%">
            <Box
                width="25%"
                minWidth={CUSTOMERS_LIST_WIDTH}
                height="100%"
                position="relative"
                borderRightWidth="borderWidth10"
                borderRightStyle="solid"
                borderRightColor="colorBorderWeak">
                <Box
                    display="flex"
                    alignItems="center"
                    height={CUSTOMERS_LIST_HEADER_HEIGHT}
                    paddingLeft="space50"
                    paddingRight="space50"
                    backgroundColor="colorBackground"
                    borderBottomWidth="borderWidth10"
                    borderBottomStyle="solid"
                    borderBottomColor="colorBorderWeak">
                    <Text
                        as="span"
                        color="colorText"
                        fontSize="fontSize40"
                        fontWeight="fontWeightBold">
                        Customers
                    </Text>
                </Box>

                <SearchInput
                    searchInputValue={searchInputValue}
                    disabled={
                        state === CustomersState.Pending ||
                        state === CustomersState.NoCustomers ||
                        state === CustomersState.Rejected
                    }
                    loading={state === CustomersState.Searching}
                    onSearchInputChange={setSearchInputValue}
                    onClear={() => setSearchInputValue('')}
                />

                {renderCustomersList()}
            </Box>

            <Flex width="75%" height="100%">
                {customerId ? (
                    <CustomerDetails />
                ) : (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="100%"
                        width="100%"
                        backgroundColor="colorBackground">
                        <Text as="span" color="colorTextWeak">
                            Select a customer
                        </Text>
                    </Box>
                )}
            </Flex>
        </Flex>
    );
}
