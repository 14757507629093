import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { LoadingScreen } from '../../components/LoadingScreen';
import { useSession } from '../../context/SessionContext';
import { PAGES } from '../../paths';

export function Logout() {
    const { session, setSession } = useSession();

    useEffect(() => {
        session?.logout().then(() => {
            setSession(undefined);
        });
    }, []);

    if (!session) {
        return <Redirect to={PAGES.LOGIN} />;
    }

    return <LoadingScreen />;
}
