import { Box } from '@twilio-paste/box';
import { Button } from '@twilio-paste/button';
import { Flex } from '@twilio-paste/flex';
import { Input } from '@twilio-paste/input';
import { SearchIcon } from '@twilio-paste/icons/cjs/SearchIcon';

type SearchBarProps = {
    placeholder: string;
    inputValue: string;
    onInputChange: (newValue: string) => void;
    onSearch: () => void;
    onClearSearch: () => void;
};
export function SearchBar({
    placeholder,
    inputValue,
    onInputChange,
    onSearch,
    onClearSearch,
}: SearchBarProps) {
    return (
        <Box marginBottom="space50">
            <Flex>
                <Flex grow maxWidth={320}>
                    <Input
                        id="search_bar"
                        name="search_bar"
                        type="text"
                        placeholder={placeholder || 'Search'}
                        onChange={(e) => {
                            onInputChange(e.target.value);
                        }}
                        onKeyDownCapture={(e) => {
                            if (e.key === 'Enter') {
                                onSearch();
                            }
                        }}
                        insertBefore={<SearchIcon decorative />}
                        value={inputValue}
                    />
                </Flex>
                <Flex>
                    <Box marginLeft="space30" display="flex" maxWidth="100%" alignItems="center">
                        <Button onClick={onSearch} variant="secondary" data-testid="search-button">
                            Search
                        </Button>
                        {inputValue && (
                            <Box marginLeft="space50">
                                <Button
                                    onClick={onClearSearch}
                                    variant="link"
                                    data-testid="clear-search-button">
                                    Clear search
                                </Button>
                            </Box>
                        )}
                    </Box>
                </Flex>
            </Flex>
        </Box>
    );
}
