import { useState, useEffect } from 'react';
import { isSupported } from 'firebase/messaging';

import { NotificationBanner, NotificationBannerProps } from './NotificationBanner';
import { detectBrowser, isNotificationSupportedBrowser } from '../../utils/browser';

export function NotificationBannerContainer(props: NotificationBannerProps) {
    const [isNotificationSupported, setIsNotificationSupported] = useState(false);

    useEffect(() => {
        isSupported().then((isBrowserSupported) => {
            if (!isBrowserSupported) return;

            const currentBrowser = detectBrowser();
            const isCurrentBrowserSupported = isNotificationSupportedBrowser(currentBrowser);
            setIsNotificationSupported(isCurrentBrowserSupported);
        });
    }, []);

    if (!isNotificationSupported) return null;

    return <NotificationBanner {...props} />;
}
