import { Link } from 'react-router-dom';

import { Box } from '@twilio-paste/box';

import {
    ISimpleConversation,
    useConversationExtras,
} from '@twilio/frontline-shared/store/conversations/SimpleConversation';

import { ConversationTile, ConversationTileLoading } from '../../../components/ConversationTile';
import { InfoBox } from '../../../components/InfoBox';
import { getUserConversationPath } from '../../../paths';
import { ConversationViewVariant } from '../../../components/Conversation/types';
import { UserConversationsState } from '../../../hooks/useUserConversations';

type UserConversationsListProps = {
    identity: string;
    selectedConversationId: string | null;
    conversations: ISimpleConversation[];
    userConversationsState: UserConversationsState;
    onUserConversationsReload: () => void;
};

export function UserConversationsList({
    identity,
    selectedConversationId,
    conversations,
    userConversationsState,
    onUserConversationsReload,
}: UserConversationsListProps) {
    const conversationExtras = useConversationExtras(identity);

    if (userConversationsState === UserConversationsState.Pending) {
        return (
            <Box overflowY="auto">
                {Array.from({ length: 50 }).map((_, rowIndex) => (
                    <ConversationTileLoading key={`loading-${rowIndex}`} />
                ))}
            </Box>
        );
    }

    if (userConversationsState === UserConversationsState.Rejected) {
        return (
            <Box height="100%" width="100%" paddingX="space50">
                <InfoBox
                    title="Unable to connect to Frontline"
                    text="We’re having issues connecting to our servers. Please try reloading or contact Twilio support if you’re still having trouble."
                    showButton
                    onClick={onUserConversationsReload}
                />
            </Box>
        );
    }

    if (userConversationsState === UserConversationsState.Resolved && !conversations.length) {
        return (
            <Box height="100%" width="100%" paddingX="space50">
                <InfoBox
                    title="No active conversations"
                    text="Once your team member’s conversations are created, they will appear here."
                />
            </Box>
        );
    }

    return (
        <Box overflowY="auto">
            {conversations.map((conversation) => (
                <Link
                    key={`conversation-${conversation.sid}`}
                    to={getUserConversationPath(identity, conversation.sid)}
                    style={{ textDecoration: 'none' }}>
                    <ConversationTile
                        conversation={conversation}
                        info={conversationExtras[conversation.sid]}
                        selectedConversationId={selectedConversationId}
                        variant={ConversationViewVariant.MANAGER}
                    />
                </Link>
            ))}
        </Box>
    );
}
