import { useState, useEffect } from 'react';

import { useSimpleUserConversations } from '@twilio/frontline-shared/store/conversations/SimpleConversation';

import { useSession } from '../context/SessionContext';

export enum UserConversationsState {
    Pending = 'pending',
    Resolved = 'resolved',
    Rejected = 'rejected',
}

export function useUserConversations(identity: string) {
    const [state, setState] = useState<UserConversationsState>(UserConversationsState.Pending);
    const conversations = useSimpleUserConversations(identity);
    const { session } = useSession();

    const getUserConversations = () =>
        session
            ?.loadUserConversations(identity)
            .then(() => setState(UserConversationsState.Resolved))
            .catch(() => setState(UserConversationsState.Rejected));

    const onReload = () => {
        setState(UserConversationsState.Pending);
        getUserConversations();
    };

    useEffect(() => {
        if (state !== UserConversationsState.Pending) {
            setState(UserConversationsState.Pending);
        }
        getUserConversations();
        return () => {
            // TODO: Interrupt "loadUserConversations" request
        };
    }, [identity]);

    return { state, conversations, onReload };
}
