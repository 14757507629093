import { ChannelType } from '@twilio/frontline-shared/types/channel';
import { FrontlineRole } from '../core/types';

export abstract class AnalyticsService {
    abstract init(): void;
    abstract logEvent(event: string, extra?: { [key: string]: any }): void;
    abstract logError(event: string, extra?: { [key: string]: any }): void;
    abstract setUser(user: AnalyticsUser): void;
}

export interface AnalyticsServiceInit {
    id: string;
    service: AnalyticsService;
}

export type AnalyticsServiceMap = { [key: string]: AnalyticsService };

export interface AnalyticsUser {
    id: string;
    accountSid: string;
    role: FrontlineRole;
    dateCreated: string;
    dateUpdated: string;
}
export type IAnalyticEvent = string | { event: string; extra?: { [key: string]: any } };
export type SharedAnalyticEvents =
    | 'click_message_failed'
    | 'click_failed_message_alert_close'
    | 'click_failed_message_alert_delete'
    | 'click_failed_message_alert_retry'
    //
    | 'click_whatsapp_reach_out'
    | 'click_whatsapp_info'
    | 'click_upgrade_to_latest_version'
    //
    | 'send_message'
    | 'send_media_message'
    | {
          event: 'conversation_marked_unread';
          extra: { conversationSid: string; from: 'my_conversations' | 'conversation' };
      }
    | {
          event: 'message_long_pressed';
          extra: { conversationSid: string; messageSid: string };
      }
    | {
          event: 'open_customer_conversation';
          extra: {
              channelType?: ChannelType;
          };
      }
    //
    | 'click_close_conversation_alert_cancel'
    | 'click_close_conversation_alert_confirm'
    | 'user_activated';
