import { ConversationSid, UserIdentity } from '@twilio/frontline-shared/types';
import { ConversationContainer } from '../../../components/Conversation/ConversationContainer';
import { ConversationViewVariant } from '../../../components/Conversation/types';
import { useSession } from '../../../context/SessionContext';

export type UserConversationProps = {
    conversationSid: ConversationSid;
    userIdentity: UserIdentity;
};

export function UserConversation({ conversationSid, userIdentity }: UserConversationProps) {
    const { session } = useSession();

    const fetchConversation = () => session!.fetchUserConversation(conversationSid, userIdentity);

    return (
        <ConversationContainer
            hideInputToolbar
            conversationSid={conversationSid}
            fetchConversation={fetchConversation}
            variant={ConversationViewVariant.MANAGER}
            participantIdentity={userIdentity}
        />
    );
}
