import { Paragraph } from '@twilio-paste/paragraph';
import { Modal, ModalBody, ModalHeader, ModalHeading } from '@twilio-paste/modal';
import { useUID } from '@twilio-paste/uid-library';

type WorkspaceIDInfoModalProps = {
    isInfoOpen: boolean;
    onDismiss: () => void;
};

export function WorkspaceIDInfoModal({ isInfoOpen, onDismiss }: WorkspaceIDInfoModalProps) {
    const modalHeadingID = useUID();

    return (
        <Modal
            ariaLabelledby={modalHeadingID}
            isOpen={isInfoOpen}
            onDismiss={onDismiss}
            data-testid="no-workspace-modal"
            size="default">
            <ModalHeader>
                <ModalHeading as="h3" id={modalHeadingID} data-testid="no-workspace-title">
                    Don’t have a workspace ID?
                </ModalHeading>
            </ModalHeader>
            <ModalBody>
                <Paragraph>
                    When your company sets up a Frontline workspace, a unique workspace ID is
                    created by your IT admins. Ask your IT admins for the unique workspace ID to
                    sign in to the app using single sign-on.
                </Paragraph>
            </ModalBody>
        </Modal>
    );
}
