import { useSelector } from 'react-redux';
import { ConnectionState } from '@twilio/frontline-shared/types';

import { IUser } from '@twilio/frontline-shared/models/User';
import { createAction, createReducer } from '@reduxjs/toolkit';
import { ExtendsChatState } from './ChatState';
import { FrontlineRole } from '../core/types';

export const setConnectionState = createAction<ConnectionState>('CHAT_SET_CONNECTION_STATE');

export const setUser = createAction<IUser>('CHAT_SET_USER');

export const setRole = createAction<FrontlineRole>('CHAT_SET_ROLE');

export type ChatSessionState = {
    connectionState: ConnectionState;
    role: FrontlineRole;
    user?: IUser;
};

export const initialState: ChatSessionState = {
    connectionState: 'connecting',
    role: FrontlineRole.User,
    user: undefined,
};

export const reduce = createReducer<ChatSessionState>(initialState, (builder) => {
    builder
        .addCase(setConnectionState, (state, action) => {
            state.connectionState = action.payload;
        })
        .addCase(setUser, (state, action) => {
            state.user = action.payload;
        })
        .addCase(setRole, (state, action) => {
            state.role = action.payload;
        });
});

export const selectCurrentUser = (state: ExtendsChatState): IUser | undefined =>
    state.chat.session.user;

export function useChatUser(): IUser {
    const user = useSelector(selectCurrentUser);
    // @ts-ignore
    return user;
}

export function useIsManager(): boolean {
    return useSelector(
        (state: ExtendsChatState) => state.chat.session.role === FrontlineRole.Manager,
    );
}

export function useConnectionState(): ConnectionState {
    return useSelector((state: ExtendsChatState) => state.chat.session.connectionState);
}
