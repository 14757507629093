import { DeepPartial } from '@twilio/frontline-shared/utils/deepPartial';
import {
    AppCallback,
    GetTemplatesByCustomerIdPayload,
} from '@twilio/frontline-shared/core/Callbacks/types';

import { FrontlineSDK } from '@twilio/frontline-shared/sdk/FrontlineSDK';

export type GetTemplatesByCustomerIdResponse = DeepPartial<CategoryObject>[];

export type CategoryObject = {
    display_name: string;
    templates: TemplateObject[];
};

export type TemplateObject = {
    content: string;
    whatsAppApproved?: boolean;
};

type GetTemplatesByCustomerId = AppCallback<
    GetTemplatesByCustomerIdPayload,
    GetTemplatesByCustomerIdResponse | undefined
>;

export const getTemplatesByCustomerId: GetTemplatesByCustomerId = ({ payload }) =>
    FrontlineSDK.shared!.callCallbackUrl('GetTemplatesByCustomerId', payload);
