import React from 'react';
import { Platform, StyleSheet } from 'react-native';

import { DefaultTheme } from '@twilio/frontline-shared/theme';
import { TwilIcon } from '@twilio/frontline-shared/components/TwilIcon';

export function ScrollToBottomIcon() {
    return (
        <TwilIcon
            name="arrowRight"
            style={styles.scrollToBottomIcon}
            size={24}
            color={DefaultTheme.backgroundColors.colorBackgroundPrimary}
        />
    );
}

const styles = StyleSheet.create({
    scrollToBottomIcon: {
        marginTop: 4,
        marginLeft: Platform.OS === 'ios' ? -1 : 0,
        transform: [{ rotate: '90deg' }],
    },
});
