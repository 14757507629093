import { useDispatch } from '@twilio/frontline-shared/store/redux';
import { Conversation } from '@twilio/frontline-shared/models/Conversation';
import { Participant } from '@twilio/frontline-shared/models/Participant';
import { ChannelType } from '@twilio/frontline-shared/types/channel';
import { WorkerUser } from '@twilio/frontline-shared/models/WorkerUser';
import { Menu, MenuButton, MenuItem, useMenuState } from '@twilio-paste/menu';
import { Button } from '@twilio-paste/button';
import { Flex } from '@twilio-paste/flex';
import { MoreIcon } from '@twilio-paste/icons/cjs/MoreIcon';
import { useState } from 'react';
import { Text } from '@twilio-paste/text';
import { useHistory } from 'react-router-dom';
import { BETA_FEATURE_FLAGS } from '@twilio/frontline-shared/sdk/FrontlineSDK';
import { markConversationUnread } from '@twilio/frontline-shared/store/conversations/actions';
import { Analytics } from '@twilio/frontline-web/src/analytics';
import { TransferConversation } from '../TransferConversation/TransferConversation';
import { CloseConversation } from '../CloseConversation/CloseConversation';
import { ConversationViewVariant } from './types';
import { PAGES } from '../../paths';
import { useSession } from '../../context/SessionContext';

type ConversationMenuProps = {
    conversationSid: Conversation['sid'];
    channelName: Conversation['friendlyName'];
    channelAvatar: Conversation['avatar'];
    channelType?: ChannelType;
    currentUserIdentity: WorkerUser['identity'];
    currentUserFriendlyName?: WorkerUser['friendly_name'];
    customerParticipantDisplayName: Participant['displayName'];
    variant: ConversationViewVariant;
};

export function ConversationMenu({
    conversationSid,
    channelName,
    channelAvatar,
    channelType,
    currentUserIdentity,
    currentUserFriendlyName,
    customerParticipantDisplayName,
    variant,
}: ConversationMenuProps) {
    const menu = useMenuState({ baseId: 'conversation-menu' });
    const history = useHistory();
    const { session } = useSession();
    const dispatch = useDispatch();
    const [isTransferConversationModalOpen, setIsTransferConversationModalOpen] = useState(false);
    const [isCloseConversationModalOpen, setIsCloseConversationModalOpen] = useState(false);

    const isMarkUnreadFlagEnabled = session?.frontlineSDK.isBetaFlagEnabled(
        BETA_FEATURE_FLAGS.MARK_CONVERSATION_UNREAD,
    );

    const markUnread = () => {
        history.push(PAGES.CONVERSATIONS);
        dispatch(markConversationUnread({ conversationSid }));
        Analytics.logEvent({
            event: 'conversation_marked_unread',
            extra: { conversationSid: conversationSid as string, from: 'conversation' },
        });
    };

    return (
        <Flex vertical grow hAlignContent="right">
            {variant === ConversationViewVariant.USER ? (
                <MenuButton {...menu} variant="secondary" data-testid="conversation-menu-button">
                    <MoreIcon decorative={false} title="More options" />
                </MenuButton>
            ) : (
                <Button
                    variant="secondary"
                    onClick={() => {
                        setIsTransferConversationModalOpen(true);
                    }}>
                    Transfer
                </Button>
            )}

            <Menu
                {...menu}
                id="conversation-menu"
                aria-label="Actions"
                data-testid="conversation-menu">
                {isMarkUnreadFlagEnabled && (
                    <MenuItem {...menu} onClick={markUnread}>
                        Mark unread
                    </MenuItem>
                )}
                <MenuItem {...menu} onClick={() => setIsTransferConversationModalOpen(true)}>
                    Transfer conversation
                </MenuItem>
                <MenuItem {...menu} onClick={() => setIsCloseConversationModalOpen(true)}>
                    <Text as="span" color="colorTextError">
                        Close conversation
                    </Text>
                </MenuItem>
            </Menu>
            <CloseConversation
                conversationSid={conversationSid}
                customerParticipantDisplayName={customerParticipantDisplayName}
                isOpen={isCloseConversationModalOpen}
                setIsOpen={setIsCloseConversationModalOpen}
            />
            <TransferConversation
                conversationSid={conversationSid}
                channelFriendlyName={channelName}
                channelAvatar={channelAvatar}
                channelType={channelType}
                currentUserIdentity={currentUserIdentity}
                currentUserFriendlyName={currentUserFriendlyName}
                isOpen={isTransferConversationModalOpen}
                setIsOpen={setIsTransferConversationModalOpen}
                variant={variant}
            />
        </Flex>
    );
}
