import { Box } from '@twilio-paste/box';
import { Button } from '@twilio-paste/button';
import { CloseIcon } from '@twilio-paste/icons/cjs/CloseIcon';

import { CustomerDetails } from '../../CustomerDetails';

type CustomerDetailsPanelProps = {
    customerId: string;
    onClose: () => void;
};

export function CustomerDetailsPanel({ customerId, onClose }: CustomerDetailsPanelProps) {
    return (
        <Box
            display="flex"
            flexDirection="column"
            width="25%"
            minWidth={320}
            height="100%"
            paddingX="space50"
            borderLeftWidth="borderWidth10"
            borderLeftStyle="solid"
            borderLeftColor="colorBorderWeak">
            <Box
                display="flex"
                alignItems="center"
                minHeight={65}
                width="100%"
                alignContent="center"
                justifyContent="flex-end"
                backgroundColor="colorBackgroundBody">
                <Button variant="secondary_icon" size="reset" onClick={onClose}>
                    <CloseIcon decorative={false} title="close" color="colorTextIcon" />
                </Button>
            </Box>

            <CustomerDetails customerId={customerId} />
        </Box>
    );
}
