import { useState } from 'react';

import {
    TemplatesByCategory,
    TemplatesModalStatus,
} from '@twilio/frontline-shared/models/Templates';

import { Modal, ModalHeader, ModalHeading } from '@twilio-paste/modal';
import { useUID } from '@twilio-paste/uid-library';

import { TemplatesModalError } from './components/TemplatesModalError';
import { TemplatesModalSpinner } from './components/TemplatesModalSpinner';
import { TemplatesModalEmpty } from './components/TemplatesModalEmpty';
import { TemplatesModalReady } from './components/TemplatesModalReady';

export type TemplatesModalProps = {
    templates: TemplatesByCategory;
    isOpen: boolean;
    status: TemplatesModalStatus;
    handleClose: () => void;
    onTemplateSelected: (template: string) => void;
    loadData: () => void;
};

export function TemplatesModal({
    status,
    isOpen,
    templates,
    handleClose,
    onTemplateSelected,
    loadData,
}: TemplatesModalProps) {
    const modalHeadingID = useUID();
    const [template, setTemplate] = useState<string | undefined>();
    const [preview, setPreview] = useState(false);

    const resetState = () => {
        setPreview(false);
        setTemplate(undefined);
    };

    const onDismiss = () => {
        resetState();
        handleClose();
    };

    const onTemplateSelect = () => {
        if (template) {
            onTemplateSelected(template);
            resetState();
        }
    };

    const renderTemplatesModalContent = () => {
        switch (status) {
            case TemplatesModalStatus.Loading:
                return <TemplatesModalSpinner />;
            case TemplatesModalStatus.Error:
                return <TemplatesModalError onRetry={loadData} />;
            case TemplatesModalStatus.NoWhatsAppTemplate:
                return <TemplatesModalEmpty onReload={loadData} />;
            case TemplatesModalStatus.Ready:
                return (
                    <TemplatesModalReady
                        preview={preview}
                        template={template}
                        templates={templates}
                        onBack={() => setPreview(false)}
                        onSend={onTemplateSelect}
                        onSetTemplate={setTemplate}
                        onSetPreview={setPreview}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <Modal size="default" ariaLabelledby={modalHeadingID} isOpen={isOpen} onDismiss={onDismiss}>
            <ModalHeader>
                <ModalHeading as="h3" id={modalHeadingID} data-testid="templates-heading">
                    Templates
                </ModalHeading>
            </ModalHeader>
            {renderTemplatesModalContent()}
        </Modal>
    );
}
