import { AnalyticsBrowser } from '@segment/analytics-next';

import { AnalyticsService, AnalyticsUser } from '@twilio/frontline-shared/analytics/type';

import { IS_DEV_BUILD, ENV_MODE, SEGMENT_DEV_WRITE_KEY, SEGMENT_PROD_WRITE_KEY } from '../env';

export class SegmentService extends AnalyticsService {
    private analytics?: AnalyticsBrowser;

    init() {
        const writeKey =
            !IS_DEV_BUILD && ENV_MODE === 'prod' ? SEGMENT_PROD_WRITE_KEY : SEGMENT_DEV_WRITE_KEY;
        this.analytics = AnalyticsBrowser.load({ writeKey });
    }

    logEvent(event: string, extra?: { [key: string]: any }) {
        if (event === 'conversation_marked_unread') {
            this.analytics?.track(event, extra);
        }
    }

    logError(event: string, extra?: { [key: string]: any }) {}

    setUser(user: AnalyticsUser) {
        this.analytics?.identify(user.id, {
            accountSid: user.accountSid,
            role: user.role,
            createdAt: user.dateCreated,
            updatedAt: user.dateUpdated,
        });
    }
}
