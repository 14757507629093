import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import { DefaultTheme } from '@twilio/frontline-shared/theme';
import { useChatContext } from './ChatContext';
import { IChatMessage } from './types';
import { isSameDay } from './utils';

export type DayProps = {
    currentMessage?: IChatMessage;
    previousMessage?: IChatMessage;
};

export function Day({ currentMessage, previousMessage }: DayProps) {
    const { dateFormatter } = useChatContext();
    if (!dateFormatter || !currentMessage || isSameDay(currentMessage, previousMessage)) {
        return null;
    }

    const createdAt =
        currentMessage.createdAt instanceof Date
            ? currentMessage.createdAt
            : new Date(currentMessage.createdAt);

    return (
        <View style={[styles.container]}>
            <View>
                <Text testID="date-text" style={[styles.text]}>
                    {dateFormatter(createdAt)}
                </Text>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 5,
        marginBottom: 10,
    },
    text: {
        color: DefaultTheme.textColors.colorTextPlaceholder,
        fontWeight: DefaultTheme.fontWeights.fontWeightSemibold,
        fontSize: DefaultTheme.fontSizes.fontSize10,
        lineHeight: DefaultTheme.lineHeights.lineHeight10,
    },
});
