import { useState, useContext, createContext, ReactNode } from 'react';
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalFooterActions,
    ModalHeader,
    ModalHeading,
} from '@twilio-paste/modal';
import { Text } from '@twilio-paste/text';
import { Button } from '@twilio-paste/button';

export type ModalInfo = {
    isVisible: boolean;
    title: string;
    message: string;
};

export interface ModalContextProps {
    modalInfo: ModalInfo;
    showModal: (title: string, message: string) => void;
    dismiss: () => void;
}

export const ModalContext = createContext<ModalContextProps>({
    modalInfo: { isVisible: false, title: '', message: '' },
    showModal: (title: string, message: string) => {},
    dismiss: () => {},
});

export function ModalProvider({ children }: { children: ReactNode }) {
    const [modalState, setModalState] = useState<ModalContextProps>({
        modalInfo: { isVisible: false, title: '', message: '' },
        showModal: (title: string, message: string) =>
            setModalState((prevState: ModalContextProps) => ({
                ...prevState,
                modalInfo: { isVisible: true, title, message },
            })),
        dismiss: () =>
            setModalState((prevState: ModalContextProps) => ({
                ...prevState,
                modalInfo: { isVisible: false, title: '', message: '' },
            })),
    });

    return (
        <ModalContext.Provider value={modalState}>
            {children}
            <Modal
                isOpen={modalState.modalInfo.isVisible}
                onDismiss={modalState.dismiss}
                size="default"
                ariaLabelledby="what">
                <ModalHeader>
                    <ModalHeading as="h3">{modalState.modalInfo.title}</ModalHeading>
                </ModalHeader>
                <ModalBody>
                    <Text as="p" whiteSpace="pre-line">
                        {modalState.modalInfo.message}
                    </Text>
                </ModalBody>
                <ModalFooter>
                    <ModalFooterActions>
                        <Button variant="primary" onClick={modalState.dismiss}>
                            OK
                        </Button>
                    </ModalFooterActions>
                </ModalFooter>
            </Modal>
        </ModalContext.Provider>
    );
}

export function useModal() {
    return useContext(ModalContext);
}
