import React, { RefObject } from 'react';
import {
    EmitterSubscription,
    FlatList,
    Keyboard,
    NativeScrollEvent,
    NativeSyntheticEvent,
    Platform,
    StyleSheet,
    TouchableOpacity,
    View,
} from 'react-native';

import { Participant } from '@twilio/frontline-shared/models/Participant';
import { DefaultTheme } from '@twilio/frontline-shared/theme';

import { IChatMessage, IChatUser } from './types';
import { EmptyChatMessage } from './EmptyChatMessage';
import { Message } from './Message';
import { ScrollToBottomIcon } from './ScrollToBottomIcon';
import { isMessageAuthoredBy } from './utils';

type MessagesListProps = {
    forwardRef: RefObject<FlatList<IChatMessage>>;
    messages: IChatMessage[];
    user?: IChatUser;
    customerParticipant?: Participant;
    onKeyboardWillShow: () => void;
    onKeyboardWillHide: () => void;
    onKeyboardDidShow: () => void;
    onKeyboardDidHide: () => void;
    onEndReached: () => void;
};

type MessagesListState = {
    showScrollBottom: boolean;
};

const ON_END_REACHED_THRESHOLD = 0.3;

export class MessagesList extends React.PureComponent<MessagesListProps, MessagesListState> {
    renderRow: (params: { item: IChatMessage; index: number }) => React.ReactElement | null;

    keyExtractor: (item: { _id: IChatMessage['_id'] }) => string;

    scrollToBottom: (animated?: boolean) => void;

    handleOnScroll: (event: NativeSyntheticEvent<NativeScrollEvent>) => void;

    attachKeyboardListeners: () => void;

    detachKeyboardListeners: () => void;

    willShowKeyboardSubscription?: EmitterSubscription;

    didShowKeyboardSubscription?: EmitterSubscription;

    willHideKeyboardSubscription?: EmitterSubscription;

    didHideKeyboardSubscription?: EmitterSubscription;

    web_lastEndReachedHeight = 0;

    constructor(props: MessagesListProps) {
        super(props);

        this.state = {
            showScrollBottom: false,
        };

        this.attachKeyboardListeners = () => {
            this.willShowKeyboardSubscription = Keyboard.addListener(
                'keyboardWillShow',
                props.onKeyboardWillShow,
            );
            this.didShowKeyboardSubscription = Keyboard.addListener(
                'keyboardDidShow',
                props.onKeyboardDidShow,
            );
            this.willHideKeyboardSubscription = Keyboard.addListener(
                'keyboardWillHide',
                props.onKeyboardWillHide,
            );
            this.didHideKeyboardSubscription = Keyboard.addListener(
                'keyboardDidHide',
                props.onKeyboardDidHide,
            );
        };

        this.detachKeyboardListeners = () => {
            this.willShowKeyboardSubscription?.remove();
            this.didShowKeyboardSubscription?.remove();
            this.willHideKeyboardSubscription?.remove();
            this.didHideKeyboardSubscription?.remove();
        };

        this.scrollToBottom = (animated = true) => {
            this.scrollTo({ offset: 0, animated });
        };

        this.handleOnScroll = (event) => {
            const { onEndReached } = this.props;
            const contentOffsetY =
                Platform.OS === 'web'
                    ? Math.abs(event.nativeEvent.contentOffset.y)
                    : event.nativeEvent.contentOffset.y;
            const scrollToBottomOffset = 200;

            if (Platform.OS === 'web') {
                const _onEndReached =
                    contentOffsetY /
                    (event.nativeEvent.contentSize.height -
                        event.nativeEvent.layoutMeasurement.height);
                if (
                    this.web_lastEndReachedHeight !== event.nativeEvent.contentSize.height &&
                    _onEndReached > 1 - ON_END_REACHED_THRESHOLD
                ) {
                    this.web_lastEndReachedHeight = event.nativeEvent.contentSize.height;
                    onEndReached();
                }
            }

            if (Math.abs(contentOffsetY) > scrollToBottomOffset) {
                this.setState({ showScrollBottom: true });
            } else {
                this.setState({ showScrollBottom: false });
            }
        };

        this.renderRow = ({ item, index }) => {
            const { messages, user, customerParticipant } = this.props;

            if (user) {
                const previousMessage = messages[index + 1];
                const nextMessage = messages[index - 1];
                const position = isMessageAuthoredBy(item, user) ? 'right' : 'left';

                return (
                    <Message
                        key={item._id}
                        user={user}
                        currentMessage={item}
                        previousMessage={previousMessage}
                        nextMessage={nextMessage}
                        position={position}
                        customerParticipant={customerParticipant}
                    />
                );
            }

            return null;
        };

        this.keyExtractor = (item) => item._id.toString();
    }

    componentDidMount() {
        const { messages } = this.props;
        if (messages?.length === 0) {
            this.attachKeyboardListeners();
        }
    }

    componentDidUpdate(prevProps: MessagesListProps) {
        const { messages } = this.props;
        if (prevProps.messages.length === 0 && messages.length > 0) {
            this.detachKeyboardListeners();
        } else if (prevProps.messages.length > 0 && messages.length === 0) {
            this.attachKeyboardListeners();
        }
    }

    componentWillUnmount() {
        this.detachKeyboardListeners();
    }

    scrollTo(options: { animated?: boolean | null; offset: number }): void {
        const { forwardRef } = this.props;
        if (forwardRef && forwardRef.current && options) {
            forwardRef.current.scrollToOffset(options);
        }
    }

    renderScrollToBottomWrapper() {
        return (
            <View style={styles.scrollToBottomStyle}>
                <TouchableOpacity
                    onPress={() => this.scrollToBottom()}
                    hitSlop={{ top: 5, left: 5, right: 5, bottom: 5 }}>
                    <ScrollToBottomIcon />
                </TouchableOpacity>
            </View>
        );
    }

    render() {
        const { forwardRef, messages, onEndReached } = this.props;
        const { showScrollBottom } = this.state;
        return (
            <View style={styles.container}>
                {showScrollBottom ? this.renderScrollToBottomWrapper() : null}
                <FlatList<IChatMessage>
                    ref={forwardRef}
                    keyExtractor={this.keyExtractor}
                    automaticallyAdjustContentInsets={false}
                    inverted={Platform.OS !== 'web'}
                    data={messages}
                    renderItem={this.renderRow}
                    scrollEventThrottle={100}
                    keyboardShouldPersistTaps={Platform.select({
                        ios: 'never',
                        android: 'always',
                        default: 'never',
                    })}
                    ListEmptyComponent={EmptyChatMessage}
                    style={styles.listStyle}
                    contentContainerStyle={styles.contentContainerStyle}
                    onEndReachedThreshold={ON_END_REACHED_THRESHOLD}
                    onEndReached={onEndReached}
                    onScroll={this.handleOnScroll}
                    ListFooterComponent={<View style={{ height: 19 }} />}
                    ListHeaderComponent={<View style={{ height: 15 }} />}
                />
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    listStyle: {
        flex: 1,
        ...Platform.select({ web: { flexDirection: 'column-reverse' } }),
    },
    contentContainerStyle: {
        flexGrow: 1,
        justifyContent: 'flex-start',
        ...Platform.select({ web: { flexDirection: 'column-reverse' } }),
    },
    scrollToBottomStyle: {
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: DefaultTheme.colors.colorGray0,
        borderColor: DefaultTheme.backgroundColors.colorBackgroundPrimary,
        borderRadius: 20,
        borderWidth: DefaultTheme.borderWidths.borderWidth20,
        borderStyle: 'solid',
        right: 10,
        bottom: DefaultTheme.space.space30,
        zIndex: 999,
        height: 40,
        width: 40,
        opacity: 1,
        position: 'absolute',
        shadowColor: 'transparent',
        shadowOpacity: 0,
        shadowOffset: {
            width: 0,
            height: 0,
        },
        shadowRadius: 0,
    },
});
