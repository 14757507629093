import { useSelector } from 'react-redux';

import { IUser } from '@twilio/frontline-shared/models/User';
import { FrontlineRole } from '@twilio/frontline-shared/core/types';

import { ExtendsChatState } from '../ChatState';
import { selectCurrentUser } from '../ChatSessionState';

export function useChatUser(): IUser {
    const user = useSelector(selectCurrentUser);
    // @ts-ignore
    return user;
}

export function useIsManager(): boolean {
    return useSelector(
        (state: ExtendsChatState) => state.chat.session.role === FrontlineRole.Manager,
    );
}
