import { useEffect, useRef, useState } from 'react';

import { Page } from '@twilio/frontline-shared/models/Page';
import { WorkerUser } from '@twilio/frontline-shared/models/WorkerUser';
import { Session } from '../core/Session';

export enum UsersState {
    Loading = 'loading',
    FetchingMore = 'fetchingMore',
    Searching = 'searching',
    Ready = 'ready',
    NoSearchResult = 'noSearchResult',
    NoUsers = 'noUsers',
}

export const WORKER_COUNT = 30;

export function useUsers({
    query,
    ignoredUserIdentity,
    session = Session.shared!,
}: {
    query: string;
    ignoredUserIdentity?: WorkerUser['identity'];
    session?: Session;
}) {
    const [state, setState] = useState<UsersState>(UsersState.Loading);
    const [users, setUsers] = useState<WorkerUser[]>([]);
    const [hasNext, setHasNext] = useState(true);
    const [pageMeta, setPageMeta] = useState<Page<WorkerUser>['meta'] | null>(null);
    const loaderRef = useRef(null);

    useEffect(() => {
        setState(UsersState.Searching);
        setPageMeta(null);
        session.frontlineSDK
            .getWorkers(query, WORKER_COUNT)
            .then((res) => {
                const newUsers = res.content.filter(
                    (user) =>
                        !ignoredUserIdentity ||
                        (ignoredUserIdentity && user.identity !== ignoredUserIdentity),
                );
                setUsers(newUsers);
                setHasNext(!!res?.meta?.next_token);
                setPageMeta(res.meta);
                if (newUsers.length === 0) {
                    setState(query.length ? UsersState.NoSearchResult : UsersState.NoUsers);
                } else {
                    setState(UsersState.Ready);
                }
            })
            .catch(console.log);
    }, [query]);

    const handleObserver = (entries: any) => {
        const target = entries[0];
        if (
            target.isIntersecting &&
            state === UsersState.Ready &&
            hasNext &&
            pageMeta?.next_token
        ) {
            setState(UsersState.FetchingMore);
            setTimeout(() => {
                session.frontlineSDK
                    .getWorkers(query, WORKER_COUNT, pageMeta?.next_token)
                    .then((res) => {
                        setUsers((stateUsers) => [
                            ...(stateUsers as WorkerUser[]),
                            ...res.content.filter(
                                (user) =>
                                    !ignoredUserIdentity ||
                                    (ignoredUserIdentity && user.identity !== ignoredUserIdentity),
                            ),
                        ]);
                        setHasNext(!!res?.meta?.next_token);
                        setPageMeta(res.meta);
                        if (!res.content.length) {
                            setHasNext(false);
                            setState(UsersState.Ready);
                        } else {
                            setState(UsersState.Ready);
                        }
                    });
            }, 1000);
        }
    };

    useEffect(() => {
        const observer = new IntersectionObserver(handleObserver);
        if (loaderRef.current) observer.observe(loaderRef.current);
    }, [handleObserver]);

    return { users, state, pageMeta, loaderRef, hasNext };
}
