import { createReducer, createSelector } from '@reduxjs/toolkit';

import { useSelector } from 'react-redux';
import { openConversationScreen } from './actions';
import {
    conversationsInitialized,
    conversationsLoadingFailure,
    conversationsLoadingStarted,
} from '../conversations/actions';

export type UIState = {
    activeConversationSid?: string;
    conversationsLoaded: boolean;
    conversationsLoadingFailed: boolean;
};

export const initialState: UIState = {
    activeConversationSid: undefined,
    conversationsLoaded: false,
    conversationsLoadingFailed: false,
};

export const reduce = createReducer<UIState>(initialState, (builder) => {
    builder
        .addCase(openConversationScreen, (state, action) => {
            const { channelSid } = action.payload;
            state.activeConversationSid = channelSid;
        })
        .addCase(conversationsLoadingStarted, (state) => {
            state.conversationsLoaded = false;
            state.conversationsLoadingFailed = false;
        })
        .addCase(conversationsInitialized, (state) => {
            state.conversationsLoaded = true;
            state.conversationsLoadingFailed = false;
        })
        .addCase(conversationsLoadingFailure, (state) => {
            state.conversationsLoadingFailed = true;
            state.conversationsLoaded = false;
        });
});

export const selectActiveConversationSid = (state: { ui: UIState }): string | undefined =>
    state.ui.activeConversationSid;

const conversationsLoadedSelector = createSelector(
    [(state: { ui: UIState }) => state.ui.conversationsLoaded],
    (conversationsLoaded: boolean) => {
        return conversationsLoaded;
    },
);

const conversationsLoadingFailedSelector = createSelector(
    [(state: { ui: UIState }) => state.ui.conversationsLoadingFailed],
    (conversationsLoadingFailed: boolean) => {
        return conversationsLoadingFailed;
    },
);

export const useConversationsLoaded = () => {
    return useSelector(conversationsLoadedSelector);
};

export const useConversationsLoadingFailed = () => {
    return useSelector(conversationsLoadingFailedSelector);
};
