import { TwilioUser, UserIdentity } from '../types';
import { isString } from '../utils/isString';

export type IUser = {
    avatar: string | undefined;
    identity: UserIdentity;
    friendlyName: string;
    state: UserState;
    activitySid: string | undefined;
};

export enum UserState {
    Active = 'active',
    Deactivated = 'deactivated',
}

const ATTRIBUTE_STATE = 'frontline.state';

type IUserAttributes = {
    avatar: string | undefined;
    'frontline.state': UserState | undefined;
    activitySid: string | undefined;
};

export const fromTwilioUser = (twilioUser: TwilioUser): IUser => {
    const attributes = twilioUser.attributes as IUserAttributes;
    const attributesAvatar = attributes.avatar;
    const attributesActivitySid = attributes.activitySid;
    const attributesState = attributes[ATTRIBUTE_STATE];

    const avatar = isString(attributesAvatar) ? attributesAvatar : undefined;
    const state =
        isString(attributesState) && isValidUserState(attributesState)
            ? (attributesState as UserState)
            : UserState.Active;
    const activitySid = isString(attributesActivitySid) ? attributesActivitySid : undefined;

    return {
        avatar,
        identity: twilioUser.identity,
        friendlyName: twilioUser.friendlyName || twilioUser.identity,
        state,
        activitySid,
    };
};

const isValidUserState = (maybeState: string): maybeState is UserState => {
    return Object.values(UserState).includes(maybeState as any);
};

export const isUserActive = (user: IUser) => {
    return user.state === UserState.Active;
};

export const isUserDeactived = (user: IUser) => {
    return user.state === UserState.Deactivated;
};
