import { ModalBody, ModalFooter, ModalFooterActions } from '@twilio-paste/modal';
import { Box } from '@twilio-paste/box';
import { Button } from '@twilio-paste/button';
import { WorkerUser } from '@twilio/frontline-shared/models/WorkerUser';
import { MAX_WIDTH } from '../../../pages/Users';
import { SelectableUserList } from '../List/SelectableUserList';
import { SearchBar } from '../../SearchBar';
import { useSearchBar } from '../../../hooks/useSearchBar';

type SelectUserPropsModalBodyAndFooter = {
    currentUserIdentity: WorkerUser['identity'];
    selectedUser: WorkerUser | undefined;
    setSelectedUser: (user: WorkerUser | undefined) => void;
    onNext: () => void;
};

export function SelectUserModalBodyAndFooter({
    currentUserIdentity,
    selectedUser,
    setSelectedUser,
    onNext,
}: SelectUserPropsModalBodyAndFooter) {
    const resetSelectedUser = () => setSelectedUser(undefined);

    const { inputValue, query, setInputValue, updateQuery, clearSearch } = useSearchBar(
        '',
        resetSelectedUser,
    );

    return (
        <>
            <ModalBody>
                <Box position="sticky" top="spaceNegative50" zIndex="zIndex10">
                    <Box
                        display="flex"
                        justifyContent="center"
                        marginLeft="space40"
                        marginRight="space40"
                        paddingTop="space50"
                        marginTop="spaceNegative50"
                        backgroundColor="colorBackgroundBody">
                        <Box position="relative" width="100%" maxWidth={MAX_WIDTH}>
                            <SearchBar
                                placeholder="Search team members..."
                                inputValue={inputValue}
                                onInputChange={setInputValue}
                                onSearch={updateQuery}
                                onClearSearch={clearSearch}
                            />
                        </Box>
                    </Box>
                </Box>

                <SelectableUserList
                    query={query}
                    onClearSearch={clearSearch}
                    currentUserIdentity={currentUserIdentity}
                    selectedUser={selectedUser}
                    onUserSelect={setSelectedUser}
                />
            </ModalBody>
            <ModalFooter>
                <ModalFooterActions>
                    <Button
                        data-testid="next-button"
                        variant="primary"
                        disabled={selectedUser === undefined}
                        onClick={onNext}>
                        Next
                    </Button>
                </ModalFooterActions>
            </ModalFooter>
        </>
    );
}
