import React from 'react';
import {
    StyleSheet,
    TouchableWithoutFeedback,
    View,
    Text,
    Alert,
    AlertButton,
    Platform,
} from 'react-native';
import { compose, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ActionSheetProps, connectActionSheet } from '@expo/react-native-action-sheet';
import Clipboard from '@react-native-community/clipboard';

import { isMediaMessage, isUndelivered } from '@twilio/frontline-shared/models/Message';
import { getErrorDescription } from '@twilio/frontline-shared/models/ErrorCodes';

import { DefaultTheme } from '@twilio/frontline-shared/theme';
import {
    HideMessage,
    hideMessage,
    RetrySendMessage,
    retrySendMessage,
} from '@twilio/frontline-shared/store/messages/actions';
import { IChatMessage, IChatUser, MessagePosition } from './types';
import { MessageStatusIcon } from './MessageStatusIcon';
import { Analytics } from '../../analytics';
import { Time } from './Time';
import { MessageText } from './MessageText';
import { isMessageAuthoredBy, isSameDay, isSameAuthor } from './utils';
import { ChatContext } from './ChatContext';

const DEFAULT_OPTION_TITLES = ['Copy Text', 'Cancel'];
const COPY_TEXT_ACTION_INDEX = 0;
const CANCEL_ACTION_INDEX = 1;

export type BubbleProps = {
    user: IChatUser;
    currentMessage: IChatMessage;
    previousMessage?: IChatMessage;
    nextMessage?: IChatMessage;
    position: MessagePosition;
    retrySend(): void;
    hideMessage(): void;
} & ActionSheetProps;

export class Bubble extends React.Component<BubbleProps> {
    static context: React.ContextType<typeof ChatContext>;

    onLongPress: () => void;

    constructor(props: BubbleProps) {
        super(props);

        this.onLongPress = () => {
            const { currentMessage, showActionSheetWithOptions } = this.props;

            if (currentMessage && currentMessage.text) {
                const options = DEFAULT_OPTION_TITLES;

                showActionSheetWithOptions(
                    {
                        options,
                        cancelButtonIndex: CANCEL_ACTION_INDEX,
                    },
                    (buttonIndex) => {
                        switch (buttonIndex) {
                            case COPY_TEXT_ACTION_INDEX:
                                Clipboard.setString(currentMessage.text);
                                break;
                            default:
                                break;
                        }
                    },
                );
                Analytics.logEvent({
                    event: 'message_long_pressed',
                    extra: {
                        messageSid: currentMessage._id as string,
                        conversationSid: currentMessage.channelSid,
                    },
                });
            }
        };
    }

    showErrorDetail = () => {
        const alertFunc = this.context.onAlert || Alert.alert;
        const { currentMessage } = this.props;
        Analytics.logEvent('click_message_failed');
        const alertButtons: AlertButton[] = [
            {
                text: 'Delete',
                style: 'destructive',
                onPress: () => {
                    this.props.hideMessage();
                    Analytics.logEvent('click_failed_message_alert_delete');
                },
            },
            {
                text: 'Cancel',
                style: 'cancel',
                onPress: () => {
                    Analytics.logEvent('click_failed_message_alert_close');
                },
            },
            {
                text: 'Retry',
                onPress: () => {
                    this.props.retrySend();
                    Analytics.logEvent('click_failed_message_alert_retry');
                },
            },
        ];
        if (currentMessage.errorCode) {
            const errorDescription = getErrorDescription(currentMessage.errorCode);
            alertFunc(
                'Message failed to send',
                `The following error occurred while attempting to send this message: ${errorDescription.description} (${currentMessage.errorCode}). Please contact your support team.`,
                errorDescription.nonRecoverable ? [{ text: 'Close' }] : alertButtons,
            );
        } else {
            alertFunc(
                'Message failed to send',
                `There has been an error while sending the message. Would you like to retry?`,
                alertButtons,
            );
        }
    };

    renderMessageText() {
        const { currentMessage, position, showActionSheetWithOptions } = this.props;
        if (currentMessage?.text) {
            return (
                <MessageText
                    currentMessage={currentMessage}
                    position={position}
                    showActionSheetWithOptions={showActionSheetWithOptions}
                />
            );
        }
        return null;
    }

    renderMessageMedia() {
        const { MessageMedia } = this.context as React.ContextType<typeof ChatContext>;
        const { currentMessage, position } = this.props;
        if (currentMessage && isMediaMessage(currentMessage) && MessageMedia) {
            return <MessageMedia currentMessage={currentMessage} position={position} />;
        }

        return null;
    }

    renderTicks() {
        const { currentMessage, user } = this.props;

        if (!isMessageAuthoredBy(currentMessage, user)) {
            return null;
        }
        return (
            <MessageStatusIcon
                status={currentMessage.status}
                monoColor
                style={styles.content.tick}
            />
        );
    }

    renderFailedStatus() {
        const { currentMessage, user } = this.props;

        if (!isMessageAuthoredBy(currentMessage, user)) {
            return null;
        }

        if (currentMessage.failed) {
            const failedMessage = Platform.select({
                web: 'Message failed to send. Click for more info.',
                default: 'Message failed to send. Tap for more info.',
            });
            return this.renderFailedButton(failedMessage);
        }
        if (isUndelivered(currentMessage)) {
            const undeliveredMessage = Platform.select({
                web: 'Message not delivered. Click for more info.',
                default: 'Message not delivered. Tap for more info.',
            });
            return this.renderFailedButton(undeliveredMessage);
        }

        return null;
    }

    renderFailedButton(text: string) {
        return (
            <TouchableWithoutFeedback onPress={this.showErrorDetail} accessibilityRole="button">
                <View style={styles.content.failedStatusContainer}>
                    <Text style={styles.content.failedStatusText}>{text}</Text>
                </View>
            </TouchableWithoutFeedback>
        );
    }

    renderTime() {
        const { currentMessage, position } = this.props;
        if (currentMessage?.createdAt) {
            return <Time currentMessage={currentMessage} position={position} />;
        }
        return null;
    }

    renderBubbleContent() {
        return (
            <View>
                {this.renderMessageMedia()}
                {this.renderMessageText()}
            </View>
        );
    }

    renderName() {
        const { position, currentMessage, previousMessage } = this.props;

        if (
            position === 'left' &&
            currentMessage &&
            !(
                previousMessage &&
                isSameAuthor(currentMessage, previousMessage) &&
                isSameDay(currentMessage, previousMessage)
            )
        ) {
            return (
                <Text testID="friendlyNameLabel" style={styles.content.name}>
                    {currentMessage.user.name}
                </Text>
            );
        }
        return null;
    }

    render() {
        const { position } = this.props;
        return (
            <View style={styles[position].container}>
                {this.renderName()}
                <View testID="full-message-container" style={styles[position].wrapper}>
                    <TouchableWithoutFeedback
                        {...Platform.select({ native: { onLongPress: this.onLongPress } })}
                        accessibilityRole="text">
                        <View testID="message-text-view">
                            {this.renderBubbleContent()}
                            <View testID="message-time-view" style={styles[position].bottom}>
                                {this.renderTime()}
                                {this.renderTicks()}
                            </View>
                        </View>
                    </TouchableWithoutFeedback>
                </View>
                {this.renderFailedStatus()}
            </View>
        );
    }
}

Bubble.contextType = ChatContext;
type BubbleOwnProps = Omit<BubbleProps, 'retrySend' | 'hideMessage' | 'showActionSheetWithOptions'>;

const mapDispatchToProps = (
    dispatch: Dispatch<RetrySendMessage | HideMessage>,
    ownProps: BubbleOwnProps,
) => {
    const { currentMessage } = ownProps;
    const channelSid = currentMessage?.channelSid;
    const messageSid = String(currentMessage?._id);

    return {
        retrySend: () => dispatch(retrySendMessage({ channelSid, messageSid })),
        hideMessage: () => dispatch(hideMessage({ channelSid, messageSid })),
    };
};

export const BubbleContainer = compose<React.FC<BubbleOwnProps>>(
    connectActionSheet,
    connect(undefined, mapDispatchToProps),
)(Bubble);

const messageComponentBottomMargin = 2;

export const styles = {
    left: StyleSheet.create({
        container: {
            flex: 1,
            alignItems: 'flex-start',
        },
        wrapper: {
            borderRadius: DefaultTheme!.radii.borderRadius8,
            backgroundColor: DefaultTheme!.backgroundColors.colorBackground,
            marginRight: 56,
            minHeight: 20,
            minWidth: 80,
            maxWidth: 450,
            justifyContent: 'flex-end',
        },
        bottom: {
            flexDirection: 'row',
            justifyContent: 'flex-start',
            marginTop: DefaultTheme!.space.space10,
            marginLeft: DefaultTheme!.space.space10,
            marginRight: DefaultTheme!.space.space10,
            marginBottom: DefaultTheme!.space.space10 - messageComponentBottomMargin,
        },
    }),
    right: StyleSheet.create({
        container: {
            flex: 1,
            alignItems: 'flex-end',
        },
        wrapper: {
            borderRadius: DefaultTheme!.radii.borderRadius8,
            backgroundColor: DefaultTheme!.backgroundColors.colorBackgroundPrimary,
            marginLeft: 56,
            minHeight: 20,
            minWidth: 80,
            maxWidth: 450,
            justifyContent: 'flex-end',
        },
        bottom: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            marginTop: DefaultTheme!.space.space10,
            marginRight: DefaultTheme!.space.space10,
            marginLeft: DefaultTheme!.space.space10,
            marginBottom: DefaultTheme!.space.space10 - messageComponentBottomMargin,
        },
    }),
    content: StyleSheet.create({
        failedStatusContainer: {
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            marginTop: DefaultTheme!.space.space5,
        },
        failedStatusText: {
            color: DefaultTheme!.textColors.colorTextLinkDestructive,
            fontSize: DefaultTheme!.fontSizes.fontSize9,
            lineHeight: DefaultTheme!.lineHeights.lineHeight9,
        },
        tick: {
            marginLeft: DefaultTheme!.space.space10 - 2,
        },
        name: {
            marginBottom: DefaultTheme!.space.space5,
            fontSize: DefaultTheme!.fontSizes.fontSize9,
            fontWeight: DefaultTheme!.fontWeights.fontWeightBold as any,
            color: DefaultTheme!.textColors.colorTextWeak,
            lineHeight: DefaultTheme!.lineHeights.lineHeight10,
        },
    }),
};
