import { useCallback, useEffect, useRef, useState } from 'react';
import { Box } from '@twilio-paste/box';

import {
    InlinePlayer,
    InlinePlayerState,
} from '@twilio/frontline-shared/components/Chat/InlinePlayer';
import { IMedia, isMediaLoading, isMediaReady } from '@twilio/frontline-shared/models/Media';
import { useDispatch } from '@twilio/frontline-shared/store/redux';
import { downloadMedia } from '@twilio/frontline-shared/store/media/actions';
import { useCurrentlyPlaying } from '@twilio/frontline-shared/store/voice/VoiceState';
import { setCurrentlyPlaying } from '@twilio/frontline-shared/store/voice/actions';
import { MessagePosition } from '@twilio/frontline-shared/components/Chat/types';
import { useDebounce } from '../../../hooks/useDebounce';

type InlinePlayerContainerProps = {
    voiceMedia: IMedia;
    messagePosition: MessagePosition;
};

export function InlinePlayerContainer({ voiceMedia, messagePosition }: InlinePlayerContainerProps) {
    const audioPlayerRef = useRef(new Audio(voiceMedia.path));

    const dispatch = useDispatch();

    // TODO: Figure out how to start playing voice file after it was downloaded
    const [playIsPressed, setPlayIsPressed] = useState(false);
    const [playerState, setPlayerState] = useState<InlinePlayerState>(InlinePlayerState.None);

    const currentlyPlayingSid = useCurrentlyPlaying();
    const [duration, setDuration] = useState(0);
    const [position, setPosition] = useState(0);
    const [isWaitingForData, setIsWaitingForData] = useState(false);
    const [debouncedIsWaitingForData] = useDebounce([isWaitingForData], 500);

    const isPlayingThis = currentlyPlayingSid === voiceMedia.sid;
    const isFileReady = isMediaReady(voiceMedia);
    const isPlaying = playerState === InlinePlayerState.Playing && isPlayingThis;
    const isPaused = playerState === InlinePlayerState.Paused && isPlayingThis;

    useEffect(() => {
        if (!isPlayingThis && playerState === InlinePlayerState.Playing) {
            audioPlayerRef.current.pause();
        }
    }, [isPlayingThis]);

    useEffect(() => {
        const playEventListener = () => {
            setPlayerState(InlinePlayerState.Playing);
        };
        audioPlayerRef.current.addEventListener('play', playEventListener);

        const pauseEventListener = () => {
            audioPlayerRef.current.pause();
            setPlayerState(InlinePlayerState.Paused);
        };
        audioPlayerRef.current.addEventListener('pause', pauseEventListener);

        const seekedEventListener = () => {
            setPosition(audioPlayerRef.current.currentTime);
        };
        audioPlayerRef.current.addEventListener('seeked', seekedEventListener);

        const durationChangeEventListener = () => {
            setDuration(audioPlayerRef.current.duration);
        };
        audioPlayerRef.current.addEventListener('durationchange', durationChangeEventListener);

        const loadedMetaData = () => {
            setDuration(audioPlayerRef.current.duration);
        };
        audioPlayerRef.current.addEventListener('loadedmetadata', loadedMetaData);

        const timeUpdateEventListener = () => {
            setPosition(audioPlayerRef.current.currentTime);
        };
        audioPlayerRef.current.addEventListener('timeupdate', timeUpdateEventListener);

        const endedListener = () => {
            setPosition(0);
        };
        audioPlayerRef.current.addEventListener('ended', endedListener);

        const playingListener = () => {
            setIsWaitingForData(false);
        };
        audioPlayerRef.current.addEventListener('playing', playingListener);

        const stalledListener = () => {
            if (playerState === InlinePlayerState.Playing) {
                setIsWaitingForData(true);
            }
        };
        audioPlayerRef.current.addEventListener('stalled', stalledListener);

        const waitingListener = () => {
            if (playerState === InlinePlayerState.Playing) {
                setIsWaitingForData(true);
            }
        };
        audioPlayerRef.current.addEventListener('waiting', waitingListener);

        return () => {
            audioPlayerRef.current.removeEventListener('play', playEventListener);
            audioPlayerRef.current.removeEventListener('pause', pauseEventListener);
            audioPlayerRef.current.removeEventListener('seeked', seekedEventListener);
            audioPlayerRef.current.removeEventListener(
                'durationchange',
                durationChangeEventListener,
            );
            audioPlayerRef.current.removeEventListener('loadedmetadata', loadedMetaData);
            audioPlayerRef.current.removeEventListener('timeupdate', timeUpdateEventListener);
            audioPlayerRef.current.removeEventListener('ended', endedListener);
            audioPlayerRef.current.removeEventListener('playing', playingListener);
            audioPlayerRef.current.removeEventListener('stalled', stalledListener);
            audioPlayerRef.current.removeEventListener('waiting', waitingListener);
        };
    }, [voiceMedia.sid]);

    const playAudioPlayer = useCallback(() => {
        audioPlayerRef.current.play();
        setIsWaitingForData(true);
    }, []);

    const handlePause = useCallback(() => {
        audioPlayerRef.current.pause();
    }, []);

    const handleSeek = useCallback((time: number) => {
        if (Number.isFinite(time)) {
            audioPlayerRef.current.currentTime = time;
        }
    }, []);

    useEffect(() => {
        if (isFileReady) {
            if (!voiceMedia.path) {
                // noop
                return;
            }

            audioPlayerRef.current.src = voiceMedia.path;
        }
    }, [isFileReady]);

    useEffect(() => {
        if (playIsPressed && isFileReady) {
            setPlayIsPressed(false);

            if (!isPlayingThis) {
                dispatch(setCurrentlyPlaying({ sid: voiceMedia.sid }));
                handleSeek(position);
            }

            playAudioPlayer();
        }
    }, [playIsPressed, isFileReady]);

    const handlePlay = () => {
        if (isPlaying) {
            return handlePause();
        }
        setPlayIsPressed(true);
        if (!isFileReady) {
            dispatch(downloadMedia({ media: voiceMedia }));
        }
        return undefined;
    };

    let playerUiState;
    if (isPlaying && !isWaitingForData) {
        playerUiState = InlinePlayerState.Playing;
    } else if (isMediaLoading(voiceMedia) || (debouncedIsWaitingForData && isPlaying)) {
        playerUiState = InlinePlayerState.Loading;
    } else if (isPaused) {
        playerUiState = InlinePlayerState.Paused;
    } else {
        playerUiState = InlinePlayerState.Ready;
    }

    return (
        <Box width={320}>
            <InlinePlayer
                onPlay={handlePlay}
                onPause={handlePause}
                onSeek={handleSeek}
                state={playerUiState}
                position={position}
                duration={duration}
                messagePosition={messagePosition}
            />
        </Box>
    );
}
