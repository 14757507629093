import {
    ACCOUNT_SUSPENDED_ERROR_ALERT,
    DEFAULT_SESSION_ERROR_ALERT,
    isInvalidAccessTokenIssuer,
    isNotFoundServiceByInstance,
    isUpdateRequiredError,
    SERVICE_NOT_FOUND_ERROR_ALERT,
} from '../models/ErrorCodes';

export class SessionError extends Error {
    cause?: Error;

    constructor(cause: Error) {
        super(cause.message);
        this.cause = cause;
    }

    get isUpdateRequiredError() {
        return isUpdateRequiredError(this.cause);
    }

    /**
     * Returns [error.title, error.message]
     */
    get alertInfo(): [string, string] {
        if (isNotFoundServiceByInstance(this.cause)) {
            return [SERVICE_NOT_FOUND_ERROR_ALERT.title, SERVICE_NOT_FOUND_ERROR_ALERT.message];
        }
        if (isInvalidAccessTokenIssuer(this.cause)) {
            return [ACCOUNT_SUSPENDED_ERROR_ALERT.title, ACCOUNT_SUSPENDED_ERROR_ALERT.message];
        }
        return [
            DEFAULT_SESSION_ERROR_ALERT.title,
            this.cause?.message || DEFAULT_SESSION_ERROR_ALERT.message,
        ];
    }
}
