import React, { useContext } from 'react';
import { AlertButton, AlertOptions } from 'react-native';
import { Conversation } from '@twilio/frontline-shared/models/Conversation';
import {
    MessageMediaProps,
    ParticipantAvatarProps,
    ActionsProps,
    ComposerProps,
    SendButtonProps,
    TemplateOnlyComposerProps,
} from './types';
import { IDraftMedia } from '../../models/Media';

export type IChatContext = {
    timeFormat?: string;
    ParticipantAvatar?: React.FC<ParticipantAvatarProps>;
    MessageMedia?: React.FC<MessageMediaProps>;
    InputToolbarContainer?: ({ children }: { children: React.ReactNode }) => JSX.Element;
    Actions?: React.FC<ActionsProps>;
    Composer?: React.FC<ComposerProps>;
    SendButton?: React.FC<SendButtonProps>;
    TemplateOnlyComposer?: React.FC<TemplateOnlyComposerProps>;
    onAlert?: (
        title: string,
        message?: string,
        buttons?: AlertButton[],
        options?: AlertOptions,
    ) => void;
    onSendMedia?: (media: IDraftMedia) => void;
    onShowRecorderAttempt?: (callback: () => void) => void;
    dateFormatter?: (date: Date) => string;
    audioRecordingEnabled?: boolean;
    conversationSid?: Conversation['sid'];
};

export const ChatContext = React.createContext<IChatContext>({});

export const useChatContext = () => {
    const context = useContext(ChatContext);
    if (context === null) {
        throw new Error('useChatContext must be used within a ChatContext.Provider');
    }
    return context;
};
