import { useState, KeyboardEvent, ChangeEvent, useEffect } from 'react';

import { Input } from '@twilio-paste/input';
import { Button } from '@twilio-paste/button';
import { Box } from '@twilio-paste/box';
import { Text } from '@twilio-paste/text';
import { Anchor } from '@twilio-paste/anchor';
import { HelpText } from '@twilio-paste/help-text';
import { Heading } from '@twilio-paste/heading';
import { Flex } from '@twilio-paste/flex';
import { Label } from '@twilio-paste/label';
import { FASClient, SSOError, SSOErrorToMessage } from '@twilio/frontline-shared/core/FASClient';
import { InformationIcon } from '@twilio-paste/icons/cjs/InformationIcon';
import { ArrowForwardIcon } from '@twilio-paste/icons/cjs/ArrowForwardIcon';

import logo from '../../assets/frontline-logo.svg';
import twilioLogo from '../../assets/twilio-logo.svg';
import { CredentialsStorage } from '../../core/CredentialsStorage';
import { WorkspaceIDInfoModal } from './components/WorkspaceIDInfoModal';

export function Login() {
    const [workspace, setWorkspace] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isInfoOpen, setIsInfoOpen] = useState(false);
    const [error, setError] = useState<SSOError>();

    useEffect(() => {
        CredentialsStorage.getAccountUniqueName().then((initialWorkspace) => {
            if (initialWorkspace) {
                setWorkspace(initialWorkspace);
            }
        });
    }, []);

    const handleLoginSSO = () => {
        const reg = /^([a-zA-Z0-9])([a-zA-Z0-9\-]){0,19}([a-zA-Z0-9])$/g;
        if (!new RegExp(reg).test(workspace)) {
            setError(SSOError.NOT_FOUND);
            return;
        }

        setIsSubmitting(true);
        FASClient.getSSOUrl(workspace)
            .then((res) => {
                CredentialsStorage.setAccountUniqueName(workspace);
                window.location.assign(res);
            })
            .catch((err) => {
                if (err === SSOError.NOT_FOUND) {
                    setError(SSOError.NOT_FOUND);
                } else {
                    setError(SSOError.REQUEST_FAILED);
                }
            })
            .finally(() => setIsSubmitting(false));
    };

    const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleLoginSSO();
        }
    };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (error) {
            setError(undefined);
        }
        setWorkspace(e.target.value);
    };

    return (
        <Box height="100vh">
            <WorkspaceIDInfoModal isInfoOpen={isInfoOpen} onDismiss={() => setIsInfoOpen(false)} />
            <Flex height="100%" vertical>
                <Flex paddingX="space50" grow={1} width="100%" vAlignContent="center">
                    <Flex width="100%">
                        <Box flex={[0, 0.5, 1]} />
                        <Box flex={1} marginY="space50">
                            <Flex vAlignContent="center" marginBottom="space130">
                                <img src={logo} style={{ height: 24, marginRight: 8 }} alt="logo" />
                                <Heading as="h5" variant="heading50" marginBottom="space0">
                                    Twilio Frontline
                                </Heading>
                            </Flex>

                            <Box marginBottom="space90">
                                <Heading
                                    as="h1"
                                    variant="heading10"
                                    marginBottom="space0"
                                    data-testid="sign-in-heading">
                                    Sign in to your workspace
                                </Heading>
                            </Box>
                            <Label htmlFor="workspace_id">Workspace ID</Label>
                            <Flex>
                                <Input
                                    aria-describedby="workspace_id_help_text"
                                    id="workspace_id"
                                    name="workspace_id"
                                    type="text"
                                    placeholder="e.g. owl-shoes-co"
                                    onKeyPress={handleKeyPress}
                                    onChange={handleInputChange}
                                    value={workspace}
                                />
                                <Box paddingLeft="space30">
                                    <Button
                                        loading={isSubmitting}
                                        disabled={workspace.length === 0}
                                        variant="primary"
                                        data-testid="submit-workspace-button"
                                        onClick={handleLoginSSO}>
                                        <ArrowForwardIcon decorative={false} title="Next" />
                                    </Button>
                                </Box>
                            </Flex>
                            {error && (
                                <HelpText variant="error" id="workspace_id_help_text">
                                    {SSOErrorToMessage[error]}
                                </HelpText>
                            )}
                            <Box marginTop="space90">
                                <Button
                                    variant="link"
                                    data-testid="no-workspace-button"
                                    onClick={() => setIsInfoOpen(true)}>
                                    <Text
                                        as="span"
                                        color="colorTextLink"
                                        fontWeight="fontWeightSemibold">
                                        Don’t have a workspace ID?
                                    </Text>
                                    <InformationIcon
                                        title="Don’t have a workspace ID?"
                                        decorative
                                    />
                                </Button>
                            </Box>
                            <Box
                                paddingTop="space130"
                                marginBottom="space70"
                                data-testid="agreement-text">
                                By logging in, you agree to the Twilio Frontline{' '}
                                <Anchor
                                    href="https://www.twilio.com/legal/frontline-terms"
                                    data-testid="terms-of-service">
                                    Terms of Service
                                </Anchor>{' '}
                                and acknowledge that you read the{' '}
                                <Anchor
                                    href="https://www.twilio.com/legal/privacy"
                                    data-testid="privacy-notice">
                                    Privacy Notice
                                </Anchor>
                                .
                            </Box>
                        </Box>
                        <Box flex={[0, 0.5, 1]} />
                    </Flex>
                </Flex>
                <Box alignSelf="center" padding="space70">
                    <img src={twilioLogo} alt="logo" />
                </Box>
            </Flex>
        </Box>
    );
}
