import { useRef, useEffect, KeyboardEvent } from 'react';

import { Box } from '@twilio-paste/box';
import { Button } from '@twilio-paste/button';
import { AttachIcon } from '@twilio-paste/icons/cjs/AttachIcon';
import { TextArea } from '@twilio-paste/textarea';

import { ComposerProps } from '@twilio/frontline-shared/components/Chat/types';

import { Actions } from './Actions';

export function Composer({
    text,
    editing,
    isOutOfWhatsAppWindow,
    hideSendAttachmentButton,
    onTemplatesPress,
    onAttachmentsPress,
    onTextChanged,
    onSendMessage,
}: ComposerProps) {
    const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

    useEffect(() => {
        // we change rows via ref because we can't change or pass height to the TextArea
        // because this is an intentional choice to keep TextAreas consistent across Twilio
        // https://github.com/twilio-labs/paste/discussions/2180
        textAreaRef.current?.setAttribute('rows', '1');
    }, [textAreaRef]);

    const handleKeyPress = (e: KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            onSendMessage();
        }
    };

    const renderAttachButton = () => {
        if (hideSendAttachmentButton) return null;
        return (
            <>
                <Button variant="link" size="reset" onClick={onAttachmentsPress}>
                    <AttachIcon decorative={false} title="attach" size="sizeIcon40" />
                </Button>
                <Box marginLeft="space50" />
            </>
        );
    };

    const showSendButton = editing || isOutOfWhatsAppWindow;

    const renderActions = () => {
        if (showSendButton) return null;
        return <Actions onPress={onTemplatesPress} />;
    };

    return (
        <Box display="flex" alignItems="center" flexGrow={1}>
            {renderAttachButton()}
            <Box marginRight={showSendButton ? 'space50' : 'space0'} flexGrow={1}>
                <TextArea
                    ref={textAreaRef}
                    value={text}
                    onChange={(e) => onTextChanged(e.target.value)}
                    onKeyPress={handleKeyPress}
                    placeholder="Add your message"
                    data-testid="message-area"
                    insertAfter={renderActions()}
                />
            </Box>
        </Box>
    );
}
