// Based on https://github.com/onurzorluer/react-image-file-resizer

type CompressFormat = 'JPEG' | 'PNG' | 'WEBP';
type OutputType = 'base64' | 'file';
type ResponseUriFunction = (value: string | File) => void;

export class ImageResizer {
    static changeHeightWidth(
        height: number,
        maxHeight: number,
        width: number,
        maxWidth: number,
    ): { height: number; width: number } {
        if (width > maxWidth) {
            height = Math.round((height * maxWidth) / width);
            width = maxWidth;
        }
        if (height > maxHeight) {
            width = Math.round((width * maxHeight) / height);
            height = maxHeight;
        }
        return { height, width };
    }

    static resizeImage(
        image: HTMLImageElement,
        maxWidth: number,
        maxHeight: number,
        compressFormat: CompressFormat = 'JPEG',
        quality = 100,
        testOptions?: { canvas: HTMLCanvasElement },
    ) {
        const qualityDecimal = quality / 100;
        const canvas = testOptions?.canvas ?? document.createElement('canvas');

        let { width, height } = image;

        const newHeightWidth = this.changeHeightWidth(height, maxHeight, width, maxWidth);

        canvas.width = newHeightWidth.width;
        canvas.height = newHeightWidth.height;

        width = newHeightWidth.width;
        height = newHeightWidth.height;

        const ctx = canvas.getContext('2d');
        if (!ctx) {
            throw new Error('context is undefined');
        }
        ctx.fillStyle = 'rgba(0, 0, 0, 0)';
        ctx.fillRect(0, 0, width, height);

        if (ctx.imageSmoothingEnabled && ctx.imageSmoothingQuality) {
            ctx.imageSmoothingQuality = 'high';
        }

        ctx.drawImage(image, 0, 0, width, height);

        return canvas.toDataURL(`image/${compressFormat}`, qualityDecimal);
    }

    static b64toByteArrays(b64Data: string | Blob) {
        const sliceSize = 512;

        const byteCharacters = atob(
            b64Data.toString().replace(/^data:image\/(png|jpeg|jpg|webp);base64,/, ''),
        );
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }
        return byteArrays;
    }

    static b64toFile(b64Data: string, fileName: string, contentType: string) {
        const byteArrays = this.b64toByteArrays(b64Data);
        return new File(byteArrays, fileName, {
            type: contentType,
            lastModified: Date.now(),
        });
    }

    static processAfterReaderOnload(
        fileName: string,
        image: HTMLImageElement,
        maxWidth: number,
        maxHeight: number,
        compressFormat: CompressFormat,
        quality: number,
        outputType: OutputType,
        responseUriFunc: ResponseUriFunction,
        testOptions?: { canvas: HTMLCanvasElement },
    ) {
        const resizedDataUrl = ImageResizer.resizeImage(
            image,
            maxWidth,
            maxHeight,
            compressFormat,
            quality,
            testOptions,
        );
        const contentType = `image/${compressFormat}`;
        if (outputType === 'base64') {
            responseUriFunc(resizedDataUrl);
        } else {
            const fileNameWithoutFormat = fileName.toString().replace(/(png|jpeg|jpg|webp)$/i, '');
            const newFileName = fileNameWithoutFormat.concat(
                compressFormat.toString().toLowerCase(),
            );
            const newFile = ImageResizer.b64toFile(resizedDataUrl, newFileName, contentType);
            responseUriFunc(newFile);
        }
    }

    static createResizedImage(
        file: File,
        maxWidth: number,
        maxHeight: number,
        compressFormat: CompressFormat,
        quality: number,
        responseUriFunc: (value: string | File) => void,
        outputType: OutputType = 'file',
    ) {
        const reader = new FileReader();
        if (!file) {
            throw new Error('File is not defined');
        }
        if (file.type && !file.type.includes('image')) {
            throw new Error('File is not an image');
        } else {
            reader.readAsDataURL(file);
            reader.onload = () => {
                const image = new Image();
                image.src = reader.result as string;
                image.onload = function () {
                    ImageResizer.processAfterReaderOnload(
                        file.name,
                        image,
                        maxWidth,
                        maxHeight,
                        compressFormat,
                        quality,
                        outputType,
                        responseUriFunc,
                    );
                };
            };
            reader.onerror = () => {
                throw new Error('Failed to load image data into FileReader');
            };
        }
    }
}

export const { createResizedImage } = ImageResizer;
