import React from 'react';
import { Platform, StyleSheet, TouchableOpacity, View } from 'react-native';
import { getBottomSpace } from 'react-native-iphone-x-helper';

import { DefaultTheme } from '@twilio/frontline-shared/theme';
import { TwilIcon } from '@twilio/frontline-shared/components/TwilIcon';

import { useChatContext } from './ChatContext';

export type ActionsProps = {
    editing: boolean;
    isOutOfWhatsAppWindow: boolean;
    onPressActionButton?: () => void;
    onPressRecorder: () => void;
};

export function Actions({
    editing,
    isOutOfWhatsAppWindow,
    onPressActionButton,
    onPressRecorder,
}: ActionsProps) {
    const { Actions: CustomActions, audioRecordingEnabled } = useChatContext();

    if (editing || isOutOfWhatsAppWindow) return null;

    if (CustomActions) {
        return <CustomActions onPress={onPressActionButton} />;
    }

    return (
        <View style={styles.actionsView}>
            <TouchableOpacity
                testID="openTemplatesButton"
                style={styles.actionsButton}
                onPress={onPressActionButton}>
                <TwilIcon name="templates" size={24} />
            </TouchableOpacity>
            {audioRecordingEnabled && (
                <TouchableOpacity
                    testID="openAudioRecorderButton"
                    style={styles.microphoneButton}
                    onPress={onPressRecorder}>
                    <TwilIcon name="microphone" size={20} />
                </TouchableOpacity>
            )}
        </View>
    );
}

const styles = StyleSheet.create({
    actionsView: {
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        position: 'absolute',
        flexDirection: 'row',
        right: 32,
        bottom: Platform.select({
            // Rendered font size slightly differ on ios/android
            ios: DefaultTheme.sizes.size15 - DefaultTheme.sizes.size1 + getBottomSpace(),
            default: DefaultTheme.sizes.size15,
        }),
        zIndex: 1,
    },
    actionsButton: {
        width: 26,
        height: 26,
        marginLeft: 10,
        marginBottom: 10,
    },
    microphoneButton: {
        width: 26,
        height: 26,
        marginLeft: 10,
        marginBottom: 10,
        padding: 2,
    },
});
