import { useState } from 'react';

import { useChatUser } from '@twilio/frontline-shared/store/session/hooks';

import { useSession } from '../context/SessionContext';

export enum AvailabilityState {
    Idle = 'idle',
    Pending = 'pending',
    Resolved = 'resolved',
    Rejected = 'rejected',
}

export function useAvailability() {
    const [state, setState] = useState<AvailabilityState>(AvailabilityState.Idle);
    const chatUser = useChatUser();
    const { session } = useSession();

    const setAvailability = (activitySid: string) => {
        if (!session) {
            return Promise.reject(new Error('Session is null or undefined'));
        }
        setState(AvailabilityState.Pending);
        return session
            .setAvailability(activitySid)
            .then(() => setState(AvailabilityState.Resolved))
            .catch((error) => {
                setState(AvailabilityState.Rejected);
                throw error;
            });
    };

    const isAvailable = chatUser?.activitySid === session?.configuration?.availableActivitySid;

    return { state, isAvailable, setAvailability };
}
