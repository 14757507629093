import { Box } from '@twilio-paste/box';
import { Text } from '@twilio-paste/text';
import { Customer } from '@twilio/frontline-shared/types/customer';

import { Avatar } from '../../../components/Avatar';

export const CUSTOMERS_LIST_ITEM_HEIGHT = 60;

type MyCustomerListItemProps = {
    customer: Customer;
    selectedCustomerId: string | null;
    onSelect: (customerId: string) => void;
};

export function MyCustomerListItem({
    customer,
    selectedCustomerId,
    onSelect,
}: MyCustomerListItemProps) {
    return (
        <Box
            as="a"
            onClick={() => onSelect(String(customer.customer_id))}
            height={CUSTOMERS_LIST_ITEM_HEIGHT}
            width="100%"
            display="flex"
            alignItems="center"
            paddingLeft="space50"
            paddingRight="space50"
            cursor="pointer"
            _hover={
                selectedCustomerId === String(customer.customer_id)
                    ? {
                          backgroundColor: 'colorBackground',
                          borderColor: 'colorBorderInverseStronger',
                      }
                    : {
                          backgroundColor: 'colorBackground',
                          borderColor: 'colorBorderWeak',
                      }
            }
            borderTopWidth="borderWidth10"
            borderBottomWidth="borderWidth10"
            borderLeftWidth="borderWidth0"
            borderRightWidth="borderWidth0"
            borderStyle="solid"
            _active={{
                backgroundColor:
                    selectedCustomerId === String(customer.customer_id)
                        ? 'colorBackground'
                        : 'colorBackgroundStrong',
            }}
            backgroundColor={
                selectedCustomerId === String(customer.customer_id)
                    ? 'colorBackground'
                    : 'colorBackgroundBody'
            }
            borderColor={
                selectedCustomerId === String(customer.customer_id)
                    ? 'colorBorderInverseStronger'
                    : 'colorBorderInverseStrongest'
            }>
            <Avatar
                size="sizeIcon70"
                friendlyName={customer.display_name}
                avatar={customer.avatar}
            />
            <Text
                as="span"
                fontSize="fontSize30"
                color="colorText"
                fontWeight="fontWeightMedium"
                marginLeft="space30"
                data-testid="customer-name">
                {customer.display_name}
            </Text>
        </Box>
    );
}
