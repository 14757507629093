import { Box } from '@twilio-paste/box';
import { Flex } from '@twilio-paste/flex';
import { Text } from '@twilio-paste/text';
import { SkeletonLoader } from '@twilio-paste/skeleton-loader';
import { ConnectivityAvailableIcon } from '@twilio-paste/icons/cjs/ConnectivityAvailableIcon';
import { ConnectivityOfflineIcon } from '@twilio-paste/icons/cjs/ConnectivityOfflineIcon';

import { IUser } from '@twilio/frontline-shared/models/User';
import { Avatar } from './Avatar';
import { useSession } from '../context/SessionContext';

export type UserInfoProps = {
    user?: IUser;
};

export function UserInfo({ user }: UserInfoProps) {
    const { session } = useSession();
    if (!user) {
        return (
            <Flex vAlignContent="center">
                <Box paddingX="space30">
                    <SkeletonLoader width={32} height={32} borderRadius="borderRadiusCircle" />
                </Box>
                <Flex vertical>
                    <SkeletonLoader width={160} height={20} borderRadius="borderRadius20" />
                    <Box marginTop="space20">
                        <SkeletonLoader width={117} height={20} borderRadius="borderRadius20" />
                    </Box>
                </Flex>
            </Flex>
        );
    }
    return (
        <Flex vAlignContent="center">
            <Box paddingX="space30">
                <Avatar size="sizeIcon70" friendlyName={user?.friendlyName} avatar={user.avatar} />
            </Box>
            <Flex vertical>
                <Text as="span" fontWeight="fontWeightBold">
                    {user.friendlyName}
                </Text>
                {session?.configuration?.availableActivitySid === user?.activitySid ? (
                    <Box marginLeft="spaceNegative20" marginTop="space20" display="inherit">
                        <ConnectivityAvailableIcon
                            color="colorTextIconAvailable"
                            decorative={false}
                            title="available"
                        />
                        Available
                    </Box>
                ) : (
                    <Box marginLeft="spaceNegative20" marginTop="space20" display="inherit">
                        <ConnectivityOfflineIcon
                            color="colorTextIconOffline"
                            decorative={false}
                            title="offline"
                        />
                        Unavailable
                    </Box>
                )}
            </Flex>
        </Flex>
    );
}
