import { Modal, ModalHeader, ModalHeading } from '@twilio-paste/modal';
import { useUID } from '@twilio-paste/uid-library';
import { WorkerUser } from '@twilio/frontline-shared/models/WorkerUser';
import { Conversation } from '@twilio/frontline-shared/models/Conversation';
import { ChannelType } from '@twilio/frontline-shared/types/channel';
import { TransferState } from '../TransferConversation';
import { ConfirmTransferModalBodyAndFooter } from './ConfirmTransferModalBodyAndFooter';
import { SelectUserModalBodyAndFooter } from './SelectUserModalBodyAndFooter';

export type TransferConversationModalProps = {
    conversationSid: Conversation['sid'];
    channelFriendlyName: Conversation['friendlyName'];
    channelAvatar: Conversation['avatar'];
    channelType: ChannelType | undefined;
    currentUserFriendlyName: WorkerUser['friendly_name'];
    currentUserIdentity: WorkerUser['identity'];
    newUser: WorkerUser | undefined;
    setNewUser: (newUser: WorkerUser | undefined) => void;
    transferState: TransferState;
    setTransferState: (state: TransferState) => void;
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    transferConversation: (conversationSid: string, newUser: WorkerUser) => void;
};

export function TransferConversationModal({
    conversationSid,
    channelFriendlyName,
    channelAvatar,
    channelType,
    currentUserFriendlyName,
    currentUserIdentity,
    newUser,
    setNewUser,
    transferState,
    setTransferState,
    isOpen,
    setIsOpen,
    transferConversation,
}: TransferConversationModalProps) {
    const modalHeadingID = useUID();

    const openConfirmTransferModal = () => {
        setTransferState(TransferState.Confirm);
    };

    const handleConfirmTransfer = () => {
        setIsOpen(false);
        transferConversation(conversationSid, newUser as WorkerUser);
    };

    const handleBack = () => {
        setTransferState(TransferState.SelectUser);
    };

    return (
        <Modal
            ariaLabelledby={modalHeadingID}
            isOpen={isOpen}
            onDismiss={() => setIsOpen(false)}
            size="default">
            <ModalHeader>
                <ModalHeading as="h3" id={modalHeadingID} data-testid="modal-title">
                    Transfer conversation
                </ModalHeading>
            </ModalHeader>
            {transferState === TransferState.SelectUser && (
                <SelectUserModalBodyAndFooter
                    currentUserIdentity={currentUserIdentity}
                    selectedUser={newUser}
                    setSelectedUser={setNewUser}
                    onNext={openConfirmTransferModal}
                />
            )}
            {transferState === TransferState.Confirm && (
                <ConfirmTransferModalBodyAndFooter
                    channelFriendlyName={channelFriendlyName}
                    channelAvatar={channelAvatar}
                    currentUserFriendlyName={currentUserFriendlyName}
                    newUser={newUser as WorkerUser}
                    onBack={handleBack}
                    onConfirm={handleConfirmTransfer}
                    channelType={channelType}
                />
            )}
        </Modal>
    );
}
