import { Box } from '@twilio-paste/box';
import { Flex } from '@twilio-paste/flex';
import { Heading } from '@twilio-paste/heading';
import { NeutralIcon } from '@twilio-paste/icons/cjs/NeutralIcon';
import { Paragraph } from '@twilio-paste/paragraph';
import { Anchor } from '@twilio-paste/anchor';

type DeveloperGuidanceProps = {
    text: string;
    docUrl?: string;
};

export function DeveloperGuidance({
    text,
    docUrl = 'https://www.twilio.com/docs/frontline',
}: DeveloperGuidanceProps) {
    return (
        <Box
            width="100%"
            padding="space60"
            borderWidth="borderWidth20"
            borderColor="colorBorderWeaker"
            borderStyle="solid"
            borderRadius="borderRadius20"
            backgroundColor="colorBackground">
            <Flex>
                <Flex paddingRight="space30">
                    <NeutralIcon decorative color="colorTextIcon" />
                </Flex>
                <Heading variant="heading50" as="h5">
                    Developer guidance
                </Heading>
            </Flex>
            <Paragraph marginBottom="space0">
                {`${text} `}
                <Anchor href={docUrl} showExternal>
                    Read the docs
                </Anchor>
            </Paragraph>
        </Box>
    );
}
