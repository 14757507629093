import { MediaObject, MediaFigure, MediaBody } from '@twilio-paste/media-object';
import { ConnectivityAvailableIcon } from '@twilio-paste/icons/cjs/ConnectivityAvailableIcon';
import { ConnectivityOfflineIcon } from '@twilio-paste/icons/cjs/ConnectivityOfflineIcon';

type MenuItemAvailabilityButtonProps = {
    isAvailable: boolean;
};

export function MenuItemAvailabilityButton({ isAvailable }: MenuItemAvailabilityButtonProps) {
    return (
        <MediaObject verticalAlign="center">
            {isAvailable ? (
                <>
                    <MediaFigure>
                        <ConnectivityOfflineIcon
                            color="colorTextIconOffline"
                            decorative={false}
                            title="offline"
                        />
                    </MediaFigure>
                    <MediaBody>Set yourself to unavailable</MediaBody>
                </>
            ) : (
                <>
                    <MediaFigure>
                        <ConnectivityAvailableIcon
                            color="colorTextIconAvailable"
                            decorative={false}
                            title="available"
                        />
                    </MediaFigure>
                    <MediaBody>Set yourself to available</MediaBody>
                </>
            )}
        </MediaObject>
    );
}
