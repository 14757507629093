import { NotificationsChannelType, UserUpdatedEventArgs } from '@twilio/conversations';
import { ChannelType } from '@twilio/notifications';
import { EventEmitter } from 'events';
import { ConnectionState, TwilsockClient } from 'twilsock';
import TypedEmitter from 'typed-emitter';

import { Analytics } from '../analytics';
import { AppCallbackPayload, AppCallbackTypePayload } from '../core/Callbacks/types';
import { ConversationsClient } from '../core/ConversationsClient';
import { FrontlineClient, FRONTLINE_GRANT_NAME } from '../core/FrontlineClient';
import { SessionError } from '../core/SessionError';
import { JWEToken, Token } from '../core/Token';
import { TokenManager, TOKEN_EXPIRED, TOKEN_UPDATED } from '../core/TokenManager';
import { FrontlineRole } from '../core/types';
import { VoiceNotificationsClient } from '../core/VoiceNotificationsClient';
import { isUpdateRequiredError } from '../models/ErrorCodes';
import { buildConfiguration, FrontlineConfiguration } from '../models/FrontlineConfiguration';
import { getCallbackError } from '../models/FrontlineError';
import { fromTwilioUser, isUserDeactived } from '../models/User';
import { ConversationSid, TwilioConversation, TwilioUser } from '../types';

type FrontlineSDKParams = {
    token: Token;
    accountUniqueName: string | null;
    options: {
        supportsVoice?: boolean;
        appName: string;
        appVersion: string;
        region?: string;
    };
};

export enum BETA_FEATURE_FLAGS {
    WEB_PUSH_NOTIFICATIONS = 'frontline-web-push-notifications',
    HIDE_SEND_ATTACHMENT_BUTTON = 'frontline-hide-send-attachment-button',
    MARK_CONVERSATION_UNREAD = 'frontline-mark-conversation-unread',
}

interface FrontlineSDKEvents {
    tokenUpdated: (token: JWEToken) => void;
    tokenExpired: () => void;
    updateRequired: () => void;
    userDeactivated: () => void;
    connectionStateChanged: (connectionState: ConnectionState) => void;
    userUpdated: ({ user, updateReasons }: UserUpdatedEventArgs) => void;
    conversationJoined: (conversation: TwilioConversation) => void;
    conversationAdded: (conversation: TwilioConversation) => void;
    conversationLeft: (conversation: TwilioConversation) => void;
    conversationRemoved: (conversation: TwilioConversation) => void;
}

export class FrontlineSDK extends (EventEmitter as new () => TypedEmitter<FrontlineSDKEvents>) {
    static shared?: FrontlineSDK;

    public configuration?: FrontlineConfiguration;

    public isVoiceEnabled?: boolean;

    public isChannelAddressMaskingEnabled?: boolean;

    public readonly token: Token;

    public role: FrontlineRole = FrontlineRole.User;

    private options: FrontlineSDKParams['options'];

    private readonly accountUniqueName: string | null;

    private enabledBetaFlags: string[] = [];

    private tokenManager?: TokenManager;

    private twilsockClient: TwilsockClient;

    private conversationsClient?: ConversationsClient;

    private frontlineClient: FrontlineClient;

    private voiceNotificationsClient?: VoiceNotificationsClient;

    constructor({ token, accountUniqueName, options }: FrontlineSDKParams) {
        super();
        this.token = token;
        this.accountUniqueName = accountUniqueName;
        this.options = options;

        this.twilsockClient = new TwilsockClient(this.token.token, FRONTLINE_GRANT_NAME, {
            clientMetadata: this.clientMetadata,
            region: this.options.region,
        });

        this.twilsockClient.on('stateChanged', (connectionState: ConnectionState) => {
            console.log('📍 connectionStateChanged: ', connectionState);
            this.emit('connectionStateChanged', connectionState);
        });

        this.frontlineClient = new FrontlineClient(this.token.token, {
            twilsockClient: this.twilsockClient,
        });

        if (this.options.supportsVoice) {
            this.voiceNotificationsClient = new VoiceNotificationsClient(this.token.token, {
                twilsockClient: this.twilsockClient,
                region: this.options.region,
            });
        }

        FrontlineSDK.shared = this;
    }

    public static async create(params: FrontlineSDKParams) {
        const frontlineSDK = new FrontlineSDK(params);
        await frontlineSDK.start();
        FrontlineSDK.shared = frontlineSDK;
        return frontlineSDK;
    }

    get clientMetadata() {
        return {
            app: this.options.appName,
            appv: this.options.appVersion,
        };
    }

    get isActive(): boolean {
        return this.tokenManager?.isActive || false;
    }

    async start() {
        try {
            // 1. Connect to Twilsock
            // Start connecting to Twilsock after instantiating all other clients/SDKs
            // and wait for the connection to fully initialize
            await this.initializeTwilsockConnection(this.twilsockClient);

            // 2. Get Frontline Role
            // It will create conversations user if it doesn't exist with the grant role
            // This should be done before initializing Conversations SDK because otherwise
            // there will be a race between frontline and conversations to provision user
            const { role } = await this.frontlineClient.getRole(this.token.identity);
            this.role = role;

            // 3. Connect to Conversations API
            // Conversations SDK has an additional initialization steps after the Twilsock connection is established.
            // This step also triggers Chat User provisioning.
            await this.initializeConversationsApiConnection();

            // 4. Fetch Frontline configuration
            const [configuration, enabledBetaFlags] = await Promise.all([
                this.getConfiguration(),
                this.getEnabledBetaFeatureFlags(),
            ]);

            this.configuration = configuration;
            this.isVoiceEnabled = configuration.voiceCallingConfigEnabled;
            this.isChannelAddressMaskingEnabled = configuration.maskChannelAddressEnabled;
            this.enabledBetaFlags = enabledBetaFlags;

            console.log('Frontline Configuration: ', configuration);
            console.log('📍 EnabledFlags: ', enabledBetaFlags);

            this.tokenManager = new TokenManager(this.token, this.configuration.ssoRealmSid);

            this.twilsockClient.on('tokenAboutToExpire', () => {
                this.refreshToken();
            });

            this.twilsockClient.on('tokenExpired', () => {
                this.refreshToken();
            });

            this.tokenManager.on(TOKEN_UPDATED, (token) => {
                this.emit(TOKEN_UPDATED, token);
                console.log('📍 trying to update token');
                this.twilsockClient
                    .updateToken(token.token)
                    .then(() => console.log('🌈 Updated Frontline Token'));
            });

            this.tokenManager.on(TOKEN_EXPIRED, () => {
                this.emit(TOKEN_EXPIRED);
            });

            this.activateUserIfNeeded(this.user);
            this.on('userUpdated', ({ user, updateReasons }) => {
                if (user.identity === this.userIdentity) {
                    if (
                        updateReasons.includes('attributes') &&
                        isUserDeactived(fromTwilioUser(user))
                    ) {
                        if (user.isSubscribed) {
                            // State updated after initialization, emit userDeactivated to logout user
                            this.emit('userDeactivated');
                        } else {
                            // State is `deactivated` during initialization, which means user is able to login via SSO
                            // Let's mark user as `active`
                            this.activateUserIfNeeded(user);
                        }
                    }
                }
            });
        } catch (err: any) {
            console.log('FRONTLINE_SDK', err);
            throw new SessionError(err);
        }
    }

    private async activateUserIfNeeded(twilioUser: TwilioUser) {
        const user = fromTwilioUser(twilioUser);
        if (isUserDeactived(user)) {
            await this.activateUser(this.userSid)
                .then(() => {
                    Analytics.logEvent('user_activated');
                })
                .catch((err) => {
                    Analytics.logError('user_activation_failed', { err });
                });
        }
    }

    public shutdown() {
        this.tokenManager?.shutdown();
        this.twilsockClient.removeAllListeners();
        this.conversationsClient?.removeAllListeners();
        this.removeAllListeners();
        this.twilsockClient.disconnect();
        FrontlineSDK.shared = undefined;
    }

    get accountSid() {
        return this.configuration?.accountSid!;
    }

    get user() {
        return this.conversationsClient!.user;
    }

    get userIdentity() {
        return this.user?.identity;
    }

    get userSid() {
        return (this.user as any)?.state?.entityName?.replace('.info', '');
    }

    get userDateCreated() {
        return (this.user as any)?.entity?.syncMapImpl?.descriptor?.date_created;
    }

    get userDateUpdated() {
        return (this.user as any)?.entity?.syncMapImpl?.descriptor?.date_updated;
    }

    get connectionState(): ConnectionState {
        return this.twilsockClient.state;
    }

    public isBetaFlagEnabled(flag: string): boolean {
        return this.enabledBetaFlags.includes(flag);
    }

    public getEnabledBetaFeatureFlags(): Promise<string[]> {
        const flagsToCheck = Object.values(BETA_FEATURE_FLAGS);

        if (flagsToCheck.length === 0) {
            return Promise.resolve([]);
        }

        return this.frontlineClient.getBetaFeature(flagsToCheck).catch((err) => {
            console.warn('Error fetching Beta Feature Flags  ', err);
            return [];
        }) as Promise<Array<string>>;
    }

    public getConfiguration(): Promise<FrontlineConfiguration> {
        return this.frontlineClient
            .getConfiguration()
            .then((rawConfig) => {
                this.configuration = buildConfiguration(rawConfig);
                return this.configuration;
            })
            .catch((err) => {
                if (isUpdateRequiredError(err)) {
                    this.emit('updateRequired');
                }
                throw err;
            });
    }

    public refreshToken(): Promise<void> {
        return this.tokenManager!.refreshToken();
    }

    private initializeTwilsockConnection(twilsockClient: TwilsockClient): Promise<TwilsockClient> {
        const twilsockClientInitialize = new Promise<TwilsockClient>((resolve, reject) => {
            const failedConnectionHandler = (error: any) => {
                reject(error);
            };

            twilsockClient.once('connectionError', failedConnectionHandler);

            twilsockClient.once('connected', () => {
                twilsockClient.off('connectionError', failedConnectionHandler);
                resolve(twilsockClient);
            });
        });

        twilsockClient.connect();

        return twilsockClientInitialize;
    }

    private async initializeConversationsApiConnection(): Promise<ConversationsClient> {
        this.conversationsClient = new ConversationsClient(this.token.token, {
            clientMetadata: this.clientMetadata,
            twilsockClient: this.twilsockClient,
            region: this.options.region,
        });

        this.conversationsClient.on('userUpdated', (args: UserUpdatedEventArgs) =>
            this.emit('userUpdated', args),
        );

        this.conversationsClient.on('conversationJoined', (conversation: TwilioConversation) =>
            // Stream of conversations that user is part of
            this.emit('conversationJoined', conversation),
        );

        this.conversationsClient.on('conversationAdded', (conversation: TwilioConversation) => {
            // Stream of ALL conversations known to SDK/User. No matter if user is a part of it or not

            // Conversations that user is not a part of are "peeked" conversations, ignore them and
            // let the code making SDK#peekConversation call handle it
            if (conversation.status !== 'notParticipating') {
                this.emit('conversationAdded', conversation);
            }
        });

        this.conversationsClient.on('conversationLeft', (conversation: TwilioConversation) =>
            this.emit('conversationLeft', conversation),
        );

        this.conversationsClient.on('conversationRemoved', (conversation: TwilioConversation) =>
            this.emit('conversationRemoved', conversation),
        );

        return new Promise((resolve, reject) => {
            const initializedListener = () => {
                this.conversationsClient!.off('initFailed', failedListener);
                resolve(this.conversationsClient!);
            };
            const failedListener = ({
                error,
            }: {
                error?: { terminal: boolean; message: string };
            }) => {
                this.conversationsClient!.off('initialized', initializedListener);
                reject(error);
            };

            this.conversationsClient!.once('initialized', initializedListener);
            this.conversationsClient!.once('initFailed', failedListener);

            // https://issues.corp.twilio.com/browse/RTDSDK-3433
            // Conversations SDK does not handle initialized twilsock clients
            // So we need to emit connected event to invoke initialization
            // of Conversations SDK
            this.twilsockClient.emit('stateChanged', 'connected');
            this.twilsockClient.emit('connected');
        });
    }

    public callCallbackUrl<
        R = any,
        T extends keyof AppCallbackTypePayload = keyof AppCallbackTypePayload,
    >(location: T, payload: Omit<AppCallbackTypePayload[T], 'Location'>): Promise<R> {
        const callbackPayload = {
            Location: location,
            ...payload,
        } as unknown as AppCallbackPayload;

        return this.frontlineClient.callCallbackUrl<R>(callbackPayload).catch((err) => {
            if (isUpdateRequiredError(err)) {
                this.emit('updateRequired');
            }
            throw getCallbackError(err);
        });
    }

    public async peekConversation(conversationSid: ConversationSid) {
        return this.conversationsClient!.getConversationBySid(conversationSid);
    }

    public setAvailability = (activitySid: string) => {
        return this.frontlineClient.setAvailability(this.userSid, activitySid);
    };

    public setConversationState = this.delegateToFrontlineClient('setConversationState');

    public getWorkers = this.delegateToFrontlineClient('getWorkers');

    public transferConversation = this.delegateToFrontlineClient('transferConversation');

    public activateUser = this.delegateToFrontlineClient('activateUser');

    public sendMessage = this.delegateToConversationsClient('sendMessage');

    public markMessagesAsRead = this.delegateToConversationsClient('markMessagesAsRead');

    public updateLastReadMessageIndex = this.delegateToConversationsClient(
        'updateLastReadMessageIndex',
    );

    public getMessages = this.delegateToConversationsClient('getMessages');

    public getUser = this.delegateToConversationsClient('getUser');

    public getUsersOfConversation = this.delegateToConversationsClient('getUsersOfConversation');

    public getConversationBySid = this.delegateToConversationsClient('getConversationBySid');

    public getConversationUnreadMessagesCount = this.delegateToConversationsClient(
        'getConversationUnreadMessagesCount',
    );

    public getSubscribedConversations = this.delegateToConversationsClient(
        'getSubscribedConversations',
    );

    public getUserConversations = this.delegateToFrontlineClient('getUserConversations');

    public createConversation = this.delegateToFrontlineClient('createConversation');

    public setParticipantLastReadTime = this.delegateToConversationsClient(
        'setParticipantLastReadTime',
    );

    public readConversation = this.delegateToConversationsClient('readConversation');

    public updateMessageAttributes = this.delegateToConversationsClient('updateMessageAttributes');

    public closeConversation = (conversationSid: string): Promise<Record<string, any>> => {
        return this.setConversationState(conversationSid, 'closed');
    };

    public setParticipantMarkUnread = this.delegateToConversationsClient(
        'setParticipantMarkUnread',
    );

    public setChatPushRegistrationId(
        channelType: NotificationsChannelType,
        registrationId: string,
    ): Promise<void> {
        return this.conversationsClient!.setPushRegistrationId(channelType, registrationId)
            .then(() => console.log('✅ Successfully registered to chat push notifications'))
            .catch((e: any) => console.log('💥 Cannot register for chat push notifications', e));
    }

    public removeChatPushRegistrationId(
        channelType: ChannelType,
        registrationId: string | null,
    ): Promise<void> {
        if (!(this.isActive && registrationId)) {
            return Promise.resolve();
        }

        return this.conversationsClient!.removePushRegistrations(channelType, registrationId)
            .then(() => {
                console.log('✅ Successfully unregistered from chat push notifications');
            })
            .catch((err: any) => {
                console.log("💥 Couldn't unregister from chat push notifications", err);
                throw err;
            });
    }

    public setVoicePushRegistrationId(
        channelType: ChannelType,
        registrationId: string,
    ): Promise<void> {
        return this.voiceNotificationsClient!.setPushRegistrationId(channelType, registrationId)
            .then(() => {
                console.log('✅ Successfully registered to voice push notifications');
            })
            .catch((e) => console.log('💥 Cannot register for voice push notifications', e));
    }

    public removeVoicePushRegistrationId(
        channelType: ChannelType,
        registrationid: string | null,
    ): Promise<void> {
        if (!(this.isVoiceEnabled && this.isActive && registrationid)) {
            return Promise.resolve();
        }

        return this.voiceNotificationsClient!.removePushRegistrations(channelType, registrationid)
            .then(() => {
                console.log('✅ Successfully unregistered from voice push notifications');
            })
            .catch((err) => {
                console.log("💥 Couldn't unregister from voice push notifications", err);
                throw err;
            });
    }

    private delegateToFrontlineClient<TMethod extends keyof FrontlineClient>(
        method: TMethod,
    ): FrontlineClient[TMethod] {
        // @ts-ignore
        return (...params: Parameters<FrontlineClient[TMethod]>) => {
            // @ts-ignore
            return this.frontlineClient[method](...params);
        };
    }

    private delegateToConversationsClient<TMethod extends keyof ConversationsClient>(
        method: TMethod,
    ): ConversationsClient[TMethod] {
        // @ts-ignore
        return (...params: Parameters<ConversationsClient[TMethod]>) => {
            // @ts-ignore
            return this.conversationsClient[method](...params);
        };
    }
}
