import { Radio, RadioGroup } from '@twilio-paste/radio-group';
import { ScreenReaderOnly } from '@twilio-paste/screen-reader-only';
import { SkeletonLoader } from '@twilio-paste/skeleton-loader';
import { Box } from '@twilio-paste/box';
import { Flex } from '@twilio-paste/flex';

import { WorkerUser } from '@twilio/frontline-shared/models/WorkerUser';

import { UserListItem, UserListItemSkeleton } from '../../UserListItem';
import { UsersState } from '../../../hooks/useUsers';

type UsersRadioGroupProps = {
    state: UsersState;
    users: WorkerUser[];
    selectedUser?: WorkerUser;
    onUserSelect: (user?: WorkerUser) => void;
};

export function UsersRadioGroup({
    state,
    users,
    selectedUser,
    onUserSelect,
}: UsersRadioGroupProps) {
    const renderRadioGroups = () => {
        if (state === UsersState.Loading) {
            return Array.from({ length: 20 }).map((_, idx) => (
                <Flex key={`sc-${idx}`} vAlignContent="center">
                    <Box marginTop="spaceNegative30" marginLeft="space10" marginRight="space30">
                        <SkeletonLoader width={17} height={17} borderRadius="borderRadius30" />
                    </Box>
                    <UserListItemSkeleton />
                </Flex>
            ));
        }

        return users.map((user, rowIndex) => (
            <Box
                key={`row-${user.sid}`}
                cursor="pointer"
                onClick={() => {
                    onUserSelect(user);
                }}>
                <Flex vAlignContent="center">
                    <Box marginTop="spaceNegative30">
                        <Radio
                            id={`opt-${user.sid}`}
                            value={user.sid}
                            checked={user.sid === selectedUser?.sid}
                            name="user">
                            <ScreenReaderOnly>{`Select row ${rowIndex}`}</ScreenReaderOnly>
                        </Radio>
                    </Box>
                    <UserListItem user={user} link={false} />
                </Flex>
            </Box>
        ));
    };

    return (
        <RadioGroup name="user" legend="">
            {renderRadioGroups()}
        </RadioGroup>
    );
}
