import React, { RefObject } from 'react';
import {
    NativeSyntheticEvent,
    Platform,
    StyleSheet,
    TextInput,
    TextInputContentSizeChangeEventData,
    View,
} from 'react-native';
import { getBottomSpace } from 'react-native-iphone-x-helper';

import { DefaultTheme } from '@twilio/frontline-shared/theme';
import { TwilIconButton } from '@twilio/frontline-shared/components/TwilIconButton';
import { TemplateOnlyComposer } from './TemplateOnlyComposer';
import { useChatContext } from './ChatContext';

export const COMPOSER_LINE_HEIGHT = DefaultTheme.lineHeights.lineHeight10;

const INPUT_PLACEHOLDER = 'Enter message';

export type ComposerProps = {
    inputRef: RefObject<TextInput>;
    text: string;
    maxLength?: number;
    composerHeight: number;
    editing: boolean;
    isOutOfWhatsAppWindow: boolean;
    hideSendAttachmentButton: boolean;
    channelName: string;
    onTemplatesPress: () => void;
    onAttachmentsPress: () => void;
    onTextChanged: (text: string) => void;
    onInputSizeChanged: (contentSize: { width: number; height: number }) => void;
    onSend: (message: string) => void;
};

export function Composer({
    inputRef,
    text,
    editing,
    isOutOfWhatsAppWindow,
    hideSendAttachmentButton,
    channelName,
    composerHeight,
    maxLength,
    onTextChanged,
    onTemplatesPress,
    onAttachmentsPress,
    onInputSizeChanged,
    onSend,
}: ComposerProps) {
    const { Composer: CustomComposer, TemplateOnlyComposer: CustomTemplateOnlyComposer } =
        useChatContext();

    if (isOutOfWhatsAppWindow) {
        const TemplateComposer = CustomTemplateOnlyComposer || TemplateOnlyComposer;
        return (
            <TemplateComposer
                name={channelName.split(' ')[0]}
                openTemplateModal={onTemplatesPress}
            />
        );
    }

    const platform = Platform.select({
        ios: {
            composerHeight,
        },
        default: {
            composerHeight: undefined,
        },
    });

    const onContentSizeChange = (e: NativeSyntheticEvent<TextInputContentSizeChangeEventData>) => {
        const { contentSize } = e.nativeEvent;
        onInputSizeChanged(contentSize);
    };

    const onChangeText = (newValue: string) => {
        onTextChanged(newValue);
    };

    const onSendMessage = () => {
        if (text) {
            onSend(text);
        }
    };

    const renderAttachButton = () => {
        if (hideSendAttachmentButton) return null;
        return (
            <TwilIconButton
                style={styles.attachmentButton}
                onPress={onAttachmentsPress}
                name="attachments"
            />
        );
    };

    if (CustomComposer) {
        // web
        return (
            <CustomComposer
                text={text}
                editing={editing}
                isOutOfWhatsAppWindow={isOutOfWhatsAppWindow}
                hideSendAttachmentButton={hideSendAttachmentButton}
                onTextChanged={onTextChanged}
                onAttachmentsPress={onAttachmentsPress}
                onTemplatesPress={onTemplatesPress}
                onSendMessage={onSendMessage}
            />
        );
    }
    // mobile
    return (
        <View style={styles.composerContainer}>
            {renderAttachButton()}
            <TextInput
                ref={inputRef}
                testID="composerField"
                autoFocus={false}
                accessible
                accessibilityLabel={INPUT_PLACEHOLDER}
                placeholder={INPUT_PLACEHOLDER}
                placeholderTextColor="rgb(96, 107, 133)"
                value={text}
                maxLength={maxLength}
                multiline
                enablesReturnKeyAutomatically
                underlineColorAndroid="transparent"
                keyboardAppearance="default"
                onContentSizeChange={onContentSizeChange}
                onChangeText={onChangeText}
                style={[styles.composerStyle, { height: platform.composerHeight }]}
            />
        </View>
    );
}

const styles = StyleSheet.create({
    composerContainer: {
        flexDirection: 'row',
        flex: 1,
    },
    composerStyle: {
        borderRadius: DefaultTheme.radii.borderRadius9,
        borderColor: DefaultTheme.colors.colorGray20,
        borderWidth: DefaultTheme.borderWidths.borderWidth10,
        paddingLeft: DefaultTheme.sizes.size20,
        paddingRight: DefaultTheme.sizes.size20,
        lineHeight: COMPOSER_LINE_HEIGHT,
        ...Platform.select({
            android: {
                paddingTop: DefaultTheme.sizes.size8,
                paddingBottom: DefaultTheme.sizes.size8,
            },
            ios: {
                paddingTop: 10,
                paddingBottom: 10,
            },
        }),
        flex: 1,
        fontSize: DefaultTheme.fontSizes.fontSize16,
        marginRight: DefaultTheme.sizes.size20,
        marginLeft: DefaultTheme.sizes.size8,
        marginTop: DefaultTheme.sizes.size20,
        marginBottom: DefaultTheme.sizes.size20 + getBottomSpace(),
        backgroundColor: 'white',
        ...Platform.select({
            web: {
                outlineWidth: 0,
                outlineColor: 'transparent',
                outlineOffset: 0,
            },
        }),
    },
    attachmentButton: {
        paddingBottom: 2,
        paddingLeft: DefaultTheme.space.space30,
        marginBottom: Platform.select({
            // Rendered font size slightly differ on ios/android
            ios: DefaultTheme.sizes.size24 - DefaultTheme.sizes.size2 + getBottomSpace(),
            default: DefaultTheme.sizes.size24 - DefaultTheme.sizes.size1,
        }),
        alignSelf: 'flex-end',
    },
});
