import React from 'react';
import { Animated, LayoutChangeEvent, StyleProp, StyleSheet } from 'react-native';

export const THUMB_SIZE = 40;

type SliderThumbProps = {
    Component: typeof Animated.View;
    isVisible: boolean;
    onLayout: (event: LayoutChangeEvent) => void;
    style: StyleProp<any>;
    start: Animated.AnimatedInterpolation<number>;
    color: string;
    vertical: boolean;
};

export function SliderThumb({
    Component,
    isVisible,
    onLayout,
    style,
    start,
    color,
    vertical,
    ...props
}: SliderThumbProps) {
    const ThumbComponent = Component || Animated.View;
    const axis = vertical ? 'translateY' : 'translateX';
    const thumbPosition = [{ [axis]: start }];
    const styleTransform = (style && style.transform) || [];
    const visibleStyle = isVisible ? {} : { height: 0, width: 0 };

    return (
        <ThumbComponent
            onLayout={onLayout}
            style={StyleSheet.flatten([
                {
                    backgroundColor: color,
                    transform: [...thumbPosition, ...styleTransform],
                    ...visibleStyle,
                },
                styles.thumb,
                style,
            ])}
            {...props}
        />
    );
}

const styles = StyleSheet.create({
    thumb: {
        position: 'absolute',
        width: THUMB_SIZE,
        height: THUMB_SIZE,
        borderRadius: THUMB_SIZE / 2,
    },
});
