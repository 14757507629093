import React from 'react';
import { Platform, StyleSheet, TouchableOpacity, View } from 'react-native';
import { isIphoneX } from 'react-native-iphone-x-helper';

import { DefaultTheme } from '@twilio/frontline-shared/theme';
import { TwilIcon } from '@twilio/frontline-shared/components/TwilIcon';
import { useChatContext } from './ChatContext';

export type SendButtonProps = {
    text?: string;
    disabled?: boolean;
    isOutOfWhatsAppWindow?: boolean;
    onSend?(message: string): void;
};

export function SendButton({ isOutOfWhatsAppWindow, ...props }: SendButtonProps) {
    const { SendButton: CustomSendButton } = useChatContext();

    const handleOnPress = () => {
        const { text, onSend } = props;
        if (text && onSend) {
            onSend(text.trim());
        }
    };

    if (isOutOfWhatsAppWindow || !props.text || props.text.trim().length === 0) {
        return null;
    }

    if (CustomSendButton) {
        return <CustomSendButton disabled={props.disabled} onPress={handleOnPress} />;
    }

    return (
        <TouchableOpacity
            testID="sendMessageButton"
            accessible
            accessibilityLabel="send"
            accessibilityRole="button"
            onPress={handleOnPress}
            disabled={props.disabled}
            style={styles.sendButtonContainer}>
            <View>
                <View style={styles.sendButtonStyles}>
                    <TwilIcon
                        name="send"
                        size={24}
                        color={DefaultTheme.textColors.colorTextInverse}
                    />
                </View>
            </View>
        </TouchableOpacity>
    );
}

const styles = StyleSheet.create({
    sendButtonContainer: {
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center',
        height: DefaultTheme.sizes.size40,
        width: DefaultTheme.sizes.size40,
        paddingRight: DefaultTheme.sizes.size20,
        paddingBottom: Platform.select({
            // Rendered font size slightly differ on ios/android
            ios: DefaultTheme.sizes.size20 + (isIphoneX() ? 78 : 0),
            default: DefaultTheme.sizes.size20,
        }),
    },
    sendButtonStyles: {
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: DefaultTheme.backgroundColors.colorBackgroundPrimary,
        borderRadius: DefaultTheme.radii.borderRadius10,
        height: DefaultTheme.sizes.size40,
        width: DefaultTheme.sizes.size40,
    },
});
