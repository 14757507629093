import { createReducer } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

import { initDrafts, setDraft } from './actions';
import { ExtendsChatState } from '../ChatState';

export type DraftState = { [key: string]: string | null };

export const initialState: DraftState = {};

export const reduce = createReducer<DraftState>(initialState, (builder) => {
    builder.addCase(initDrafts, (state, action) => {
        const { drafts } = action.payload;
        return drafts;
    });
    builder.addCase(setDraft, (state, action) => {
        const { message, channelSid } = action.payload;
        state[channelSid] = message;
    });
});

export const useChannelDraft = (channelSid: string): string => {
    return useSelector<ExtendsChatState, string>((state) => {
        return state.chat.drafts[channelSid] || '';
    });
};
