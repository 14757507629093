import { useConversationCustomer } from '@twilio/frontline-shared/store/ParticipantsState';
import { useTemplates } from '@twilio/frontline-shared/hooks/useTemplates';
import { ConversationSid } from '@twilio/frontline-shared/types';
import { ChannelType } from '@twilio/frontline-shared/types/channel';

import { TemplatesModal, TemplatesModalProps } from './TemplatesModal';

export type TemplatesModalContainerProps = {
    conversationSid: ConversationSid;
    channelType: ChannelType;
    isOutOfWhatsAppWindow: boolean;
    onTemplateSelected: (text: string) => void;
} & Omit<TemplatesModalProps, 'templates' | 'loadData' | 'status'>;

export function TemplatesModalContainer({
    conversationSid,
    channelType,
    isOutOfWhatsAppWindow,
    isOpen,
    handleClose,
    onTemplateSelected,
}: TemplatesModalContainerProps) {
    const customerParticipant = useConversationCustomer(conversationSid);

    const { status, templates, fetch } = useTemplates(
        conversationSid,
        channelType,
        customerParticipant?.customerId,
        isOutOfWhatsAppWindow,
    );

    return (
        <TemplatesModal
            isOpen={isOpen}
            templates={templates}
            handleClose={handleClose}
            loadData={fetch}
            status={status}
            onTemplateSelected={onTemplateSelected}
        />
    );
}
