import { FrontlineErrors } from './ErrorCodes';

class FrontlineError extends Error {
    public code!: number;

    public toString = (): string => {
        return `${this.constructor.name} code=${this.code}`;
    };
}

export class EmptyCallbackURLError extends FrontlineError {
    public code: number = FrontlineErrors.CALLBACK_EMPTY_URL;
}

export class ErrorCallbackResponseError extends FrontlineError {
    public code: number = FrontlineErrors.CALLBACK_ERROR_RESPONSE;
}

export class InvalidCallbackResponseError extends FrontlineError {
    public code: number = FrontlineErrors.CALLBACK_INVALID_RESPONSE;
}

export class InvalidCallbackURLError extends FrontlineError {
    public code: number = FrontlineErrors.CALLBACK_INVALID_URL;
}

export function getCallbackError(err: any): Error {
    switch (err?.body?.code) {
        case FrontlineErrors.CALLBACK_EMPTY_URL:
            return new EmptyCallbackURLError();
        case FrontlineErrors.CALLBACK_ERROR_RESPONSE:
            return new ErrorCallbackResponseError();
        case FrontlineErrors.CALLBACK_INVALID_RESPONSE:
            return new InvalidCallbackResponseError();
        case FrontlineErrors.CALLBACK_INVALID_URL:
            return new InvalidCallbackURLError();
        default:
            return err;
    }
}
