import { isSameDay as isSameDayFn } from 'date-fns';

import { ChannelType } from '../../types/channel';
import {
    IChatBaseMessage,
    IChatMessage,
    IChatUser,
    IChatSystemMessage,
    ChatMessage,
} from './types';
import { ConversationsUsersState } from '../../store/users/ConversationsUsersState';
import {
    SystemEvent,
    getSystemMessageText,
    FrontlineEventType,
    CallEventEndedPayload,
} from '../../models/Event';
import {
    ConversationMessage,
    isSending,
    isSent,
    isFailed,
    MessageStatus,
    getChatMessageReadStatus,
} from '../../models/Message';
import { isNumber } from '../../utils/isNumber';
import { Participant } from '../../models/Participant';

export const isSameDay = (
    message?: IChatBaseMessage,
    anotherMessage?: IChatBaseMessage,
): boolean => {
    const createdAt = message?.createdAt;
    const anotherCreatedAt = anotherMessage?.createdAt;

    if (!createdAt || !anotherCreatedAt) {
        return false;
    }

    return isSameDayFn(createdAt, anotherCreatedAt);
};

export const isSameUser = (user?: IChatUser, anotherUser?: IChatUser): boolean => {
    return !!(user && anotherUser && user._id === anotherUser._id);
};

export const isMessageAuthoredBy = (message?: IChatMessage, user?: IChatUser): boolean => {
    return isSameUser(message?.user, user);
};

export const isSameAuthor = (message?: IChatMessage, anotherMessage?: IChatMessage): boolean => {
    return isMessageAuthoredBy(message, anotherMessage?.user);
};

export const getLastReadMessageTime = (
    lastConsumedMessageIndex: number | undefined | null,
    lastConsumedSystemMessageTime: number,
    messages: Array<ConversationMessage>,
) => {
    const lastConsumedMessageTime = isNumber(lastConsumedMessageIndex)
        ? messages.find((m) => m.index === lastConsumedMessageIndex)?.createdAt.getTime() || 0
        : -1;

    return Math.max(lastConsumedMessageTime, lastConsumedSystemMessageTime);
};

export const toGiftedChatMessages = (
    messages: ConversationMessage[],
    users: ConversationsUsersState,
    events: SystemEvent[],
    hasOlderMessages: boolean,
    customerParticipant?: Participant,
    lastChatReadTime?: number,
    channelType?: ChannelType,
): Array<ChatMessage> => {
    const systemMessages: Array<IChatSystemMessage> = events.reduce<Array<IChatSystemMessage>>(
        (acc, event, index) => {
            if (
                !messages.length ||
                messages[0].index === 0 ||
                !hasOlderMessages ||
                event.date >= messages[0].createdAt.getTime()
            ) {
                if (event.type === FrontlineEventType.CallEnded) {
                    const {
                        sid,
                        date,
                        state,
                        inbound,
                        duration,
                        initiatorIdentity,
                        initiatorParticipantSid,
                    } = event;

                    const callEndedPayload = {
                        state,
                        inbound,
                        duration,
                        initiatorIdentity,
                        initiatorParticipantSid,
                    } as CallEventEndedPayload;

                    const message: IChatSystemMessage = {
                        _id: `system-${sid}-${index}`,
                        systemMessageType: FrontlineEventType.CallEnded,
                        system: true,
                        text: '',
                        createdAt: date,
                        payload: callEndedPayload,
                    };
                    acc.push(message);
                } else {
                    const message: IChatSystemMessage = {
                        _id: `system-${event.sid}-${index}`,
                        systemMessageType: event.type,
                        system: true,
                        text: getSystemMessageText(event, users),
                        createdAt: event.date,
                    };
                    acc.push(message);
                }
            }
            return acc;
        },
        [] as Array<IChatSystemMessage>,
    );

    return messages
        .map((m) =>
            toGiftedChatMessage(m, users, customerParticipant, lastChatReadTime, channelType),
        )
        .concat(systemMessages || []);
};

export const toGiftedChatMessage = (
    message: ConversationMessage,
    users: ConversationsUsersState,
    customerParticipant?: Participant,
    lastChatReadTime?: number,
    channelType?: ChannelType,
): ChatMessage => {
    const user = {
        _id: message.author,
        name: message.author,
    } as IChatMessage['user'];
    if (customerParticipant?.sid && message.participantSid === customerParticipant.sid) {
        user.name = customerParticipant.displayName || user.name;
        user.avatar = customerParticipant.avatar;
    } else if (users[message.author]) {
        user.name = users[message.author].friendlyName || user.name;
        user.avatar = users[message.author].avatar;
    }

    const status = getChatMessageReadStatus(message, lastChatReadTime, channelType);

    return {
        _id: message.sid,
        index: message.index,
        text: message.body || '',
        image: message.mediaSid,
        mediaSid: message.mediaSid,
        channelSid: message.channelSid,
        createdAt: message.createdAt.getTime(),
        pending: isSending(message),
        sent: isSent(message),
        failed: isFailed(message),
        messageType: message.messageType,
        user,
        status,
        errorCode: message.errorCode,
        participantSid: message.participantSid,
    };
};

export const isWaitingForResponse = (messages: ConversationMessage[]) => {
    return (
        messages.length > 0 &&
        messages[messages.length - 1]?.attributes?.whatsAppApprovedTemplate &&
        messages[messages.length - 1].status !== MessageStatus.Failed &&
        messages[messages.length - 1].status !== MessageStatus.Undelivered
    );
};

export const unreadMessagesSystemMessage = (
    unreadCount: number,
    createdAt: number | Date,
): IChatSystemMessage => ({
    _id: 'horizon',
    system: true,
    systemMessageType: 'horizon',
    text: `${unreadCount} NEW`,
    createdAt,
});

export const compareMessages = (messageA: ChatMessage, messageB: ChatMessage): 1 | -1 | 0 => {
    if (messageA.createdAt > messageB.createdAt) {
        return -1;
    }
    if (messageA.createdAt === messageB.createdAt) {
        return 0;
    }
    return 1;
};

export const shouldReadConversation = (
    hasLastMessage: boolean,
    hasMarkedUnreadAt: boolean,
    conversationSid: string,
    unreadMessagesCount: number,
    isFocused: boolean,
) => {
    return (
        conversationSid &&
        ((unreadMessagesCount > 0 && hasLastMessage) ||
            (hasMarkedUnreadAt && !hasLastMessage) ||
            (unreadMessagesCount > 0 && !hasLastMessage && !hasMarkedUnreadAt)) &&
        isFocused
    );
};
