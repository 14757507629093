// noinspection HttpUrlsUsage

import { TwilsockClient } from 'twilsock';

import { Page } from '@twilio/frontline-shared/models/Page';
import { WorkerUser } from '@twilio/frontline-shared/models/WorkerUser';
import { AppCallbackPayload } from '@twilio/frontline-shared/core/Callbacks/types';
import { Paginator, RestPaginator } from '@twilio/conversations';
import {
    CreateConversationRequestBody,
    CreateConversationResponse,
    GetRoleResponse,
} from '@twilio/frontline-shared/core/types';
import { ConversationSid, UserIdentifier } from '@twilio/frontline-shared/types';

type ClientOptions = {
    twilsockClient: TwilsockClient;
};

export type UserConversationRest = {
    conversation_sid: ConversationSid;
};

type UserConversationsResponse = {
    conversations: UserConversationRest[];
    meta: {
        next_token?: string;
        previous_token?: string;
    };
};

export const FRONTLINE_GRANT_NAME = 'frontline';

const MAX_CONVERSATIONS_PER_PAGE = 50;

export class FrontlineClient {
    private twilsockClient: TwilsockClient;

    constructor(token: string, options: ClientOptions) {
        this.twilsockClient = options.twilsockClient;
    }

    public getConfiguration(): Promise<Record<string, any>> {
        return this.get('http://frontline.twilio.com/v1/Configuration');
    }

    public getBetaFeature(flags: Array<string>): Promise<Record<string, any>> {
        const flagsQuery = flags.join('&Flag=');
        return this.get(`http://frontline.twilio.com/v1/BetaFeatures?Flag=${flagsQuery}`);
    }

    public getRole(identity: string): Promise<GetRoleResponse> {
        return this.get(
            `http://frontline.twilio.com/v1/Users/${encodeURIComponent(identity)}/Role`,
        );
    }

    public setConversationState(
        conversationSid: string,
        state: string,
    ): Promise<Record<string, any>> {
        return this.post(
            `http://frontline.twilio.com/v1/Conversations/${conversationSid}`,
            JSON.stringify({ state }),
        );
    }

    public setAvailability(userSid: string, activitySid: string): Promise<Record<string, any>> {
        return this.post(
            `http://frontline.twilio.com/v1/Users/${userSid}`,
            JSON.stringify({ activity_sid: activitySid }),
        );
    }

    public getWorkers(
        search: string,
        pageSize: number,
        pageToken?: string,
    ): Promise<Page<WorkerUser>> {
        const queryString = [
            `Search=${encodeURIComponent(search)}`,
            `PageSize=${pageSize}`,
            `PageToken=${pageToken || ''}`,
        ].join('&');
        return this.get(`http://frontline.twilio.com/v1/Users?${queryString}`);
    }

    public transferConversation(
        conversationSid: string,
        targetUser: string,
        fromUser?: string,
    ): Promise<void> {
        const payload = {
            target_user: targetUser,
            from_user: fromUser,
        };
        return this.post(
            `http://frontline.twilio.com/v1/Conversations/${conversationSid}/Transfers`,
            JSON.stringify(payload),
        );
    }

    public activateUser(userSid: string): Promise<void> {
        return this.post(
            `http://frontline.twilio.com/v1/Users/${userSid}`,
            JSON.stringify({ state: 'active' }),
        );
    }

    public getUserConversations(
        userIdentifier: UserIdentifier,
        token?: string,
    ): Promise<Paginator<UserConversationRest>> {
        const queryString = [
            `PageSize=${MAX_CONVERSATIONS_PER_PAGE}`,
            `PageToken=${token || ''}`,
        ].join('&');
        return this.get<UserConversationsResponse>(
            `http://frontline.twilio.com/v1/Users/${encodeURIComponent(
                userIdentifier,
            )}/Conversations?${queryString}`,
        ).then(({ conversations, meta: { next_token, previous_token } }) => {
            return new RestPaginator(
                conversations,
                (newToken: string) => this.getUserConversations(userIdentifier, newToken),
                previous_token,
                next_token,
            );
        });
    }

    public createConversation(
        requestBody: CreateConversationRequestBody,
    ): Promise<CreateConversationResponse> {
        return this.post<CreateConversationResponse>(
            'http://frontline.twilio.com/v1/Conversations',
            JSON.stringify(requestBody),
        );
    }

    public callCallbackUrl<T>(payload: AppCallbackPayload): Promise<T> {
        return this.post<T>(`http://frontline.twilio.com/v1/Callback`, JSON.stringify(payload));
    }

    private get<T extends unknown>(this: FrontlineClient, url: string): Promise<T> {
        return this.twilsockClient
            .get(url, {}, FRONTLINE_GRANT_NAME)
            .then((response) => {
                console.log(`Twilsock: upstream reply ${JSON.stringify(response)}`);
                return response.body as T;
            })
            .catch((err) => {
                console.log(`Twilsock: upstream error ${JSON.stringify(err)}`);
                throw err;
            });
    }

    private post<T extends unknown>(this: FrontlineClient, url: string, body: any): Promise<T> {
        return this.twilsockClient
            .post(url, {}, body, FRONTLINE_GRANT_NAME)
            .then((response) => {
                console.log(`Twilsock: upstream reply ${JSON.stringify(response)}`);
                return response.body as T;
            })
            .catch((err) => {
                console.log(`Twilsock: upstream error ${JSON.stringify(err)}`);
                throw err;
            });
    }
}
