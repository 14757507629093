import { Conversation } from '@twilio/frontline-shared/models/Conversation';
import { ConversationSid, TwilioConversation, UserIdentity } from '@twilio/frontline-shared/types';
import { createAction } from '@reduxjs/toolkit';

export const addConversation = createAction<{ conversation: Conversation }>('ADD_CONVERSATION');

export type AddConversation = ReturnType<typeof addConversation>;

export const addUserConversation =
    createAction<{
        conversationSid?: ConversationSid;
        conversation?: Conversation;
        userIdentity: UserIdentity;
    }>('ADD_USER_CONVERSATION');

export type AddUserConversation = ReturnType<typeof addUserConversation>;

export const removeConversation = createAction<{ conversationSid: string }>('REMOVE_CONVERSATION');

export type RemoveConversation = ReturnType<typeof removeConversation>;

export const removeUserConversation = createAction<{
    conversationSid: string;
    userIdentity: string;
}>('REMOVE_USER_CONVERSATION');

export const updateConversation =
    createAction<{
        conversationSid: ConversationSid;
        conversation: Partial<Conversation>;
    }>('UPDATE_CONVERSATION');

export type UpdateConversation = ReturnType<typeof updateConversation>;

export const saveUnreadMessagesCount = createAction<{
    conversationSid: ConversationSid;
    count: number;
    lastConsumedMessageIndex?: number;
}>('SAVE_UNREAD_MESSAGES_COUNT');

export type SaveUnreadMessagesCount = ReturnType<typeof saveUnreadMessagesCount>;

export const saveUnreadSystemMessagesCount = createAction<{
    conversationSid: ConversationSid;
    count: number;
}>('SAVE_UNREAD_SYSTEM_MESSAGES_COUNT');

export type SaveUnreadSystemMessagesCount = ReturnType<typeof saveUnreadSystemMessagesCount>;

export const fetchUnreadMessagesCount = createAction<{
    conversationSid: ConversationSid;
}>('FETCH_UNREAD_MESSAGES_COUNT');

export type FetchUnreadMessagesCount = ReturnType<typeof fetchUnreadMessagesCount>;

export const markMessagesAsRead = createAction<{
    conversationSid: ConversationSid;
    messageIndex: number;
}>('MARK_CONVERSATION_MESSAGES_AS_READ');

export type MarkConversationMessagesAsRead = ReturnType<typeof markMessagesAsRead>;

export const loadPrevMessages = createAction<{
    conversationSid: ConversationSid;
    messageIndex?: number;
    messageCreatedAt: Date;
}>('LOAD_PREV_CONVERSATION_MESSAGES');

export type LoadPrevMessages = ReturnType<typeof loadPrevMessages>;

export const conversationsLoadingStarted = createAction('CONVERSATIONS_LOADING_STARTED');

export type ConversationsLoadingStarted = ReturnType<typeof conversationsLoadingStarted>;

export const conversationsInitialized = createAction('CONVERSATIONS_INITIALIZED');

export type ConversationsInitialized = ReturnType<typeof conversationsInitialized>;

export const conversationsLoadingFailure = createAction('CONVERSATIONS_LOADING_FAILURE');

export type ConversationsLoadingFailure = ReturnType<typeof conversationsLoadingFailure>;

export const updateAppIconBadge = createAction('UPDATE_APP_ICON_BADGE');

export type UpdateAppIconBadge = ReturnType<typeof updateAppIconBadge>;

export const watchConversationEvents = createAction<{
    conversation: TwilioConversation;
}>('WATCH_CONVERSATION_EVENTS');

export type WatchConversationEvents = ReturnType<typeof watchConversationEvents>;

export const setIsAllPreviousMessagesFetched = createAction<{
    conversationSid: ConversationSid;
    fetched: boolean;
}>('SET_ALL_PREVIOUS_MESSAGES_FETCHED');

export type SetIsAllPreviousMessagesFetched = ReturnType<typeof setIsAllPreviousMessagesFetched>;

export const setUnreadSystemMessageCount = createAction<{
    conversationSid: ConversationSid;
    lastReadTime?: number;
}>('SET_UNREAD_SYSTEM_MESSAGE_COUNT');

export type SetUnreadSystemMessageCount = ReturnType<typeof setUnreadSystemMessageCount>;

export const markSystemMessagesAsRead = createAction<{
    conversationSid: ConversationSid;
}>('MARK_SYSTEM_MESSAGES_AS_READ');

export type MarkSystemMessagesAsRead = ReturnType<typeof markSystemMessagesAsRead>;

export const setUnreadMessagesCount = createAction<{
    unreadMessagesCount: number;
}>('SET_UNREAD_MESSAGES_COUNT');

export type SetUnreadMessagesCount = ReturnType<typeof setUnreadMessagesCount>;

export const markConversationUnread = createAction<{
    conversationSid: ConversationSid;
}>('MARK_CONVERSATION_UNREAD');

export type MarkConversationUnread = ReturnType<typeof markConversationUnread>;

export const readConversation =
    createAction<{
        conversationSid: ConversationSid;
    }>('READ_CONVERSATION');

export type ReadConversation = ReturnType<typeof readConversation>;

export const saveMarkedUnreadAt =
    createAction<{
        conversationSid: ConversationSid;
        markedUnreadAt?: number;
    }>('SAVE_MARKED_UNREAD_AT');

export type SaveMarkedUnreadAt = ReturnType<typeof saveMarkedUnreadAt>;
