import { useState } from 'react';
import { useWindowDimensions } from 'react-native';
import { Box } from '@twilio-paste/box';
import { Button } from '@twilio-paste/button';

import { isMediaLoading, MediaImage } from '@twilio/frontline-shared/models/Media';
import { IChatMessage } from '@twilio/frontline-shared/components/Chat/types';

import { MessageMediaPreviewModal } from './MessageMediaPreviewModal';

type MessageImageProps = {
    image: MediaImage;
    currentMessage: IChatMessage;
};

export function MessageImage({ image, currentMessage }: MessageImageProps) {
    const [isOpen, setIsOpen] = useState(false);

    if (!image || isMediaLoading(image)) {
        return null;
    }

    return (
        <Box paddingTop="space30" paddingLeft="space30" paddingRight="space30">
            <Button variant="primary_icon" size="reset" onClick={() => setIsOpen(true)}>
                <Box
                    as="img"
                    src={image.path}
                    borderRadius="borderRadius20"
                    style={{
                        width: 320,
                    }}
                />
            </Button>

            <MessageMediaPreviewModal
                isOpen={isOpen}
                onDismiss={() => setIsOpen(false)}
                image={image}
                message={currentMessage}
            />
        </Box>
    );
}
