import { Link } from 'react-router-dom';
import { Box } from '@twilio-paste/box';
import { Flex } from '@twilio-paste/flex';
import { ConnectivityAvailableIcon } from '@twilio-paste/icons/cjs/ConnectivityAvailableIcon';
import { ConnectivityOfflineIcon } from '@twilio-paste/icons/cjs/ConnectivityOfflineIcon';
import { SkeletonLoader } from '@twilio-paste/skeleton-loader';
import { Text } from '@twilio-paste/text';

import { WorkerUser } from '@twilio/frontline-shared/models/WorkerUser';
import { userConversationsPath } from '../paths';
import { Avatar } from './Avatar';
import { useSession } from '../context/SessionContext';

type UserListItemProps = {
    user: WorkerUser;
    link?: boolean;
};

export function UserListItem({ user, link = true }: UserListItemProps) {
    const { session } = useSession();

    return (
        <Flex data-testid="user-group" grow={1}>
            <Avatar
                data-testid="avatar"
                size="sizeIcon70"
                friendlyName={user.friendly_name}
                avatar={user.avatar}
            />
            <Flex marginLeft="space30" vertical>
                {link ? (
                    <Link to={userConversationsPath(user.identity)}>
                        <Text
                            as="span"
                            color="colorTextLink"
                            fontSize="fontSize30"
                            fontWeight="fontWeightMedium"
                            lineHeight="lineHeight20">
                            {user.friendly_name || user.identity}
                        </Text>
                    </Link>
                ) : (
                    <Text
                        data-testid="identifier"
                        as="span"
                        fontSize="fontSize30"
                        fontWeight="fontWeightMedium"
                        lineHeight="lineHeight20">
                        {user.friendly_name || user.identity}
                    </Text>
                )}
                {session?.configuration?.availableActivitySid === user.activity_sid ? (
                    <Box
                        marginLeft="spaceNegative20"
                        display="inherit"
                        data-testid="status-available">
                        <ConnectivityAvailableIcon
                            color="colorTextIconAvailable"
                            decorative={false}
                            title="available"
                        />
                        Available
                    </Box>
                ) : (
                    <Box
                        marginLeft="spaceNegative20"
                        display="inherit"
                        data-testid="status-unavailable">
                        <ConnectivityOfflineIcon
                            color="colorTextIconOffline"
                            decorative={false}
                            title="offline"
                        />
                        Unavailable
                    </Box>
                )}
            </Flex>
        </Flex>
    );
}

export function UserListItemSkeleton() {
    return (
        <Flex>
            <SkeletonLoader width={32} height={32} borderRadius="borderRadiusCircle" />
            <Flex marginLeft="space30" vertical>
                <SkeletonLoader width={158} height={20} borderRadius="borderRadius20" />
                <Box marginTop="space20">
                    <SkeletonLoader width={117} height={20} borderRadius="borderRadius20" />
                </Box>
            </Flex>
        </Flex>
    );
}
