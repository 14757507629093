import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box } from '@twilio-paste/box';
import { Flex } from '@twilio-paste/flex';
import { Text } from '@twilio-paste/text';

import { useSimpleConversations } from '@twilio/frontline-shared/store/conversations/SimpleConversation';
import {
    useConversationsLoaded,
    useConversationsLoadingFailed,
} from '@twilio/frontline-shared/store/ui/UIState';
import { useConversationCustomer } from '@twilio/frontline-shared/store/ParticipantsState';
import { getMyConversationPath, PAGES } from '../../paths';
import { useQuery } from '../../hooks/useQuery';

import { ConversationContainer } from '../../components/Conversation/ConversationContainer';
import { MyConversationsHeader } from './components/MyConversationsHeader';
import { MyConversationsList } from './components/MyConversationsList';
import { ConversationViewVariant } from '../../components/Conversation/types';
import { MyNewConversation } from './components/MyNewConversation';
import { CustomerDetailsPanel } from './components/CustomerDetailsPanel';
import { useToasterContext } from '../../context/ToasterContext';
import { ToastMessage } from '../../components/ToastMessage';

const CONVERSATIONS_LIST_WIDTH = 320;

export function MyConversations() {
    const history = useHistory();
    const [showNewConversation, setShowNewConversation] = useState<boolean>(false);
    const [conversationCustomerId, setConversationCustomerId] = useState<string | null>(null);
    const conversations = useSimpleConversations();
    const conversationsLoaded = useConversationsLoaded();
    const conversationsLoadingFailed = useConversationsLoadingFailed();
    const toaster = useToasterContext();
    const query = useQuery();
    const conversationId = query.get('conversation_id');
    const customerParticipant = useConversationCustomer(String(conversationId));

    const handleListItemClick = (convoId: string) => {
        const path = getMyConversationPath(convoId);
        history.push(path);
        setShowNewConversation(false);
        setConversationCustomerId(null);
    };

    const handleShowNewConversation = () => {
        history.push(PAGES.CONVERSATIONS);
        setShowNewConversation(true);
    };

    const handleShowCustomerDetailsPanel = () => {
        if (customerParticipant?.attributes?.customer_id) {
            setConversationCustomerId(String(customerParticipant?.attributes?.customer_id));
        } else {
            toaster.push({
                message: (
                    <ToastMessage
                        title="Unable to find customer."
                        text="The customer doesn’t have a configured ID. Please contact IT support."
                    />
                ),
                variant: 'error',
                dismissAfter: 7500,
                id: `customer_details_panel_${conversationId}`,
            });
        }
    };

    const renderConversationContent = () => {
        if (showNewConversation) {
            return (
                <MyNewConversation
                    onClose={() => setShowNewConversation(false)}
                    onCreateConversationSucceed={handleListItemClick}
                />
            );
        }

        if (conversationId) {
            return (
                <ConversationContainer
                    key={conversationId}
                    conversationSid={conversationId}
                    variant={ConversationViewVariant.USER}
                    onConversationHeaderClick={handleShowCustomerDetailsPanel}
                />
            );
        }

        return (
            <Box width="100%" backgroundColor="colorBackground">
                <Flex vAlignContent="center" hAlignContent="center" height="100%">
                    <Text color="colorTextWeak" as="p" textAlign="center">
                        Select a conversation
                    </Text>
                </Flex>
            </Box>
        );
    };

    return (
        <Flex width="100%" height="100%">
            <Flex vertical minWidth={CONVERSATIONS_LIST_WIDTH} width="25%" height="100%">
                <Box
                    display="flex"
                    flexDirection="column"
                    width="100%"
                    height="100%"
                    alignItems="stretch"
                    borderRightWidth="borderWidth10"
                    borderRightStyle="solid"
                    borderRightColor="colorBorderWeak">
                    <MyConversationsHeader
                        onShowNewConversation={handleShowNewConversation}
                        conversationsLoaded={conversationsLoaded}
                        conversationsLoadingFailed={conversationsLoadingFailed}
                    />

                    <MyConversationsList
                        conversations={conversations}
                        conversationsLoaded={conversationsLoaded}
                        conversationsLoadingFailed={conversationsLoadingFailed}
                        selectedConversationId={conversationId}
                        showNewConversation={showNewConversation}
                        onListItemClick={handleListItemClick}
                        onShowNewConversation={handleShowNewConversation}
                    />
                </Box>
            </Flex>
            <Flex width="75%" height="100%" vAlignContent="stretch" hAlignContent="center">
                {renderConversationContent()}
            </Flex>
            {conversationCustomerId && (
                <CustomerDetailsPanel
                    customerId={conversationCustomerId}
                    onClose={() => setConversationCustomerId(null)}
                />
            )}
        </Flex>
    );
}
