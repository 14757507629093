import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { WorkerUser } from '@twilio/frontline-shared/models/WorkerUser';
import { Conversation } from '@twilio/frontline-shared/models/Conversation';
import { ChannelType } from '@twilio/frontline-shared/types/channel';
import { useDispatch } from '@twilio/frontline-shared/store/redux';
import { removeUserConversation } from '@twilio/frontline-shared/store/conversations/actions';
import { useSession } from '../../context/SessionContext';
import { TransferConversationModal } from './Modal/TransferConversationModal';

import { WaitingOverlay } from '../WaitingOverlay';
import { useToasterContext } from '../../context/ToasterContext';
import {
    transferConversationErrorToast,
    transferConversationManagerSuccessToast,
    transferConversationSuccessToast,
} from './toasts';
import { ConversationViewVariant } from '../Conversation/types';

export const MODAL_BOX_HEIGHT = 400;

export const enum TransferState {
    SelectUser = 'selectUser',
    Confirm = 'confirm',
    Transferring = 'transferring',
}

export type TransferConversationProps = {
    conversationSid: Conversation['sid'];
    channelFriendlyName: Conversation['friendlyName'];
    channelAvatar: Conversation['avatar'];
    channelType: ChannelType | undefined;
    currentUserIdentity: WorkerUser['identity'];
    currentUserFriendlyName?: WorkerUser['friendly_name'];
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    variant: ConversationViewVariant;
};

export function TransferConversation({
    conversationSid,
    channelFriendlyName,
    channelAvatar,
    channelType,
    currentUserIdentity,
    currentUserFriendlyName,
    isOpen,
    setIsOpen,
    variant,
}: TransferConversationProps) {
    const history = useHistory();
    const toaster = useToasterContext();
    const { session } = useSession();
    const dispatch = useDispatch();
    const [transferState, setTransferState] = useState<TransferState>(TransferState.SelectUser);
    const [newUser, setNewUser] = React.useState<WorkerUser | undefined>(undefined);

    const transferConversation = (convoSid: string, workerUser: WorkerUser) => {
        setTransferState(TransferState.Transferring);
        const isManager = variant === ConversationViewVariant.MANAGER;
        const fromUser = isManager ? currentUserIdentity : undefined;

        session?.frontlineSDK
            .transferConversation(convoSid, workerUser.sid, fromUser)
            .then(() => {
                if (isManager) {
                    dispatch(
                        removeUserConversation({
                            conversationSid: convoSid,
                            userIdentity: currentUserIdentity,
                        }),
                    );
                    session?.fetchUpdatedUserConversation(convoSid);
                    toaster.push(
                        transferConversationManagerSuccessToast(
                            convoSid,
                            channelFriendlyName,
                            currentUserFriendlyName || currentUserIdentity,
                            workerUser.friendly_name,
                            workerUser.identity,
                            history,
                        ),
                    );
                } else {
                    toaster.push(
                        transferConversationSuccessToast(
                            convoSid,
                            channelFriendlyName,
                            currentUserFriendlyName || currentUserIdentity,
                            workerUser.friendly_name,
                            workerUser.identity,
                        ),
                    );
                }

                history.replace(history.location.pathname);
            })
            .catch((e) => {
                setNewUser(undefined);
                setTransferState(TransferState.SelectUser);
                toaster.push(
                    transferConversationErrorToast(
                        convoSid,
                        channelFriendlyName,
                        workerUser,
                        transferConversation,
                    ),
                );
                if (e?.body?.message) {
                    console.log(
                        `Error occurred while attempting to transfer conversation: ${e?.body?.message}.`,
                    );
                }
            });
    };

    return (
        <>
            <TransferConversationModal
                conversationSid={conversationSid}
                channelFriendlyName={channelFriendlyName}
                channelAvatar={channelAvatar}
                channelType={channelType}
                currentUserFriendlyName={currentUserFriendlyName || currentUserIdentity || ''}
                currentUserIdentity={currentUserIdentity}
                newUser={newUser}
                setNewUser={setNewUser}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                transferState={transferState}
                setTransferState={setTransferState}
                transferConversation={transferConversation}
            />
            <WaitingOverlay
                isOpen={transferState === TransferState.Transferring}
                message="Transferring conversation"
            />
        </>
    );
}
