import { MutableRefObject } from 'react';
import { Text } from '@twilio-paste/text';
import { Box } from '@twilio-paste/box';
import { Spinner } from '@twilio-paste/spinner';
import { SuccessIcon } from '@twilio-paste/icons/cjs/SuccessIcon';

type UsersListFootProps = {
    hasNext: boolean;
    nextToken?: string;
    loaderRef: MutableRefObject<null>;
};

export function UsersListFoot({ hasNext, nextToken, loaderRef }: UsersListFootProps) {
    return (
        <Box
            paddingTop="space60"
            paddingBottom="space60"
            display="flex"
            backgroundColor="colorBackgroundBody"
            justifyContent="center">
            {hasNext ? (
                <>
                    <Spinner
                        data-testid="loading-spinner"
                        decorative={false}
                        color="colorTextWeak"
                        title="Loading more team members"
                    />
                    <Box key={nextToken} ref={loaderRef} marginLeft="space30" display="inherit">
                        <Text
                            data-testid="loading-text"
                            as="span"
                            color="colorTextWeak"
                            fontSize="fontSize30"
                            fontWeight="fontWeightMedium"
                            lineHeight="lineHeight20">
                            Loading more team members
                        </Text>
                    </Box>
                </>
            ) : (
                <>
                    <SuccessIcon decorative color="colorTextSuccess" />
                    <Box marginLeft="space20" display="inherit">
                        <Text
                            data-testid="end-of-list-text"
                            as="span"
                            color="colorTextWeak"
                            fontSize="fontSize30"
                            fontWeight="fontWeightMedium"
                            lineHeight="lineHeight20">
                            You’ve reached the end of the list
                        </Text>
                    </Box>
                </>
            )}
        </Box>
    );
}
