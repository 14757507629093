import { ErrorType } from '../../../hooks/useCustomers';
import { EmptyCustomersListView } from './EmptyCustomersListView';

type EmptyCustomersListProps = {
    errorType: ErrorType | null;
    emptyViewOffsetHeight: number;
    onReload: () => void;
};

export function EmptyCustomersList({
    errorType,
    emptyViewOffsetHeight,
    onReload,
}: EmptyCustomersListProps) {
    if (errorType === 'emptyCallbackURLError') {
        return (
            <EmptyCustomersListView
                guidanceText="To integrate your CRM with Frontline, add a CRM callback URL in the Twilio console."
                title="No CRM connected"
                text="Please contact your IT team to connect a CRM to Frontline."
                offsetHeight={emptyViewOffsetHeight}
                onReload={onReload}
            />
        );
    }
    if (errorType === 'twilsockError') {
        return (
            <EmptyCustomersListView
                title="Unable to connect to Frontline"
                text="We’re having issues connecting to our servers. Please try reloading or contact Twilio support if you’re still having trouble."
                offsetHeight={emptyViewOffsetHeight}
                onReload={onReload}
            />
        );
    }
    if (errorType === 'general') {
        return (
            <EmptyCustomersListView
                guidanceText="Frontline isn’t getting an expected response from your CRM integration. Check your CRM callback or  the Twilio console Error Logs."
                title="Unable to load customers"
                text="Please contact your IT team for help loading customer information into Frontline."
                offsetHeight={emptyViewOffsetHeight}
                onReload={onReload}
            />
        );
    }

    return (
        <EmptyCustomersListView
            title="No customers assigned yet"
            text="Once customers are assigned to you in your CRM, they will appear here. Please contact your IT team to update your CRM."
            offsetHeight={emptyViewOffsetHeight}
            onReload={onReload}
        />
    );
}
