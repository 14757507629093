import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalFooterActions,
    ModalHeader,
    ModalHeading,
} from '@twilio-paste/modal';
import { Button } from '@twilio-paste/button';
import { useUID } from '@twilio-paste/uid-library';
import { Paragraph } from '@twilio-paste/paragraph';

import { getChannelName, getChannelDisplayName } from '@twilio/frontline-shared/models/Participant';
import { ChannelType } from '@twilio/frontline-shared/types/channel';
import { FrontlineErrors } from '@twilio/frontline-shared/models/ErrorCodes';

export type ConversationError = 'AlreadyParticipant' | 'Stranded';

type ConversationWarningModalProps = {
    conversationError: ConversationError | null;
    channelType?: ChannelType;
    friendlyName?: string;
    onClose: () => void;
};

export function ConversationWarningModal({
    conversationError,
    channelType,
    friendlyName,
    onClose,
}: ConversationWarningModalProps) {
    const modalHeadingID = useUID();
    const channelDisplayName = channelType ? getChannelName(channelType as ChannelType) : 'Unknown';

    const renderWarningInfo = () => {
        if (conversationError === 'AlreadyParticipant') {
            const participantChannelName = getChannelDisplayName(channelDisplayName, true);
            return (
                <Paragraph>
                    {friendlyName} is already engaged in {participantChannelName} conversation with
                    this customer.
                </Paragraph>
            );
        }
        const strandedChannelName = getChannelDisplayName(channelDisplayName);
        return (
            <Paragraph>
                {strandedChannelName} conversation was created with this customer, but no team
                member was added.
            </Paragraph>
        );
    };

    const renderWarningRecommendation = () => {
        if (conversationError === 'AlreadyParticipant') {
            return (
                <Paragraph>
                    Reach out to your co-worker to close or transfer the conversation, or contact IT
                    support.
                </Paragraph>
            );
        }
        return (
            <Paragraph>
                Ask your IT support team to add you to this conversation or delete it.
            </Paragraph>
        );
    };

    return (
        <Modal
            ariaLabelledby={modalHeadingID}
            isOpen={Boolean(conversationError)}
            onDismiss={onClose}
            size="default">
            <ModalHeader>
                <ModalHeading data-testid="error-heading" as="h3" id={modalHeadingID}>
                    Conversation already exists
                </ModalHeading>
            </ModalHeader>
            <ModalBody data-testid="error-body">
                {renderWarningInfo()}

                {renderWarningRecommendation()}

                <Paragraph marginBottom="space0">
                    Developer guidance: In the Twilio console, open error logs and search for error{' '}
                    {FrontlineErrors.OUTGOING_CONVERSATION_CONVERSATION_ALREADY_EXISTS}.
                </Paragraph>
            </ModalBody>
            <ModalFooter>
                <ModalFooterActions>
                    <Button variant="primary" onClick={onClose}>
                        OK
                    </Button>
                </ModalFooterActions>
            </ModalFooter>
        </Modal>
    );
}
