import React from 'react';
import { StyleSheet, View } from 'react-native';

import { Participant } from '@twilio/frontline-shared/models/Participant';

import { DefaultTheme } from '@twilio/frontline-shared/theme';
import { BubbleContainer } from './Bubble';
import { IChatMessage, IChatSystemMessage, IChatUser } from './types';
import { ChatAvatar } from './ChatAvatar';
import { SystemMessage } from './SystemMessage';
import { Day } from './Day';

export type MessageProps = {
    user: IChatUser;
    currentMessage: IChatMessage;
    previousMessage?: IChatMessage;
    nextMessage?: IChatMessage;
    position: 'left' | 'right';
    customerParticipant?: Participant;
};

function Message({
    currentMessage,
    previousMessage,
    nextMessage,
    position,
    customerParticipant,
    user,
}: MessageProps) {
    if (currentMessage) {
        return (
            <View>
                <Day currentMessage={currentMessage} previousMessage={previousMessage} />
                {currentMessage.system ? (
                    <SystemMessage
                        currentMessage={currentMessage as unknown as IChatSystemMessage}
                        customerParticipant={customerParticipant}
                    />
                ) : (
                    <View style={containerStyles[position]}>
                        {position === 'left' ? (
                            <ChatAvatar
                                currentMessage={currentMessage}
                                previousMessage={previousMessage}
                            />
                        ) : null}
                        <BubbleContainer
                            user={user}
                            currentMessage={currentMessage}
                            previousMessage={previousMessage}
                            nextMessage={nextMessage}
                            position={position}
                        />
                    </View>
                )}
            </View>
        );
    }
    return null;
}

const MessageMemoed = React.memo(Message);

export { MessageMemoed as Message };

const containerStyles = StyleSheet.create({
    left: {
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'flex-start',
        marginLeft: 8,
        marginRight: 0,
        marginBottom: DefaultTheme.space.space10,
    },
    right: {
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        marginLeft: 0,
        marginRight: 8,
        marginBottom: DefaultTheme.space.space10,
    },
});
