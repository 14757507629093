import { Box } from '@twilio-paste/box';
import { Card } from '@twilio-paste/card';
import { Heading } from '@twilio-paste/heading';
import { Paragraph } from '@twilio-paste/paragraph';

export function NoTeamMembersBox() {
    return (
        <Box marginTop="space60">
            <Card>
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Heading as="h4" variant="heading40">
                        No team members yet
                    </Heading>
                    <Paragraph>
                        When team members sign into the Frontline mobile app for the first time,
                        they will appear here.
                    </Paragraph>
                </Box>
            </Card>
        </Box>
    );
}
