import React, { ErrorInfo, ReactNode } from 'react';
import { Box } from '@twilio-paste/box';
import { Flex } from '@twilio-paste/flex';
import { Button } from '@twilio-paste/button';
import Logo from '../assets/frontline-logo.svg';
import Illustration from '../assets/something-went-wrong.svg';
import { Analytics } from '../analytics';

type ErrorBoundaryProps = { children: ReactNode };

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, { hasError: boolean }> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: Error) {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        Analytics.logError('UNHANDLED_ERROR', { err: error, errorInfo: errorInfo.componentStack });
    }

    render() {
        const { hasError } = this.state;
        const { children } = this.props;
        if (hasError) {
            return (
                <Flex vertical hAlignContent="center" height="100vh">
                    <Flex paddingTop="space70" paddingBottom="space70">
                        <Box
                            as="img"
                            src={Logo}
                            alt="logo"
                            paddingRight="space30"
                            height="sizeSquare70"
                        />
                        <Box
                            marginTop="space10"
                            fontWeight="fontWeightBold"
                            fontSize="fontSize30"
                            lineHeight="lineHeight20">
                            Twilio Frontline
                        </Box>
                    </Flex>
                    <Flex
                        vertical
                        height="100%"
                        hAlignContent="center"
                        vAlignContent="center"
                        paddingTop="space70"
                        paddingBottom="space70">
                        <Box as="img" src={Illustration} alt="illustration" />
                        <Box
                            fontWeight="fontWeightBold"
                            fontSize="fontSize40"
                            lineHeight="lineHeight40"
                            paddingBottom="space40">
                            Something went wrong
                        </Box>
                        <Box
                            fontWeight="fontWeightLight"
                            fontSize="fontSize30"
                            lineHeight="lineHeight20"
                            paddingBottom="space70">
                            An internal error occurred. Please reload the page or try again later.
                        </Box>
                        <Button
                            variant="primary"
                            onClick={() => {
                                window.location.reload();
                            }}>
                            Reload
                        </Button>
                    </Flex>
                </Flex>
            );
        }

        return children;
    }
}
