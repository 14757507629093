import React from 'react';
import { TouchableOpacity } from 'react-native';

import { TwilIcon, TwilIconProps } from './TwilIcon';

type TwilIconButtonProps = {
    onPress(): void;
    testID?: string;
    children?: React.ReactNode;
} & TwilIconProps;

export function TwilIconButton({ onPress, children, ...iconProps }: TwilIconButtonProps) {
    return (
        <TouchableOpacity onPress={() => onPress()} style={{ flexDirection: 'row' }}>
            <TwilIcon {...iconProps} />
            {children}
        </TouchableOpacity>
    );
}
