import * as Sentry from '@sentry/types';
import { AnalyticsService } from './type';

export abstract class SentryService extends AnalyticsService {
    sentry: any;
}

export const SENTRY_ID = 'sentry';

export const Severity = {
    Critical: 'critical',
    Debug: 'debug',
    Error: 'error',
    Fatal: 'fatal',
    Info: 'info',
    Log: 'log',
    Warning: 'warning',
};

export const MESSAGES_TO_MATCH = {
    timeout: /Twilsock: request timeout:/i,
    errorDisconnected: /Error: disconnected/i,
};

export const MAX_NUM_OF_ERRORS = 10;
export const FORBIDDEN_STATUS = 403;
export const FAILED_TO_SEND_MSG = 'FAILED_TO_SEND_A_MESSAGE';

const hasReportedError: { [key: string]: number } = {};

export const beforeSendSharedFilter = (event: Sentry.Event, hint?: Sentry.EventHint) => {
    const error = <Error>hint?.originalException;
    const extra = event?.extra;
    const extraMessage = <string>extra?.message;
    const status = <number>extra?.status;
    // JG the error.message is for web and the event.message is for mobile.
    const message = error?.message || event?.message;

    if (extraMessage) {
        // This is our protection for errors we throw so we don't throw,
        //  44k errors from 1 user in a day
        if (!hasReportedError[extraMessage]) {
            hasReportedError[extraMessage] = 1;
        } else if (hasReportedError[extraMessage] <= MAX_NUM_OF_ERRORS) {
            hasReportedError[extraMessage] += 1;
        } else {
            return null;
        }
        if (status && status === FORBIDDEN_STATUS && extraMessage === FAILED_TO_SEND_MSG) {
            return null;
        }
    }
    if (message) {
        if (
            message.match(MESSAGES_TO_MATCH.timeout) ||
            message.match(MESSAGES_TO_MATCH.errorDisconnected)
        ) {
            // do not send known issue
            return null;
        }
    }

    return event;
};
