import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { FASClient } from '@twilio/frontline-shared/core/FASClient';
import { internetConnectionStateChanged } from '@twilio/frontline-shared/store/InternetConnectionState';
import { useDispatch } from '@twilio/frontline-shared/store/redux';

import { Layout } from './components/Layout/Layout';
import { ManagerRoute, PrivateRoute, ProtectedRoute } from './components/Route';
import { PAGES } from './paths';
import { FRONTLINE_VERSION, TWILIO_ENV, TWILIO_REGION } from './env';
import { initializeAnalytics } from './analytics';

import { Users } from './pages/Users';
import { Login } from './pages/Login';
import { AuthSSO } from './pages/AuthSSO';
import { UserConversations } from './pages/UserConversations';
import { MyCustomers } from './pages/MyCustomers';
import { MyConversations } from './pages/MyConversations';
import { Logout } from './pages/Logout';

export function App() {
    const dispatch = useDispatch();

    useEffect(() => {
        window.addEventListener('online', () => {
            dispatch(internetConnectionStateChanged(true));
        });

        window.addEventListener('offline', () => {
            dispatch(internetConnectionStateChanged(false));
        });

        return () => {
            window.removeEventListener('online', () => {
                dispatch(internetConnectionStateChanged(true));
            });
            window.removeEventListener('offline', () => {
                dispatch(internetConnectionStateChanged(false));
            });
        };
    });

    return (
        <Router>
            <Switch>
                <ProtectedRoute path={PAGES.SSO}>
                    <AuthSSO />
                </ProtectedRoute>
                <ProtectedRoute path={PAGES.LOGIN}>
                    <Login />
                </ProtectedRoute>
                <PrivateRoute path={PAGES.LOGOUT}>
                    <Logout />
                </PrivateRoute>
                <PrivateRoute path={[PAGES.DEFAULT]}>
                    <Layout>
                        <Switch>
                            <Route path={PAGES.CONVERSATIONS}>
                                <MyConversations />
                            </Route>
                            <Route path={PAGES.CUSTOMERS}>
                                <MyCustomers />
                            </Route>
                            <ManagerRoute path={PAGES.USER_CONVERSATIONS}>
                                <UserConversations />
                            </ManagerRoute>
                            <ManagerRoute path={PAGES.DEFAULT}>
                                <Users />
                            </ManagerRoute>
                        </Switch>
                    </Layout>
                </PrivateRoute>
            </Switch>
        </Router>
    );
}

FASClient.initialize({
    SSO_REDIRECT_URL: window.location.origin + PAGES.SSO,
    FRONTLINE_API_BASE_URL: `https://frontline-api.${TWILIO_REGION || 'us1'}.twilio.com`,
    IAM_BASE_URL: `https://iam.${TWILIO_ENV ? `${TWILIO_ENV}.` : ''}twilio.com`,
});

initializeAnalytics();

(window as any).frontline = { version: FRONTLINE_VERSION };
