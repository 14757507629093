import { useSelector } from '@twilio/frontline-shared/store/redux';
import { ExtendsChatState } from '@twilio/frontline-shared/store/ChatState';
import { Conversation } from '@twilio/frontline-shared/models/Conversation';
import { selectConversation, selectMyConversationSids } from './selectors';

export const useMyConversationSids = () => {
    return useSelector<ExtendsChatState, string[]>(selectMyConversationSids);
};

export const useConversation = (conversationSid: string) => {
    return useSelector<ExtendsChatState, Conversation | undefined>((state) =>
        selectConversation(state, conversationSid),
    );
};
