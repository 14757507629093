import React from 'react';
import { ViewStyle } from 'react-native';

import { MessageStatus } from '@twilio/frontline-shared/models/Message';

import { TwilIcon } from '@twilio/frontline-shared/components/TwilIcon';
import { DefaultTheme } from '@twilio/frontline-shared/theme';
import { ScalingActivityIndicator } from '../ScalingActivityIndicator';

const STATUS_SIZE = 12;

export function MessageStatusIcon({
    status,
    monoColor,
    style,
}: {
    status?: MessageStatus;
    monoColor?: boolean;
    style?: ViewStyle;
}) {
    const iconStyle = style;

    const accentColor = monoColor
        ? DefaultTheme.colors.colorGray0
        : DefaultTheme.colors.colorGray50;

    switch (status) {
        case MessageStatus.Read:
            return (
                <TwilIcon
                    color={accentColor}
                    name="statusRead"
                    size={STATUS_SIZE}
                    style={iconStyle}
                />
            );
        case MessageStatus.Delivered:
            return (
                <TwilIcon
                    color={accentColor}
                    name="statusDelivered"
                    size={STATUS_SIZE}
                    style={iconStyle}
                />
            );
        case MessageStatus.Sent:
            return (
                <TwilIcon
                    color={accentColor}
                    name="statusSent"
                    size={STATUS_SIZE}
                    style={iconStyle}
                />
            );
        case MessageStatus.Sending:
            return (
                <ScalingActivityIndicator
                    color={accentColor}
                    size={STATUS_SIZE}
                    style={iconStyle}
                />
            );

        case MessageStatus.Failed:
        case MessageStatus.Undelivered:
            return (
                <TwilIcon
                    color={
                        monoColor
                            ? 'rgb(252, 207, 207)'
                            : DefaultTheme.backgroundColors.colorBackgroundError
                    }
                    name="alert"
                    size={STATUS_SIZE}
                    style={iconStyle}
                />
            );
        default:
            return null;
    }
}
