import { ToasterToast } from '@twilio-paste/toast';
import { Anchor } from '@twilio-paste/anchor';
import { Text } from '@twilio-paste/text';
import { Conversation } from '@twilio/frontline-shared/models/Conversation';
import { WorkerUser } from '@twilio/frontline-shared/models/WorkerUser';
import { History } from 'history';
import { ToastMessage } from '../ToastMessage';
import { CustomToastMessage } from '../CustomToastMessage';
import { userConversationsPath } from '../../paths';

const SUCCESS_TOAST_TIMEOUT = 5000;
const ERROR_TOAST_TIMEOUT = 18000;

export function transferConversationSuccessToast(
    conversationSid: Conversation['sid'],
    conversationFriendlyName: Conversation['friendlyName'],
    prevUserFriendlyName: WorkerUser['friendly_name'],
    newUserFriendlyName: WorkerUser['friendly_name'],
    newUserIdentity: WorkerUser['identity'],
): ToasterToast {
    return {
        id: `transfer_conversation_success_${conversationSid}`,
        message: (
            <ToastMessage
                text={`${conversationFriendlyName} transferred from ${prevUserFriendlyName} to ${
                    newUserFriendlyName || newUserIdentity
                }.`}
            />
        ),
        variant: 'success',
        dismissAfter: SUCCESS_TOAST_TIMEOUT,
    };
}

export function transferConversationManagerSuccessToast(
    conversationSid: Conversation['sid'],
    conversationFriendlyName: Conversation['friendlyName'],
    prevUserFriendlyName: WorkerUser['friendly_name'],
    newUserFriendlyName: WorkerUser['friendly_name'],
    newUserIdentity: WorkerUser['identity'],
    history: History,
): ToasterToast {
    return {
        id: `transfer_conversation_manager_success_${conversationSid}`,
        message: (
            <CustomToastMessage>
                <Text as="span">
                    {conversationFriendlyName} transferred from {prevUserFriendlyName} to{' '}
                    <Anchor
                        onClick={(e) => {
                            e.preventDefault();
                            history.push(userConversationsPath(newUserIdentity));
                        }}
                        href={userConversationsPath(newUserIdentity)}>
                        {newUserFriendlyName || newUserIdentity}
                    </Anchor>
                    .
                </Text>
            </CustomToastMessage>
        ),
        variant: 'success',
        dismissAfter: SUCCESS_TOAST_TIMEOUT,
    };
}

export function transferConversationErrorToast(
    conversationSid: Conversation['sid'],
    conversationFriendlyName: Conversation['friendlyName'],
    newUser: WorkerUser,
    transferConversation: (conversationSid: string, newUser: WorkerUser) => void,
): ToasterToast {
    const errorMessage = (
        <ToastMessage
            title={`Unable to transfer ${conversationFriendlyName} to ${
                newUser.friendly_name || newUser.identity
            }.`}
            text="Try again or contact your support team if the problem continues."
            onRetry={() => transferConversation(conversationSid, newUser)}
        />
    );

    return {
        message: errorMessage,
        variant: 'error',
        dismissAfter: ERROR_TOAST_TIMEOUT,
        id: `transfer_conversation_error_${conversationSid}`,
    };
}
