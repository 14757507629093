import { Box } from '@twilio-paste/box';
import { Text } from '@twilio-paste/text';
import { Flex } from '@twilio-paste/flex';

import {
    ISimpleConversation,
    useConversationExtras,
} from '@twilio/frontline-shared/store/conversations/SimpleConversation';

import { ConversationTile, ConversationTileLoading } from '../../../components/ConversationTile';
import { InfoBox } from '../../../components/InfoBox';
import { useSession } from '../../../context/SessionContext';
import { ConversationViewVariant } from '../../../components/Conversation/types';
import { Avatar } from '../../../components/Avatar';

export type MyConversationsListProps = {
    conversationsLoaded: boolean;
    conversationsLoadingFailed: boolean;
    selectedConversationId: string | null;
    conversations: ISimpleConversation[];
    showNewConversation: boolean;
    onListItemClick: (conversationId: string) => void;
    onShowNewConversation: () => void;
};

export function MyConversationsList({
    conversationsLoaded,
    conversationsLoadingFailed,
    selectedConversationId,
    conversations,
    showNewConversation,
    onListItemClick,
    onShowNewConversation,
}: MyConversationsListProps) {
    const { session } = useSession();
    const identity = session?.frontlineSDK.userIdentity as string;
    const conversationExtras = useConversationExtras(identity);

    const renderNewConversationItem = () => (
        <Box
            display="flex"
            alignItems="center"
            width="100%"
            minHeight={72}
            paddingLeft="space60"
            paddingRight="space50"
            backgroundColor="colorBackground"
            cursor="pointer">
            <Avatar size="sizeIcon70" />
            <Text as="span" marginLeft="space30" fontWeight="fontWeightMedium">
                New conversation
            </Text>
        </Box>
    );

    if (conversationsLoadingFailed) {
        return (
            <Flex vertical width="100%" height={`calc(100% - ${60}px)`} padding="space50">
                <Flex vertical grow width="100%" vAlignContent="center" hAlignContent="center">
                    <InfoBox
                        title="Unable to load conversations"
                        text="Please contact your IT team for help loading conversations into Frontline."
                        showButton
                        onClick={() => session?.loadMyConversations()}
                    />
                </Flex>
            </Flex>
        );
    }

    if (!conversationsLoaded) {
        return (
            <Box overflowY="auto">
                {Array.from({ length: 50 }).map((_, rowIndex) => (
                    <ConversationTileLoading key={`loading-${rowIndex}`} />
                ))}
            </Box>
        );
    }

    if (!conversations.length && !showNewConversation) {
        return (
            <Box height="100%" width="100%" paddingX="space50">
                <InfoBox
                    showButton
                    title="No active conversations"
                    text="You can initiate a new conversation using the “Start a conversation” action below."
                    buttonText="Start a conversation"
                    onClick={onShowNewConversation}
                />
            </Box>
        );
    }

    return (
        <Box overflowY="auto">
            {showNewConversation && renderNewConversationItem()}
            {conversations.map((conversation) => (
                <Box
                    data-testid="conversation-box"
                    key={`conversation-${conversation.sid}`}
                    as="a"
                    cursor="pointer"
                    onClick={() => onListItemClick(conversation.sid)}>
                    <ConversationTile
                        variant={ConversationViewVariant.USER}
                        conversation={conversation}
                        info={conversationExtras[conversation.sid]}
                        selectedConversationId={selectedConversationId}
                    />
                </Box>
            ))}
        </Box>
    );
}
