import { IUser } from '@twilio/frontline-shared/models/User';
import { ToasterToast } from '@twilio-paste/toast';
import { ToastMessage } from '../../../components/ToastMessage';

const ERROR_TOAST_TIMEOUT = 14000;

export function userNotFoundErrorToast(userIdentity: IUser['identity']): ToasterToast {
    const message = (
        <ToastMessage text="Unable to display conversations for your team member. They may have been removed from your workspace." />
    );

    return {
        message,
        variant: 'error',
        dismissAfter: ERROR_TOAST_TIMEOUT,
        id: `user_not_found_error_${userIdentity}`,
    };
}
