import { ManageCustomerFieldType } from '../types/customer';
import { Channel } from '../types/channel';

/** Phone number REGEX Definition
 *
 * Source: https://stackoverflow.com/a/16702965
 *
 * ^\s*                #Line start, match any whitespaces at the beginning if any.
 * (whatsapp\:\+)?     #GROUP 1: Whatsapp prefix.  Optional.
 * (?:\+?(\d{1,3}))?   #GROUP 2: The country code. Optional.
 * [-. (]*             #Allow certain non numeric characters that may appear between the Country Code and the Area Code.
 * (\d{3})             #GROUP 3: The Area Code. Required.
 * [-. )]*             #Allow certain non numeric characters that may appear between the Area Code and the Exchange number.
 * (\d{3})             #GROUP 4: The Exchange number. Required.
 * [-. ]*              #Allow certain non numeric characters that may appear between the Exchange number and the Subscriber number.
 * (\d{4})             #Group 5: The Subscriber Number. Required.
 * (?: *x(\d+))?       #Group 6: The Extension number. Optional.
 * \s*$                #Match any ending whitespaces if any and the end of string.
 */
export const NUM_CHARS_TO_MASK = 4;
export const PHONE_NUMBER_REGEX_PART_COUNT = 7; // Group 6 has an array length of 7
export const PHONE_NUMBER_REGEX =
    /^\s*(?:\+?(whatsapp\:\+)?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

/** Email REGEX Definition
 * ([a-zA-Z0-9+._-]+)   #GROUP 1: Username
 * ([a-zA-Z0-9._-]+)    #GROUP 2: Domain Part 1
 * (\.[a-zA-Z0-9_-]+)   #GROUP 3: Domain Part 2 (.com)
 */
export const EMAIL_REGEX_PART_COUNT = 4;
export const EMAIL_REGEX = /([a-zA-Z0-9+._-]+)@([a-zA-Z0-9._-]+)(\.[a-zA-Z0-9_-]+)/;

/** Phone number extension REGEX definition.  An extension in the form of x432 in a string will be found. */
export const PHONE_NUMBER_EXT_REGEX_PART_COUNT = 3;
export const PHONE_NUMBER_EXT_REGEX = /(?: *x(\d+))|(?: *\;?ext=(\d+))/;

export class ManagerCustomerFormConstants {
    static readonly CUSTOMER_NAME: keyof ManageCustomerFieldType = 'customerName';
    static readonly COMPANY: keyof ManageCustomerFieldType = 'company';
    static readonly CHANNELS: keyof ManageCustomerFieldType = 'channels';
    static readonly COUNTRY: keyof Channel = 'country';
    static readonly TYPE: keyof Channel = 'type';
    static readonly VALUE: keyof Channel = 'value';
    static readonly MIN_CHANNEL_VALUE: number = 4;
    static readonly MAX_CHANNEL_VALUE: number = 20;
}
