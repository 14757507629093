import { Platform, TextStyle } from 'react-native';

const backgroundColors = {
    colorBackground: 'rgb(244, 244, 246)',
    colorBackgroundBody: 'rgb(255, 255, 255)',
    colorBackgroundBrand: 'rgb(35, 54, 89)',
    colorBackgroundBrandHighlight: 'rgb(242, 47, 70)',
    colorBackgroundDark: 'rgb(174, 178, 193)',
    colorBackgroundDarker: 'rgb(136, 145, 170)',
    colorBackgroundDarkest: 'rgb(75, 86, 113)',
    colorBackgroundDestructive: 'rgb(214, 31, 31)',
    colorBackgroundDestructiveDark: 'rgb(117, 12, 12)',
    colorBackgroundDestructiveDarker: 'rgb(74, 11, 11)',
    colorBackgroundDestructiveLight: 'rgb(245, 138, 138)',
    colorBackgroundDestructiveLighter: 'rgb(252, 207, 207)',
    colorBackgroundDestructiveLightest: 'rgb(254, 236, 236)',
    colorBackgroundError: 'rgb(214, 31, 31)',
    colorBackgroundErrorLightest: 'rgb(254, 236, 236)',
    colorBackgroundInverse: 'rgb(31, 48, 76)',
    colorBackgroundNeutralLightest: 'rgb(235, 244, 255)',
    colorBackgroundOverlay: 'rgba(18, 28, 45, 0.5)',
    colorBackgroundPrimary: 'rgb(2, 99, 224)',
    colorBackgroundPrimaryDark: 'rgb(0, 20, 137)',
    colorBackgroundPrimaryDarker: 'rgb(3, 11, 93)',
    colorBackgroundPrimaryLight: 'rgb(102, 179, 255)',
    colorBackgroundPrimaryLighter: 'rgb(204, 228, 255)',
    colorBackgroundPrimaryLightest: 'rgb(235, 244, 255)',
    colorBackgroundRequired: 'rgb(235, 86, 86)',
    colorBackgroundRowStriped: 'rgb(244, 244, 246)',
    colorBackgroundSuccess: 'rgb(20, 176, 83)',
    colorBackgroundSuccessLightest: 'rgb(237, 253, 243)',
    colorBackgroundWarning: 'rgb(244, 124, 34)',
    colorBackgroundWarningLightest: 'rgb(254, 245, 238)',
};

const borderColors = {
    colorBorder: 'rgb(174, 178, 193)',
    colorBorderDark: 'rgb(96, 107, 133)',
    colorBorderDestructive: 'rgb(214, 31, 31)',
    colorBorderDestructiveDark: 'rgb(117, 12, 12)',
    colorBorderDestructiveDarker: 'rgb(74, 11, 11)',
    colorBorderDestructiveLight: 'rgb(245, 138, 138)',
    colorBorderDestructiveLighter: 'rgb(252, 207, 207)',
    colorBorderError: 'rgb(214, 31, 31)',
    colorBorderErrorLight: 'rgb(245, 138, 138)',
    colorBorderErrorLightest: 'rgb(254, 236, 236)',
    colorBorderInput: 'rgb(96, 107, 133)',
    colorBorderInputFocus: 'rgb(2, 99, 224)',
    colorBorderInputHover: 'rgb(2, 99, 224)',
    colorBorderInverse: 'rgb(255, 255, 255)',
    colorBorderLight: 'rgb(225, 227, 234)',
    colorBorderNeutralLight: 'rgb(136, 145, 170)',
    colorBorderPrimary: 'rgb(2, 99, 224)',
    colorBorderPrimaryDark: 'rgb(0, 20, 137)',
    colorBorderPrimaryDarker: 'rgb(3, 11, 93)',
    colorBorderPrimaryLight: 'rgb(102, 179, 255)',
    colorBorderPrimaryLighter: 'rgb(204, 228, 255)',
    colorBorderSuccess: 'rgb(20, 176, 83)',
    colorBorderSuccessLightest: 'rgb(237, 253, 243)',
    colorBorderWarning: 'rgb(244, 124, 34)',
    colorBorderWarningLight: 'rgb(255, 179, 122)',
    colorBorderWarningLightest: 'rgb(254, 245, 238)',
};

export const colors = {
    colorBrand: 'rgb(35, 54, 89)',
    colorBrandHighlight: 'rgb(242, 47, 70)',
    colorGray0: 'rgb(255, 255, 255)',
    colorGray10: 'rgb(244, 244, 246)',
    colorGray100: 'rgb(18, 28, 45)',
    colorGray20: 'rgb(225, 227, 234)',
    colorGray30: 'rgb(202, 205, 216)',
    colorGray40: 'rgb(174, 178, 193)',
    colorGray50: 'rgb(136, 145, 170)',
    colorGray60: 'rgb(96, 107, 133)',
    colorGray70: 'rgb(75, 86, 113)',
    colorGray80: 'rgb(57, 71, 98)',
    colorGray90: 'rgb(31, 48, 76)',
};

const borderWidths = {
    borderWidth: 0,
    borderWidth10: 1,
    borderWidth20: 2,
};

const radii = {
    borderRadius0: 0,
    borderRadius4: 4,
    borderRadius8: 8,
    borderRadius9: 22,
    borderRadius10: 40,
    borderRadiusCircle: 50,
};

const sizes = {
    size0: 0,
    size1: 1,
    size2: 2,
    size8: 8,
    size13: 12,
    size15: 14,
    size20: 16,
    size24: 24,
    size40: 44,
};

const fontSizes = {
    fontSize9: 12,
    fontSize10: 14,
    fontSize16: 16,
    fontSize18: 18,
    fontSize20: 20,
    fontSize24: 24,
    fontSize30: 32,
};

const fontWeightSemibold: TextStyle['fontWeight'] = Platform.select({
    android: '700',
    default: '600',
});

const fontWeights: { [key: string]: TextStyle['fontWeight'] } = {
    fontWeightSemibold,
    fontWeightBold: '700',
    fontWeightLight: '300',
    fontWeightMedium: '500',
    fontWeightNormal: '400',
};

const lineHeights = {
    lineHeight9: 16,
    lineHeight10: 20,
    lineHeight20: 28,
    lineHeight30: 32,
    lineHeight44: 44,
};

const space = {
    space0: 0,
    space5: 4,
    space7: 6,
    space10: 8,
    space20: 12,
    space30: 16,
    space35: 20,
    space40: 24,
    space45: 28,
    space50: 32,
    space60: 40,
    space70: 48,
};

const textColors = {
    colorText: 'rgb(18, 28, 45)',
    colorTextBrandHighlight: 'rgb(242, 47, 70)',
    colorTextBrandInverse: 'rgb(255, 255, 255)',
    colorTextError: 'rgb(214, 31, 31)',
    colorTextErrorDark: 'rgb(173, 17, 17)',
    colorTextIcon: 'rgb(75, 86, 113)',
    colorTextInverse: 'rgb(255, 255, 255)',
    colorTextInverseWeak: 'rgb(136, 145, 170)',
    colorTextLabel: 'rgb(18, 28, 45)',
    colorTextLinkDark: 'rgb(0, 20, 137)',
    colorTextLinkDarker: 'rgb(3, 11, 93)',
    colorTextLinkDestructive: 'rgb(214, 31, 31)',
    colorTextLinkDestructiveDark: 'rgb(173, 17, 17)',
    colorTextLinkDestructiveDarker: 'rgb(117, 12, 12)',
    colorTextLinkDestructiveLight: 'rgb(245, 138, 138)',
    colorTextLinkLight: 'rgb(102, 179, 255)',
    colorTextSuccess: 'rgb(20, 176, 83)',
    colorTextWarning: 'rgb(244, 124, 34)',
    colorTextWarningDark: 'rgb(195, 83, 35)',
    colorTextWeak: 'rgb(75, 86, 113)',
    colorTextPlaceholder: colors.colorGray60,
    colorTextButton: backgroundColors.colorBackgroundPrimary,
    colorTextLink: 'rgb(2, 99, 224)',
};

export const DefaultTheme = {
    backgroundColors,
    borderColors,
    borderWidths,
    colors,
    radii,
    sizes,
    fontSizes,
    fontWeights,
    lineHeights,
    space,
    textColors,
};
