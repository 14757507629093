import { Box } from '@twilio-paste/box';
import { Flex } from '@twilio-paste/flex';
import { SkeletonLoader } from '@twilio-paste/skeleton-loader';

import { CUSTOMERS_LIST_ITEM_HEIGHT } from './MyCustomerListItem';

export function MyCustomersListLoader() {
    return (
        <Flex vertical>
            {Array(30)
                .fill(null)
                .map((_, idx) => (
                    <Box
                        key={idx}
                        height={CUSTOMERS_LIST_ITEM_HEIGHT}
                        width="100%"
                        display="flex"
                        alignItems="center"
                        paddingLeft="space50"
                        paddingRight="space50">
                        <SkeletonLoader width={32} height={32} borderRadius="borderRadiusCircle" />
                        <Box marginLeft="space30">
                            <SkeletonLoader width={158} height={20} borderRadius="borderRadius20" />
                        </Box>
                    </Box>
                ))}
        </Flex>
    );
}
