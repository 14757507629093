import { Box } from '@twilio-paste/box';
import { Button } from '@twilio-paste/button';
import { Flex } from '@twilio-paste/flex';
import { Heading } from '@twilio-paste/heading';
import { Popover, PopoverButton, PopoverContainer } from '@twilio-paste/popover';
import { Text } from '@twilio-paste/text';
import { InformationIcon } from '@twilio-paste/icons/cjs/InformationIcon';
import { TemplateOnlyComposerProps } from '@twilio/frontline-shared/components/Chat/types';
import { Analytics } from '@twilio/frontline-web/src/analytics';

const WHATSAPP_WINDOW_INFO_TITLE = 'Engaging on WhatsApp';
const WHATSAPP_WINDOW_INFO_DESCRIPTION =
    'To start a conversation with this customer on WhatsApp, you must first send a WhatsApp-approved template and wait for a response. You then have 24 hours to reply before this process becomes applicable again.';

export function TemplateOnlyComposer({ name, openTemplateModal }: TemplateOnlyComposerProps) {
    return (
        <Flex hAlignContent="center" vAlignContent="center" width="100%">
            <Button
                variant="primary"
                onClick={() => {
                    openTemplateModal?.();
                    Analytics.logEvent('click_whatsapp_reach_out');
                }}>
                <Text as="span" color="colorTextInverse" data-testid="reachOutButton">
                    Reach out to {name}
                </Text>
            </Button>

            <Box marginLeft="space40">
                <PopoverContainer baseId="popover-example">
                    <PopoverButton variant="secondary_icon" size="icon_small">
                        <InformationIcon
                            decorative={false}
                            color="colorTextLink"
                            size="sizeIcon40"
                            title={WHATSAPP_WINDOW_INFO_TITLE}
                        />
                    </PopoverButton>
                    <Popover aria-label="Popover">
                        <Box maxWidth={296}>
                            <Heading as="h4" variant="heading40" color="colorText">
                                {WHATSAPP_WINDOW_INFO_TITLE}
                            </Heading>
                            <Text as="span" color="colorText">
                                {WHATSAPP_WINDOW_INFO_DESCRIPTION}
                            </Text>
                        </Box>
                    </Popover>
                </PopoverContainer>
            </Box>
        </Flex>
    );
}
