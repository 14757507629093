import { ReactNode } from 'react';
import { Box } from '@twilio-paste/box';

export function InputToolbarContainer({ children }: { children: ReactNode }) {
    return (
        <Box
            display="flex"
            alignItems="center"
            borderTopWidth="borderWidth10"
            borderTopStyle="solid"
            borderTopColor="colorBorderWeak"
            paddingTop="space40"
            paddingBottom="space40"
            paddingLeft="space50"
            paddingRight="space50">
            {children}
        </Box>
    );
}
