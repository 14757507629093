import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box } from '@twilio-paste/box';
import { Heading } from '@twilio-paste/heading';
import { Flex } from '@twilio-paste/flex';
import { Text } from '@twilio-paste/text';
import { Stack } from '@twilio-paste/stack';
import { Spinner } from '@twilio-paste/spinner';

import { ChannelType } from '@twilio/frontline-shared/types/channel';
import { CustomerLink } from '@twilio/frontline-shared/types/customer';
import { isSafeUrl, isStringPresent, toSafeUrl } from '@twilio/frontline-shared/utils/stringUtils';
import { isObject } from '@twilio/frontline-shared/utils/isObject';
import { CreateConversationRequestBody } from '@twilio/frontline-shared/core/types';
import { FrontlineErrors } from '@twilio/frontline-shared/models/ErrorCodes';
import { findExistingConversationSid } from '@twilio/frontline-shared/models/Conversation';
import { useMyConversationSids } from '@twilio/frontline-shared/store/conversations/hooks';
import { useParticipants } from '@twilio/frontline-shared/store/ParticipantsState';
import { Analytics } from '@twilio/frontline-shared/analytics';

import { Avatar } from '../../components/Avatar';
import { InfoBox } from '../../components/InfoBox';
import { useCustomer, CustomerState } from '../../hooks/useCustomer';
import { useQuery } from '../../hooks/useQuery';
import { getMyConversationPath } from '../../paths';
import { WaitingOverlay } from '../../components/WaitingOverlay';
import { ToastMessage } from '../../components/ToastMessage';
import { useSession } from '../../context/SessionContext';
import { useToasterContext } from '../../context/ToasterContext';

import { EmptyCustomerDetails } from './components/EmptyCustomerDetails';
import { CustomerDetailsContent } from './components/CustomerDetailsContent';
import { ConversationWarningModal, ConversationError } from './components/ConversationWarningModal';

type FailedChannel = {
    type: ChannelType;
    value: string;
    workerIdentity?: string;
};

type CustomerDetailsProps = {
    customerId?: string;
    onCreateConversationCallback?: (conversationId: string) => void;
};

export function CustomerDetails({
    customerId: participantCustomerId,
    onCreateConversationCallback,
}: CustomerDetailsProps) {
    const [showLoadingModal, setShowLoadingModal] = useState<boolean>(false);
    const [conversationError, setConversationError] = useState<ConversationError | null>(null);
    const [failedChannel, setFailedChannel] = useState<FailedChannel>();
    const history = useHistory();
    const query = useQuery();
    const conversationSids = useMyConversationSids();
    const participants = useParticipants();
    const customerId = (query.get('customer_id') as string) ?? participantCustomerId;
    const { state, customer, onReload } = useCustomer(customerId);
    const { session } = useSession();
    const toaster = useToasterContext();

    if (state === CustomerState.Pending) {
        return (
            <Flex height="100%" width="100%" vAlignContent="center" hAlignContent="center">
                <Stack orientation="horizontal" spacing="space30">
                    <Spinner decorative={false} title="Loading" color="colorTextWeak" />
                    <Text as="span" color="colorTextWeak">
                        Loading customer
                    </Text>
                </Stack>
            </Flex>
        );
    }

    if (state === CustomerState.Rejected) {
        return (
            <InfoBox
                title="Unable to display customer"
                text="Please contact your support team or try again later."
                showButton
                onClick={onReload}
            />
        );
    }

    if (state === CustomerState.NoCustomer) {
        return (
            <InfoBox
                title="No details to display"
                text="This record has no details stored. Populate this on your CRM or contact your support team."
                showButton
                onClick={onReload}
            />
        );
    }

    const smsChannels = customer?.channels?.filter((channel) => channel.type === ChannelType.Sms);
    const whatsAppChannels = customer?.channels?.filter(
        (channel) => channel.type === ChannelType.Whatsapp,
    );
    const chatChannels = customer?.channels?.filter((channel) => channel.type === ChannelType.Chat);

    const isLinkValid = (maybeLink: Partial<CustomerLink>): maybeLink is CustomerLink => {
        return (
            isObject(maybeLink) &&
            isStringPresent(maybeLink.value) &&
            isStringPresent(maybeLink.display_name) &&
            isStringPresent(maybeLink.type) &&
            isSafeUrl(maybeLink.value)
        );
    };
    const filteredLinks = customer?.links
        ?.map((maybeLink) => ({ ...maybeLink, value: toSafeUrl(maybeLink.value) }))
        .filter(isLinkValid);

    const isEmpty = !customer?.channels?.length && !filteredLinks?.length && !customer?.details;

    const handleCreateConversation = (type: ChannelType, value: string) => {
        const existingConversationSid = findExistingConversationSid(
            customerId,
            type,
            conversationSids,
            participants,
        );
        if (existingConversationSid) {
            const path = getMyConversationPath(existingConversationSid);
            history.push(path);
            Analytics.logEvent({
                event: 'open_customer_conversation',
                extra: { channelType: type },
            });
            return;
        }
        setShowLoadingModal(true);
        const conversationRequestBody: CreateConversationRequestBody = {
            customer: {
                id: customerId,
                displayName: customer?.display_name ?? value,
                avatar: customer?.avatar,
            },
            channel: { type, value },
        };
        session?.frontlineSDK
            ?.createConversation(conversationRequestBody)
            .then((conversation) => {
                if (onCreateConversationCallback) {
                    onCreateConversationCallback(conversation.sid);
                } else {
                    const path = getMyConversationPath(conversation.sid);
                    history.push(path);
                }
            })
            .catch((e) => {
                console.log('error', e);
                const workerIdentity = e?.body?.params?.workerIdentity;
                setFailedChannel({ type, value, workerIdentity });
                if (
                    e?.body?.code ===
                    FrontlineErrors.OUTGOING_CONVERSATION_CONVERSATION_ALREADY_EXISTS
                ) {
                    setConversationError(workerIdentity ? 'AlreadyParticipant' : 'Stranded');
                } else {
                    const timestamp = Date.now();
                    toaster.push({
                        message: (
                            <ToastMessage
                                title="Unable to create conversation."
                                text="Please check your internet connection and retry, or contact IT support."
                                onRetry={() => handleCreateConversation(type, value)}
                            />
                        ),
                        variant: 'error',
                        dismissAfter: 8000,
                        id: `conversation_${customerId}_${timestamp}`,
                    });
                }
            })
            .finally(() => setShowLoadingModal(false));
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            width="100%"
            height="100%"
            paddingY="space90"
            overflowY="auto">
            <Flex vertical hAlignContent="center">
                <Avatar
                    size="sizeIcon110"
                    friendlyName={customer?.display_name}
                    avatar={customer?.avatar}
                />
                <Box marginTop="space40" marginBottom="space100" textAlign="center">
                    <Heading
                        as="h3"
                        variant="heading30"
                        marginBottom="space0"
                        data-testid="customer-friendly-name">
                        {customer?.display_name}
                    </Heading>
                </Box>
            </Flex>

            {isEmpty ? (
                <EmptyCustomerDetails />
            ) : (
                <CustomerDetailsContent
                    key={customer?.customer_id}
                    smsChannels={smsChannels}
                    whatsAppChannels={whatsAppChannels}
                    chatChannels={chatChannels}
                    links={filteredLinks}
                    details={customer?.details}
                    onCreateConversation={handleCreateConversation}
                />
            )}

            <WaitingOverlay isOpen={showLoadingModal} message="Creating conversation" />

            <ConversationWarningModal
                conversationError={conversationError}
                channelType={failedChannel?.type}
                friendlyName={failedChannel?.workerIdentity}
                onClose={() => setConversationError(null)}
            />
        </Box>
    );
}
