import React from 'react';
import { View, TouchableOpacity, Text, Alert, StyleSheet } from 'react-native';
import { DefaultTheme } from '@twilio/frontline-shared/theme';
import { getBottomSpace } from 'react-native-iphone-x-helper';
import { Analytics } from '@twilio/frontline-shared/analytics';
import { TwilIconButton } from '../TwilIconButton';
import { useChatContext } from './ChatContext';
import { TemplateOnlyComposerProps } from './types';

export function TemplateOnlyComposer({ name, openTemplateModal }: TemplateOnlyComposerProps) {
    const { onAlert } = useChatContext();
    const alertFunc = onAlert || Alert.alert;
    return (
        <View style={styles.composer}>
            <TouchableOpacity
                onPress={() => {
                    openTemplateModal?.();
                    Analytics.logEvent('click_whatsapp_reach_out');
                }}
                style={styles.templateButton}>
                <Text
                    testID="reachOutButton"
                    allowFontScaling={false}
                    style={styles.buttonText}
                    numberOfLines={1}>
                    Reach out to {name}
                </Text>
            </TouchableOpacity>
            <TwilIconButton
                testID="templateInfoButton"
                style={styles.infoIcon}
                onPress={() => {
                    alertFunc(
                        'Engaging on WhatsApp',
                        'To start a conversation with this customer on WhatsApp you must first send a WhatsApp-approved template and wait for a response. You then have 24 hours to reply before this process becomes applicable again.',
                    );
                    Analytics.logEvent('click_whatsapp_info');
                }}
                name="info"
            />
        </View>
    );
}

const styles = StyleSheet.create({
    buttonText: {
        color: DefaultTheme.textColors.colorTextInverse,
        fontWeight: DefaultTheme.fontWeights.fontWeightSemibold,
        paddingHorizontal: DefaultTheme.space.space10,
    },
    infoIcon: {
        marginLeft: DefaultTheme.space.space30,
    },
    templateButton: {
        flex: 1,
        paddingVertical: DefaultTheme.sizes.size13,
        backgroundColor: DefaultTheme.backgroundColors.colorBackgroundPrimary,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: DefaultTheme.radii.borderRadius4,
    },
    composer: {
        paddingTop: DefaultTheme.sizes.size20,
        paddingLeft: DefaultTheme.sizes.size20,
        paddingRight: DefaultTheme.sizes.size24,
        marginBottom: DefaultTheme.sizes.size20 + getBottomSpace(),
        flexDirection: 'row',
        alignItems: 'center',
    },
});
