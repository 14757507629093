import { Heading } from '@twilio-paste/heading';
import { Flex } from '@twilio-paste/flex';
import { Box } from '@twilio-paste/box';
import { DataGrid, DataGridHead, DataGridHeader, DataGridRow } from '@twilio-paste/data-grid';

import { SearchBar } from '../../components/SearchBar';
import { useUsers, UsersState } from '../../hooks/useUsers';
import { NoTeamMembersBox } from '../../components/NoTeamMembersBox';
import { NoSearchResultBox } from '../../components/NoSearchResultBox';
import { useSearchBar } from '../../hooks/useSearchBar';
import { UsersListFoot } from './components/UsersListFoot';
import { UsersList } from './components/UsersList';

export const MAX_WIDTH = 1280;

export function Users() {
    const { inputValue, query, setInputValue, updateQuery, clearSearch } = useSearchBar('');
    const { users, state, loaderRef, pageMeta, hasNext } = useUsers({ query });

    const renderUsersContent = () => {
        if (state === UsersState.NoSearchResult) {
            return <NoSearchResultBox query={query} onClearSearch={clearSearch} />;
        }
        if (state === UsersState.NoUsers) {
            return <NoTeamMembersBox />;
        }
        return (
            <Box>
                <UsersList users={users} state={state} />
                <UsersListFoot
                    hasNext={hasNext}
                    loaderRef={loaderRef}
                    nextToken={pageMeta?.next_token}
                />
            </Box>
        );
    };

    return (
        <>
            <Box position="sticky" top={0} zIndex="zIndex10">
                <Box
                    display="flex"
                    justifyContent="center"
                    paddingTop="space40"
                    marginLeft="space40"
                    marginRight="space40"
                    backgroundColor="colorBackgroundBody">
                    <Box position="relative" width="100%" maxWidth={MAX_WIDTH}>
                        <Heading as="h1" variant="heading10">
                            Team activity
                        </Heading>
                        <SearchBar
                            placeholder="Search team members..."
                            inputValue={inputValue}
                            onInputChange={setInputValue}
                            onSearch={updateQuery}
                            onClearSearch={clearSearch}
                        />
                        <DataGrid aria-label="User information table header" striped>
                            <DataGridHead>
                                <DataGridRow>
                                    <DataGridHeader>Team members</DataGridHeader>
                                </DataGridRow>
                            </DataGridHead>
                        </DataGrid>
                    </Box>
                </Box>
            </Box>
            <Flex hAlignContent="center">
                <Box
                    position="relative"
                    maxWidth={MAX_WIDTH}
                    width="100%"
                    marginTop="spaceNegative20"
                    marginLeft="space40"
                    marginRight="space40"
                    paddingBottom="space200">
                    {renderUsersContent()}
                </Box>
            </Flex>
        </>
    );
}
