import { createSelector } from '@reduxjs/toolkit';

import {
    FrontlineEventType,
    getSystemMessageText,
    getUnreadCallMsgText,
} from '@twilio/frontline-shared/models/Event';
import {
    Conversation,
    isLastMessageSystemEvent,
} from '@twilio/frontline-shared/models/Conversation';
import { IMedia } from '@twilio/frontline-shared/models/Media';
import { Participant } from '@twilio/frontline-shared/models/Participant';
import { ChannelType } from '@twilio/frontline-shared/types/channel';
import { ConversationMessage } from '@twilio/frontline-shared/models/Message';
import { ParticipantsState } from '@twilio/frontline-shared/store/ParticipantsState';
import { ExtendsChatState } from '@twilio/frontline-shared/store/ChatState';
import { DraftState } from '@twilio/frontline-shared/store/draft/DraftState';
import { ChannelMediaState } from '@twilio/frontline-shared/store/media/ChannelMediaState';
import { ChatMessagesState } from '@twilio/frontline-shared/store/messages/ChatMessagesState';
import { useSelector } from '@twilio/frontline-shared/store/redux';
import { ConversationsUsersState } from '@twilio/frontline-shared/store/users/ConversationsUsersState';
import { ConversationSid, UserIdentity } from '@twilio/frontline-shared/types';
import { ConversationsState } from './ConversationsState';
import { selectUserConversationsSids } from './selectors';

/*
 * ISimpleConversation has only the necessary attributes of Conversation
 * which are used to render Conversations List in order to prevent unnecessary
 * rerendering
 */
export type ISimpleConversation = Pick<
    Conversation,
    | 'sid'
    | 'friendlyName'
    | 'avatar'
    | 'lastMessage'
    | 'lastSystemEvent'
    | 'lastMessageTime'
    | 'unreadMessagesCount'
    | 'unreadSystemMessagesCount'
    | 'createdAt'
    | 'markedUnreadAt'
> & {
    lastSystemEventText?: string;
};

export type ConversationInfo = {
    lastMessage?: ConversationMessage;
    lastMessageMedia?: IMedia;
    draft?: string;
    userParticipant?: Participant;
    lastChatReadTime: number;
    channelType: ChannelType;
};

const conversationExtrasSelector = (userIdentity: string) =>
    createSelector(
        [
            (state: ExtendsChatState) => state.chat.messages,
            (state: ExtendsChatState) => state.chat.drafts,
            (state: ExtendsChatState) => state.chat.media,
            (state: ExtendsChatState) => state.chat.participants,
        ],
        (
            messages: ChatMessagesState,
            drafts: DraftState,
            media: ChannelMediaState,
            participants: ParticipantsState,
        ) => {
            return Object.keys(messages).reduce<{
                [key: string]: ConversationInfo;
            }>((accumulator, conversationSid) => {
                const lastMessage =
                    messages[conversationSid]?.[messages[conversationSid].length - 1];
                let userParticipant;
                let lastChatReadTime = 0;
                let channelType = ChannelType.Chat;

                participants[conversationSid]?.forEach((p) => {
                    if (p.identity === userIdentity) {
                        userParticipant = p;
                    } else if (p.channelType === ChannelType.Chat) {
                        if (lastChatReadTime > 0) {
                            lastChatReadTime = Math.min(lastChatReadTime, p.lastMessageReadTime);
                        } else {
                            lastChatReadTime = p.lastMessageReadTime;
                        }
                    } else if (p.channelType) {
                        channelType = p.channelType;
                    }
                });

                accumulator[conversationSid] = {
                    lastMessage: lastMessage || undefined,
                    draft: drafts[conversationSid] || undefined,
                    lastMessageMedia: lastMessage?.mediaSid
                        ? media[lastMessage.mediaSid]
                        : undefined,
                    userParticipant,
                    lastChatReadTime,
                    channelType,
                };
                return accumulator;
            }, {});
        },
    );

export const useConversationExtras = (userIdentity: string) => {
    return useSelector(conversationExtrasSelector(userIdentity));
};

const convertToSimpleConversations = (
    conversationSids: ConversationSid[],
    conversations: ConversationsState['allConversations'],
    users: ConversationsUsersState,
): ISimpleConversation[] => {
    return conversationSids
        .map((conversationSid) => {
            return conversations[conversationSid];
        })
        .filter<Conversation>((conversation): conversation is Conversation => !!conversation)
        .map<ISimpleConversation>((conversation) => {
            let lastSystemEventText;
            if (conversation.lastSystemEvent && isLastMessageSystemEvent(conversation)) {
                lastSystemEventText =
                    conversation.lastSystemEvent?.type === FrontlineEventType.CallEnded
                        ? getUnreadCallMsgText(
                              conversation.lastSystemEvent.state,
                              conversation.lastSystemEvent.inbound,
                          )
                        : getSystemMessageText(conversation.lastSystemEvent, users);
            }

            return {
                sid: conversation.sid,
                friendlyName: conversation.friendlyName,
                avatar: conversation.avatar,
                lastMessage: conversation.lastMessage,
                createdAt: conversation.createdAt,
                lastMessageTime: conversation.lastMessageTime,
                lastSystemEvent: conversation.lastSystemEvent,
                unreadMessagesCount: conversation.unreadMessagesCount,
                unreadSystemMessagesCount: conversation.unreadSystemMessagesCount,
                markedUnreadAt: conversation.markedUnreadAt,
                lastSystemEventText,
            };
        });
};

const simpleConversationsSelector = createSelector(
    [
        (state: ExtendsChatState) => state.chat.channels.myConversations,
        (state: ExtendsChatState) => state.chat.channels.allConversations,
        (state: ExtendsChatState) => state.chat.users,
    ],
    convertToSimpleConversations,
);

export const useSimpleConversations = () => {
    return useSelector(simpleConversationsSelector);
};

const simpleUserConversationsSelector = createSelector(
    [
        (state: ExtendsChatState, userIdentity: UserIdentity) =>
            selectUserConversationsSids(state, userIdentity),
        (state: ExtendsChatState) => state.chat.channels.allConversations,
        (state: ExtendsChatState) => state.chat.users,
    ],
    convertToSimpleConversations,
);

export const useSimpleUserConversations = (userIdentity: UserIdentity) => {
    return useSelector((state: ExtendsChatState) =>
        simpleUserConversationsSelector(state, userIdentity),
    );
};

export const useUnreadMessagesCount = () =>
    useSelector((state: ExtendsChatState) => state.chat.channels.unreadMessagesCount);
