export const TWILIO_ENV = import.meta.env.VITE_TWILIO_ENV;
export const TWILIO_REGION = import.meta.env.VITE_TWILIO_REGION;
export const FRONTLINE_VERSION = import.meta.env.VITE_FRONTLINE_VERSION || 'local';

export const IS_DEV_BUILD = import.meta.env.DEV;
export const ENV_MODE = import.meta.env.MODE;
export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN as string;
export const HEAP_APP_ID = import.meta.env.VITE_HEAP_APP_ID as string;
export const SEGMENT_DEV_WRITE_KEY = import.meta.env.VITE_SEGMENT_DEV_WRITE_KEY as string;
export const SEGMENT_PROD_WRITE_KEY = import.meta.env.VITE_SEGMENT_PROD_WRITE_KEY as string;

export const VAPID_KEY = import.meta.env.VITE_VAPID_KEY as string;
export const FIREBASE_API_KEY = import.meta.env.VITE_FIREBASE_API_KEY as string;
export const FIREBASE_AUTH_DOMAIN = import.meta.env.VITE_FIREBASE_AUTH_DOMAIN as string;
export const FIREBASE_DATABASE_URL = import.meta.env.VITE_FIREBASE_DATABASE_URL as string;
export const FIREBASE_PROJECT_ID = import.meta.env.VITE_FIREBASE_PROJECT_ID as string;
export const FIREBASE_STORAGE_BUCKET = import.meta.env.VITE_FIREBASE_STORAGE_BUCKET as string;
export const FIREBASE_MESSAGING_SENDER_ID = import.meta.env
    .VITE_FIREBASE_MESSAGING_SENDER_ID as string;
export const FIREBASE_APP_ID = import.meta.env.VITE_FIREBASE_APP_ID as string;
export const FIREBASE_MEASUREMENT_ID = import.meta.env.VITE_FIREBASE_MEASUREMENT_ID as string;
