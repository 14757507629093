import { Attributes, MediaSid, TwilioMedia } from '../types';
import { isString } from '../utils/isString';
import { DistributiveOmit } from '../utils/typescript';

export enum MediaStatus {
    NotDownloaded,
    Downloading,
    Ready,
    Error,
}

export enum MediaType {
    Image,
    Voice,
    Document,
    Unsupported,
}

type MediaBase = {
    sid: MediaSid;
    filename?: string;
    contentType: string;
    size: number;
    state: MediaStatus;
    path?: string;
    getContentTemporaryUrl?: TwilioMedia['getContentTemporaryUrl'];
};

export type MediaImage = MediaBase & {
    type: MediaType.Image;
    width?: number;
    height?: number;
};

export type MediaVoice = MediaBase & {
    type: MediaType.Voice;
};

export type MediaDocument = MediaBase & {
    type: MediaType.Document;
};

export type MediaUnsupported = MediaBase & {
    type: MediaType.Unsupported;
};

export type IMedia = MediaImage | MediaVoice | MediaDocument | MediaUnsupported;

export type IDraftMedia = DistributiveOmit<IMedia, 'sid' | 'state'>;

export const fromTwilioMedia = (twilioMedia: TwilioMedia, attributes: Attributes): IMedia => {
    const attributesMediaId = isString(attributes.mediaId) ? attributes.mediaId : undefined;

    return {
        sid: attributesMediaId || twilioMedia.sid,
        filename: twilioMedia.filename || undefined,
        contentType: twilioMedia.contentType,
        size: twilioMedia.size,
        state: MediaStatus.NotDownloaded,
        type: getMediaType(twilioMedia),
        getContentTemporaryUrl: twilioMedia.getContentTemporaryUrl.bind(twilioMedia),
    };
};

export const isMediaLoading = (media: { state?: MediaStatus }): boolean => {
    return media.state === MediaStatus.Downloading;
};
export const isMediaReady = (media: { state?: MediaStatus }): boolean => {
    return media.state === MediaStatus.Ready;
};
export const isMediaNotDownloaded = (media: { state?: MediaStatus }): boolean => {
    return media.state === MediaStatus.NotDownloaded;
};

export const isImage = (media: { contentType: string }): boolean => {
    const match = media.contentType.match('png|jpg|jpeg|bmp|gif|webp');
    return !!match;
};

export const isVoice = (media: { contentType: string }): boolean => {
    // @todo: add content types
    const match = media.contentType.match('m4a|mp3|aac|ogg|amr|audio/mpeg');
    return !!match;
};

export const isDocument = (media: { contentType: string }): boolean => {
    const match = media.contentType.match('pdf');
    return !!match;
};

export const getMediaType = (media: { contentType: string }): MediaType => {
    if (isImage(media)) {
        return MediaType.Image;
    }
    if (isVoice(media)) {
        return MediaType.Voice;
    }
    if (isDocument(media)) {
        return MediaType.Document;
    }
    return MediaType.Unsupported;
};

export const isMediaDocument = (media: IDraftMedia): boolean => {
    return media.type === MediaType.Document;
};
export const isMediaImage = (media: IDraftMedia): boolean => {
    return media.type === MediaType.Image;
};

export const isMediaVoice = (media: IDraftMedia): boolean => {
    return media.type === MediaType.Voice;
};

export const createMediaFromPartial = (props: Partial<IMedia>): IDraftMedia => {
    const obj = {
        type: props.type,
        path: props.path || '',
        size: props.size || 0,
        contentType: props.contentType || '',
    } as Partial<IMedia>;
    if (obj.type === MediaType.Image && props.type === MediaType.Image) {
        obj.width = props.width || 0;
        obj.height = props.height || 0;
    }
    if (obj.type === MediaType.Document) {
        obj.filename = props.filename || '';
    }
    return obj as IDraftMedia;
};
