import { useHistory } from 'react-router-dom';

import { Flex } from '@twilio-paste/flex';
import { Box } from '@twilio-paste/box';
import { ArrowBackIcon } from '@twilio-paste/icons/cjs/ArrowBackIcon';
import { Button } from '@twilio-paste/button';

import { IUser } from '@twilio/frontline-shared/models/User';

import { PAGES } from '../../../paths';
import { UserInfo } from '../../../components/UserInfo';

type UserConversationsHeaderProps = {
    user: IUser;
};

export function UserConversationsHeader({ user }: UserConversationsHeaderProps) {
    const navHistory = useHistory();

    return (
        <Box
            width="100%"
            paddingX="space40"
            paddingY="space30"
            backgroundColor="colorBackground"
            borderBottomWidth="borderWidth10"
            borderBottomStyle="solid"
            borderBottomColor="colorBorderWeak">
            <Flex paddingY="space10" vAlignContent="center">
                <Button variant="link" onClick={() => navHistory.push(PAGES.DEFAULT)}>
                    <ArrowBackIcon size="sizeIcon70" decorative={false} title="back" />
                </Button>
                <UserInfo user={user} />
            </Flex>
        </Box>
    );
}
