import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useChatContext } from './ChatContext';

import { IChatMessage } from './types';
import { isSameDay, isSameAuthor } from './utils';

export const AVATAR_SIZE = 32;

export type ChatAvatarProps = {
    currentMessage?: IChatMessage;
    previousMessage?: IChatMessage;
};

export function ChatAvatar({ currentMessage, previousMessage }: ChatAvatarProps) {
    const { ParticipantAvatar } = useChatContext();

    const renderBlankSpace =
        isSameAuthor(currentMessage, previousMessage) && isSameDay(currentMessage, previousMessage);

    return !renderBlankSpace && ParticipantAvatar ? (
        <View style={styles.container}>
            <ParticipantAvatar
                avatar={currentMessage?.user?.avatar}
                name={
                    currentMessage?.user._id !== currentMessage?.user.name
                        ? currentMessage?.user.name
                        : undefined
                }
            />
        </View>
    ) : (
        <View style={[styles.container, styles.blank]} />
    );
}

const styles = StyleSheet.create({
    container: {
        alignSelf: 'flex-start',
        marginRight: 8,
    },
    avatar: {
        marginTop: 24,
    },
    blank: {
        width: AVATAR_SIZE,
    },
});
