import { useEffect, useState, useRef } from 'react';
import { Customer, CustomersList } from '@twilio/frontline-shared/types/customer';
import { getCustomersList } from '@twilio/frontline-shared/core/Callbacks/CRMCallbacks';
import { GetCustomersListPayload } from '@twilio/frontline-shared/core/Callbacks/types';
import { EmptyCallbackURLError } from '@twilio/frontline-shared/models/FrontlineError';
import { TwilsockError } from '@twilio/frontline-shared/types';

const CUSTOMER_PAGE_SIZE = 30;

export enum CustomersState {
    Pending = 'pending',
    FetchingMore = 'fetchingMore',
    Resolved = 'resolved',
    Searching = 'searching',
    NoCustomers = 'noCustomers',
    NoSearchResults = 'noSearchResults',
    Rejected = 'rejected',
}

export type ErrorType = 'emptyCallbackURLError' | 'twilsockError' | 'general';

export function useCustomers(query: string) {
    const [state, setState] = useState<CustomersState>(CustomersState.Pending);
    const [errorType, setErrorType] = useState<ErrorType | null>(null);
    const [nextPageToken, setNextPageToken] = useState<CustomersList['nextPageToken']>(undefined);
    const [customers, setCustomers] = useState<Customer[]>([]);
    const [hasNext, setHasNext] = useState(true);
    const [searchable, setSearchable] = useState<boolean>(false);
    const [filteredCustomers, setFilteredCustomers] = useState<Customer[]>([]);
    const [isMounted, setIsMounted] = useState<boolean>(false);
    const loaderRef = useRef(null);

    const filterCustomers = (_customers: Customer[], search: string, _searchable: boolean) => {
        if (search.length && !_searchable) {
            return _customers.filter(
                (el) => (el.display_name || '').toLowerCase().indexOf(search.toLowerCase()) > -1,
            );
        }
        return _customers;
    };

    const updateFilteredCustomers = (
        _customers: Customer[],
        _query: string,
        _searchable: boolean,
    ) => {
        const filtered = filterCustomers(_customers, _query || '', _searchable);
        if (_query && filtered.length === 0) {
            setState(CustomersState.NoSearchResults);
        } else {
            setState(CustomersState.Resolved);
        }
        return setFilteredCustomers(filtered);
    };

    const fetchCustomers = ({
        customers: _customers,
        query: _query,
        anchor,
        nextPageToken: _nextPageToken,
    }: {
        customers: Customer[];
        query?: string;
        anchor?: string;
        nextPageToken?: CustomersList['nextPageToken'];
    }) => {
        setErrorType(null);

        const payload: Omit<GetCustomersListPayload, 'Location'> = {
            PageSize: CUSTOMER_PAGE_SIZE,
        };
        if (searchable && _query) {
            payload.Query = _query;
        }
        if (_nextPageToken) {
            payload.NextPageToken = _nextPageToken;
        } else {
            payload.Anchor = anchor;
        }
        return getCustomersList({ payload })
            .then(
                async ({
                    customers: newCustomers,
                    searchable: _searchable,
                    nextPageToken: newNextPageToken,
                }) => {
                    setSearchable(_searchable);
                    setNextPageToken(newNextPageToken);

                    const isSearchEnabled = !!_searchable;

                    let isAllCustomersFetched = false;
                    const isTokenBasedPaginationEnabled =
                        !!newNextPageToken || newNextPageToken === null;

                    if (isTokenBasedPaginationEnabled) {
                        // Token Based pagination enabled

                        if (newNextPageToken === null) {
                            isAllCustomersFetched = true;
                            setHasNext(!isAllCustomersFetched);
                        }
                    } else if (
                        // Anchor Based pagination
                        !newCustomers ||
                        newCustomers.length === 0 ||
                        newCustomers.length < CUSTOMER_PAGE_SIZE
                    ) {
                        isAllCustomersFetched = true;
                        setHasNext(!isAllCustomersFetched);
                    }

                    const updatedCustomers: Customer[] = _customers.concat(newCustomers || []);

                    if (isSearchEnabled) {
                        // Search feature enabled

                        setCustomers(updatedCustomers);
                        updateFilteredCustomers(updatedCustomers, _query || '', isSearchEnabled);
                    } else {
                        // Search feature disabled. Manual search

                        const newFilteredCustomers = filterCustomers(
                            newCustomers || [],
                            _query || '',
                            isSearchEnabled,
                        );

                        if (!isAllCustomersFetched && newFilteredCustomers.length === 0) {
                            // Fetching more customers for Manual Search

                            await fetchCustomers({
                                customers: updatedCustomers,
                                query: _query,
                                anchor: String(
                                    updatedCustomers[updatedCustomers.length - 1].customer_id,
                                ),
                                nextPageToken: newNextPageToken,
                            });
                        } else {
                            // Manual Search is done

                            setCustomers(updatedCustomers);
                            updateFilteredCustomers(
                                updatedCustomers,
                                _query || '',
                                isSearchEnabled,
                            );
                        }
                    }

                    if (!updatedCustomers.length && !_query) {
                        setState(CustomersState.NoCustomers);
                    }
                },
            )
            .catch((e) => {
                console.log(`Fetch customers list failed: ${e}`);
                setState(CustomersState.Rejected);
                if (e instanceof EmptyCallbackURLError) {
                    setErrorType('emptyCallbackURLError');
                } else if (e instanceof TwilsockError) {
                    setErrorType('twilsockError');
                } else {
                    setErrorType('general');
                }
            });
    };

    const onLoadData = () => {
        setState(CustomersState.Pending);
        if (!searchable && customers.length) {
            return updateFilteredCustomers(customers, query || '', searchable);
        }
        setHasNext(true);
        return fetchCustomers({ customers: [], query });
    };

    const onLoadMore = (entries: any) => {
        const target = entries[0];
        if (!target.isIntersecting || !hasNext || state === CustomersState.FetchingMore) {
            return;
        }
        setState(CustomersState.FetchingMore);
        fetchCustomers({
            customers,
            query,
            anchor:
                customers.length > 0
                    ? String(customers[customers.length - 1].customer_id)
                    : undefined,
            nextPageToken,
        });
    };

    const onSearchCustomers = (_query: string) => {
        if (searchable) {
            setState(CustomersState.Searching);
            setHasNext(true);
            fetchCustomers({ customers: [], query: _query });
        } else {
            const filtered = filterCustomers(customers, _query, searchable);
            if (filtered.length > 0 || !hasNext) {
                setFilteredCustomers(filtered);
                setState(
                    query && filtered.length === 0
                        ? CustomersState.NoSearchResults
                        : CustomersState.Resolved,
                );
                return;
            }
            setState(CustomersState.Searching);
            fetchCustomers({
                customers,
                query,
                anchor: customers.length
                    ? String(customers[customers.length - 1].customer_id)
                    : undefined,
                nextPageToken,
            });
        }
    };

    useEffect(() => {
        fetchCustomers({ customers: [] });
    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver(onLoadMore);
        if (loaderRef.current) {
            observer.observe(loaderRef.current);
        }

        return () => {
            if (loaderRef.current) {
                observer.unobserve(loaderRef.current);
            }
        };
    }, [onLoadMore]);

    useEffect(() => {
        if (isMounted) {
            onSearchCustomers(query);
        } else {
            setIsMounted(true);
        }
    }, [query]);

    return {
        customers: filteredCustomers,
        state,
        nextPageToken,
        errorType,
        loaderRef,
        hasNext,
        onLoadData,
        onSearchCustomers,
    };
}
