import React from 'react';
import {
    Platform,
    ActivityIndicator as RNActivityIndicator,
    ActivityIndicatorProps,
} from 'react-native';

const SIZE_SMALL = 20;
const SIZE_LARGE = 36;

export function ScalingActivityIndicator(
    props: Omit<ActivityIndicatorProps, 'size'> & { size?: number },
) {
    const { size, style } = props;
    return Platform.OS === 'ios' && size ? (
        /* iOS's ActivityIndicator size is fixed 20 (small) or 36 (large) */
        <RNActivityIndicator
            {...props}
            style={[
                style,
                {
                    transform: [
                        {
                            scale: size / (size > SIZE_LARGE ? SIZE_LARGE : SIZE_SMALL),
                        },
                    ],
                },
            ]}
        />
    ) : (
        <RNActivityIndicator {...props} />
    );
}
