import { Button } from '@twilio-paste/button';

import { SendButtonProps } from '@twilio/frontline-shared/components/Chat/types';

export function SendButton({ disabled, onPress }: SendButtonProps) {
    return (
        <Button variant="primary" type="button" disabled={disabled} onClick={onPress}>
            Send
        </Button>
    );
}
