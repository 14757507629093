export type Action<TType = string> = {
    type: TType;
};

export type PayloadAction<TType, TPayload extends unknown> = {
    payload: TPayload;
} & Action<TType>;

type ActionCreator<TType, TPayload extends any> = TPayload extends undefined
    ? () => Action<TType>
    : (payload: TPayload) => PayloadAction<TType, TPayload>;

type ActionObj<TType, TPayload extends unknown> = ActionCreator<TType, TPayload> & {
    getType: () => TType;
};

export const createAction = <TType extends string>(type: TType) => {
    return <TPayload extends unknown = undefined>(): ActionObj<typeof type, TPayload> => {
        const actionCreator = (payload: TPayload | undefined = undefined) => {
            return {
                type,
                ...(payload && { payload }),
            };
        };

        const action = Object.assign(actionCreator, {
            getType: () => type,
        });

        return action as ActionObj<typeof type, TPayload>;
    };
};

export const isActionOf = <TType extends string, TPayload>(
    actionCreator: ActionObj<TType, TPayload>,
    action: Action,
): action is ReturnType<ActionObj<TType, TPayload>> => {
    return actionCreator.getType() === action.type;
};
