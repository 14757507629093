import { Button } from '@twilio-paste/button';
import { Card } from '@twilio-paste/card';
import { Flex } from '@twilio-paste/flex';
import { Heading } from '@twilio-paste/heading';
import { ModalBody, ModalFooter, ModalFooterActions } from '@twilio-paste/modal';
import { Paragraph } from '@twilio-paste/paragraph';
import { Radio, RadioGroup } from '@twilio-paste/radio-group';
import { Box } from '@twilio-paste/box';
import { ArrowBackIcon } from '@twilio-paste/icons/esm/ArrowBackIcon';

import { TemplatesByCategory } from '@twilio/frontline-shared/models/Templates';

type TemplatesModalReadyProps = {
    template?: string;
    templates: TemplatesByCategory;
    preview: boolean;
    onBack: () => void;
    onSend: () => void;
    onSetTemplate: (s: string) => void;
    onSetPreview: (isPreview: boolean) => void;
};

export function TemplatesModalReady({
    template,
    templates,
    preview,
    onBack,
    onSend,
    onSetTemplate,
    onSetPreview,
}: TemplatesModalReadyProps) {
    if (preview) {
        return (
            <>
                <ModalBody>
                    <Flex vertical>
                        <Heading as="h5" variant="heading50" data-testid="message-preview-heading">
                            Message preview
                        </Heading>
                        <Box width="100%">
                            <Card>
                                <Paragraph
                                    data-testid="template-message-preview-text"
                                    marginBottom="space0">
                                    {template}
                                </Paragraph>
                            </Card>
                        </Box>
                    </Flex>
                </ModalBody>
                <ModalFooter>
                    <ModalFooterActions justify="start">
                        <Button variant="secondary" onClick={onBack}>
                            <ArrowBackIcon decorative={false} title="Back icon" />
                            Back
                        </Button>
                    </ModalFooterActions>
                    <ModalFooterActions>
                        <Button
                            data-testid="template-preview-send-button"
                            variant="primary"
                            onClick={onSend}>
                            Send
                        </Button>
                    </ModalFooterActions>
                </ModalFooter>
            </>
        );
    }

    return (
        <>
            <ModalBody>
                {templates.map((group, groupIndex) => (
                    <Box paddingBottom="space60" key={`${group.display_name}_${groupIndex}`}>
                        <RadioGroup
                            name="template"
                            value={template}
                            legend={`${group.display_name} (${group.templates.length})`}
                            onChange={onSetTemplate}>
                            {group.templates.map((groupTemplate, index) => (
                                <Radio
                                    key={`template_${groupIndex}_${index}`}
                                    id={`template_${groupIndex}_${index}`}
                                    value={groupTemplate.content}
                                    name="campaign">
                                    {groupTemplate.content}
                                </Radio>
                            ))}
                        </RadioGroup>
                    </Box>
                ))}
            </ModalBody>
            <ModalFooter>
                <ModalFooterActions>
                    <Button
                        variant="primary"
                        data-testid="templates-modal-next-button"
                        disabled={!template}
                        onClick={() => template && onSetPreview(true)}>
                        Next
                    </Button>
                </ModalFooterActions>
            </ModalFooter>
        </>
    );
}
