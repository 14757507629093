export function FrontlineLogo({ color, size }: { color?: string; size?: number }) {
    return (
        <svg
            id="f5e7710c-6de2-4fbd-b22e-b159da615594"
            data-name="Red"
            xmlns="http://www.w3.org/2000/svg"
            width={size || '32'}
            height={size || '32'}
            viewBox="0 0 32 32">
            <path
                d="M13.80048.13546.62494,7.74231a1,1,0,0,0-.5.866L.125,27.86558.12477,30.9985a1,1,0,0,0,1.90314.42942l2.9205-6.14321a1,1,0,0,1,.40314-.43667l9.44892-5.45532a1,1,0,0,0,.5-.866V1.00149A1,1,0,0,0,13.80048.13546Z"
                fill={color || '#ee3349'}
            />
            <path
                d="M28.278,11.12917l3.109-9.36343a1,1,0,0,0-1.449-1.18115L17.8005,7.592a1,1,0,0,0-.5.866V25.98327a1,1,0,0,0,1.5.866l12.57456-7.25985a1,1,0,0,0,.38048-1.34013l-3.409-6.33093A1,1,0,0,1,28.278,11.12917Z"
                fill={color || '#ee3349'}
            />
        </svg>
    );
}
