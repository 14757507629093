import React, { useEffect, useState } from 'react';
import { Box } from '@twilio-paste/box';
import { Spinner } from '@twilio-paste/spinner';
import { Text } from '@twilio-paste/text';
import { useSession } from '../../../context/SessionContext';

export function LoadingProgress() {
    const [text, setText] = useState<string>();
    const { session } = useSession();

    useEffect(() => {
        const interval = setInterval(() => {
            if (!session?.isConversationsFetched) {
                if (!session?.conversationsCount) {
                    setText('');
                } else {
                    setText(`Fetching (${session?.conversationsCount}) ...`);
                }
            } else {
                setText(
                    `Preparing (${session?.initializedConversationsCount}/${session?.conversationsCount}) ...`,
                );
            }
        }, 500);

        return () => clearInterval(interval);
    }, [session?.isConversationsFetched]);
    return (
        <Box display="flex" flexDirection="row" alignItems="center">
            {text && <Spinner decorative size="sizeIcon10" />}
            <Text
                as="p"
                paddingLeft="space20"
                color="colorTextDecorative10"
                fontWeight="fontWeightSemibold"
                fontSize="fontSize20">
                {text}
            </Text>
        </Box>
    );
}
