import { createAction } from '@reduxjs/toolkit';

export type DraftMap = { [key: string]: string | null };

export const loadDrafts = createAction('LOAD_DRAFTS');

export type LoadDrafts = ReturnType<typeof loadDrafts>;

export const initDrafts =
    createAction<{
        drafts: DraftMap;
    }>('INIT_DRAFTS');

export type InitDrafts = ReturnType<typeof initDrafts>;

export const setDraft =
    createAction<{
        channelSid: string;
        message: string;
    }>('SET_DRAFT');
export type SetDraft = ReturnType<typeof setDraft>;

export const saveDraft =
    createAction<{
        channelSid: string;
        message: string;
    }>('SAVE_DRAFT');
export type SaveDraft = ReturnType<typeof saveDraft>;

export type DraftActions = LoadDrafts | InitDrafts | SetDraft;
