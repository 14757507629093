import { Box } from '@twilio-paste/box';
import { Heading } from '@twilio-paste/heading';
import { Text } from '@twilio-paste/text';
import { Card } from '@twilio-paste/card';
import { Stack } from '@twilio-paste/stack';
import { Button } from '@twilio-paste/button';
import { Anchor } from '@twilio-paste/anchor';
import { LinkExternalIcon } from '@twilio-paste/icons/cjs/LinkExternalIcon';

import { CustomerLink, CustomerDetails } from '@twilio/frontline-shared/types/customer';
import { CustomerChannel, ChannelType } from '@twilio/frontline-shared/types/channel';
import { getChannelName, isChannelType } from '@twilio/frontline-shared/models/Participant';
import { getChannelAddress } from '@twilio/frontline-shared/utils/stringUtils';
import { useSession } from '../../../context/SessionContext';

export type CustomerDetailsContentProps = {
    smsChannels?: Partial<CustomerChannel>[];
    whatsAppChannels?: Partial<CustomerChannel>[];
    chatChannels?: Partial<CustomerChannel>[];
    links?: CustomerLink[];
    details?: CustomerDetails;
    onCreateConversation: (type: ChannelType, value: string) => void;
};

export function CustomerDetailsContent({
    smsChannels,
    whatsAppChannels,
    chatChannels,
    links,
    details,
    onCreateConversation,
}: CustomerDetailsContentProps) {
    const { session } = useSession();

    const isChannelAddressMaskingEnabled = !!session?.isChannelAddressMaskingEnabled;

    const onCreate = (type: ChannelType | string, value?: string) => {
        if (
            !Object.values(ChannelType).includes(type as ChannelType) ||
            !value ||
            (type !== ChannelType.Sms && type !== ChannelType.Whatsapp && type !== ChannelType.Chat)
        ) {
            return;
        }
        onCreateConversation(type, value);
    };

    const renderCardRow = (type: ChannelType | string, value?: string) => {
        const channelDisplayName = isChannelType(type) ? getChannelName(type as ChannelType) : type;
        const channelAddressSplit = getChannelAddress(value, isChannelAddressMaskingEnabled).split(
            ':',
        );

        return (
            <Box
                key={String(type) + String(value)}
                display="flex"
                justifyContent="space-between"
                paddingX="space50"
                _hover={{ backgroundColor: 'colorBackground' }}
                width="100%"
                paddingY="space40">
                <Button fullWidth variant="link" onClick={() => onCreate(type, value)}>
                    <Box
                        display="flex"
                        padding="space10"
                        justifyContent="space-between"
                        width="100%">
                        <Text
                            wordBreak="break-word"
                            color="colorTextLink"
                            as="div"
                            data-testid="customer-contact-item"
                            fontSize="fontSize30"
                            fontWeight="fontWeightMedium">
                            {channelAddressSplit[0]}
                            {channelAddressSplit.length > 1 ? ':' : null}
                            &#8203;
                            {channelAddressSplit.length > 1 ? channelAddressSplit[1] : null}
                        </Text>

                        <Text
                            as="div"
                            color="colorTextWeak"
                            fontSize="fontSize30"
                            fontWeight="fontWeightMedium">
                            {channelDisplayName}
                        </Text>
                    </Box>
                </Button>
            </Box>
        );
    };

    const renderCardLink = (link: CustomerLink) => {
        return (
            <Box
                key={String(link.type) + String(link.value) + String(link.display_name)}
                width="100%">
                <Anchor
                    href={link.value}
                    width="100%"
                    variant="inverse"
                    data-testid="customer-additional-details-item">
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        paddingY="space40"
                        paddingX="space50"
                        _hover={{
                            backgroundColor: 'colorBackground',
                            color: 'colorTextLink',
                            cursor: 'pointer',
                            textDecoration: 'underline',
                        }}>
                        <Text as="div" fontWeight="fontWeightMedium" color="colorTextLink">
                            {link.display_name}
                            <Box
                                as="span"
                                display="inline-block"
                                flexShrink={0}
                                paddingLeft="space10"
                                verticalAlign="middle">
                                <LinkExternalIcon decorative={false} title={link.value} />
                            </Box>
                        </Text>
                        <Text
                            as="div"
                            fontWeight="fontWeightMedium"
                            color="colorTextWeak"
                            fontSize="fontSize30">
                            {link.type}
                        </Text>
                    </Box>
                </Anchor>
            </Box>
        );
    };

    const hasContactDetails = smsChannels?.length || whatsAppChannels?.length;

    return (
        <Box maxWidth={400} width="100%">
            <Stack orientation="vertical" spacing="space60">
                {hasContactDetails && (
                    <Card paddingLeft="space0" paddingRight="space0" paddingY="space40">
                        <Box paddingX="space50">
                            <Heading
                                as="h5"
                                variant="heading50"
                                data-testid="contact-details-title">
                                Contact details
                            </Heading>
                        </Box>
                        {smsChannels?.map((channel) =>
                            renderCardRow(ChannelType.Sms, channel.value),
                        )}
                        {whatsAppChannels?.map((channel) =>
                            renderCardRow(ChannelType.Whatsapp, channel.value),
                        )}
                        {chatChannels?.map((channel) =>
                            renderCardRow(ChannelType.Chat, channel.value),
                        )}
                    </Card>
                )}

                {links?.length && (
                    <Card paddingLeft="space0" paddingRight="space0" paddingTop="space40">
                        <Box paddingX="space50">
                            <Heading
                                as="h5"
                                variant="heading50"
                                data-testid="additional-details-title">
                                Additional details
                            </Heading>
                        </Box>
                        {links.map(renderCardLink)}
                    </Card>
                )}

                {details && (
                    <Card paddingX="space40" paddingY="space40">
                        <Heading as="h5" variant="heading50" data-testid="details-title">
                            {details.title}
                        </Heading>
                        <Box display="flex" flexDirection="column" paddingTop="space40">
                            <Text as="span" whiteSpace="pre-wrap" data-testid="details-text">
                                {details.content}
                            </Text>
                        </Box>
                    </Card>
                )}
            </Stack>
        </Box>
    );
}
