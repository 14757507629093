import { Box } from '@twilio-paste/box';
import { Flex } from '@twilio-paste/flex';
import { Participant } from '@twilio/frontline-shared/models/Participant';
import { ChannelType } from '@twilio/frontline-shared/types/channel';
import { Conversation } from '@twilio/frontline-shared/models/Conversation';
import { WorkerUser } from '@twilio/frontline-shared/models/WorkerUser';
import { ConversationState } from '../Conversation/Conversation';
import { ConversationInfoBox } from '../Conversation/ConversationInfoBox';
import { ConversationViewVariant } from '../Conversation/types';
import { ConversationMenu } from '../Conversation/ConversationMenu';

type ConversationHeaderProps = {
    channelSid?: Conversation['sid'];
    channelName?: Conversation['friendlyName'];
    channelAvatar?: Conversation['avatar'];
    channelType?: ChannelType;
    state: ConversationState;
    currentUserIdentity: WorkerUser['identity'];
    currentUserFriendlyName?: WorkerUser['friendly_name'];
    customerParticipantDisplayName: Participant['displayName'];
    variant: ConversationViewVariant;
    onCaptionClick?: () => void;
};

export function ConversationHeader({
    channelSid,
    channelName,
    channelAvatar,
    channelType,
    state,
    currentUserIdentity,
    currentUserFriendlyName,
    customerParticipantDisplayName,
    variant,
    onCaptionClick,
}: ConversationHeaderProps) {
    return (
        <Box
            width="100%"
            paddingX="space40"
            paddingY="space30"
            backgroundColor="colorBackground"
            borderBottomWidth="borderWidth10"
            borderBottomStyle="solid"
            borderBottomColor="colorBorderWeak">
            <Flex vAlignContent="center">
                <Flex paddingY="space10" vAlignContent="center">
                    <ConversationInfoBox
                        friendlyName={channelName}
                        avatar={channelAvatar}
                        channelType={channelType || ChannelType.Chat}
                        state={state}
                        onCaptionClick={onCaptionClick}
                    />
                </Flex>
                {state === 'ready' && (
                    <ConversationMenu
                        conversationSid={channelSid as Conversation['sid']}
                        channelName={channelName as Conversation['friendlyName']}
                        channelAvatar={channelAvatar}
                        channelType={channelType}
                        currentUserIdentity={currentUserIdentity}
                        currentUserFriendlyName={currentUserFriendlyName}
                        customerParticipantDisplayName={customerParticipantDisplayName}
                        variant={variant}
                    />
                )}
            </Flex>
        </Box>
    );
}
