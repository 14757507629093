import { ReactNode } from 'react';
import { Box } from '@twilio-paste/box';

import { LeftNavbar } from '../LeftNavbar';
import { Header } from '../Header';
import { NotificationBanner } from '../NotificationBanner';

import css from './layout-grid.module.css';

export function Layout({ children }: { children: ReactNode }) {
    return (
        <div className={css['grid-container']}>
            <NotificationBanner className={css['grid-banner-item']} />
            <Header className={css['grid-header-item']} />
            <LeftNavbar className={css['grid-sidebar-item']} />
            <Box width="100%" overflowY="auto" className={css['grid-main-item']}>
                {children}
            </Box>
        </div>
    );
}
