import React, { useState } from 'react';
import { Box } from '@twilio-paste/box';
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalFooterActions,
    ModalHeader,
    ModalHeading,
} from '@twilio-paste/modal';
import { Truncate } from '@twilio-paste/truncate';
import { Alert } from '@twilio-paste/alert';
import { useUID } from '@twilio-paste/uid-library';
import { TwilIcon } from '@twilio/frontline-shared/components/TwilIcon';
import { colors } from '@twilio-paste/design-tokens';
import { Text } from '@twilio-paste/text';
import { Flex } from '@twilio-paste/flex';
import { bytesToMb } from '@twilio/frontline-shared/utils/filesize';
import { Button } from '@twilio-paste/button';
import { ArrowBackIcon } from '@twilio-paste/icons/cjs/ArrowBackIcon';
import { Spinner } from '@twilio-paste/spinner';
import {
    createMediaFromPartial,
    getMediaType,
    IDraftMedia,
} from '@twilio/frontline-shared/models/Media';

import { ChannelType } from '@twilio/frontline-shared/types/channel';
import { useChannelTypeSizeLimits } from '@twilio/frontline-shared/hooks/useChannelTypeSizeLimits';
import {
    channelResolutions,
    getCompressionSizeLimit,
    getImageExtension,
    isCompressibleExtension,
    shouldCompress,
} from '@twilio/frontline-shared/utils/compression';
import { useSession } from '../../context/SessionContext';
import {
    compressImageInTwoStages,
    getImageDimensionsFromDataURL,
    hasImageExtension,
} from '../../utils/compression';
import { createObjectURL } from '../../utils/file';
import { ellipsisMiddleText } from '../../utils/text';

type MediaConfirmationModalProps = {
    fileRef: React.MutableRefObject<HTMLInputElement | null>;
    channelType: ChannelType;
    onSendMedia: (media: IDraftMedia) => void;
};

export function MediaConfirmationModal({
    fileRef,
    onSendMedia,
    channelType,
}: MediaConfirmationModalProps) {
    const { session } = useSession();

    const channelTypeSizeLimits = useChannelTypeSizeLimits(session?.configuration);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const modalHeadingID = useUID();
    const [fileName, setFileName] = useState('');
    const [fileSize, setFileSize] = useState(0);
    const [loading, setIsLoading] = useState(false);
    const [imageObjectURL, setImageObjectURL] = useState<string | undefined>();
    const sizeLimit = channelTypeSizeLimits[channelType];

    const exceedsLimit = fileSize > sizeLimit;

    const processImage = async () => {
        if (!fileRef.current || !fileRef.current.files?.length) {
            return;
        }
        const ref = fileRef.current.files[0];
        const refName = ref.name;

        const imageExtension = getImageExtension(refName);
        const extension = imageExtension === 'png' ? 'png' : 'jpg';

        const originalSizeInMb = bytesToMb(ref.size);

        const channelSizeLimit = getCompressionSizeLimit(channelTypeSizeLimits, channelType);

        if (
            shouldCompress(channelTypeSizeLimits, channelType, channelSizeLimit, originalSizeInMb)
        ) {
            const { width, height } = await getImageDimensionsFromDataURL(createObjectURL(ref));

            const largerDimension = Math.max(width, height);
            const maxChannelTargetDimension =
                channelResolutions[channelType as ChannelType.Sms | ChannelType.Whatsapp][
                    extension
                ];
            const scaledDimension = Math.min(maxChannelTargetDimension, largerDimension);

            try {
                const { result, size } = await compressImageInTwoStages(
                    ref,
                    scaledDimension,
                    extension,
                    channelSizeLimit,
                );
                setImageObjectURL(result);
                setFileSize(bytesToMb(size));
            } catch (e) {
                setImageObjectURL(createObjectURL(ref));
                setFileSize(bytesToMb(ref.size));
            }
        } else {
            setImageObjectURL(createObjectURL(ref));
            setFileSize(bytesToMb(ref.size));
        }
    };

    const handleFileChange = () => {
        if (!fileRef.current || !fileRef.current.files?.length) {
            return;
        }
        const refName = fileRef.current.files[0].name;
        const extension = getImageExtension(refName);
        if (isCompressibleExtension(extension)) {
            setIsLoading(true);
            processImage().finally(() => setIsLoading(false));
        } else if (hasImageExtension(refName)) {
            setImageObjectURL(createObjectURL(fileRef.current.files[0]));
        }

        setIsModalOpen(true);
        setFileName(ellipsisMiddleText(refName));
        setFileSize(bytesToMb(fileRef.current.files[0].size));
    };

    const handleClose = () => {
        setIsModalOpen(false);
        setImageObjectURL(undefined);
        if (fileRef.current?.value) {
            fileRef.current.value = '';
        }
    };

    const handleChooseFile = () => {
        handleClose();
        fileRef.current?.click();
    };

    const handleSend = () => {
        if (fileRef.current) {
            const fileBlob = fileRef.current.files![0];
            const blobUrl = imageObjectURL ?? createObjectURL(fileBlob);

            onSendMedia(
                createMediaFromPartial({
                    filename: fileBlob.name,
                    type: getMediaType({ contentType: fileBlob.type }),
                    path: blobUrl,
                    contentType: fileBlob.type,
                    size: fileBlob.size,
                }),
            );
            handleClose();
        }
    };

    return (
        <>
            <input
                type="file"
                onChange={handleFileChange}
                ref={fileRef}
                style={{ display: 'none' }}
            />
            <Modal
                size="default"
                ariaLabelledby={modalHeadingID}
                isOpen={isModalOpen}
                onDismiss={handleClose}>
                <ModalHeader>
                    <ModalHeading as="h3" id={modalHeadingID}>
                        Send File
                    </ModalHeading>
                </ModalHeader>
                <ModalBody>
                    <Flex vertical hAlignContent="center">
                        {hasImageExtension(fileName) ? (
                            <Box>
                                {loading && (
                                    <Box
                                        display="flex"
                                        width="100%"
                                        height="100px"
                                        alignItems="center"
                                        justifyContent="center">
                                        <Spinner size="sizeIcon20" decorative title="Loading" />
                                        <Text
                                            as="p"
                                            paddingLeft="space30"
                                            lineHeight="lineHeight30"
                                            color="colorTextWeak"
                                            fontSize="fontSize30">
                                            Loading
                                        </Text>
                                    </Box>
                                )}
                                {imageObjectURL && !loading && (
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        alignItems="center"
                                        justifyContent="center">
                                        <img
                                            style={{ maxWidth: '100%', maxHeight: '50vh' }}
                                            src={imageObjectURL}
                                            alt="Upload preview"
                                        />
                                        <Box paddingTop="space40">
                                            <Text as="span" color="colorTextWeak">
                                                {fileSize} MB,{' '}
                                                {getImageExtension(fileName).toUpperCase()} image
                                            </Text>
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                        ) : (
                            <Box
                                minWidth={300}
                                maxWidth={500}
                                padding="space30"
                                borderWidth="borderWidth10"
                                borderStyle="solid"
                                borderColor="colorBorderWeak"
                                borderRadius="borderRadius20">
                                <Flex vAlignContent="center">
                                    <Box padding="space40">
                                        <TwilIcon name="document" color={colors.colorGray60} />
                                    </Box>
                                    <Box marginLeft="space10" overflow="hidden">
                                        <Text fontWeight="fontWeightMedium" as="p">
                                            <Truncate title={fileName}>{fileName}</Truncate>
                                        </Text>
                                        <Text
                                            color="colorTextWeak"
                                            fontWeight="fontWeightMedium"
                                            as="p">
                                            {fileSize} MB
                                        </Text>
                                    </Box>
                                </Flex>
                            </Box>
                        )}
                        {!loading && exceedsLimit && (
                            <Box paddingTop="space40">
                                <Alert variant="error">
                                    <Text as="span">
                                        <strong>File size too large.</strong> Choose a file of{' '}
                                        {sizeLimit}MB or less.
                                    </Text>
                                </Alert>
                            </Box>
                        )}
                    </Flex>
                </ModalBody>
                <ModalFooter>
                    <ModalFooterActions justify="start">
                        <Button variant="secondary" onClick={handleChooseFile}>
                            <ArrowBackIcon decorative={false} title="Choose File" />
                            <Text as="span">Choose file</Text>
                        </Button>
                    </ModalFooterActions>
                    <ModalFooterActions>
                        <Button
                            data-testid="send-file-button"
                            disabled={exceedsLimit}
                            onClick={handleSend}
                            variant="primary">
                            Send
                        </Button>
                    </ModalFooterActions>
                </ModalFooter>
            </Modal>
        </>
    );
}
