import { Flex } from '@twilio-paste/flex';
import { Text } from '@twilio-paste/text';
import { Conversation } from '@twilio/frontline-shared/models/Conversation';
import { getChannelName } from '@twilio/frontline-shared/models/Participant';
import { ChannelType } from '@twilio/frontline-shared/types/channel';
import { Avatar } from '../../Avatar';

type ConversationItemProps = {
    friendlyName?: Conversation['friendlyName'];
    avatar?: Conversation['avatar'];
    channelType?: ChannelType;
};

export function ConversationListItem({ friendlyName, avatar, channelType }: ConversationItemProps) {
    return (
        <Flex grow={1} vAlignContent="center">
            <Avatar size="sizeIcon70" friendlyName={friendlyName} avatar={avatar} />
            <Flex marginLeft="space30" grow>
                <Flex grow>
                    <Text
                        as="span"
                        color="colorText"
                        fontSize="fontSize30"
                        fontWeight="fontWeightMedium"
                        lineHeight="lineHeight20">
                        {friendlyName || ''}
                    </Text>
                </Flex>
                <Flex>
                    <Text as="label" marginLeft="auto" textAlign="right" color="colorTextWeak">
                        {getChannelName(channelType || ChannelType.Chat)}
                    </Text>
                </Flex>
            </Flex>
        </Flex>
    );
}
