const PREFIX = 'draftText-';

export type DraftMap = { [key: string]: string | null };
class DraftManagerClass {
    static shared = new DraftManagerClass();

    keyFor(sid: string) {
        return `${PREFIX}${sid}`;
    }

    saveDraft(conversationSid: string, text: string) {
        const key = DraftStorageManager.keyFor(conversationSid);
        if (!text || text === '') {
            localStorage.removeItem(key);
        } else {
            localStorage.setItem(key, text);
        }
    }

    getDraft(conversationSid: string) {
        return localStorage.getItem(DraftStorageManager.keyFor(conversationSid));
    }

    private async getDraftKeys(): Promise<string[]> {
        const keys = Object.keys(localStorage);
        const draftKeys = keys.filter((k) => k.startsWith(PREFIX));
        return draftKeys;
    }

    async clearAllDrafts() {
        const draftKeys = await DraftStorageManager.getDraftKeys();
        draftKeys.forEach((k) => {
            localStorage.removeItem(k);
        });
    }

    async getAll(): Promise<DraftMap> {
        const draftKeys = await DraftStorageManager.getDraftKeys();
        const map: DraftMap = {};
        draftKeys.forEach((k) => {
            map[k.substring(PREFIX.length)] = localStorage.getItem(k);
        });
        return map;
    }
}
export const DraftStorageManager = DraftManagerClass.shared;
