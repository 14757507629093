import { Button } from '@twilio-paste/button';
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalFooterActions,
    ModalHeader,
    ModalHeading,
} from '@twilio-paste/modal';
import { Paragraph } from '@twilio-paste/paragraph';
import { useUID } from '@twilio-paste/uid-library';

type BetaInfoModalProps = {
    isOpen: boolean;
    onClose: () => void;
};

export function BetaInfoModal({ isOpen, onClose }: BetaInfoModalProps) {
    const modalHeadingID = useUID();

    return (
        <Modal ariaLabelledby={modalHeadingID} isOpen={isOpen} onDismiss={onClose} size="default">
            <ModalHeader>
                <ModalHeading as="h3" id={modalHeadingID}>
                    Beta
                </ModalHeading>
            </ModalHeader>
            <ModalBody>
                <Paragraph>
                    Twilio Frontline’s web interface is in Beta and may occasionally fall short of
                    speed or performance benchmarks, have gaps in functionality, and may contain
                    bugs.
                </Paragraph>

                <Paragraph>
                    If you run into any issues, please reach out to your administrator so they can
                    open a ticket with Twilio support.
                </Paragraph>
                <Paragraph> Thanks again for helping us build a better Twilio.</Paragraph>
            </ModalBody>
            <ModalFooter>
                <ModalFooterActions>
                    <Button variant="primary" onClick={onClose}>
                        Got it
                    </Button>
                </ModalFooterActions>
            </ModalFooter>
        </Modal>
    );
}
