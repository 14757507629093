import { useSelector } from '@twilio/frontline-shared/store/redux';
import { MediaType } from '@twilio/frontline-shared/models/Media';
import { MessageMediaProps } from '@twilio/frontline-shared/components/Chat/types';

import { AppState } from '../../../store/AppState';
import { MessageImage } from './MessageImage';
import { MessageDocument } from './MessageDocument';
import { InlinePlayerContainer } from './InlinePlayerContainer';
import { canPlayFile } from '../../../core/AudioService';

export function MessageMediaContainer({ currentMessage, position }: MessageMediaProps) {
    const media = useSelector((state: AppState) =>
        currentMessage?.mediaSid ? state.chat.media[currentMessage.mediaSid] : undefined,
    );

    if (!media) {
        return null;
    }

    if (media.type === MediaType.Image) {
        return <MessageImage image={media} currentMessage={currentMessage} />;
    }

    if (media.type === MediaType.Voice && canPlayFile(media.contentType)) {
        return <InlinePlayerContainer voiceMedia={media} messagePosition={position} />;
    }

    return <MessageDocument document={media} />;
}
