import { Flex } from '@twilio-paste/flex';
import { Box } from '@twilio-paste/box';
import { Button } from '@twilio-paste/button';
import { PlusIcon } from '@twilio-paste/icons/cjs/PlusIcon';
import { Heading } from '@twilio-paste/heading';
import { LoadingProgress } from './LoadingProgress';

type MyConversationsHeaderProps = {
    onShowNewConversation: () => void;
    conversationsLoaded: boolean;
    conversationsLoadingFailed: boolean;
};

export function MyConversationsHeader({
    onShowNewConversation,
    conversationsLoaded,
    conversationsLoadingFailed,
}: MyConversationsHeaderProps) {
    return (
        <Box
            width="100%"
            paddingX="space50"
            backgroundColor="colorBackground"
            borderBottomWidth="borderWidth10"
            borderBottomStyle="solid"
            borderBottomColor="colorBorderWeak">
            <Flex paddingY="space10" vAlignContent="center" hAlignContent="between">
                <Box>
                    <Heading as="h4" variant="heading40" marginBottom="space0">
                        Conversations
                    </Heading>
                    {!conversationsLoaded && !conversationsLoadingFailed && <LoadingProgress />}
                </Box>
                <Box paddingY="space40">
                    <Button
                        data-testid="new-conversation-plus-button"
                        variant="secondary"
                        size="icon"
                        onClick={onShowNewConversation}>
                        <PlusIcon decorative={false} title="Create new conversation" />
                    </Button>
                </Box>
            </Flex>
        </Box>
    );
}
