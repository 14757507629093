import { Flex } from '@twilio-paste/flex';
import { Text } from '@twilio-paste/text';
import { Button } from '@twilio-paste/button';

type InfoBoxProps = {
    title: string;
    text: string;
    showButton?: boolean;
    buttonText?: string;
    onClick?: () => void;
};

export function InfoBox({
    title,
    text,
    showButton = false,
    buttonText = 'Reload',
    onClick,
}: InfoBoxProps) {
    return (
        <Flex height="100%" width="100%" vAlignContent="center" hAlignContent="center">
            <Flex vertical hAlignContent="center">
                <Text
                    as="h4"
                    fontSize="fontSize40"
                    textAlign="center"
                    marginBottom="space40"
                    data-testid="title">
                    {title}
                </Text>
                <Text as="span" textAlign="center" marginBottom="space70" data-testid="text">
                    {text}
                </Text>
                {showButton && (
                    <Button data-testid="generic-button" variant="primary" onClick={onClick}>
                        {buttonText}
                    </Button>
                )}
            </Flex>
        </Flex>
    );
}
