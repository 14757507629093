import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { DefaultTheme } from '@twilio/frontline-shared/theme';
import { useChatContext } from './ChatContext';
import { Actions, ActionsProps } from './Actions';
import { Composer, ComposerProps } from './Composer';
import { SendButton, SendButtonProps } from './SendButton';
import { AudioRecorderContainerProps } from './types';

type InputToolbarAdditionalProps = {
    AudioRecorder?: React.FC<AudioRecorderContainerProps>;
};

export type InputToolbarProps = Omit<ActionsProps, 'onPressRecorder'> &
    SendButtonProps &
    ComposerProps &
    InputToolbarAdditionalProps;

export function InputToolbar(props: InputToolbarProps) {
    const { InputToolbarContainer, onShowRecorderAttempt } = useChatContext();

    const { AudioRecorder } = props;

    const [showRecorder, setShowRecorder] = useState(false);

    const handlePressRecorder = () => {
        if (!onShowRecorderAttempt) {
            return;
        }
        onShowRecorderAttempt(() => {
            setShowRecorder(true);
        });
    };

    if (InputToolbarContainer) {
        // web
        return (
            <InputToolbarContainer>
                <Composer {...props} />
                <SendButton {...props} />
            </InputToolbarContainer>
        );
    }

    if (AudioRecorder && showRecorder) {
        return (
            <View style={styles.container}>
                <AudioRecorder {...props} setShowRecorder={setShowRecorder} />
            </View>
        );
    }
    // mobile
    return (
        <View style={styles.container}>
            <View style={styles.primary}>
                <Actions {...props} onPressRecorder={handlePressRecorder} />
                <Composer {...props} />
                <SendButton {...props} />
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        borderTopWidth: StyleSheet.hairlineWidth,
        borderTopColor: '#b2b2b2',
        backgroundColor: DefaultTheme.colors.colorGray10,
    },
    primary: {
        flexDirection: 'row',
        alignItems: 'flex-end',
    },
});
