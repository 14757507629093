import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Alert } from '@twilio-paste/alert';
import { Text } from '@twilio-paste/text';
import { Anchor } from '@twilio-paste/anchor';
import { Box } from '@twilio-paste/box';

import { BETA_FEATURE_FLAGS } from '@twilio/frontline-shared/sdk/FrontlineSDK';
import { Analytics } from '@twilio/frontline-shared/analytics';

import {
    getFirebaseToken,
    requestPermission,
    unregisterOldServiceWorker,
} from '../../core/Firebase';
import { useSession } from '../../context/SessionContext';
import { getMyConversationPath } from '../../paths';

export type NotificationBannerProps = {
    className?: string;
};

export function NotificationBanner({ className }: NotificationBannerProps) {
    const history = useHistory();
    const { session } = useSession();
    const isNotificationsFlagEnabled = session?.frontlineSDK.isBetaFlagEnabled(
        BETA_FEATURE_FLAGS.WEB_PUSH_NOTIFICATIONS,
    );

    const [isEnableNotificationPermission, setIsEnableNotificationPermission] = useState(
        Notification.permission === 'default',
    );

    const showNotificationPermissionBanner =
        isNotificationsFlagEnabled && isEnableNotificationPermission;

    const handleRequestPermission = async () => {
        try {
            const firebaseToken = await requestPermission();
            if (firebaseToken) {
                session?.setPushRegistrationId(firebaseToken);
            } else {
                console.error('token is undefined');
                Analytics.logError('FIREBASE_TOKEN', { message: 'token is undefined' });
            }

            setIsEnableNotificationPermission(false);
        } catch (err: any) {
            console.error('An error occurred while requesting notification permission. ', err);
            Analytics.logError('REQUEST_NOTIFICATIONS_PERMISSION', err);
        }
    };

    const handleFetchFirebaseToken = async () => {
        try {
            const firebaseToken = await getFirebaseToken();
            if (firebaseToken) {
                session?.setPushRegistrationId(firebaseToken);
            } else {
                console.error('token is undefined');
                Analytics.logError('FIREBASE_TOKEN', { message: 'token is undefined' });
            }
        } catch (err: any) {
            console.error('An error occurred while retrieving firebase token. ', err);
            Analytics.logError('REQUEST_NOTIFICATIONS_PERMISSION', err);
        }
    };

    useEffect(() => {
        if (isNotificationsFlagEnabled && Notification.permission === 'granted') {
            handleFetchFirebaseToken();
        }
    }, [isNotificationsFlagEnabled]);

    useEffect(() => {
        const handleReceiveMessageFromSW = (event: MessageEvent<any>) => {
            console.log('Receive the message from service worker: ', event);
            if (event?.data?.conversationId) {
                const conversationPath = getMyConversationPath(event?.data?.conversationId);
                history.push(conversationPath);
            }
        };

        navigator?.serviceWorker?.addEventListener('message', handleReceiveMessageFromSW);

        return () => {
            navigator?.serviceWorker?.removeEventListener('message', handleReceiveMessageFromSW);
        };
    }, []);

    useEffect(() => {
        unregisterOldServiceWorker();
    }, []);

    if (!showNotificationPermissionBanner) {
        return null;
    }

    return (
        <Box className={className}>
            <Alert variant="neutral" onDismiss={() => setIsEnableNotificationPermission(false)}>
                <Text as="span">Frontline needs permission to </Text>
                <Anchor href="#" onClick={handleRequestPermission}>
                    enable push notifications.
                </Anchor>
            </Alert>
        </Box>
    );
}
