// Used for compression
// Resolution in pixels for supported combination of channel + image format
import { ChannelType } from '@twilio/frontline-shared/types/channel';
import { ChannelTypeSizeLimitsType } from '../hooks/useChannelTypeSizeLimits';

export const channelResolutions = {
    [ChannelType.Sms]: {
        png: 1920,
        jpg: 2560,
    },
    [ChannelType.Whatsapp]: {
        png: 5120,
        jpg: 7920,
    },
};

export const compressionUpperLimitInMb = 25;

export const shouldCompress = (
    sizeLimits: ChannelTypeSizeLimitsType,
    channelType: ChannelType,
    channelSizeLimit: number,
    originalSizeInMb: number,
) =>
    sizeLimits &&
    [ChannelType.Whatsapp, ChannelType.Sms].includes(channelType) &&
    originalSizeInMb > channelSizeLimit &&
    originalSizeInMb < compressionUpperLimitInMb;

export const getCompressionSizeLimit = (
    sizeLimits: ChannelTypeSizeLimitsType,
    channelType: ChannelType,
) => {
    if (channelType === ChannelType.Sms) {
        return 0.5;
    }
    return sizeLimits[channelType];
};

export const getImageExtension = (name?: string) => {
    const split = (name || '').split('.');
    const result = split[split.length - 1] || '';
    return result.toLowerCase();
};

export const isCompressibleExtension = (extension: string) => {
    return ['jpg', 'jpeg', 'png'].includes(extension);
};
