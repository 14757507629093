import { buffers, eventChannel } from 'redux-saga';

import {
    TwilioConversation,
    TwilioParticipant,
    TwilioMessage,
    ConversationUpdatedEventArgs,
    MessageUpdateReason,
    ParticipantUpdatedEventArgs,
} from '@twilio/frontline-shared/types';
import {
    fromTwilioMessage,
    isMediaMessage,
    getMessageStatus,
    isUnsuccessfulMessage,
    isHiddenMessage,
} from '@twilio/frontline-shared/models/Message';
import { fromTwilioParticipant } from '@twilio/frontline-shared/models/Participant';
import { ChannelType } from '@twilio/frontline-shared/types/channel';
import { fromTwilioConversation } from '@twilio/frontline-shared/models/Conversation';
import { Action } from '@twilio/frontline-shared/store/redux';
import {
    newMessage,
    updateMessageStatus,
    fetchDeliveryReceipt,
    removeMessage,
} from '../messages/actions';
import { newMedia } from '../media/actions';
import { addParticipants, removeParticipant, updateParticipant } from '../ParticipantsState';
import {
    fetchUnreadMessagesCount,
    saveMarkedUnreadAt,
    setUnreadSystemMessageCount,
    updateConversation,
} from './actions';
import { addConversationsUsers } from '../users/actions';
import { FrontlineSDK } from '../../sdk/FrontlineSDK';

export function createConversationEventsSagaChannel(conversation: TwilioConversation) {
    return eventChannel((dispatch) => {
        conversation.on('updated', (updatedEvent: ConversationUpdatedEventArgs) => {
            const { conversation: twilioConversation, updateReasons } = updatedEvent;
            console.log('Conversation updated: ', twilioConversation.sid, updateReasons);

            const updatedConversation = fromTwilioConversation(twilioConversation);
            dispatch(
                updateConversation({
                    conversationSid: updatedConversation.sid,
                    conversation: updatedConversation,
                }),
            );
            if (updateReasons.includes('lastReadMessageIndex')) {
                dispatch(fetchUnreadMessagesCount({ conversationSid: updatedConversation.sid }));
            }
            if (updateReasons.includes('attributes')) {
                dispatch(setUnreadSystemMessageCount({ conversationSid: updatedConversation.sid }));
            }
        });

        conversation.on('messageAdded', (twilioMessage: TwilioMessage) => {
            const message = fromTwilioMessage(twilioMessage);
            console.log('Conversation message added: ', message);

            if (isHiddenMessage(message)) {
                return;
            }
            dispatch(newMessage({ channelSid: conversation.sid, message }));

            if (isMediaMessage(message)) {
                dispatch(
                    newMedia({
                        media: twilioMessage.attachedMedia![0],
                        message,
                    }),
                );
            }
        });

        conversation.on('participantJoined', (twilioParticipant: TwilioParticipant) => {
            console.log('Conversation participant joined: ', twilioParticipant.sid);

            const conversationSid = twilioParticipant.conversation.sid;
            dispatch(
                addParticipants({
                    conversationSid,
                    participants: [fromTwilioParticipant(twilioParticipant)],
                }),
            );

            if (twilioParticipant.type === ChannelType.Chat && twilioParticipant.identity) {
                dispatch(addConversationsUsers({ identities: [twilioParticipant.identity] }));
            }
        });

        conversation.on(
            'participantUpdated',
            ({ participant: twilioParticipant, updateReasons }: ParticipantUpdatedEventArgs) => {
                console.log('Conversation participant updated: ', updateReasons);
                if (
                    updateReasons.includes('attributes') ||
                    updateReasons.includes('lastReadTimestamp')
                ) {
                    const participant = fromTwilioParticipant(twilioParticipant);
                    dispatch(
                        updateParticipant({
                            conversationSid: conversation.sid,
                            participant,
                        }),
                    );
                    if (
                        updateReasons.includes('attributes') &&
                        participant.identity &&
                        participant.identity === FrontlineSDK.shared?.userIdentity
                    ) {
                        dispatch(
                            setUnreadSystemMessageCount({
                                conversationSid: conversation.sid,
                                lastReadTime: participant.lastReadTime,
                            }),
                        );
                        dispatch(
                            saveMarkedUnreadAt({
                                conversationSid: conversation.sid,
                                markedUnreadAt: participant.markedUnreadAt,
                            }),
                        );
                    }
                }
            },
        );

        conversation.on('participantLeft', (twilioParticipant: TwilioParticipant) => {
            console.log('Conversation participant left: ', twilioParticipant.sid);

            dispatch(
                removeParticipant({
                    conversationSid: conversation.sid,
                    participantSid: twilioParticipant.sid,
                }),
            );
        });

        conversation.on('messageRemoved', (twilioMessage: TwilioMessage) => {
            console.log('messageRemoved: ', twilioMessage.sid);
            dispatch(
                removeMessage({
                    channelSid: twilioMessage.conversation.sid,
                    messageSid: twilioMessage.sid,
                }),
            );
        });

        conversation.on(
            'messageUpdated',
            ({
                message: twilioMessage,
                updateReasons,
            }: {
                message: TwilioMessage;
                updateReasons: MessageUpdateReason[];
            }) => {
                console.log('messageUpdated: ', twilioMessage.sid, updateReasons);

                if (isAggregatedDeliveryChanged(updateReasons)) {
                    console.log('aggregatedDelivery', twilioMessage.aggregatedDeliveryReceipt);
                    const status = getMessageStatus(twilioMessage.aggregatedDeliveryReceipt!);
                    if (isUnsuccessfulMessage({ status }) && conversation.lastMessage) {
                        dispatch(
                            fetchDeliveryReceipt({
                                message: twilioMessage,
                                isLastMessage:
                                    twilioMessage.index === conversation.lastMessage.index,
                            }),
                        );
                    } else
                        dispatch(
                            updateMessageStatus({
                                messageSid: twilioMessage.sid,
                                channelSid: twilioMessage.conversation.sid,
                                status,
                            }),
                        );
                }
            },
        );

        return () => {
            conversation.removeAllListeners();
        };
    }, buffers.expanding<Action>());
}

function isAggregatedDeliveryChanged(updateReasons: MessageUpdateReason[]) {
    return updateReasons.indexOf('deliveryReceipt') > -1;
}
