import { useState } from 'react';
import { Text } from '@twilio-paste/text';
import { Box } from '@twilio-paste/box';
import { Flex } from '@twilio-paste/flex';
import { Button } from '@twilio-paste/button';
import { useMenuState, Menu, MenuItem, MenuButton, MenuSeparator } from '@twilio-paste/menu';
import { Spinner } from '@twilio-paste/spinner';
import { ProductFrontlineIcon } from '@twilio-paste/icons/cjs/ProductFrontlineIcon';
import { NewIcon } from '@twilio-paste/icons/cjs/NewIcon';

import { useChatUser } from '@twilio/frontline-shared/store/ChatSessionState';

import { Avatar } from '../Avatar';
import { useSession } from '../../context/SessionContext';
import { useToasterContext } from '../../context/ToasterContext';
import { AvailabilityState, useAvailability } from '../../hooks/useAvailability';
import { MenuItemAvailabilityButton } from './components/MenuItemAvailabilityButton';
import { UserInfoBox } from './components/UserInfoBox';
import { BetaInfoModal } from './components/BetaInfoModal';
import { ToastMessage } from '../ToastMessage';
import { ConnectionBadge } from './components/ConnectionBadge';

const HEADER_HEIGHT_CSS_VARIABLE = 'var(--header-height)';

type HeaderProps = {
    showAvailability?: boolean;
    className?: string;
};

export function Header({ className, showAvailability = true }: HeaderProps) {
    const [isOpen, setIsOpen] = useState(false);
    const [showLogoutSpinner, setShowLogoutSpinner] = useState(false);
    const menu = useMenuState();
    const user = useChatUser();
    const { session } = useSession();
    const { state, isAvailable, setAvailability } = useAvailability();
    const toaster = useToasterContext();

    const handleLogout = () => {
        setShowLogoutSpinner(true);
        session?.redirectToLogout();
    };

    const handleToggleAvailability = () => {
        const activitySid = isAvailable
            ? session?.configuration?.unavailableActivitySid
            : session?.configuration?.availableActivitySid;
        if (!activitySid) return;
        menu.hide();
        setAvailability(activitySid).catch(() =>
            toaster.push({
                message: (
                    <ToastMessage
                        title="Unable to change your availability."
                        text="Try again or contact your support team if the problem continues."
                        onRetry={handleToggleAvailability}
                    />
                ),
                variant: 'error',
                dismissAfter: 4000,
                id: `availability_${activitySid}`,
            }),
        );
    };

    return (
        <Box
            style={{ height: HEADER_HEIGHT_CSS_VARIABLE }}
            display="flex"
            alignItems="center"
            width="100%"
            paddingRight="space80"
            backgroundColor="colorBackgroundPrimaryStrongest"
            className={className}>
            <Box
                style={{ height: HEADER_HEIGHT_CSS_VARIABLE, width: HEADER_HEIGHT_CSS_VARIABLE }}
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderRightStyle="solid"
                borderRightWidth="borderWidth10"
                borderRightColor="colorBorder">
                <ProductFrontlineIcon size="sizeIcon50" decorative color="colorTextInverse" />
            </Box>
            <Flex marginLeft="space50" grow vertical>
                <Flex vAlignContent="center">
                    <Text
                        as="span"
                        marginRight="space30"
                        fontWeight="fontWeightBold"
                        fontSize="fontSize20"
                        color="colorTextInverse">
                        Twilio Frontline
                    </Text>
                    <NewIcon decorative color="colorTextInverseWeak" size="sizeIcon10" />
                    <Button variant="destructive_link" onClick={() => setIsOpen(true)}>
                        <Text
                            as="span"
                            fontWeight="fontWeightSemibold"
                            fontSize="fontSize20"
                            color="colorTextInverseWeak"
                            marginLeft="space10">
                            Beta
                        </Text>
                    </Button>
                </Flex>

                <Flex vAlignContent="center">
                    <Text
                        as="span"
                        color="colorTextInverse"
                        fontWeight="fontWeightBold"
                        fontSize="fontSize30"
                        data-testid="header-workspaceId-name"
                        paddingY="space10">
                        {session?.accountUniqueName}
                    </Text>
                    <ConnectionBadge />
                </Flex>
            </Flex>

            <MenuButton variant="reset" {...menu} baseId="userInfoMenuButton">
                <Avatar size="sizeIcon40" friendlyName={user?.friendlyName} avatar={user?.avatar} />
                <UserInfoBox
                    userName={user?.friendlyName || user?.identity}
                    isAvailable={isAvailable}
                    showAvailabilityLoading={state === AvailabilityState.Pending}
                    showAvailability={showAvailability}
                />
            </MenuButton>
            <Menu {...menu} baseId="headerMenu" placement="bottom-end" aria-label="Settings">
                {showAvailability && (
                    <>
                        <MenuItem
                            {...menu}
                            baseId="availabilityMenuItem"
                            onClick={handleToggleAvailability}>
                            <MenuItemAvailabilityButton isAvailable={isAvailable} />
                        </MenuItem>
                        <MenuSeparator />
                    </>
                )}
                <MenuItem
                    {...menu}
                    onClick={handleLogout}
                    baseId="logoutMenuItem"
                    data-testid="menu-item-logout">
                    <Flex vAlignContent="center">
                        Logout
                        {showLogoutSpinner && (
                            <Box marginLeft="space40">
                                <Spinner
                                    color="colorTextIconInverse"
                                    size="sizeIcon10"
                                    decorative
                                    title="Loading"
                                />
                            </Box>
                        )}
                    </Flex>
                </MenuItem>
            </Menu>

            <BetaInfoModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
        </Box>
    );
}
