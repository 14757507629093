import async from 'async';
import { useHistory } from 'react-router-dom';

import { Token } from '@twilio/frontline-shared/core/Token';
import { FrontlineSDK, BETA_FEATURE_FLAGS } from '@twilio/frontline-shared/sdk/FrontlineSDK';
import { FrontlineConfiguration } from '@twilio/frontline-shared/models/FrontlineConfiguration';
import { batch, Dispatch } from '@twilio/frontline-shared/store/redux';
import {
    setConnectionState,
    setRole,
    setUser,
} from '@twilio/frontline-shared/store/ChatSessionState';
import { fromTwilioUser, isUserDeactived } from '@twilio/frontline-shared/models/User';
import { logout } from '@twilio/frontline-shared/store/ui/actions';
import {
    ConversationSid,
    TwilioConversation,
    TwilioUser,
    UserIdentity,
    UserUpdateReason,
} from '@twilio/frontline-shared/types';
import {
    addConversation,
    addUserConversation,
    updateConversation,
    conversationsInitialized,
    conversationsLoadingFailure,
    conversationsLoadingStarted,
    removeConversation,
    updateAppIconBadge,
    watchConversationEvents,
} from '@twilio/frontline-shared/store/conversations/actions';
import {
    fromTwilioMessages,
    isHiddenMessage,
    isMediaMessage,
    isMessageFromWhatsApp,
    isUnsuccessfulMessage,
} from '@twilio/frontline-shared/models/Message';
import { fromTwilioParticipant } from '@twilio/frontline-shared/models/Participant';
import { ChannelType } from '@twilio/frontline-shared/types/channel';
import { fromTwilioConversation } from '@twilio/frontline-shared/models/Conversation';
import { addMessages, fetchDeliveryReceipt } from '@twilio/frontline-shared/store/messages/actions';
import { newMedia } from '@twilio/frontline-shared/store/media/actions';
import { setWindowExpireTime } from '@twilio/frontline-shared/store/WhatsAppWindowState';
import { setConversationUsers } from '@twilio/frontline-shared/store/users/actions';
import { addParticipants } from '@twilio/frontline-shared/store/ParticipantsState';
import { ISession } from '@twilio/frontline-shared/core/ISession';
import { Analytics } from '@twilio/frontline-web/src/analytics';
import { UserConversationRest } from '@twilio/frontline-shared/core/FrontlineClient';
import { SessionError } from '@twilio/frontline-shared/core/SessionError';

import { delayTimer } from '@twilio/frontline-shared/utils/async';
import { loadDrafts } from '@twilio/frontline-shared/store/draft/actions';
import { FRONTLINE_VERSION, TWILIO_REGION } from '../env';
import { CredentialsStorage } from './CredentialsStorage';
import { getFirebaseToken, deleteFirebaseToken } from './Firebase';
import { PAGES } from '../paths';
import { DraftStorageManager } from './DraftStorageManager';

type SessionParams = {
    token: Token;
    accountUniqueName: string | null;
    dispatch: Dispatch;
    history: ReturnType<typeof useHistory>;
};

const MESSAGE_PAGE_SIZE = 30;
const INITIAL_RETRY_DELAY = 300;
const FETCHING_REQUEST_LIMITS = 4;

type QueueWorkerParams =
    | {
          type: 'myConversation';
          conversation: TwilioConversation;
      }
    | {
          type: 'userConversation';
          userIdentity: string;
          conversation: UserConversationRest;
      };

export class Session implements ISession {
    static shared?: Session;

    public static async create(params: SessionParams) {
        const session = new Session(params);
        await session.start();
        Session.shared = session;
        return session;
    }

    public readonly token: Token;

    public readonly accountUniqueName: string | null;

    public frontlineSDK: FrontlineSDK;

    public configuration?: FrontlineConfiguration;

    public isChannelAddressMaskingEnabled?: boolean;

    public conversationsCount = 0;

    public initializedConversationsCount = 0;

    public isConversationsFetched = false;

    private dispatch: Dispatch;

    private history: ReturnType<typeof useHistory>;

    private readonly subscribedConversations: Set<string>;

    private conversationQueue?: async.QueueObject<QueueWorkerParams>;

    private constructor({ token, accountUniqueName, dispatch, history }: SessionParams) {
        this.token = token;
        this.accountUniqueName = accountUniqueName;
        this.dispatch = dispatch;
        this.history = history;
        this.subscribedConversations = new Set<string>();

        this.frontlineSDK = new FrontlineSDK({
            token,
            accountUniqueName,
            options: {
                appName: 'Frontline Web',
                appVersion: FRONTLINE_VERSION as string,
                region: TWILIO_REGION as string,
                supportsVoice: false,
            },
        });

        this.initConversationQueue();
    }

    get userIdentity() {
        return this.frontlineSDK.userIdentity;
    }

    get userSid() {
        return this.frontlineSDK.userSid;
    }

    async redirectToLogout() {
        this.history.push(PAGES.LOGOUT);
    }

    async logout() {
        return Promise.resolve()
            .then(() => {
                const isNotificationsFlagEnabled = this.frontlineSDK.isBetaFlagEnabled(
                    BETA_FEATURE_FLAGS.WEB_PUSH_NOTIFICATIONS,
                );
                const isEnableNotificationPermission =
                    isNotificationsFlagEnabled && Notification.permission === 'granted';
                return isEnableNotificationPermission
                    ? this.unsubscribeFromPushNotifications()
                    : Promise.resolve(true);
            })
            .then(() => {
                this.frontlineSDK.shutdown();
                CredentialsStorage.clear();
                DraftStorageManager.clearAllDrafts();
                this.dispatch(logout());
                Session.shared = undefined;
            });
    }

    async start() {
        try {
            await this.frontlineSDK.start();

            this.configuration = this.frontlineSDK.configuration;
            this.isChannelAddressMaskingEnabled = this.frontlineSDK.isChannelAddressMaskingEnabled;

            this.frontlineSDK.on('tokenUpdated', (token) => {
                CredentialsStorage.setToken(token);
            });

            this.frontlineSDK.on('tokenExpired', () => {
                this.redirectToLogout();
            });

            this.frontlineSDK.on('userDeactivated', () => {
                this.redirectToLogout();
            });

            this.frontlineSDK.on('userUpdated', ({ user }) => {
                if (user.identity === this.userIdentity) {
                    this.dispatch(setUser(fromTwilioUser(user)));
                }
            });

            this.frontlineSDK.on('connectionStateChanged', (connectionState) => {
                this.dispatch(setConnectionState(connectionState));
            });

            this.dispatch(setConnectionState(this.frontlineSDK.connectionState));
            this.dispatch(setUser(fromTwilioUser(this.frontlineSDK.user)));
            this.dispatch(setRole(this.frontlineSDK.role));

            if (this.frontlineSDK.userSid) {
                Analytics.setUser({
                    id: this.frontlineSDK.userSid,
                    accountSid: this.frontlineSDK.accountSid,
                    role: this.frontlineSDK.role,
                    dateCreated: this.frontlineSDK.userDateCreated,
                    dateUpdated: this.frontlineSDK.userDateUpdated,
                });
            }

            this.loadMyConversations();
            this.dispatch(loadDrafts());
        } catch (err: any) {
            console.log('ERROR', err);
            await CredentialsStorage.clear();
            throw new SessionError(err);
        }
    }

    public async loadMyConversations() {
        const startTime = Date.now();
        this.dispatch(conversationsLoadingStarted());
        this.conversationsCount = 0;
        let res;
        let pageRetryCount = 1;
        do {
            try {
                if (!res) {
                    res = await this.frontlineSDK.getSubscribedConversations();
                } else {
                    res = await res.nextPage();
                }
                this.conversationQueue!.push(
                    res.items.map((conversation) => ({
                        type: 'myConversation',
                        conversation,
                    })),
                );
                this.conversationsCount += res.items.length;
                pageRetryCount = 1;
            } catch (err: any) {
                pageRetryCount += 1;
                await delayTimer(INITIAL_RETRY_DELAY * 2 ** pageRetryCount);
            }
        } while (res?.hasNextPage && pageRetryCount <= FETCHING_REQUEST_LIMITS);

        this.isConversationsFetched = true;

        const onFinishedSuccess = () => {
            const endTime = Date.now();
            const convoCount = this.subscribedConversations.size;
            console.log(`${convoCount} conversation(s) initialized in ${endTime - startTime}ms`);
            this.initializationFinished();
            this.subscribeConversationsEvents();
            return Promise.resolve();
        };

        if (pageRetryCount > FETCHING_REQUEST_LIMITS) {
            this.dispatch(conversationsLoadingFailure());
            return Promise.reject(new Error('Unable to load conversations'));
        }

        if (this.conversationQueue!.length() === 0 && this.conversationQueue!.running() === 0) {
            return onFinishedSuccess();
        }
        return this.conversationQueue!.drain().then(onFinishedSuccess);
    }

    public setAvailability = (activitySid: string) => {
        return this.frontlineSDK.setAvailability(activitySid);
    };

    public closeConversation = (conversationSid: ConversationSid): Promise<Record<string, any>> => {
        return this.frontlineSDK.closeConversation(conversationSid);
    };

    public fetchMyConversation(conversationSid: ConversationSid): Promise<unknown> {
        return this.frontlineSDK
            .getConversationBySid(conversationSid)
            .then(this.processMyConversation)
            .catch(() => Promise.reject(new Error('fetching conversation failed')));
    }

    public fetchUserConversation(
        conversationSid: ConversationSid,
        userIdentity: UserIdentity,
    ): Promise<void> {
        return this.frontlineSDK
            .peekConversation(conversationSid)
            .then((conversation) => this.processUserConversation(conversation, userIdentity))
            .catch(() => Promise.reject(new Error('fetching user conversation failed')));
    }

    public fetchUpdatedUserConversation(conversationSid: ConversationSid): Promise<void> {
        return this.frontlineSDK
            .peekConversation(conversationSid)
            .then((conversation) =>
                this.processUpdatedUserConversation(conversationSid, conversation),
            )
            .catch(() => Promise.reject(new Error('fetching updated user conversation failed')));
    }

    public setPushRegistrationId(fcmToken: string) {
        this.frontlineSDK.setChatPushRegistrationId('fcm', fcmToken);
    }

    private unsubscribeFromPushNotifications() {
        return getFirebaseToken()
            .then((token) => this.frontlineSDK.removeChatPushRegistrationId('fcm', token))
            .then(deleteFirebaseToken)
            .catch((err: any) => {
                console.error('An error occurred while deleting firebase token. ', err);
                Analytics.logError('DELETE_FIREBASE_TOKEN', err);
            });
    }

    // TODO: Move this method to Frontline SDK
    public async loadUserConversations(identity: string): Promise<void> {
        let response;
        let pageRetryCount = 1;
        do {
            try {
                if (!response) {
                    response = await this.frontlineSDK.getUserConversations(identity);
                } else {
                    response = await response.nextPage();
                }
                const userConversations: QueueWorkerParams[] = response.items.map(
                    (userConversation) => ({
                        type: 'userConversation',
                        userIdentity: identity,
                        conversation: userConversation,
                    }),
                );
                this.conversationQueue?.push(userConversations);
                pageRetryCount = 1;
            } catch (err: any) {
                pageRetryCount += 1;
                await delayTimer(INITIAL_RETRY_DELAY * 2 ** pageRetryCount);
            }
        } while (response?.hasNextPage && pageRetryCount <= FETCHING_REQUEST_LIMITS);

        const onFinishedSuccess = () => {
            return Promise.resolve();
        };

        if (pageRetryCount > FETCHING_REQUEST_LIMITS) {
            return Promise.reject(new Error('Unable to load conversations'));
        }

        if (this.conversationQueue?.length() === 0 && this.conversationQueue?.running() === 0) {
            return onFinishedSuccess();
        }

        return this.conversationQueue?.drain().then(onFinishedSuccess);
    }

    private subscribeConversationsEvents() {
        this.frontlineSDK.on('conversationJoined', (conversation: TwilioConversation) => {
            console.log('conversationJoined: ', conversation.sid);
            this.processMyConversation(conversation).then(() =>
                this.dispatch(updateAppIconBadge()),
            );
        });

        this.frontlineSDK.on('conversationAdded', (conversation: TwilioConversation) => {
            console.log('conversationAdded: ', conversation.sid);
            this.processMyConversation(conversation).then(() =>
                this.dispatch(updateAppIconBadge()),
            );
        });

        this.frontlineSDK.on('conversationLeft', (conversation: TwilioConversation) => {
            console.log('conversationLeft: ', conversation.sid);
            this.dispatch(removeConversation({ conversationSid: conversation.sid }));
            this.subscribedConversations.delete(conversation.sid);
            this.dispatch(updateAppIconBadge());
        });

        this.frontlineSDK.on('conversationRemoved', (conversation: TwilioConversation) => {
            console.log('conversationRemoved: ', conversation.sid);
            this.dispatch(removeConversation({ conversationSid: conversation.sid }));
            this.subscribedConversations.delete(conversation.sid);
            this.dispatch(updateAppIconBadge());
        });
    }

    private initializationFinished = () => {
        this.dispatch(conversationsInitialized());
        this.dispatch(updateAppIconBadge());
    };

    private processUpdatedUserConversation(
        conversationSid: ConversationSid,
        conversation: TwilioConversation,
    ): void {
        const convo = fromTwilioConversation(conversation);
        this.dispatch(updateConversation({ conversationSid, conversation: convo }));
    }

    // TODO: Move this queue to Frontline SDK
    private initConversationQueue() {
        const concurrency = 10;
        this.conversationQueue = async.queue(
            (params: QueueWorkerParams, callback: (err?: any) => void) => {
                Promise.resolve()
                    .then(() => {
                        if (params.type === 'userConversation') {
                            return this.frontlineSDK
                                .peekConversation(params.conversation.conversation_sid)
                                .then((conversation) =>
                                    this.processUserConversation(conversation, params.userIdentity),
                                );
                        }
                        return this.processMyConversation(params.conversation).then(() => {
                            this.initializedConversationsCount += 1;
                        });
                    })
                    .then(() => {
                        callback();
                    })
                    .catch((err) => {
                        callback(err);
                    });
            },
            concurrency,
        );
        this.conversationQueue.error((err: any, params: QueueWorkerParams) => {
            let conversationSid: ConversationSid;
            if (params.type === 'myConversation') {
                conversationSid = params.conversation.sid;
            } else {
                conversationSid = params.conversation.conversation_sid;
            }
            this.subscribedConversations.delete(conversationSid);
            this.dispatch(removeConversation({ conversationSid }));
            this.conversationQueue!.push(params);
        });
    }

    private processMyConversation = (conversation: TwilioConversation): Promise<unknown> => {
        if (!this.subscribedConversations.has(conversation.sid)) {
            this.subscribedConversations.add(conversation.sid);
            this.dispatch(watchConversationEvents({ conversation }));
        }

        const messagesPromise = conversation.getMessages(MESSAGE_PAGE_SIZE);
        const participantsPromise = conversation.getParticipants();
        const unreadMessagesCountPromise = this.frontlineSDK.getConversationUnreadMessagesCount(
            conversation.sid,
        );

        return Promise.all([messagesPromise, participantsPromise, unreadMessagesCountPromise]).then(
            async ([messagesPage, twilioParticipants, unreadMessagesCount]) => {
                const twilioMessages = messagesPage.items.filter(
                    (message) => !isHiddenMessage(message),
                );
                const messages = fromTwilioMessages(twilioMessages);

                const whatsappParticipant = twilioParticipants.find(
                    (m) => m.type === ChannelType.Whatsapp,
                );
                const convo = fromTwilioConversation(conversation);

                const users = await this.frontlineSDK.getUsersOfConversation(
                    convo,
                    twilioParticipants,
                    twilioMessages,
                );
                batch(() => {
                    if (!Session.shared?.subscribedConversations.has(conversation.sid)) {
                        return;
                    }
                    this.dispatch(
                        addMessages({
                            channelSid: conversation.sid,
                            messages,
                        }),
                    );
                    for (const [index, message] of messages.entries()) {
                        const twilioMessage = twilioMessages[index];
                        if (isMediaMessage(message)) {
                            this.dispatch(
                                newMedia({
                                    media: twilioMessage.attachedMedia![0],
                                    message,
                                }),
                            );
                        }

                        if (isUnsuccessfulMessage(message)) {
                            this.dispatch(
                                fetchDeliveryReceipt({
                                    message: twilioMessage,
                                    isLastMessage: index === twilioMessages.length - 1,
                                }),
                            );
                        }
                    }

                    let lastMessageTime: Date | undefined;
                    if (whatsappParticipant) {
                        for (let i = messages.length - 1; i >= 0; i--) {
                            if (isMessageFromWhatsApp(messages[i])) {
                                lastMessageTime = messages[i].createdAt;
                                break;
                            }
                        }
                        if (lastMessageTime) {
                            this.dispatch(
                                setWindowExpireTime({
                                    channelSid: conversation.sid,
                                    lastMessageAt: lastMessageTime,
                                }),
                            );
                        }
                    }

                    this.dispatch(setConversationUsers({ users }));

                    const participants = twilioParticipants.map(fromTwilioParticipant);
                    this.dispatch(
                        addParticipants({
                            conversationSid: conversation.sid,
                            participants,
                        }),
                    );

                    const userParticipant = participants.find(
                        (p) => p.identity === this.frontlineSDK.userIdentity,
                    );
                    const unreadSystemMessages = convo.events.filter(
                        (e) => e.date > (userParticipant?.lastReadTime || 0),
                    );
                    this.dispatch(
                        addConversation({
                            conversation: {
                                ...convo,
                                markedUnreadAt: userParticipant?.markedUnreadAt,
                                unreadMessagesCount, // unread for user, not valid for managers
                                unreadSystemMessagesCount: unreadSystemMessages.length,
                                isAllPreviousMessagesFetched:
                                    messagesPage.items.length < MESSAGE_PAGE_SIZE ||
                                    /* if the first message's index is 0, there is no older messages */
                                    twilioMessages[0]?.index === 0,
                            },
                        }),
                    );
                });
            },
        );
    };

    private processUserConversation = (
        conversation: TwilioConversation,
        userIdentity: UserIdentity,
    ): Promise<void> => {
        if (this.subscribedConversations.has(conversation.sid)) {
            this.dispatch(
                addUserConversation({
                    userIdentity,
                    conversationSid: conversation.sid,
                }),
            );
            return Promise.resolve();
        }
        this.subscribedConversations.add(conversation.sid);
        this.dispatch(watchConversationEvents({ conversation }));

        const messagesPromise = conversation.getMessages(MESSAGE_PAGE_SIZE);
        const participantsPromise = conversation.getParticipants();

        return Promise.all([messagesPromise, participantsPromise]).then(
            async ([messagesPage, twilioParticipants]) => {
                const twilioMessages = messagesPage.items.filter(
                    (message) => !isHiddenMessage(message),
                );
                const messages = fromTwilioMessages(twilioMessages);

                const convo = fromTwilioConversation(conversation);

                const users = await this.frontlineSDK.getUsersOfConversation(
                    convo,
                    twilioParticipants,
                    twilioMessages,
                );
                batch(() => {
                    if (!Session.shared?.subscribedConversations.has(conversation.sid)) {
                        return;
                    }
                    this.dispatch(
                        addMessages({
                            channelSid: conversation.sid,
                            messages,
                        }),
                    );
                    for (const [index, message] of messages.entries()) {
                        const twilioMessage = twilioMessages[index];
                        if (isMediaMessage(message)) {
                            this.dispatch(
                                newMedia({
                                    media: twilioMessage.attachedMedia![0],
                                    message,
                                }),
                            );
                        }
                    }

                    this.dispatch(setConversationUsers({ users }));

                    const participants = twilioParticipants.map(fromTwilioParticipant);
                    this.dispatch(
                        addParticipants({
                            conversationSid: conversation.sid,
                            participants,
                        }),
                    );

                    this.dispatch(
                        addUserConversation({
                            userIdentity,
                            conversation: {
                                ...convo,
                                unreadMessagesCount: 0,
                                unreadSystemMessagesCount: 0,
                                isAllPreviousMessagesFetched:
                                    messagesPage.items.length < MESSAGE_PAGE_SIZE ||
                                    /* if the first message's index is 0, there is no older messages */
                                    twilioMessages[0]?.index === 0,
                            },
                        }),
                    );
                });
            },
        );
    };
}
