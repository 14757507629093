import { Button } from '@twilio-paste/button';
import { Flex } from '@twilio-paste/flex';
import { Box } from '@twilio-paste/box';
import { Input } from '@twilio-paste/input';
import { Spinner } from '@twilio-paste/spinner';
import { SearchIcon } from '@twilio-paste/icons/cjs/SearchIcon';

export const SEARCH_INPUT_HEIGHT = 60;

type SearchInputProps = {
    searchInputValue?: string;
    disabled?: boolean;
    loading?: boolean;
    onClear?: () => void;
    onSearchInputChange: (value: string) => void;
};

export function SearchInput({
    searchInputValue,
    disabled = false,
    loading = false,
    onSearchInputChange,
    onClear,
}: SearchInputProps) {
    return (
        <Flex
            height={SEARCH_INPUT_HEIGHT}
            vAlignContent="center"
            paddingX="space50"
            paddingY="space40">
            <Input
                ref={(inputElement) => inputElement?.focus()}
                type="search"
                placeholder="Search customers"
                data-testid="search-entry"
                onChange={(e) => onSearchInputChange(e.target.value)}
                value={searchInputValue}
                disabled={disabled}
                insertBefore={
                    <SearchIcon decorative={false} title="Search" color="colorTextIcon" />
                }
            />
            {loading && (
                <Box position="absolute" right={searchInputValue ? 'space190' : 'space70'}>
                    <Spinner decorative={false} title="Loading" color="colorTextIcon" />
                </Box>
            )}
            {searchInputValue && (
                <Box marginLeft="space50">
                    <Button variant="link" onClick={onClear}>
                        Clear
                    </Button>
                </Box>
            )}
        </Flex>
    );
}
