import {
    Client,
    Participant,
    User,
    Message,
    Paginator,
    SendMediaOptions,
    JSONObject,
} from '@twilio/conversations';

import {
    ATTRIBUTE_LAST_READ_TIME,
    ATTRIBUTE_MARKED_UNREAD_AT,
} from '@twilio/frontline-shared/models/Participant';
import { ChannelType } from '@twilio/frontline-shared/types/channel';
import {
    getIdentitiesFromSystemEvents,
    Conversation,
} from '@twilio/frontline-shared/models/Conversation';
import { allSettled, FulfilledResult } from '@twilio/frontline-shared/utils/allSettled';
import { Attributes, TwilioMessage } from '../types';

import { isNumber } from '../utils/isNumber';
import { fromTwilioUser } from '../models/User';

const MESSAGE_PAGE_SIZE = 30;

export type {
    UserUpdatedEventArgs,
    ConnectionState,
    UserUpdateReason,
    Participant,
    NotificationTypes,
} from '@twilio/conversations';
export class ConversationsClient extends Client {
    get userIdentity() {
        return this.user?.identity;
    }

    sendMessage = (
        conversationSid: string,
        message: string | FormData | SendMediaOptions | null,
        messageAttributes?: any,
    ): Promise<number> => {
        return this!
            .getConversationBySid(conversationSid)
            .then((conversation) => conversation.sendMessage(message, messageAttributes));
    };

    markMessagesAsRead = (conversationSid: string, index: number): Promise<number> => {
        return this!
            .getConversationBySid(conversationSid)
            .then((conversation) => conversation.advanceLastReadMessageIndex(index));
    };

    updateLastReadMessageIndex = (
        conversationSid: string,
        index: number | null,
    ): Promise<number> => {
        return this!
            .getConversationBySid(conversationSid)
            .then((conversation) => conversation.updateLastReadMessageIndex(index));
    };

    getMessages = (
        conversationSid: string,
        pageSize?: number,
        anchor?: number,
        direction?: 'backwards' | 'forward',
    ): Promise<Paginator<Message>> => {
        return this!
            .getConversationBySid(conversationSid)
            .then((conversation) => conversation.getMessages(pageSize, anchor, direction));
    };

    getConversationMessagesCount = (conversationSid: string): Promise<number> => {
        return this.getConversationBySid(conversationSid).then((conversation) =>
            conversation.getMessagesCount(),
        );
    };

    setParticipantLastReadTime = (
        conversationSid: string,
        lastMessageTime: number,
    ): Promise<Participant> => {
        return this!
            .getConversationBySid(conversationSid)
            .then((conversation) => {
                return conversation.getParticipantByIdentity(this.userIdentity!);
            })
            .then((participant: Participant | null) => {
                if (!participant) {
                    return Promise.reject(new Error('No participant found'));
                }
                const attributes = { ...(participant.attributes as JSONObject) };
                attributes[ATTRIBUTE_LAST_READ_TIME] = lastMessageTime;
                return participant.updateAttributes(attributes);
            });
    };

    setParticipantMarkUnread = (
        conversationSid: string,
        markedUnreadAt?: number,
        lastReadTime?: number,
    ): Promise<Participant> => {
        return this!
            .getConversationBySid(conversationSid)
            .then((conversation) => {
                return conversation.getParticipantByIdentity(this.userIdentity!);
            })
            .then((participant: Participant | null) => {
                if (!participant) {
                    return Promise.reject(new Error('No participant found'));
                }
                const attributes = { ...(participant.attributes as JSONObject) };
                if (markedUnreadAt !== undefined) {
                    attributes[ATTRIBUTE_MARKED_UNREAD_AT] = markedUnreadAt;
                } else {
                    delete attributes[ATTRIBUTE_MARKED_UNREAD_AT];
                }
                if (lastReadTime !== undefined) {
                    attributes[ATTRIBUTE_LAST_READ_TIME] = lastReadTime;
                }
                return participant.updateAttributes(attributes);
            });
    };

    readConversation = (
        conversationSid: string,
        lastReadTime: number | undefined,
        removeMarkedUnreadAt: boolean,
        markMessagesRead: boolean,
        lastMessageIndex: number | null | undefined,
    ): Promise<Participant | null> => {
        return this!.getConversationBySid(conversationSid).then((conversation) => {
            return conversation
                .getParticipantByIdentity(this.userIdentity!)
                .then((participant: Participant | null) => {
                    if (!participant) {
                        return Promise.reject(new Error('No participant found'));
                    }
                    const attributes = { ...(participant.attributes as JSONObject) };
                    if (removeMarkedUnreadAt) {
                        delete attributes[ATTRIBUTE_MARKED_UNREAD_AT];
                    }
                    if (lastReadTime !== undefined) {
                        attributes[ATTRIBUTE_LAST_READ_TIME] = lastReadTime;
                    }
                    if (markMessagesRead && typeof lastMessageIndex !== undefined) {
                        return conversation
                            .advanceLastReadMessageIndex(lastMessageIndex!)
                            .then(() => participant.updateAttributes(attributes));
                    }
                    return participant.updateAttributes(attributes);
                });
        });
    };

    getConversationUnreadMessagesCount = (conversationSid: string): Promise<number> => {
        return this!.getConversationBySid(conversationSid).then((conversation) => {
            const { lastReadMessageIndex } = conversation;
            const lastMessageIndex = conversation?.lastMessage?.index;

            if (!isNumber(lastMessageIndex)) {
                return 0;
            }

            const isAllMessagesAreUnread = !isNumber(lastReadMessageIndex) && lastMessageIndex >= 0;
            const isAllMessagesAreRead =
                isNumber(lastReadMessageIndex) && lastReadMessageIndex >= lastMessageIndex;

            if (isAllMessagesAreUnread) {
                return conversation.getMessagesCount();
            }
            if (isAllMessagesAreRead) {
                return 0;
            }
            return conversation.getUnreadMessagesCount().then((unreadMessagesCount) => {
                if (isNumber(unreadMessagesCount)) {
                    return unreadMessagesCount;
                }
                // An edge case when previous checks false positively said
                // that unread messages horizon was set.
                return conversation.getMessagesCount();
            });
        });
    };

    updateMessageAttributes = ({
        conversationSid,
        messageIdx,
        attributes,
    }: {
        conversationSid: string;
        messageIdx: number;
        attributes: Attributes;
    }): Promise<Message> => {
        return this.getConversationBySid(conversationSid).then((conversation) => {
            return conversation.getMessages(1, messageIdx).then((message) => {
                return message.items[0].updateAttributes(attributes as JSONObject);
            });
        });
    };

    async getUsersOfConversation(
        conversation: Conversation,
        participants: Participant[],
        twilioMessages: TwilioMessage[],
    ) {
        const participantsSet = new Set(participants.map((participant) => participant.sid));
        const usersSet = new Set<string>();
        participants.forEach((participant) => {
            if (participant.type === ChannelType.Chat && participant.identity) {
                usersSet.add(participant.identity);
            }
        });

        twilioMessages.forEach((twilioMessage) => {
            // Handle removed participants
            if (
                twilioMessage.participantSid &&
                twilioMessage.author &&
                !participantsSet.has(twilioMessage.participantSid)
            ) {
                usersSet.add(twilioMessage.author);
            }
        });
        let systemEventUsers;
        if (twilioMessages.length < MESSAGE_PAGE_SIZE || twilioMessages[0]?.index === 0) {
            systemEventUsers = getIdentitiesFromSystemEvents(conversation, 0, Date.now());
        } else if (twilioMessages.length === MESSAGE_PAGE_SIZE) {
            const oldestMessageTime = twilioMessages[0].dateCreated?.getTime();
            systemEventUsers = getIdentitiesFromSystemEvents(
                conversation,
                oldestMessageTime || 0,
                Date.now(),
            );
        }
        systemEventUsers?.forEach((u) => usersSet.add(u));

        const userPromises = await allSettled(Array.from(usersSet).map((a) => this.getUser(a)));
        return userPromises
            .filter((r) => r.status === 'fulfilled')
            .map((u) => fromTwilioUser((u as FulfilledResult<User>).value));
    }
}
