import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import { Participant } from '@twilio/frontline-shared/models/Participant';
import { isNumber } from '@twilio/frontline-shared/utils/isNumber';
import { CallSystemMsgState } from '@twilio/frontline-shared/models/Event';

import { useChatUser } from '@twilio/frontline-shared/store/ChatSessionState';
import { useConversationsUsers } from '@twilio/frontline-shared/store/users/ConversationsUsersState';
import { TwilIcon } from '@twilio/frontline-shared/components/TwilIcon';
import { DefaultTheme } from '@twilio/frontline-shared/theme';
import { IChatBaseMessage, IChatSystemMessage } from './types';
import { styles as bubbleStyles } from './Bubble';
import { Time } from './Time';
import { useChatContext } from './ChatContext';

export type MessageCallEventProps = {
    message: IChatSystemMessage;
    messagePayload?: Record<string, any>;
    customerParticipant?: Participant;
};

const pluralize = (count: number, unit: string) =>
    `${count} ${unit}${Number(count) !== 1 ? 's' : ''}`;

export const formatDuration = (value = 0) => {
    const hours = Math.floor(value / 3600);
    const hoursString = hours ? `${pluralize(hours, 'hour')} ` : '';

    const minutes = Math.floor(value / 60);
    const minuteString = minutes ? `${pluralize(minutes, 'minute')} ` : '';

    const secondsString = pluralize(Math.floor(value % 60), 'second');

    if (hoursString) {
        return hoursString + minuteString;
    }

    return minuteString + secondsString;
};

export const getIconName = (state: CallSystemMsgState, inbound: boolean) => {
    switch (state) {
        case CallSystemMsgState.COMPLETED:
            return inbound ? 'inboundSysMsg' : 'outboundSysMsg';
        case CallSystemMsgState.MISSED:
        case CallSystemMsgState.NO_ANSWER:
        case CallSystemMsgState.BUSY:
            return 'missedSysMsg';
        case CallSystemMsgState.FAILED:
            return 'failedSysMsg';
        default:
            return '';
    }
};

export const getMessageText = (
    state: CallSystemMsgState,
    inbound: boolean,
    customerName: string,
) => {
    switch (state) {
        case CallSystemMsgState.COMPLETED:
            return `Call ${inbound ? 'from' : 'to'} ${customerName}`;
        // TODO: There is a backend issue which always results in busy instead of missed for inbound calls
        case CallSystemMsgState.MISSED:
        case CallSystemMsgState.NO_ANSWER:
        case CallSystemMsgState.BUSY:
            return inbound
                ? `Missed Call from ${customerName}`
                : `Call to ${customerName} was not answered`;
        case CallSystemMsgState.FAILED:
            return `Call ${inbound ? 'from' : 'to'} ${customerName} failed`;
        default:
            return `Call ${inbound ? 'from' : 'to'} ${customerName}`;
    }
};

export function MessageCallEvent({
    message,
    messagePayload,
    customerParticipant,
}: MessageCallEventProps) {
    const { ParticipantAvatar } = useChatContext();
    const chatUser = useChatUser();
    const conversationUsers = useConversationsUsers();

    if (!messagePayload) {
        return null;
    }

    const { state, inbound, duration, initiatorIdentity } = messagePayload;
    const currentWorkerIdentity = chatUser.identity;

    const position = inbound
        ? 'left'
        : initiatorIdentity === currentWorkerIdentity
        ? 'right'
        : 'left';

    const iconName = getIconName(state, inbound);
    const customerName = customerParticipant?.displayName || 'Customer';
    const text = getMessageText(state, inbound, customerName);
    const durationText =
        isNumber(duration) &&
        (state === CallSystemMsgState.COMPLETED || state === CallSystemMsgState.FAILED)
            ? formatDuration(Number(duration))
            : '';

    // For outbound calls, if the initiatorIdentity is not of the current worker(transfer use case)
    // We will show the otherWorker's name and avatar for system message
    let otherWorkerName = '';
    let otherWorkerAvatar = '';
    if (!inbound && initiatorIdentity !== currentWorkerIdentity) {
        otherWorkerName = conversationUsers[initiatorIdentity]?.friendlyName || initiatorIdentity;
        otherWorkerAvatar = conversationUsers[initiatorIdentity]?.avatar || '';
    }

    // Product directive is to show names for customers and previous workers (before transfer)
    const leftAuthorName = inbound ? customerName : otherWorkerName;

    const contentColor = {
        color:
            position === 'left'
                ? DefaultTheme.textColors.colorText
                : DefaultTheme.textColors.colorTextBrandInverse,
    };

    const renderTime = (_message: IChatBaseMessage) => {
        if (_message.createdAt) {
            return <Time position={position} currentMessage={_message} />;
        }
        return null;
    };

    const renderAvatar = () => {
        if (!ParticipantAvatar) {
            return null;
        }
        const avatar = inbound ? customerParticipant?.avatar : otherWorkerAvatar;
        return (
            <View style={styles.avatar}>
                <ParticipantAvatar avatar={avatar?.toString()} name={leftAuthorName} />
            </View>
        );
    };

    const bubbleContent = (
        <View style={bubbleStyles[position].wrapper}>
            <View>
                <View style={styles.content}>
                    <View style={styles.iconView}>
                        {iconName ? (
                            <TwilIcon name={iconName} size={36} style={contentColor} />
                        ) : null}
                    </View>
                    <View style={{ flexDirection: 'column' }}>
                        <Text style={[contentColor, styles.nameText]} testID="callText">
                            {text}
                        </Text>
                        {durationText ? (
                            <Text style={[contentColor, styles.durationText]} testID="callDuration">
                                {durationText}
                            </Text>
                        ) : null}
                    </View>
                </View>
                <View style={bubbleStyles[position].bottom}>{renderTime(message)}</View>
            </View>
        </View>
    );

    return (
        <View style={[bubbleStyles[position].container, styles.container]}>
            <View style={styles.avatarAndBubble}>
                {leftAuthorName ? renderAvatar() : null}
                <View>
                    {leftAuthorName ? (
                        <Text style={bubbleStyles.content.name}>{leftAuthorName}</Text>
                    ) : null}
                    {bubbleContent}
                </View>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: { margin: DefaultTheme.space.space10 },
    content: {
        flexDirection: 'row',
        marginTop: DefaultTheme.space.space20,
        marginRight: DefaultTheme.space.space30,
        marginBottom: DefaultTheme.space.space0,
        marginLeft: DefaultTheme.space.space10,
    },
    iconView: {
        marginRight: DefaultTheme.space.space10,
    },

    nameText: {
        fontSize: DefaultTheme.fontSizes.fontSize16,
        lineHeight: DefaultTheme.lineHeights.lineHeight10,
    },
    durationText: {
        fontSize: DefaultTheme.fontSizes.fontSize9,
    },
    avatar: { alignItems: 'flex-start', marginRight: DefaultTheme.space.space10 },
    avatarAndBubble: {
        flexDirection: 'row',
    },
});
