import { JWEToken } from './Token';

export const FRONTLINE_TOKEN_KEY = 'frontlineToken';
export const FRONTLINE_REALM_SID_KEY = 'frontlineRealmSid';
export const FRONTLINE_ACCOUNT_UNIQUE_NAME_KEY = 'frontlineAccountUniqueName';
export const FRONTLINE_UPDATE_REQUIRED_VERSION = 'frontlineUpdateRequiredVersion';

export interface ICredentialsStorage {
    getAccountUniqueName(): Promise<string | null>;
    setAccountUniqueName(accountUniqueName: string): Promise<void>;

    getRealmSID(): Promise<string | null>;
    setRealmSID(realmSid: string): Promise<void>;

    getToken(): Promise<JWEToken | null>;
    setToken(token: JWEToken): Promise<void>;

    getUpdateRequiredVersion(): Promise<string | null>;
    setUpdateRequiredVersion(version: string): Promise<void>;

    clear(): Promise<void>;
}
