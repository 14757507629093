import { AccountSid, SsoRealmSid } from '../types';

export type FrontlineConfigurationArgs = {
    accountSid: AccountSid;
    ssoRealmSid: SsoRealmSid;
    availableActivitySid: string;
    unavailableActivitySid: string;
    smsUploadLimit: number;
    whatsappUploadLimit: number;
    facebookUploadLimit: number;
    chatUploadLimit: number;
    voiceCallingConfigEnabled: boolean;
    supportAddressType: SupportAddressType;
    supportAddress: string;
    maskChannelAddressEnabled: boolean;
    updateUntil: number | undefined;
    customerCrudEnabled: boolean;
};

export enum SupportAddressType {
    Email = 'email',
    Url = 'url',
}

export class FrontlineConfiguration {
    public readonly accountSid: AccountSid;

    public readonly ssoRealmSid: SsoRealmSid;

    public readonly availableActivitySid: string;

    public readonly unavailableActivitySid: string;

    public readonly smsUploadLimit: number;

    public readonly whatsappUploadLimit: number;

    public readonly facebookUploadLimit: number;

    public readonly chatUploadLimit: number;

    public readonly voiceCallingConfigEnabled: boolean;

    public readonly supportAddressType: SupportAddressType;

    public readonly supportAddress: string;

    public readonly maskChannelAddressEnabled: boolean;

    public readonly updateUntil: number | undefined;

    public readonly customerCrudEnabled: boolean;

    constructor({
        accountSid,
        ssoRealmSid,
        availableActivitySid,
        unavailableActivitySid,
        smsUploadLimit,
        whatsappUploadLimit,
        facebookUploadLimit,
        chatUploadLimit,
        voiceCallingConfigEnabled,
        supportAddressType,
        supportAddress,
        maskChannelAddressEnabled,
        updateUntil,
        customerCrudEnabled,
    }: FrontlineConfigurationArgs) {
        this.accountSid = accountSid;
        this.ssoRealmSid = ssoRealmSid;
        this.availableActivitySid = availableActivitySid;
        this.unavailableActivitySid = unavailableActivitySid;
        this.smsUploadLimit = smsUploadLimit;
        this.whatsappUploadLimit = whatsappUploadLimit;
        this.facebookUploadLimit = facebookUploadLimit;
        this.chatUploadLimit = chatUploadLimit;
        this.voiceCallingConfigEnabled = voiceCallingConfigEnabled;
        this.supportAddressType = supportAddressType;
        this.supportAddress = supportAddress;
        this.maskChannelAddressEnabled = maskChannelAddressEnabled;
        this.updateUntil = updateUntil;
        this.customerCrudEnabled = customerCrudEnabled;
    }
}

export function buildConfiguration(rawConfig: Record<string, any>) {
    return new FrontlineConfiguration({
        accountSid: rawConfig.account_sid,
        ssoRealmSid: rawConfig.sso_realm_sid,
        availableActivitySid: rawConfig.taskrouter_available_activity_sid,
        unavailableActivitySid: rawConfig.taskrouter_unavailable_activity_sid,
        smsUploadLimit: rawConfig.sms_upload_limit,
        whatsappUploadLimit: rawConfig.whatsapp_upload_limit,
        facebookUploadLimit: rawConfig.facebook_upload_limit,
        chatUploadLimit: rawConfig.chat_upload_limit,
        voiceCallingConfigEnabled: !!rawConfig.voice_calling_enabled,
        supportAddressType: rawConfig.support_address_type,
        supportAddress: rawConfig.support_address,
        maskChannelAddressEnabled: !!rawConfig.mask_channel_addresses_enabled,
        updateUntil: rawConfig.update_until,
        customerCrudEnabled: !!rawConfig.crm_crud_enabled,
    });
}
