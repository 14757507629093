import { Redirect, Route } from 'react-router-dom';

import { useAuth, AuthState } from '../../hooks/useAuth';
import { LoadingScreen } from '../LoadingScreen';
import { PAGES } from '../../paths';

export function PrivateRoute({ children, ...rest }: any) {
    const auth = useAuth();
    return (
        <Route
            {...rest}
            render={({ location }) => {
                switch (auth.authState) {
                    case AuthState.Unknown:
                        return <LoadingScreen />;
                    case AuthState.Authenticated:
                        return children;
                    default:
                        return (
                            <Redirect
                                to={{
                                    pathname: PAGES.LOGIN,
                                    state: { from: location },
                                }}
                            />
                        );
                }
            }}
        />
    );
}
