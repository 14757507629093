import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Theme } from '@twilio-paste/theme';

import { Provider } from '@twilio/frontline-shared/store/redux';
import { FrontlineSDK } from '@twilio/frontline-shared/sdk/FrontlineSDK';
import { createStore } from './store/createStore';
import { App } from './App';
import { SessionProvider } from './context/SessionContext';
import { ToasterProvider } from './context/ToasterContext';
import { ErrorBoundary } from './components/ErrorBoundary';

import './index.css';
import { ModalProvider } from './context/ModalContext';

const store = createStore(undefined, FrontlineSDK);

ReactDOM.render(
    <StrictMode>
        {/* @ts-ignore */}
        <Theme.Provider theme="default" style={{ height: '100%' }}>
            <ErrorBoundary>
                <Provider store={store}>
                    <SessionProvider>
                        <ToasterProvider>
                            <ModalProvider>
                                <App />
                            </ModalProvider>
                        </ToasterProvider>
                    </SessionProvider>
                </Provider>
            </ErrorBoundary>
        </Theme.Provider>
    </StrictMode>,
    document.getElementById('root'),
);
