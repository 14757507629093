import { useState } from 'react';
import { Heading } from '@twilio-paste/heading';
import { Button } from '@twilio-paste/button';
import { Flex } from '@twilio-paste/flex';
import { Box } from '@twilio-paste/box';
import { CloseIcon } from '@twilio-paste/icons/cjs/CloseIcon';
import { ArrowBackIcon } from '@twilio-paste/icons/cjs/ArrowBackIcon';

import { trimSpaces } from '@twilio/frontline-shared/utils/stringUtils';

import { useCustomers, CustomersState } from '../../../hooks/useCustomers';
import { MyCustomersListLoader } from '../../MyCustomers/components/MyCustomersListLoader';
import { MyCustomerListItem } from '../../MyCustomers/components/MyCustomerListItem';
import { LoadingMore } from '../../../components/LoadingMore';
import { CustomerDetails } from '../../CustomerDetails';
import { EmptyCustomersList } from '../../MyCustomers/components/EmptyCustomersList';
import { useDebounce } from '../../../hooks/useDebounce';
import { SearchInput, SEARCH_INPUT_HEIGHT } from '../../MyCustomers/components/SearchInput';
import { NoSearchResults } from '../../MyCustomers/components/NoSearchResults';

const CONVERSATION_HEADER_HEIGHT = 65;

type MyNewConversationProps = {
    onClose: () => void;
    onCreateConversationSucceed: (conversationId: string) => void;
};

export function MyNewConversation({
    onClose,
    onCreateConversationSucceed,
}: MyNewConversationProps) {
    const [selectedCustomerId, setSelectedCustomerId] = useState<string | null>(null);
    const [searchInputValue, setSearchInputValue] = useState<string>('');
    const searchValue = useDebounce(trimSpaces(searchInputValue), 500);
    const { state, customers, hasNext, loaderRef, errorType, onLoadData } =
        useCustomers(searchValue);

    const renderCustomersList = () => {
        if (state === CustomersState.Pending) {
            return <MyCustomersListLoader />;
        }

        if (state === CustomersState.NoCustomers || state === CustomersState.Rejected) {
            return (
                <EmptyCustomersList
                    errorType={errorType}
                    emptyViewOffsetHeight={SEARCH_INPUT_HEIGHT}
                    onReload={onLoadData}
                />
            );
        }

        if (selectedCustomerId) {
            return (
                <CustomerDetails
                    customerId={selectedCustomerId}
                    onCreateConversationCallback={onCreateConversationSucceed}
                />
            );
        }

        if (state === CustomersState.NoSearchResults) {
            return <NoSearchResults searchValue={searchValue} />;
        }

        return (
            <Box height={`calc(100% - ${SEARCH_INPUT_HEIGHT}px)`} overflowY="auto">
                {customers.map((customer, index) => (
                    <MyCustomerListItem
                        // eslint-disable-next-line react/no-array-index-key
                        key={`customer_${index}_${String(customer.customer_id)}`}
                        customer={customer}
                        selectedCustomerId={selectedCustomerId}
                        onSelect={setSelectedCustomerId}
                    />
                ))}

                {(state === CustomersState.Resolved || state === CustomersState.FetchingMore) &&
                    hasNext && <LoadingMore loaderRef={loaderRef} />}
            </Box>
        );
    };

    return (
        <Flex vertical width="100%" height="100%">
            <Box
                display="flex"
                alignItems="center"
                minHeight={CONVERSATION_HEADER_HEIGHT}
                width="100%"
                alignContent="center"
                justifyContent="space-between"
                backgroundColor="colorBackgroundBody"
                paddingX="space50">
                {selectedCustomerId ? (
                    <Button
                        variant="secondary_icon"
                        size="reset"
                        onClick={() => setSelectedCustomerId(null)}>
                        <ArrowBackIcon
                            decorative={false}
                            title="back"
                            size="sizeIcon50"
                            color="colorTextLink"
                        />
                    </Button>
                ) : (
                    <Heading as="h4" variant="heading40" marginBottom="space0">
                        Start a conversation
                    </Heading>
                )}

                <Button variant="secondary_icon" size="reset" onClick={onClose}>
                    <CloseIcon decorative={false} title="close" color="colorTextIcon" />
                </Button>
            </Box>

            <Box width="100%" height={`calc(100% - ${CONVERSATION_HEADER_HEIGHT}px)`}>
                {!selectedCustomerId && (
                    <SearchInput
                        searchInputValue={searchInputValue}
                        disabled={
                            state === CustomersState.Pending ||
                            state === CustomersState.NoCustomers ||
                            state === CustomersState.Rejected
                        }
                        loading={state === CustomersState.Searching}
                        onSearchInputChange={setSearchInputValue}
                        onClear={() => setSearchInputValue('')}
                    />
                )}

                {renderCustomersList()}
            </Box>
        </Flex>
    );
}
