import { ModalBody, ModalFooter, ModalFooterActions } from '@twilio-paste/modal';
import { Heading } from '@twilio-paste/heading';
import { Button } from '@twilio-paste/button';
import { ArrowBackIcon } from '@twilio-paste/icons/cjs/ArrowBackIcon';
import { Box } from '@twilio-paste/box';
import { WorkerUser } from '@twilio/frontline-shared/models/WorkerUser';
import { Conversation } from '@twilio/frontline-shared/models/Conversation';
import { ChannelType } from '@twilio/frontline-shared/types/channel';
import { UserListItem } from '../../UserListItem';
import { ConversationListItem } from '../List/ConversationListItem';
import { MODAL_BOX_HEIGHT } from '../TransferConversation';

type ConfirmTransferModalBodyAndFooterProps = {
    channelFriendlyName: Conversation['friendlyName'];
    channelAvatar: Conversation['avatar'];
    currentUserFriendlyName: WorkerUser['friendly_name'];
    newUser: WorkerUser;
    channelType: ChannelType | undefined;
    onConfirm: () => void;
    onBack: () => void;
};

export function ConfirmTransferModalBodyAndFooter({
    channelFriendlyName,
    channelAvatar,
    currentUserFriendlyName,
    newUser,
    channelType,
    onConfirm,
    onBack,
}: ConfirmTransferModalBodyAndFooterProps) {
    return (
        <>
            <ModalBody>
                <Box height={MODAL_BOX_HEIGHT} width="100%">
                    <Heading as="h3" variant="heading50">
                        Transfer from {currentUserFriendlyName} to
                    </Heading>
                    <Box margin="space50" paddingBottom="space50">
                        <UserListItem user={newUser} link={false} />
                    </Box>
                    <Heading as="h3" variant="heading50">
                        1 conversation
                    </Heading>
                    <Box margin="space50">
                        <ConversationListItem
                            friendlyName={channelFriendlyName}
                            avatar={channelAvatar}
                            channelType={channelType}
                        />
                    </Box>
                </Box>
            </ModalBody>
            <ModalFooter>
                <ModalFooterActions justify="start">
                    <Button data-testid="back-button" variant="secondary" onClick={onBack}>
                        <ArrowBackIcon decorative={false} title="go back" />
                        Back
                    </Button>
                </ModalFooterActions>
                <ModalFooterActions>
                    <Button data-testid="confirm-button" variant="primary" onClick={onConfirm}>
                        Confirm transfer
                    </Button>
                </ModalFooterActions>
            </ModalFooter>
        </>
    );
}
