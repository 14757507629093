import { Box } from '@twilio-paste/box';
import { Card } from '@twilio-paste/card';
import { Text } from '@twilio-paste/text';

export function NoSearchResults({ searchValue }: { searchValue: string }) {
    return (
        <Box paddingX="space50" marginY="space20">
            <Card>
                <Text
                    data-testid="text"
                    as="h4"
                    fontSize="fontSize40"
                    marginY="space60"
                    textAlign="center">{`No results for “${searchValue}”`}</Text>
            </Card>
        </Box>
    );
}
