import { Box } from '@twilio-paste/box';
import { Flex } from '@twilio-paste/flex';

import { WorkerUser } from '@twilio/frontline-shared/models/WorkerUser';

import { NoSearchResultBox } from '../../NoSearchResultBox';
import { NoTeamMembersBox } from '../../NoTeamMembersBox';
import { MAX_WIDTH } from '../../../pages/Users';
import { UsersState, useUsers } from '../../../hooks/useUsers';
import { UsersRadioGroup } from './UsersRadioGroup';
import { MODAL_BOX_HEIGHT } from '../TransferConversation';
import { UsersListFoot } from '../../../pages/Users/components/UsersListFoot';

const SEARCH_BAR_TOP_ADJUSTING_GAP = 48;

type UserListProps = {
    query: string;
    currentUserIdentity: WorkerUser['identity'];
    selectedUser: WorkerUser | undefined;
    onClearSearch: () => void;
    onUserSelect: (user: WorkerUser | undefined) => void;
};

export function SelectableUserList({
    query,
    currentUserIdentity,
    selectedUser,
    onUserSelect,
    onClearSearch,
}: UserListProps) {
    const { users, state, loaderRef, pageMeta, hasNext } = useUsers({
        query,
        ignoredUserIdentity: currentUserIdentity,
    });

    const renderSelectableUsersListContent = () => {
        if (state === UsersState.NoSearchResult) {
            return <NoSearchResultBox query={query} onClearSearch={onClearSearch} />;
        }
        if (state === UsersState.NoUsers) {
            return <NoTeamMembersBox />;
        }

        return (
            <Box data-testid="coworker-list">
                <UsersRadioGroup
                    state={state}
                    users={users}
                    selectedUser={selectedUser}
                    onUserSelect={onUserSelect}
                />
                <UsersListFoot
                    hasNext={hasNext}
                    loaderRef={loaderRef}
                    nextToken={pageMeta?.next_token}
                />
            </Box>
        );
    };

    return (
        <Flex hAlignContent="center">
            <Box
                position="relative"
                maxWidth={MAX_WIDTH}
                height={MODAL_BOX_HEIGHT - SEARCH_BAR_TOP_ADJUSTING_GAP}
                width="100%"
                marginTop="spaceNegative20"
                marginLeft="space40"
                marginRight="space40"
                paddingBottom="space200">
                {renderSelectableUsersListContent()}
            </Box>
        </Flex>
    );
}
