import React, { useState } from 'react';
import { AlertButton, AlertOptions } from 'react-native';

import { Button } from '@twilio-paste/button';
import { Paragraph } from '@twilio-paste/paragraph';
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalFooterActions,
    ModalHeader,
    ModalHeading,
} from '@twilio-paste/modal';

import { useUID } from '@twilio-paste/uid-library';

type AlertProps = {
    title: string;
    message?: string;
    buttons?: AlertButton[];
    options?: AlertOptions;
};

export const useAlert = () => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [props, setProps] = useState<AlertProps>();
    const showAlert = (p: AlertProps) => {
        setProps(p);
        setIsOpen(true);
    };

    const close = () => {
        setIsOpen(false);
        props?.options?.onDismiss?.();
    };

    return { showAlert, alertProps: props, isOpen, close };
};

export function Alert({
    alertProps,
    isOpen,
    close,
}: Omit<ReturnType<typeof useAlert>, 'showAlert'>) {
    const modalHeadingID = useUID();
    const destructiveButtons = alertProps?.buttons
        ? alertProps.buttons.filter((button) => button.style === 'destructive')
        : [];
    const nonDestructiveButtons = alertProps?.buttons
        ? alertProps.buttons.filter((button) => button.style !== 'destructive')
        : [];

    const getButtonVariant = (style: string | undefined) => {
        switch (style) {
            case 'destructive':
                return 'destructive_secondary';
            case 'cancel':
                return 'secondary';
            default:
                return 'primary';
        }
    };

    const renderButtons = (buttons: AlertButton[]) => {
        return buttons.map((button, index) => {
            return (
                <Button
                    key={index}
                    variant={getButtonVariant(button.style)}
                    onClick={() => {
                        button.onPress?.();
                        close();
                    }}>
                    {button.text}
                </Button>
            );
        });
    };

    return (
        <Modal ariaLabelledby={modalHeadingID} isOpen={isOpen} onDismiss={close} size="default">
            <ModalHeader>
                <ModalHeading as="h3" id={modalHeadingID}>
                    {alertProps?.title || ''}
                </ModalHeading>
            </ModalHeader>
            <ModalBody>
                <Paragraph marginBottom="space0">{alertProps?.message}</Paragraph>
            </ModalBody>
            <ModalFooter>
                <ModalFooterActions justify="start">
                    {renderButtons(destructiveButtons)}
                </ModalFooterActions>
                <ModalFooterActions>
                    {alertProps?.buttons ? (
                        renderButtons(nonDestructiveButtons)
                    ) : (
                        <Button variant="primary" onClick={close}>
                            Ok
                        </Button>
                    )}
                </ModalFooterActions>
            </ModalFooter>
        </Modal>
    );
}
