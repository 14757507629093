import { Box } from '@twilio-paste/box';
import { Flex } from '@twilio-paste/flex';
import { Text } from '@twilio-paste/text';
import { getChannelName } from '@twilio/frontline-shared/models/Participant';
import {
    ConversationInfo,
    ISimpleConversation,
} from '@twilio/frontline-shared/store/conversations/SimpleConversation';
import { SkeletonLoader } from '@twilio-paste/skeleton-loader';
import {
    getUnreadMessagesCount,
    isLastMessageSystemEvent,
} from '@twilio/frontline-shared/models/Conversation';
import { formatDateToNow } from '@twilio/frontline-shared/utils/date';
import { ConversationTileMessage } from './ConversationTileMessage';
import { Avatar } from './Avatar';
import { Badge } from './Badge';
import { ConversationViewVariant } from './Conversation/types';
import { is24Hour } from '../utils/date';

type ConversationTileProps = {
    conversation: ISimpleConversation;
    info?: ConversationInfo;
    selectedConversationId: string | null;
    variant: ConversationViewVariant;
};

export function ConversationTile({
    conversation,
    info,
    selectedConversationId,
    variant,
}: ConversationTileProps) {
    const unreadMessagesCount = getUnreadMessagesCount(conversation);
    const isMarkedUnread = conversation.markedUnreadAt
        ? conversation.markedUnreadAt > conversation.lastMessageTime
        : false;
    const hasAnyUnread = unreadMessagesCount > 0 || isMarkedUnread;

    return (
        <Box
            data-testid="conversation-tile"
            display="flex"
            boxSizing="content-box"
            _hover={
                selectedConversationId === conversation.sid
                    ? {
                          backgroundColor: 'colorBackground',
                          borderColor: 'colorBorderInverseStronger',
                      }
                    : {
                          backgroundColor: 'colorBackground',
                          borderColor: 'colorBorderWeak',
                      }
            }
            borderTopWidth="borderWidth10"
            borderBottomWidth="borderWidth10"
            borderLeftWidth="borderWidth0"
            borderRightWidth="borderWidth0"
            borderStyle="solid"
            _active={{
                backgroundColor:
                    selectedConversationId === conversation.sid
                        ? 'colorBackground'
                        : 'colorBackgroundStrong',
            }}
            backgroundColor={
                selectedConversationId === conversation.sid
                    ? 'colorBackground'
                    : 'colorBackgroundBody'
            }
            borderColor={
                selectedConversationId === conversation.sid
                    ? 'colorBorderInverseStronger'
                    : 'colorBorderInverseStrongest'
            }
            alignItems="center"
            paddingX="space40"
            paddingY="space30">
            <Box
                paddingX="space30"
                display="flex"
                alignItems="center"
                data-testid="conversation-box">
                {variant === ConversationViewVariant.USER && hasAnyUnread && <Badge mini />}
                <Box position="relative">
                    <Avatar
                        size="sizeIcon70"
                        friendlyName={conversation.friendlyName}
                        avatar={conversation.avatar}
                    />
                    {variant === ConversationViewVariant.USER &&
                        unreadMessagesCount > 0 &&
                        !isMarkedUnread && <Badge value={unreadMessagesCount} />}
                </Box>
            </Box>
            <Flex vertical grow={1}>
                <Flex hAlignContent="between" width="100%" paddingY="space10">
                    <Text
                        data-testid="conversation-box-friendly-name"
                        as="span"
                        fontWeight={hasAnyUnread ? 'fontWeightBold' : 'fontWeightNormal'}
                        whiteSpace="nowrap"
                        style={{
                            textOverflow: 'ellipsis',
                        }}
                        overflow="hidden">
                        {conversation.friendlyName}
                    </Text>
                    <Text
                        color={hasAnyUnread ? 'colorText' : 'colorTextWeak'}
                        data-testid="conversation-box-timestamp"
                        as="span"
                        paddingLeft="space30"
                        whiteSpace="nowrap">
                        {conversation.lastMessageTime &&
                            formatDateToNow(
                                conversation.lastMessageTime,
                                is24Hour(),
                                (date: Date) => date.toLocaleDateString(window.navigator.language),
                            )}
                    </Text>
                </Flex>
                <Flex hAlignContent="between" width="100%">
                    <ConversationTileMessage
                        variant={variant}
                        lastSystemMessageText={conversation.lastSystemEventText}
                        lastMessage={
                            isLastMessageSystemEvent(conversation)
                                ? conversation.lastSystemEvent
                                : info?.lastMessage
                        }
                        lastChatReadTime={info?.lastChatReadTime}
                        media={info?.lastMessageMedia}
                        unreadMessagesCount={unreadMessagesCount}
                        draftMessage={info?.draft}
                    />
                    <Text
                        paddingLeft="space30"
                        color={hasAnyUnread ? 'colorText' : 'colorTextWeak'}
                        data-testid="conversation-box-channel"
                        fontWeight="fontWeightNormal"
                        as="span">
                        {info?.channelType && getChannelName(info?.channelType)}
                    </Text>
                </Flex>
            </Flex>
        </Box>
    );
}

export function ConversationTileLoading() {
    return (
        <Flex vAlignContent="center" paddingX="space40" paddingY="space30">
            <Box paddingX="space30">
                <SkeletonLoader size="sizeIcon70" borderRadius="borderRadiusCircle" />
            </Box>
            <Flex vertical grow={1}>
                <SkeletonLoader width={158} height={20} borderRadius="borderRadius20" />
                <Box marginTop="space20">
                    <SkeletonLoader width={117} height={20} borderRadius="borderRadius20" />
                </Box>
            </Flex>
        </Flex>
    );
}
