import { IMessage, LocalMessage, MessageStatus } from '@twilio/frontline-shared/models/Message';
import { IDraftMedia } from '@twilio/frontline-shared/models/Media';
import {
    Attributes,
    ConversationSid,
    MessageSid,
    TwilioMessage,
} from '@twilio/frontline-shared/types';
import { createAction } from '../Action';

export const addMessages =
    createAction('CHAT_ADD_MESSAGES')<{
        channelSid: ConversationSid;
        messages: IMessage[];
    }>();

export type AddMessages = ReturnType<typeof addMessages>;

export const newMessage =
    createAction('CHAT_NEW_MESSAGE')<{
        channelSid: ConversationSid;
        message: IMessage;
    }>();

export type NewMessage = ReturnType<typeof newMessage>;

export const sendMessage =
    createAction('CHAT_SEND_MESSAGE')<{
        channelSid: ConversationSid;
        body: string;
        attributes?: Attributes;
    }>();

export type SendMessage = ReturnType<typeof sendMessage>;

export const sendMediaMessage =
    createAction('CHAT_SEND_MEDIA_MESSAGE')<{
        channelSid: ConversationSid;
        media: IDraftMedia;
    }>();

export type SendMediaMessage = ReturnType<typeof sendMediaMessage>;

export const sendMessageRequest = createAction('CHAT_SEND_MESSAGE_REQUEST')<{
    channelSid: ConversationSid;
    message: LocalMessage;
}>();

export type SendMessageRequest = ReturnType<typeof sendMessageRequest>;

export const sendMessageSuccess = createAction('CHAT_SEND_MESSAGE_SUCCESS')<{
    channelSid: ConversationSid;
    message: IMessage;
}>();

export type SendMessageSuccess = ReturnType<typeof sendMessageSuccess>;

export const sendMessageFailure = createAction('CHAT_SEND_MESSAGE_FAILURE')<{
    channelSid: ConversationSid;
    id: string;
}>();

export type SendMessageFailure = ReturnType<typeof sendMessageFailure>;

export const retrySendMessage =
    createAction('CHAT_RETRY_SEND_MESSAGE')<{
        channelSid: ConversationSid;
        messageSid: MessageSid;
    }>();

export type RetrySendMessage = ReturnType<typeof retrySendMessage>;

export const hideMessage =
    createAction('CHAT_HIDE_MESSAGE')<{
        channelSid: ConversationSid;
        messageSid: MessageSid;
    }>();

export type HideMessage = ReturnType<typeof hideMessage>;

export const removeMessage =
    createAction('CHAT_REMOVE_MESSAGE')<{
        channelSid: ConversationSid;
        messageSid: MessageSid;
    }>();

export type RemoveMessage = ReturnType<typeof removeMessage>;

export const updateMessageStatus = createAction('CHAT_UPDATE_MESSAGE_STATUS')<{
    channelSid: ConversationSid;
    messageSid: MessageSid;
    status: MessageStatus;
}>();

export type UpdateMessageStatus = ReturnType<typeof updateMessageStatus>;

export const fetchDeliveryReceipt = createAction('CHAT_FETCH_MESSAGE_RECEIPT')<{
    message: TwilioMessage;
    isLastMessage?: boolean;
}>();

export type FetchDeliveryReceipt = ReturnType<typeof fetchDeliveryReceipt>;

export const setMessageError =
    createAction('CHAT_SET_MESSAGE_ERROR')<{
        channelSid: ConversationSid;
        messageSid: MessageSid;
        errorCode?: number;
        status: MessageStatus;
    }>();

export type SetMessageError = ReturnType<typeof setMessageError>;

export type ChatMessagesActions =
    | AddMessages
    | NewMessage
    | SendMessage
    | SendMessageRequest
    | SendMessageSuccess
    | SendMessageFailure
    | RetrySendMessage
    | HideMessage
    | RemoveMessage
    | UpdateMessageStatus
    | SetMessageError;
