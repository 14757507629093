import { Box } from '@twilio-paste/box';
import { Text } from '@twilio-paste/text';

type BadgeProps = {
    value?: number | string;
    mini?: boolean;
    backgroundColor?: 'colorBackgroundPrimaryStronger'; // list of supported tokens
};

export function Badge({ value, mini, backgroundColor }: BadgeProps) {
    if (mini) {
        return (
            <Box
                data-testid="badge-dot"
                marginLeft="spaceNegative40"
                marginRight="space20"
                width="sizeSquare30"
                height="sizeSquare30"
                borderRadius="borderRadius20"
                backgroundColor={backgroundColor || 'colorBackgroundPrimaryStronger'}
            />
        );
    }
    return (
        <Box
            marginRight="spaceNegative20"
            marginBottom="spaceNegative20"
            display="flex"
            position="absolute"
            right={0}
            bottom={0}
            alignItems="center"
            alignSelf="center"
            justifyContent="center"
            backgroundColor="colorBackgroundPrimaryStronger"
            height="sizeSquare50"
            borderRadius="borderRadius30"
            minWidth="sizeSquare50">
            <Text
                marginX="space20"
                as="span"
                data-testid="conversation-box-badge-number"
                color="colorTextWeakest"
                fontSize="fontSize10"
                fontWeight="fontWeightBold"
                lineHeight="lineHeight10">
                {value}
            </Text>
        </Box>
    );
}
