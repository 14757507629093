import { isNumber } from '../utils/isNumber';
import { IUser } from './User';

type SystemEventBase = {
    sid: string;
    date: number;
    type: FrontlineEventType;
};

type SystemEventTransfer = SystemEventBase & {
    type: FrontlineEventType.Transfer;
    by?: string;
    from: string;
    to: string;
};

type SystemEventMessage = SystemEventBase & {
    type: FrontlineEventType.Message;
    text: string;
};

export enum CallSystemMsgState {
    COMPLETED = 'completed',
    MISSED = 'missed',
    FAILED = 'failed',
    BUSY = 'busy',
    NO_ANSWER = 'no-answer',
}

export type CallEventEndedPayload = {
    state: CallSystemMsgState;
    inbound: boolean;
    duration?: number;
    initiatorParticipantSid?: string;
    initiatorIdentity?: string;
};

export type SystemEventCallEnded = SystemEventBase &
    CallEventEndedPayload & {
        type: FrontlineEventType.CallEnded;
    };

// Important: Do not forget to update checkSystemEvent function if you add a new type!
// Important: If your new event has identities inside, update getIdentitiesFromSystemEvents function
export type SystemEvent = SystemEventTransfer | SystemEventMessage | SystemEventCallEnded;

export type SystemEventCheckType = 'valid' | 'invalid' | 'unsupported';

export enum FrontlineEventType {
    Transfer = 'transfer',
    Message = 'message',
    CallEnded = 'call_ended',
}

export const UNSUPPORTED_MESSAGE_TEXT =
    'You received a message that cannot be displayed on this version of the app. Tap to upgrade.';

export const isSystemEvent = (maybeEvent: unknown): maybeEvent is SystemEvent => {
    const result = checkSystemEvent(maybeEvent);
    return result === 'valid' || result === 'unsupported';
};

export const checkSystemEvent = (maybeEvent: unknown): SystemEventCheckType => {
    const event = maybeEvent as SystemEvent;
    if (event.sid && event.date && event.type) {
        if (!isNumber(event.date)) {
            return 'invalid';
        }
        if (event.type === FrontlineEventType.Transfer) {
            if (event.from && event.to) {
                return 'valid';
            }
        } else if (event.type === FrontlineEventType.Message) {
            if (event.text) {
                return 'valid';
            }
        } else if (event.type === FrontlineEventType.CallEnded) {
            if (event.state && typeof event.inbound !== 'undefined') {
                return 'valid';
            }
        }
        return 'unsupported';
    }
    return 'invalid';
};

const getSystemMessageByTemplate = (from: string, to: string, by?: string) => {
    return by
        ? `${by} transferred from ${from} to ${to}.`
        : `${from} transferred to ${to} and left the conversation.`;
};

const getUserFriendlyName = (identity: string, users: { [key: string]: IUser }) =>
    users[identity]?.friendlyName || identity;

export const getSystemMessageText = (
    event: SystemEvent,
    users: { [key: string]: IUser },
): string => {
    switch (event.type) {
        case FrontlineEventType.Transfer: {
            const by = event.by ? getUserFriendlyName(event.by, users) : undefined;
            const from = getUserFriendlyName(event.from, users);
            const to = getUserFriendlyName(event.to, users);
            const systemMessageByTemplate = getSystemMessageByTemplate(from, to, by);
            return systemMessageByTemplate;
        }
        case FrontlineEventType.Message: {
            return event.text;
        }
        default:
            return UNSUPPORTED_MESSAGE_TEXT;
    }
};

export const isUnsupportedSystemMessage = (event: SystemEvent) => {
    return checkSystemEvent(event) === 'unsupported';
};

export const getUnreadCallMsgText = (state: CallSystemMsgState, inbound: boolean) => {
    switch (state) {
        case CallSystemMsgState.MISSED:
        case CallSystemMsgState.BUSY:
            return inbound ? 'Missed Call' : 'Outgoing Call was busy';
        case CallSystemMsgState.NO_ANSWER:
            return 'Call was not answered';
        case CallSystemMsgState.FAILED:
            return 'Failed Call';
        default:
            return inbound ? 'Incoming Call' : 'Outgoing Call';
    }
};
