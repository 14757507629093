import { ModalBody } from '@twilio-paste/modal';
import { Button } from '@twilio-paste/button';
import { Flex } from '@twilio-paste/flex';
import { Heading } from '@twilio-paste/heading';
import { Paragraph } from '@twilio-paste/paragraph';

export function TemplatesModalError({ onRetry }: { onRetry: () => void }) {
    return (
        <ModalBody>
            <Flex vertical hAlignContent="center" vAlignContent="center">
                <Heading as="h4" variant="heading40" data-testId="templates-failed-to-fetch-header">
                    Failed to fetch templates
                </Heading>
                <Paragraph data-testId="templates-failed-to-fetch-text">
                    Please contact your support team or try again later.
                </Paragraph>
                <Button
                    data-testId="templates-failed-to-fetch-retry-button"
                    variant="primary"
                    autoFocus
                    onClick={onRetry}>
                    Retry
                </Button>
            </Flex>
        </ModalBody>
    );
}
