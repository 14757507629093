import { call, cancelled, put, race, select, take } from '@redux-saga/core/effects';

import {
    getIdentitiesFromSystemEvents,
    Conversation,
} from '@twilio/frontline-shared/models/Conversation';
import { TwilioMessage, Paginator } from '@twilio/frontline-shared/types';
import {
    ConversationMessage,
    fromTwilioMessages,
    isHiddenMessage,
    isLocalMessage,
    isMediaMessage,
    isUnsuccessfulMessage,
} from '@twilio/frontline-shared/models/Message';
import { isNumber } from '@twilio/frontline-shared/utils/isNumber';
import { Participant } from '@twilio/frontline-shared/models/Participant';
import { EventChannel } from 'redux-saga';
import { Action } from 'redux';
import {
    LoadPrevMessages,
    MarkConversationMessagesAsRead,
    saveUnreadMessagesCount,
    FetchUnreadMessagesCount,
    updateAppIconBadge,
    WatchConversationEvents,
    removeConversation,
    RemoveConversation,
    AddConversation,
    addConversation,
    setIsAllPreviousMessagesFetched,
    saveUnreadSystemMessagesCount,
    MarkSystemMessagesAsRead,
    MarkConversationUnread,
    setUnreadSystemMessageCount,
    markMessagesAsRead,
    ReadConversation,
    saveMarkedUnreadAt,
} from './actions';
import { selectConversation } from './selectors';
import { addMessages, fetchDeliveryReceipt } from '../messages/actions';
import { newMedia } from '../media/actions';
import { selectChannelMessages } from '../messages/ChatMessagesState';
import { createConversationEventsSagaChannel } from './ConversationEventsListener';
import { logout } from '../ui/actions';
import { selectConversationParticipants, selectUserParticipant } from '../ParticipantsState';
import { addConversationsUsers } from '../users/actions';
import { ConversationsUsersState, selectUsers } from '../users/ConversationsUsersState';
import { FrontlineSDK } from '../../sdk/FrontlineSDK';
import { Analytics } from '../../analytics';

export function* fetchChannelUnreadMessagesCountSaga(
    frontlineSDK: typeof FrontlineSDK,
    action: FetchUnreadMessagesCount,
) {
    const { conversationSid } = action.payload;
    const conversation: Conversation | undefined = yield select(
        selectConversation,
        conversationSid,
    );

    if (!conversation) {
        return;
    }

    const messages: ConversationMessage[] = yield select(selectChannelMessages, conversationSid);
    const mostRecentMessage: ConversationMessage | undefined = messages[messages.length - 1];

    const mostRecentMessageIndex = mostRecentMessage?.index;
    const { lastConsumedMessageIndex } = conversation;

    let unreadMessagesCount: number;
    const allChannelMessagesAreRead =
        isNumber(mostRecentMessageIndex) &&
        isNumber(lastConsumedMessageIndex) &&
        lastConsumedMessageIndex >= mostRecentMessageIndex;
    if (allChannelMessagesAreRead) {
        unreadMessagesCount = 0;
    } else {
        unreadMessagesCount = yield call(
            frontlineSDK.shared!.getConversationUnreadMessagesCount,
            conversationSid,
        );
    }

    yield put(saveUnreadMessagesCount({ conversationSid, count: unreadMessagesCount }));
    yield put(updateAppIconBadge());
}

export function* markSystemMessagesAsReadSaga(
    frontlineSDK: typeof FrontlineSDK,
    action: MarkSystemMessagesAsRead,
) {
    const { conversationSid } = action.payload;
    const conversation: Conversation | undefined = yield select(
        selectConversation,
        conversationSid,
    );
    if (!conversation) {
        return;
    }
    if (conversation.lastSystemEvent) {
        const participant: Participant = yield select(selectUserParticipant, conversationSid);
        const lastSystemMessageTime = conversation.lastSystemEvent.date;
        if (lastSystemMessageTime && lastSystemMessageTime !== participant.lastReadTime) {
            yield put(saveUnreadSystemMessagesCount({ conversationSid, count: 0 }));
            yield call(
                frontlineSDK.shared!.setParticipantLastReadTime,
                conversationSid,
                lastSystemMessageTime,
            );
        }
    }
    yield put(updateAppIconBadge());
}

export function* markConversationUnreadSaga(
    frontlineSDK: typeof FrontlineSDK,
    action: MarkConversationUnread,
) {
    const { conversationSid } = action.payload;
    const markedUnreadAt = Date.now();
    const conversation: Conversation | undefined = yield select(
        selectConversation,
        conversationSid,
    );
    if (!conversation) {
        return;
    }
    yield put(
        saveMarkedUnreadAt({
            conversationSid,
            markedUnreadAt,
        }),
    );
    try {
        yield call(
            frontlineSDK.shared!.setParticipantMarkUnread,
            conversationSid,
            markedUnreadAt,
            conversation.lastMessageTime,
        );
    } catch (err: any) {
        Analytics.logError('SET_PARTICIPANT_MARK_UNREAD_FAILED', err);
    }
    yield put(updateAppIconBadge());
    if (isNumber(conversation.lastConsumedMessageIndex)) {
        try {
            yield call(
                frontlineSDK.shared!.updateLastReadMessageIndex,
                conversationSid,
                conversation.lastConsumedMessageIndex
                    ? conversation.lastConsumedMessageIndex - 1
                    : null,
            );
        } catch (err: any) {
            Analytics.logError('MARK_MESSAGES_AS_UNREAD_FAILED', err);
        }
    }
}

export function* readConversationSaga(frontlineSDK: typeof FrontlineSDK, action: ReadConversation) {
    const { conversationSid } = action.payload;
    const conversation: Conversation | undefined = yield select(
        selectConversation,
        conversationSid,
    );

    // Messages
    const allChannelMessages: ConversationMessage[] = yield select(
        selectChannelMessages,
        conversationSid,
    );

    const persistedChannelMessages = allChannelMessages.filter(
        (message) => !isLocalMessage(message),
    );

    let shouldMarkMessagesAsRead = false;
    let mostRecentMessage: ConversationMessage | undefined;
    if (persistedChannelMessages.length > 0) {
        mostRecentMessage = persistedChannelMessages[persistedChannelMessages.length - 1];
        if ((mostRecentMessage?.index ?? -1) > (conversation?.lastConsumedMessageIndex ?? -1)) {
            shouldMarkMessagesAsRead = true;
            yield put(saveUnreadMessagesCount({ conversationSid, count: 0 }));
        }
    }

    // System Messages
    let shouldUpdateSystemMessageReadTime = false;
    let lastSystemMessageTime = 0;
    if (conversation?.lastSystemEvent) {
        const participant: Participant = yield select(selectUserParticipant, conversationSid);
        lastSystemMessageTime = conversation.lastSystemEvent.date;
        if (lastSystemMessageTime && lastSystemMessageTime !== participant.lastReadTime) {
            yield put(saveUnreadSystemMessagesCount({ conversationSid, count: 0 }));
            shouldUpdateSystemMessageReadTime = true;
        }
    }

    // Marked Unread
    let shouldRemoveMarkUnread = false;
    if (conversation && conversation.markedUnreadAt) {
        shouldRemoveMarkUnread = true;
        yield put(
            saveMarkedUnreadAt({
                conversationSid,
                markedUnreadAt: undefined,
            }),
        );
    }

    // Update part
    const nothingToUpdate =
        !shouldMarkMessagesAsRead && !shouldRemoveMarkUnread && !shouldUpdateSystemMessageReadTime;

    if (nothingToUpdate) {
        return;
    }

    if (shouldMarkMessagesAsRead && !shouldRemoveMarkUnread && !shouldUpdateSystemMessageReadTime) {
        yield put(
            markMessagesAsRead({
                conversationSid,
                messageIndex: mostRecentMessage!.index!,
            }),
        );
        return;
    }

    try {
        yield call(
            frontlineSDK.shared!.readConversation,
            conversationSid,
            lastSystemMessageTime,
            shouldRemoveMarkUnread,
            shouldMarkMessagesAsRead,
            mostRecentMessage?.index,
        );
    } catch (err: any) {
        Analytics.logError('READ_CONVERSATION_FAILED', err);
    }
    yield put(updateAppIconBadge());
}

export function* readChannelMessagesSaga(
    frontlineSDK: typeof FrontlineSDK,
    action: MarkConversationMessagesAsRead,
) {
    const { conversationSid, messageIndex } = action.payload;
    const conversation: Conversation | undefined = yield select(
        selectConversation,
        conversationSid,
    );

    if (!conversation || !isNumber(messageIndex)) {
        return;
    }

    if (
        isNumber(conversation.lastConsumedMessageIndex) &&
        conversation.lastConsumedMessageIndex >= messageIndex
    ) {
        // Message is already read
        return;
    }

    yield put(
        saveUnreadMessagesCount({
            conversationSid,
            count: 0,
            lastConsumedMessageIndex: messageIndex,
        }),
    );

    yield put(updateAppIconBadge());
    try {
        yield call(frontlineSDK.shared!.markMessagesAsRead, conversationSid, messageIndex);
    } catch (err: any) {
        Analytics.logError('MARK_MESSAGES_AS_READ_FAILED', err);
    }
}

export function* loadPrevMessagesSaga(frontlineSDK: typeof FrontlineSDK, action: LoadPrevMessages) {
    const { messageIndex, conversationSid, messageCreatedAt } = action.payload;
    const conversation: Conversation | undefined = yield select(
        selectConversation,
        conversationSid,
    );
    const participants: Participant[] | undefined = yield select(
        selectConversationParticipants,
        conversationSid,
    );
    const participantSet = new Set(participants?.map((participant) => participant.sid));

    if (!conversation) {
        return;
    }

    const pageSize = 30;
    const direction = 'backwards';
    const messagesPage: Paginator<TwilioMessage> = yield call(
        frontlineSDK.shared!.getMessages,
        conversationSid,
        pageSize,
        messageIndex,
        direction,
    );

    const twilioMessages = messagesPage.items.filter((message) => !isHiddenMessage(message));
    const messages = fromTwilioMessages(twilioMessages);
    yield put(addMessages({ channelSid: conversationSid, messages }));

    const usersSet = new Set<string>();

    for (const [index, message] of messages.entries()) {
        const twilioMessage = twilioMessages[index];
        // Handle removed user participants
        if (
            twilioMessage.participantSid &&
            twilioMessage.author &&
            !participantSet.has(twilioMessage.participantSid)
        ) {
            usersSet.add(twilioMessage.author);
        }
        if (isMediaMessage(message)) {
            yield put(
                newMedia({
                    media: twilioMessage.attachedMedia![0],
                    message,
                }),
            );
        }

        if (isUnsuccessfulMessage(message)) {
            yield put(fetchDeliveryReceipt({ message: twilioMessage }));
        }
    }
    const isAllPreviousMessagesFetched = messagesPage.items.length < pageSize;

    let systemEventUsers;
    if (isAllPreviousMessagesFetched) {
        systemEventUsers = getIdentitiesFromSystemEvents(
            conversation,
            0,
            messageCreatedAt.getTime(),
        );
    } else {
        systemEventUsers = getIdentitiesFromSystemEvents(
            conversation,
            messages[0]?.createdAt.getTime() || 0,
            messageCreatedAt.getTime(),
        );
    }

    if (systemEventUsers.size > 0) {
        const users: ConversationsUsersState = yield select(selectUsers);
        systemEventUsers.forEach((u) => {
            if (!users[u]) {
                usersSet.add(u);
            }
        });
    }
    if (usersSet.size > 0) {
        yield put(addConversationsUsers({ identities: Array.from(usersSet) }));
    }

    if (isAllPreviousMessagesFetched) {
        yield put(setIsAllPreviousMessagesFetched({ conversationSid, fetched: true }));
    }
}

function* listenConversationEvents(action: WatchConversationEvents) {
    const { conversation } = action.payload;
    const conversationEventsSagaChannel: EventChannel<Action> = yield call(
        createConversationEventsSagaChannel,
        conversation,
    );
    try {
        let conversationInitialized: Conversation | boolean | undefined = yield select(
            selectConversation,
            conversation?.sid,
        );
        while (true) {
            if (!conversationInitialized) {
                const addConversationAction: AddConversation = yield take(addConversation.type);
                if (addConversationAction.payload.conversation.sid === conversation.sid) {
                    conversationInitialized = true;
                }
            } else {
                const _action: Action = yield take(conversationEventsSagaChannel);
                yield put(_action);
            }
        }
    } finally {
        const isCancelled: boolean = yield cancelled();
        if (isCancelled) {
            conversationEventsSagaChannel.close();
        }
    }
}

function* listenRemoveConversation(action: WatchConversationEvents) {
    while (true) {
        const removeConversationAction: RemoveConversation = yield take(removeConversation.type);
        if (removeConversationAction.payload.conversationSid === action.payload.conversation.sid) {
            break;
        }
    }
}

export function* watchConversationEventsSaga(action: WatchConversationEvents) {
    yield race({
        listener: call(listenConversationEvents, action),
        removeListener: call(listenRemoveConversation, action),
        logoutListener: take(logout.type),
    });
}
