import { useState, useEffect } from 'react';
import { LayoutAnimation, Platform } from 'react-native';

import { SystemEvent } from '@twilio/frontline-shared/models/Event';
import {
    ConversationMessage,
    IMessage,
    isLocalMessage,
} from '@twilio/frontline-shared/models/Message';

export function useConsumptionHorizon(
    channelSid: string | undefined,
    unreadMessagesCount: number,
    lastConsumedMessageTime: number,
    messages: Array<ConversationMessage>,
    identity: string,
    events: SystemEvent[],
) {
    const [consumptionHorizon, setConsumptionHorizon] = useState(lastConsumedMessageTime);
    const [newMessagesCount, setNewMessagesCount] = useState<number | undefined>();

    const isMessageAfter =
        (index: number) =>
        (message: ConversationMessage): message is IMessage => {
            return !isLocalMessage(message) && message.createdAt.getTime() > index;
        };

    useEffect(() => {
        if (newMessagesCount === 0) {
            return;
        }
        const unread = messages.filter<IMessage>(isMessageAfter(consumptionHorizon));
        const unreadSystem = events.filter((e) => e.date > consumptionHorizon);

        if (unread.find((m) => m.author === identity)) {
            if (Platform.OS === 'ios') {
                LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
            }
            setNewMessagesCount(0);
            setConsumptionHorizon(unread[unread.length - 1].createdAt.getTime());
        } else {
            setNewMessagesCount(unreadSystem.length + unread.length);
        }
    }, [channelSid, unreadMessagesCount, messages, events]);

    return [consumptionHorizon, newMessagesCount];
}
