import { Text } from '@twilio-paste/text';
import { Box } from '@twilio-paste/box';
import { Flex } from '@twilio-paste/flex';
import { ChevronDownIcon } from '@twilio-paste/icons/cjs/ChevronDownIcon';
import { Spinner } from '@twilio-paste/spinner';
import { ConnectivityAvailableIcon } from '@twilio-paste/icons/cjs/ConnectivityAvailableIcon';
import { ConnectivityOfflineIcon } from '@twilio-paste/icons/cjs/ConnectivityOfflineIcon';

type UserInfoBoxProps = {
    userName: string;
    isAvailable: boolean;
    showAvailabilityLoading: boolean;
    showAvailability: boolean;
};

export function UserInfoBox({
    userName,
    isAvailable,
    showAvailabilityLoading,
    showAvailability,
}: UserInfoBoxProps) {
    const renderAvailabilityBox = () => {
        if (showAvailability) {
            return (
                <Flex vAlignContent="center" data-testid="availability-group">
                    {isAvailable ? (
                        <>
                            <ConnectivityAvailableIcon
                                color="colorTextIconAvailable"
                                decorative={false}
                                title="available"
                            />
                            <Text
                                as="span"
                                color="colorTextInverse"
                                marginRight="space30"
                                data-testid="status-availability">
                                Available
                            </Text>
                        </>
                    ) : (
                        <>
                            <ConnectivityOfflineIcon
                                color="colorTextIconOffline"
                                data-test-id="unavailableCircle"
                                decorative={false}
                                title="offline"
                            />
                            <Text
                                as="span"
                                color="colorTextInverse"
                                marginRight="space30"
                                data-testid="status-availability">
                                Unavailable
                            </Text>
                        </>
                    )}

                    {showAvailabilityLoading && (
                        <Spinner
                            color="colorTextBrandInverse"
                            size="sizeIcon10"
                            decorative={false}
                            title="Loading"
                        />
                    )}
                </Flex>
            );
        }
        return null;
    };

    return (
        <Box display="flex" flexDirection="column">
            <Flex data-testid="settings">
                <Text
                    as="span"
                    color="colorTextInverse"
                    data-testid="header-user-name"
                    marginLeft="space20"
                    fontWeight="fontWeightSemibold">
                    {userName}
                </Text>
                <ChevronDownIcon decorative={false} color="colorTextInverse" title="Settings" />
            </Flex>

            {renderAvailabilityBox()}
        </Box>
    );
}
