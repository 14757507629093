import React from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { format } from 'date-fns';

import { FrontlineEventType } from '@twilio/frontline-shared/models/Event';
import { Participant } from '@twilio/frontline-shared/models/Participant';
import { DefaultTheme } from '@twilio/frontline-shared/theme';
import { TwilIcon } from '@twilio/frontline-shared/components/TwilIcon';
import { openStore } from '@twilio/frontline-shared/utils/openStore';

import { IChatSystemMessage } from './types';
import { ConsumptionHorizon } from './ConsumptionHorizon';
import { MessageCallEvent } from './MessageCallEvent';
import { useChatContext } from './ChatContext';

export type SystemMessageProps = {
    currentMessage?: IChatSystemMessage;
    customerParticipant?: Participant;
};

export function SystemMessage({ currentMessage, customerParticipant }: SystemMessageProps) {
    if (!currentMessage) {
        return null;
    }

    const { systemMessageType: type, payload } = currentMessage;

    if (type === FrontlineEventType.CallEnded) {
        return (
            <MessageCallEvent
                message={currentMessage}
                customerParticipant={customerParticipant}
                messagePayload={payload}
            />
        );
    }

    if (type === 'horizon') {
        return <ConsumptionHorizon currentMessage={currentMessage} />;
    }

    if (type === FrontlineEventType.Message || type === FrontlineEventType.Transfer) {
        return (
            <View style={styles.container}>
                <Text testID="systemMessage" style={styles.text}>
                    {currentMessage.text}
                </Text>
                <SystemMessageTime date={currentMessage.createdAt} />
            </View>
        );
    }
    return (
        <View style={styles.container}>
            <TouchableOpacity onPress={openStore}>
                <Text style={[styles.text, styles.errorText]}>
                    <TwilIcon
                        name="alert"
                        size={12}
                        color={DefaultTheme.textColors.colorTextError}
                    />
                    {` ${/* for spacing */ currentMessage.text}`}
                </Text>
            </TouchableOpacity>
            <SystemMessageTime date={currentMessage.createdAt} />
        </View>
    );
}

function SystemMessageTime({ date }: { date: Date | number }) {
    const { timeFormat } = useChatContext();

    return (
        <Text testID="timestamp" style={styles.time}>
            {format(date, timeFormat!)}
        </Text>
    );
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        flex: 1,
        flexDirection: 'column',
        marginVertical: DefaultTheme.space.space10,
        justifyContent: 'center',
    },
    text: {
        textAlign: 'center',
        textAlignVertical: 'center',
        marginHorizontal: DefaultTheme.space.space30,
        color: DefaultTheme.textColors.colorTextWeak,
        fontSize: DefaultTheme.fontSizes.fontSize10,
        lineHeight: DefaultTheme.lineHeights.lineHeight10,
    },
    errorText: { color: DefaultTheme.textColors.colorTextError },
    time: {
        fontSize: DefaultTheme.fontSizes.fontSize9,
        color: DefaultTheme.textColors.colorTextWeak,
        lineHeight: DefaultTheme.lineHeights.lineHeight10,
        marginTop: DefaultTheme.space.space5,
    },
});
