import { Box } from '@twilio-paste/box';
import { ParticipantAvatarProps } from '@twilio/frontline-shared/components/Chat/types';
import { Avatar } from '../Avatar';

export function ParticipantAvatar({ name, avatar }: ParticipantAvatarProps) {
    return (
        <Box marginTop="space70" data-testid="message-avatar">
            <Avatar size="sizeIcon70" friendlyName={name} avatar={avatar} />
        </Box>
    );
}
