import { useEffect, useState } from 'react';
import {
    getTemplatesByCustomerId,
    GetTemplatesByCustomerIdResponse,
} from '@twilio/frontline-shared/core/Callbacks/TemplatesCallbacks';
import { EmptyCallbackURLError } from '@twilio/frontline-shared/models/FrontlineError';
import { TemplatesByCategory, TemplatesModalStatus } from '../models/Templates';
import { WhatsAppSessionState } from '../types/WhatsAppSessionState';
import { GetTemplatesByCustomerIdPayload } from '../core/Callbacks/types';
import { ChannelType } from '../types/channel';

export function useTemplates(
    conversationSid: string | undefined,
    channelType: ChannelType,
    customerId: string | undefined,
    isOutOfWhatsAppWindow: boolean | undefined,
) {
    const [status, setStatus] = useState<TemplatesModalStatus>(TemplatesModalStatus.Loading);
    const [templates, setTemplates] = useState<TemplatesByCategory>([]);

    const mapResponseToTemplates = (response?: GetTemplatesByCustomerIdResponse) => {
        const mapped = response
            ?.map((category?) => {
                const categoryTemplates = category?.templates
                    ?.map((template) => {
                        return { ...template, content: template?.content || '' };
                    })
                    .filter(
                        (template) =>
                            template.content !== '' &&
                            (!isOutOfWhatsAppWindow || template.whatsAppApproved),
                    );
                return {
                    display_name: category?.display_name || '',
                    templates: categoryTemplates || [],
                };
            })
            .filter((c) => c.templates.length > 0);
        if ((!mapped || mapped.length === 0) && isOutOfWhatsAppWindow) {
            setStatus(TemplatesModalStatus.NoWhatsAppTemplate);
        }
        return mapped || [];
    };

    const fetch = () => {
        setStatus(TemplatesModalStatus.Loading);

        const payload: Omit<GetTemplatesByCustomerIdPayload, 'Location'> = {
            ConversationSid: conversationSid || '',
            CustomerId: customerId || '',
            ChannelType: channelType,
        };
        if (channelType === ChannelType.Whatsapp) {
            payload.WhatsAppSessionState = isOutOfWhatsAppWindow
                ? WhatsAppSessionState.Closed
                : WhatsAppSessionState.Open;
        }

        getTemplatesByCustomerId({ payload })
            .then(function (data?: GetTemplatesByCustomerIdResponse) {
                console.log(data);
                setStatus(TemplatesModalStatus.Ready);
                const templatesData = mapResponseToTemplates(data);
                setTemplates(templatesData);
            })
            .catch((e) => {
                if (e instanceof EmptyCallbackURLError) {
                    // templates callback url not set
                }

                console.log('Fetch templates failed: ', e);
                setStatus(TemplatesModalStatus.Error);
            });
    };

    useEffect(() => {
        fetch();
    }, [conversationSid, customerId, isOutOfWhatsAppWindow]);

    return { status, templates, fetch };
}
