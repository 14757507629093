import React from 'react';

import { Text } from '@twilio-paste/text';

import { IMedia, isImage, isMediaVoice } from '@twilio/frontline-shared/models/Media';
import { ConversationMessage, isMediaMessage } from '@twilio/frontline-shared/models/Message';
import { shouldRenderDraft } from '@twilio/frontline-shared/models/Conversation';
import { isSystemEvent, SystemEvent } from '@twilio/frontline-shared/models/Event';
import { ConversationViewVariant } from './Conversation/types';

type ConversationListItemPreviewProps = {
    lastMessage: ConversationMessage | SystemEvent | undefined;
    lastChatReadTime?: number;
    draftMessage?: string;
    unreadMessagesCount: number;
    media?: IMedia;
    lastSystemMessageText?: string;
    variant: ConversationViewVariant;
};

function PreviewText({
    unreadMessagesCount,
    isMarkedUnread,
    italic,
    children,
}: {
    unreadMessagesCount: number;
    isMarkedUnread: boolean;
    italic?: boolean;
    children?: React.ReactNode;
}) {
    return (
        <Text
            data-testid="conversation-box-preview-message"
            style={{
                textOverflow: 'ellipsis',
            }}
            whiteSpace="nowrap"
            fontStyle={italic ? 'italic' : 'normal'}
            color={unreadMessagesCount > 0 || isMarkedUnread ? 'colorText' : 'colorTextWeak'}
            as="span"
            overflow="hidden">
            {children}
        </Text>
    );
}

// TODO: Implement media preview icons
// TODO: Implement status icon (if needed)
export function ConversationTileMessage({
    lastMessage,
    lastChatReadTime,
    media,
    draftMessage,
    unreadMessagesCount,
    lastSystemMessageText,
    variant,
}: ConversationListItemPreviewProps) {
    if (
        variant === ConversationViewVariant.USER &&
        shouldRenderDraft(draftMessage, unreadMessagesCount, lastMessage)
    ) {
        return (
            <PreviewText
                data-testid="draftBody"
                unreadMessagesCount={unreadMessagesCount}
                isMarkedUnread>
                <Text
                    data-testid="draftIndicator"
                    as="span"
                    color="colorTextWarning"
                    fontWeight="fontWeightMedium">
                    {'Draft: '}
                </Text>
                {draftMessage}
            </PreviewText>
        );
    }
    if (!lastMessage) {
        return (
            <PreviewText unreadMessagesCount={unreadMessagesCount} italic isMarkedUnread>
                No messages
            </PreviewText>
        );
    }
    if (isSystemEvent(lastMessage)) {
        return (
            <PreviewText unreadMessagesCount={unreadMessagesCount} isMarkedUnread>
                {lastSystemMessageText}
            </PreviewText>
        );
    }

    // let status = getChatMessageReadStatus(lastMessage, lastChatReadTime);
    return (
        <MessageContentPreview
            unreadMessagesCount={unreadMessagesCount}
            lastMessage={lastMessage}
            media={media}
        />
    );
}

function MessageContentPreview({
    unreadMessagesCount,
    lastMessage,
    media,
}: {
    unreadMessagesCount: number;
    lastMessage: ConversationMessage;
    media?: IMedia;
}) {
    if (isMediaMessage(lastMessage) && media) {
        let fileType = 'Document';
        if (isImage(media)) {
            fileType = 'Image';
        }
        if (isMediaVoice(media)) {
            fileType = 'Audio';
        }
        return (
            <PreviewText unreadMessagesCount={unreadMessagesCount} isMarkedUnread>
                {media.filename || fileType}
            </PreviewText>
        );
    }
    return (
        <PreviewText unreadMessagesCount={unreadMessagesCount} isMarkedUnread>
            {lastMessage.body}
        </PreviewText>
    );
}
