import { Reducer } from 'redux';
import { useSelector } from 'react-redux';
import isAfter from 'date-fns/isAfter';
import addHours from 'date-fns/addHours';

import { omit } from 'ramda';
import { createAction } from './Action';
import { ExtendsChatState } from './ChatState';

type WhatsAppWindowActions = SetWindowExpireTime | ClearWindowExpireTime;

export const setWindowExpireTime = createAction('WHATSAPP_SET_WINDOW_EXPIRE_TIME')<{
    channelSid: string;
    lastMessageAt: Date;
}>();

type SetWindowExpireTime = ReturnType<typeof setWindowExpireTime>;

export const clearWindowExpireTime = createAction('WHATSAPP_CLEAR_WINDOW_EXPIRE_TIME')<{
    channelSid: string;
}>();

type ClearWindowExpireTime = ReturnType<typeof clearWindowExpireTime>;

export type WhatsAppWindowState = {
    [key: string]: Date;
};

export const initialState: WhatsAppWindowState = {};

export const reduce: Reducer<WhatsAppWindowState, WhatsAppWindowActions> = (
    state = initialState,
    action,
) => {
    switch (action.type) {
        case setWindowExpireTime.getType(): {
            const { channelSid, lastMessageAt } = action.payload;
            return { ...state, [channelSid]: add24Hours(lastMessageAt) };
        }
        case clearWindowExpireTime.getType(): {
            const { channelSid } = action.payload;
            return omit([channelSid], state);
        }
        default:
            return state;
    }
};

export function useWhatsAppWindowState(channelSid: string): Date | undefined {
    return useSelector((state: ExtendsChatState) => state.chat.whatsAppWindow[channelSid]);
}

export function isWindowClosed(date?: Date): boolean {
    return date ? isAfter(new Date(), date) : true;
}
export function add24Hours(date: Date): Date {
    return addHours(date, 24);
}
