import { isString } from './isString';
import {
    EMAIL_REGEX,
    EMAIL_REGEX_PART_COUNT,
    PHONE_NUMBER_EXT_REGEX,
    PHONE_NUMBER_EXT_REGEX_PART_COUNT,
    NUM_CHARS_TO_MASK,
} from './constants';

const SAFE_URL_REGEX = /^(https:|mailto:)\S*$/;
const URL_WITH_PROTOCOL_REGEX = /^(\w+:)\S*$/;

export const trimSpaces = (search: string) => {
    return search
        .split(' ')
        .filter((s) => s)
        .join(' ');
};

export const isSafeUrl = (input: string | undefined) => {
    if (input === undefined) {
        return false;
    }
    return SAFE_URL_REGEX.test(input);
};

export const toSafeUrl = (input: string | undefined) => {
    if (input === undefined) {
        return undefined;
    }

    let maybeSafeUrl = input;
    if (!URL_WITH_PROTOCOL_REGEX.test(maybeSafeUrl)) {
        maybeSafeUrl = `https://${maybeSafeUrl}`;
    }

    if (isSafeUrl(maybeSafeUrl)) {
        return maybeSafeUrl;
    }

    return undefined;
};

export const isStringPresent = (maybeString: unknown) =>
    isString(maybeString) && maybeString.length > 0;

/** Returns a masked channel address given a string.
 *  If the string is a valid email address, it will be parsed and return the first letter of the email and the domain unmasked.
 *  If the string has a phone extension present, the extension will stay unmasked and the last NUM_CHARS_TO_MASK digits before the extension will be masked.
 *  Everything else will mask the last NUM_CHARS_TO_MASK characters of the string.
 * @param { string } channelAddress - A string to be masked.
 * @returns { string } A masked string.
 */
export const maskChannelAddress = (channelAddress: string | undefined): string => {
    if (!isStringPresent(channelAddress) || channelAddress === undefined) {
        return '';
    }

    const anyCharRegex = /./g;
    const spaceCharRegex = / */g;
    const maskCharacter = 'X';
    const formattedChannelAddress = channelAddress.replace(spaceCharRegex, '');
    const emailParts = formattedChannelAddress.match(EMAIL_REGEX);

    // If valid email format
    if (emailParts && emailParts.length === EMAIL_REGEX_PART_COUNT) {
        const userNameFirstLetter = emailParts[1][0];
        const emailDomain = emailParts[3];
        const stringToMask = formattedChannelAddress.slice(
            formattedChannelAddress.indexOf(userNameFirstLetter) + 1,
            formattedChannelAddress.length - emailDomain.length,
        );
        const maskedString = stringToMask.replace(anyCharRegex, maskCharacter);

        return `${userNameFirstLetter}${maskedString}${emailDomain}`;
    }

    // If valid phone extension present
    const phoneExtParts = formattedChannelAddress.match(PHONE_NUMBER_EXT_REGEX);
    if (phoneExtParts && phoneExtParts.length === PHONE_NUMBER_EXT_REGEX_PART_COUNT) {
        const phoneExt = phoneExtParts[0];
        const extStringStartIndex = formattedChannelAddress.indexOf(phoneExt);
        const stringWithoutExt = formattedChannelAddress.slice(0, extStringStartIndex);
        const stringToMask = stringWithoutExt.slice(
            stringWithoutExt.length - NUM_CHARS_TO_MASK,
            stringWithoutExt.length,
        );
        const stringToNotMask = formattedChannelAddress.slice(
            0,
            extStringStartIndex - NUM_CHARS_TO_MASK,
        );
        const maskedString = stringToMask.replace(anyCharRegex, maskCharacter);

        return `${stringToNotMask}${maskedString}${phoneExt}`;
    }

    // Edge case - if length <= NUM_CHARS_TO_MASK
    if (formattedChannelAddress.length <= NUM_CHARS_TO_MASK) {
        const maskedString = formattedChannelAddress.replace(anyCharRegex, maskCharacter);
        return maskedString;
    }

    // Everything else, mask last NUM_CHARS_TO_MASK characters
    const stringToNotMask = formattedChannelAddress.slice(
        0,
        formattedChannelAddress.length - NUM_CHARS_TO_MASK,
    );
    const stringToMask = channelAddress.slice(
        formattedChannelAddress.length - NUM_CHARS_TO_MASK,
        formattedChannelAddress.length,
    );

    const maskedString = stringToMask.replace(anyCharRegex, maskCharacter);
    return `${stringToNotMask}${maskedString}`;
};

export const getChannelAddress = (
    channelValue: string | undefined,
    maskAddress: boolean,
): string => {
    if (!isStringPresent(channelValue) || channelValue === undefined) {
        return '';
    }

    return maskAddress ? maskChannelAddress(channelValue) : channelValue;
};
