import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import { DefaultTheme } from '@twilio/frontline-shared/theme';
import { IChatSystemMessage } from './types';

export function ConsumptionHorizon({ currentMessage }: { currentMessage?: IChatSystemMessage }) {
    if (!currentMessage || !currentMessage.text) {
        return null;
    }

    return (
        <View style={styles.container}>
            <Text style={styles.text}>{currentMessage.text}</Text>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        backgroundColor: DefaultTheme.backgroundColors.colorBackgroundPrimaryLightest,
        height: DefaultTheme.sizes.size24,
        flex: 1,
        flexDirection: 'row',
        marginVertical: DefaultTheme.space.space10,
        justifyContent: 'center',
    },
    text: {
        textAlign: 'center',
        textAlignVertical: 'center',
        color: DefaultTheme.textColors.colorTextButton,
        fontWeight: DefaultTheme.fontWeights.fontWeightSemibold,
        fontSize: DefaultTheme.fontSizes.fontSize10,
        lineHeight: DefaultTheme.lineHeights.lineHeight10,
    },
});
