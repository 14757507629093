import { EventEmitter } from 'events';

import { FASClient } from '@twilio/frontline-shared/core/FASClient';
import { JWEToken, Token } from '@twilio/frontline-shared/core/Token';

export const TOKEN_EXPIRED = 'tokenExpired';
export const TOKEN_UPDATED = 'tokenUpdated';

export class TokenManager extends EventEmitter {
    static refreshToken = (token: JWEToken, ssoRealmSid: string): Promise<JWEToken> => {
        return FASClient.refreshToken(ssoRealmSid, token.token).then((res) => {
            console.log('Refreshed token', res);
            const newToken = new JWEToken(token.identity, res.token, res.expiration);
            return newToken;
        });
    };

    private _tokenData?: Token;

    private ssoRealmSid?: string;

    public constructor(token: Token, realmSid: string) {
        super();
        this._tokenData = token;
        this.ssoRealmSid = realmSid;
    }

    public shutdown() {
        this.removeAllListeners();
    }

    public get token(): Token {
        return this._tokenData!;
    }

    public get isActive(): boolean {
        return this.token?.isValid();
    }

    public refreshToken(): Promise<void> {
        return this.updateToken().catch(() => {
            console.log('😠 Can`t update token');
            if (!this.token.isValid()) {
                this.emit(TOKEN_EXPIRED);
            }
        });
    }

    private updateToken(): Promise<void> {
        return TokenManager.refreshToken(this.token, this.ssoRealmSid!).then((newToken) => {
            this._tokenData = newToken;
            this.emit(TOKEN_UPDATED, newToken);
        });
    }
}
