import { ReactNode } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';

import { Box } from '@twilio-paste/box';

type NavbarLinkProps = {
    path: string;
    children: ReactNode;
};

export function NavbarLink({ children, path }: NavbarLinkProps) {
    const history = useHistory();
    const match = useRouteMatch(path);

    return (
        <Box
            as="a"
            onClick={() => history.push(path)}
            display="flex"
            flexDirection="row"
            paddingTop="space40"
            paddingBottom="space40"
            paddingLeft="space50"
            _hover={{
                textDecoration: match?.isExact ? 'none' : 'underline',
            }}
            backgroundColor={match?.isExact ? 'colorBackgroundStrong' : undefined}
            fontWeight={match?.isExact ? 'fontWeightBold' : 'fontWeightNormal'}
            color={match?.isExact ? 'colorTextLink' : undefined}
            cursor="pointer">
            {children}
        </Box>
    );
}
