import { createAction } from '@reduxjs/toolkit';
import { IUser } from '@twilio/frontline-shared/models/User';

export const addConversationsUsers =
    createAction<{ identities: string[] }>('CONVERSATIONS_ADD_USERS');
export type AddConversationsUsers = ReturnType<typeof addConversationsUsers>;

export const setConversationUsers = createAction<{ users: IUser[] }>('CONVERSATIONS_SET_USERS');
export const upsertConversationsUser = createAction<{ user: IUser }>('CONVERSATIONS_UPSERT_USER');
export const removeConversationsUser = createAction<{ identity: string }>(
    'CONVERSATIONS_REMOVE_USER',
);
