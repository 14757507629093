import { Reducer } from 'redux';

import { IMedia, MediaStatus } from '@twilio/frontline-shared/models/Media';
import { ChannelMediaActions, initializeMedia, loadedMedia, loadMedia, sendMedia } from './actions';
import { ExtendsChatState } from '../ChatState';

export type ChannelMediaState = { [key: string]: IMedia };

export const initialState: ChannelMediaState = {};

export const reduce: Reducer<ChannelMediaState, ChannelMediaActions> = (
    state = initialState,
    action,
) => {
    switch (action.type) {
        case initializeMedia.getType(): {
            const { media } = action.payload;
            const newState = { ...state, [media.sid]: media };

            return newState;
        }
        case loadMedia.getType(): {
            const { media } = action.payload;
            const newMedia = { ...media, state: MediaStatus.Downloading };
            const newState = { ...state, [media.sid]: newMedia };

            return newState;
        }
        case loadedMedia.getType(): {
            const { mediaSid, path, height, width } = action.payload;
            const media = state[mediaSid];

            const newMedia = { ...media, path, height, width, state: MediaStatus.Ready };
            const newState = { ...state, [media.sid]: newMedia };

            return newState;
        }
        case sendMedia.getType(): {
            const { media } = action.payload;

            const newState = { ...state, [media.sid]: media };

            return newState;
        }
        default: {
            return state;
        }
    }
};

export const selectMedia = (state: ExtendsChatState, mediaSid: string): IMedia | undefined =>
    state.chat.media[mediaSid];
