import { TwilsockClient } from 'twilsock';
import { ChannelType, Notifications } from '@twilio/notifications';

type ClientOptions = {
    twilsockClient: TwilsockClient;
    region?: string;
};

const VOICE_NOTIFICATION_MESSAGE_TYPE = 'twilio.voice.call';

const VOICE_GRANT_NAME = 'voice';

export class VoiceNotificationsClient {
    private notificationsClient: Notifications;

    constructor(token: string, options: ClientOptions) {
        this.notificationsClient = new Notifications(token, {
            twilsockClient: options.twilsockClient,
            productId: VOICE_GRANT_NAME,
            region: options.region,
        });
    }

    public setPushRegistrationId(channelType: ChannelType, registrationId: string): Promise<void> {
        this.notificationsClient.subscribe(channelType, VOICE_NOTIFICATION_MESSAGE_TYPE);
        this.notificationsClient.setPushRegistrationId(channelType, registrationId);
        return this.notificationsClient.commitChanges();
    }

    public removePushRegistrations(
        channelType: ChannelType,
        registrationId: string,
    ): Promise<void> {
        return this.notificationsClient.removeRegistrations(channelType, registrationId);
    }
}
