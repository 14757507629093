import { DataGrid, DataGridBody, DataGridCell, DataGridRow } from '@twilio-paste/data-grid';

import { WorkerUser } from '@twilio/frontline-shared/models/WorkerUser';

import { UserListItem, UserListItemSkeleton } from '../../../components/UserListItem';
import { UsersState } from '../../../hooks/useUsers';

type UsersListProps = {
    state: UsersState;
    users: WorkerUser[];
};

export function UsersList({ state, users }: UsersListProps) {
    const renderListBody = () => {
        if (state === UsersState.Loading || state === UsersState.Searching) {
            return Array.from({ length: 100 }).map((_, idx) => (
                <DataGridRow key={`row-${idx}`}>
                    <DataGridCell key={`cell-${idx}`}>
                        <UserListItemSkeleton />
                    </DataGridCell>
                </DataGridRow>
            ));
        }

        return users.map((user, idx) => (
            <DataGridRow key={`row-${idx}`}>
                <DataGridCell key={`cell-${idx}`}>
                    <UserListItem user={user} />
                </DataGridCell>
            </DataGridRow>
        ));
    };

    return (
        <DataGrid aria-label="User information table content" striped>
            <DataGridBody>{renderListBody()}</DataGridBody>
        </DataGrid>
    );
}
