import {
    AnalyticsService,
    AnalyticsServiceInit,
    AnalyticsServiceMap,
    AnalyticsUser,
    IAnalyticEvent,
    SharedAnalyticEvents,
} from './type';

export class AnalyticsClass<T extends IAnalyticEvent> {
    services: AnalyticsService[] = [];

    servicesMap: AnalyticsServiceMap = {};

    init(services: AnalyticsServiceInit[] = []) {
        services.forEach(({ id, service }) => {
            service.init();
            this.servicesMap[id] = service;
            this.services.push(service);
        });
    }

    logEvent(e: T) {
        if (typeof e === 'string') {
            this.services.forEach((s) => s.logEvent(e));
        } else {
            this.services.forEach((s) => s.logEvent(e.event, e.extra));
        }
    }

    logError(event: string, extra: { [key: string]: any }) {
        this.services.forEach((s) => s.logError(event, extra));
    }

    setUser(user: AnalyticsUser) {
        this.services.forEach((s) => s.setUser(user));
    }

    getService(id = '') {
        const service = this.servicesMap[id];
        if (service) {
            return service;
        }
        return {};
    }
}

export const Analytics = new AnalyticsClass<SharedAnalyticEvents>();
