import { Box } from '@twilio-paste/box';
import { Card } from '@twilio-paste/card';
import { Heading } from '@twilio-paste/heading';
import { Paragraph } from '@twilio-paste/paragraph';
import { Button } from '@twilio-paste/button';

type NoSearchResultBoxProps = {
    query: string;
    onClearSearch: () => void;
};

export function NoSearchResultBox({ query, onClearSearch }: NoSearchResultBoxProps) {
    return (
        <Box marginTop="space60">
            <Card>
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Heading as="h4" variant="heading40">
                        No results for “{query}”
                    </Heading>
                    <Paragraph>Try searching by team member name or email address.</Paragraph>
                    <Button
                        data-testid="view-all-button"
                        onClick={onClearSearch}
                        variant="secondary">
                        View all team members
                    </Button>
                </Box>
            </Card>
        </Box>
    );
}
