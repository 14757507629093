import { Redirect, Route } from 'react-router-dom';

import { useAuth, AuthState } from '../../hooks/useAuth';
import { LoadingScreen } from '../LoadingScreen';
import { PAGES } from '../../paths';

export function ProtectedRoute({ children, ...rest }: any) {
    const auth = useAuth();
    return (
        <Route
            {...rest}
            render={() => {
                switch (auth.authState) {
                    case AuthState.Unknown:
                        return <LoadingScreen />;
                    case AuthState.Authenticated:
                        return <Redirect to={PAGES.DEFAULT} />;
                    default:
                        return children;
                }
            }}
        />
    );
}
