import { Analytics } from '@twilio/frontline-shared/analytics';
import { Linking, Platform } from 'react-native';

export const openStore = () => {
    Analytics.logEvent('click_upgrade_to_latest_version');
    if (Platform.OS === 'ios') {
        Linking.openURL('itms-apps://apps.apple.com/app/id1541714273').catch(() => {
            Linking.openURL('https://apps.apple.com/app/id1541714273');
        });
    } else if (Platform.OS === 'android') {
        Linking.openURL('market://details?id=com.twilio.frontline').catch(() => {
            Linking.openURL('https://play.google.com/store/apps/details?id=com.twilio.frontline');
        });
    }
};
