import { combineReducers } from '@twilio/frontline-shared/store/redux';
import { reduce as conversationsUsersReducer } from '@twilio/frontline-shared/store/users/ConversationsUsersState';
import { reduce as internetConnectionReducer } from '@twilio/frontline-shared/store/InternetConnectionState';
import { reduce as whatsAppWindowReducer } from '@twilio/frontline-shared/store/WhatsAppWindowState';
import { reduce as channelsReducer } from '@twilio/frontline-shared/store/conversations/ConversationsState';
import { reduce as messagesReducer } from '@twilio/frontline-shared/store/messages/ChatMessagesState';
import { reduce as participantsReducer } from '@twilio/frontline-shared/store/ParticipantsState';
import { reduce as mediaReducer } from '@twilio/frontline-shared/store/media/ChannelMediaState';
import { reduce as sessionReducer } from '@twilio/frontline-shared/store/ChatSessionState';
import { reduce as draftReducer } from '@twilio/frontline-shared/store/draft/DraftState';
import { reduce as uiReducer } from '@twilio/frontline-shared/store/ui/UIState';
import { reduce as voiceReducer } from '@twilio/frontline-shared/store/voice/VoiceState';
import { ChatState } from '@twilio/frontline-shared/store/ChatState';
import { FrontlineSDK } from '@twilio/frontline-shared/sdk/FrontlineSDK';
import { createFrontlineStore } from '@twilio/frontline-shared/store/createStore';
import { rootSaga } from './rootSaga';
import { AppState } from './AppState';
import { IS_DEV_BUILD } from '../env';

const chatReducer = combineReducers<ChatState>({
    channels: channelsReducer,
    messages: messagesReducer,
    participants: participantsReducer,
    media: mediaReducer,
    session: sessionReducer,
    drafts: draftReducer,
    whatsAppWindow: whatsAppWindowReducer,
    voice: voiceReducer,
    users: conversationsUsersReducer,
});

const appReducer = combineReducers<AppState>({
    connection: internetConnectionReducer,
    chat: chatReducer,
    ui: uiReducer,
});

export function createStore(state: AppState | undefined, frontlineSDK: typeof FrontlineSDK) {
    return createFrontlineStore<AppState>({
        state,
        appReducer,
        rootSaga,
        frontlineSDK,
        isDev: IS_DEV_BUILD,
    });
}
