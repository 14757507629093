import React from 'react';
import { TextStyle } from 'react-native';

import { DefaultTheme } from '@twilio/frontline-shared/theme';
import icoMoonConfig from './icomoonconfig.json';
import createIconSetFromIcoMoon from './createIconSetFromIcoMoon';

const TwilIconSet = createIconSetFromIcoMoon(icoMoonConfig);

export type TwilIconProps = {
    allowFontScaling?: boolean;
    name: string;
    size?: number;
    color?: string;
    style?: TextStyle;
};

export const TWILICON_HEIGHT = 24;

export function TwilIcon(props: TwilIconProps) {
    return (
        <TwilIconSet
            size={TWILICON_HEIGHT}
            color={DefaultTheme.backgroundColors.colorBackgroundPrimary}
            testID={props.name}
            {...props}
        />
    );
}
