import { useEffect } from 'react';
import { TwilioConversation, ConversationSid } from '../types';
import { ISession } from '../core/ISession';

export const useOnConversationRemoved = (
    conversationSid: ConversationSid | undefined,
    session: ISession | undefined,
    onConversationRemoved: () => void,
) => {
    useEffect(() => {
        const listener = (conversation: TwilioConversation) => {
            if (conversationSid === conversation.sid) {
                onConversationRemoved();
            }
        };
        session?.frontlineSDK.addListener('conversationRemoved', listener);
        return () => {
            session?.frontlineSDK.removeListener('conversationRemoved', listener);
        };
    }, [conversationSid]);
};
