import { useState } from 'react';

export function useSearchBar(initialQuery: string, resetSelectedUser?: () => void) {
    // user is typing - value is stored in inputValue,
    // user pressed search - inputValue is copied to query
    // and search is executed by useEffect[query] in useUsers(query)
    const [query, setQuery] = useState(initialQuery);
    const [inputValue, setInputValue] = useState(initialQuery);

    const updateQuery = () => {
        setQuery(inputValue);
        resetSelectedUser?.();
    };

    const clearSearch = () => {
        setInputValue('');
        setQuery('');
        resetSelectedUser?.();
    };

    return { inputValue, query, setInputValue, updateQuery, clearSearch };
}
