import { takeEvery } from 'redux-saga/effects';

import { hideMessage, HideMessage, newMessage, NewMessage } from './messages/actions';
import {
    loadPrevMessages,
    LoadPrevMessages,
    MarkConversationMessagesAsRead,
    markMessagesAsRead,
    fetchUnreadMessagesCount,
    FetchUnreadMessagesCount,
    WatchConversationEvents,
    watchConversationEvents,
    MarkSystemMessagesAsRead,
    markSystemMessagesAsRead,
    MarkConversationUnread,
    markConversationUnread,
    ReadConversation,
    readConversation,
} from './conversations/actions';
import {
    loadPrevMessagesSaga,
    readChannelMessagesSaga,
    fetchChannelUnreadMessagesCountSaga,
    watchConversationEventsSaga,
    markSystemMessagesAsReadSaga,
    markConversationUnreadSaga,
    readConversationSaga,
} from './conversations/sagas';
import { addConversationsUsers, AddConversationsUsers } from './users/actions';
import { addConversationsUsersSaga } from './users/sagas';
import { FrontlineSDK } from '../sdk/FrontlineSDK';
import { receiveMessageSaga, hideMessageSaga } from './messages/sagas';

export function* watchNewMessage() {
    yield takeEvery<NewMessage>(newMessage.getType(), receiveMessageSaga);
}

export function* watchChannelReadAllMessages(frontlineSDK: typeof FrontlineSDK) {
    yield takeEvery<MarkConversationMessagesAsRead, typeof readChannelMessagesSaga>(
        markMessagesAsRead.type,
        readChannelMessagesSaga,
        frontlineSDK,
    );
    yield takeEvery<MarkSystemMessagesAsRead, typeof markSystemMessagesAsReadSaga>(
        markSystemMessagesAsRead.type,
        markSystemMessagesAsReadSaga,
        frontlineSDK,
    );
}

export function* watchChannelLoadMessages(frontlineSDK: typeof FrontlineSDK) {
    yield takeEvery<LoadPrevMessages, typeof loadPrevMessagesSaga>(
        loadPrevMessages.type,
        loadPrevMessagesSaga,
        frontlineSDK,
    );
}

export function* watchConversationEventsAction() {
    yield takeEvery<WatchConversationEvents>(
        watchConversationEvents.type,
        watchConversationEventsSaga,
    );
}

export function* watchFetchUnreadMessagesCount(frontlineSDK: typeof FrontlineSDK) {
    yield takeEvery<FetchUnreadMessagesCount, typeof fetchChannelUnreadMessagesCountSaga>(
        fetchUnreadMessagesCount.type,
        fetchChannelUnreadMessagesCountSaga,
        frontlineSDK,
    );
}

export function* watchMarkConversationUnread(frontlineSDK: typeof FrontlineSDK) {
    yield takeEvery<MarkConversationUnread, typeof markConversationUnreadSaga>(
        markConversationUnread.type,
        markConversationUnreadSaga,
        frontlineSDK,
    );
}

export function* watchReadConversation(frontlineSDK: typeof FrontlineSDK) {
    yield takeEvery<ReadConversation, typeof readConversationSaga>(
        readConversation.type,
        readConversationSaga,
        frontlineSDK,
    );
}

export function* watchUserActions(frontlineSDK: typeof FrontlineSDK) {
    yield takeEvery<AddConversationsUsers, typeof addConversationsUsersSaga>(
        addConversationsUsers.type,
        addConversationsUsersSaga,
        frontlineSDK,
    );
}

export function* watchHideMessage(frontlineSDK: typeof FrontlineSDK) {
    yield takeEvery<HideMessage, typeof hideMessageSaga>(
        hideMessage.getType(),
        hideMessageSaga,
        frontlineSDK,
    );
}
