/* CHANNEL TYPES */
export enum ChannelType {
    Chat = 'chat',
    Sms = 'sms',
    Whatsapp = 'whatsapp',
    Facebook = 'fb',
}

export type Channel = {
    id?: number;
    type: ChannelType;
    value: string;
    country?: string;
};

export type CustomerChannel = {
    type: string;
    value: string;
};

export type ChannelTypeItem = {
    value: ChannelType;
    display: string;
};

export const channelTypes: ChannelTypeItem[] = [
    {
        value: ChannelType.Sms,
        display: 'SMS',
    },
    {
        value: ChannelType.Whatsapp,
        display: 'WhatsApp',
    },
];
