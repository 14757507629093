import {
    ICredentialsStorage,
    FRONTLINE_ACCOUNT_UNIQUE_NAME_KEY,
    FRONTLINE_REALM_SID_KEY,
    FRONTLINE_TOKEN_KEY,
} from '@twilio/frontline-shared/core/ICredentialsStorage';
import { JWEToken } from '@twilio/frontline-shared/core/Token';

class CredentialsStorageImp implements ICredentialsStorage {
    getAccountUniqueName(): Promise<string | null> {
        return Promise.resolve(window.localStorage.getItem(FRONTLINE_ACCOUNT_UNIQUE_NAME_KEY));
    }

    setAccountUniqueName(accountUniqueName: string): Promise<void> {
        window.localStorage.setItem(FRONTLINE_ACCOUNT_UNIQUE_NAME_KEY, accountUniqueName);
        return Promise.resolve();
    }

    getRealmSID(): Promise<string | null> {
        return Promise.resolve(window.localStorage.getItem(FRONTLINE_REALM_SID_KEY));
    }

    setRealmSID(realmSid: string): Promise<void> {
        window.localStorage.setItem(FRONTLINE_REALM_SID_KEY, realmSid);
        return Promise.resolve();
    }

    getToken(): Promise<JWEToken | null> {
        const token = window.localStorage.getItem(FRONTLINE_TOKEN_KEY);
        console.log(token);
        return Promise.resolve(token ? JSON.parse(token) : null);
    }

    setToken(token: JWEToken): Promise<void> {
        window.localStorage.setItem(FRONTLINE_TOKEN_KEY, JSON.stringify(token));
        return Promise.resolve();
    }

    getUpdateRequiredVersion(): Promise<string | null> {
        throw new Error('Method not implemented.');
    }

    setUpdateRequiredVersion(version: string): Promise<void> {
        throw new Error('Method not implemented.');
    }

    clear(): Promise<void> {
        window.localStorage.removeItem(FRONTLINE_TOKEN_KEY);
        window.localStorage.removeItem(FRONTLINE_REALM_SID_KEY);
        return Promise.resolve();
    }
}

export const CredentialsStorage = new CredentialsStorageImp();
