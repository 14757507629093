export function is24Hour() {
    return new Date().toLocaleTimeString(window.navigator.language).length < 9;
}

export function dateFormatter(date: Date) {
    return date.toLocaleDateString(window.navigator.language);
}

export function dateTimeFormatter(date: Date) {
    return date.toLocaleString(window.navigator.language);
}
