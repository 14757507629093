import { useSelector } from 'react-redux';
import { remove } from 'ramda';
import { createAction, createReducer } from '@reduxjs/toolkit';

import { Participant, isCustomerParticipant } from '@twilio/frontline-shared/models/Participant';
import { ChannelType } from '@twilio/frontline-shared/types/channel';
import { indexExists } from '@twilio/frontline-shared/utils/array';
import { ConversationSid, ParticipantSid } from '@twilio/frontline-shared/types';
import { ExtendsChatState, ChatState } from './ChatState';

export const addParticipants =
    createAction<{
        conversationSid: ConversationSid;
        participants: Participant[];
    }>('ADD_PARTICIPANTS');
export const updateParticipant =
    createAction<{
        conversationSid: ConversationSid;
        participant: Participant;
    }>('UPDATE_PARTICIPANT');
export const removeParticipant =
    createAction<{
        conversationSid: ConversationSid;
        participantSid: ParticipantSid;
    }>('REMOVE_PARTICIPANT');

export type ParticipantsState = { [key: ConversationSid]: Participant[] };

const initialState: ParticipantsState = {};

export const reduce = createReducer<ParticipantsState>(initialState, (builder) => {
    builder
        .addCase(addParticipants, (state, action) => {
            const { conversationSid, participants: participantsToAdd } = action.payload;
            state[conversationSid] = (state[conversationSid] || []).concat(participantsToAdd);
        })
        .addCase(updateParticipant, (state, action) => {
            const { conversationSid, participant } = action.payload;
            const participantIndex = state[conversationSid]?.findIndex(
                (p) => p.sid === participant.sid,
            );
            if (indexExists(participantIndex)) {
                state[conversationSid][participantIndex] = participant;
            }
        })
        .addCase(removeParticipant, (state, action) => {
            const { conversationSid, participantSid } = action.payload;
            const participantIndex = state[conversationSid]?.findIndex(
                (p) => p.sid === participantSid,
            );
            if (indexExists(participantIndex)) {
                state[conversationSid] = remove(participantIndex, 1, state[conversationSid]);
            }
        });
});

export const selectParticipants = (state: { chat: ChatState }) => state.chat.participants;

export const selectConversationParticipants = (
    state: ExtendsChatState,
    conversationSid: string,
): Participant[] => selectParticipants(state)[conversationSid] || [];

export const selectConversationCustomer = (
    state: ExtendsChatState,
    conversationSid: string,
): Participant | undefined =>
    selectConversationParticipants(state, conversationSid).find(isCustomerParticipant);

export const selectChannelType = (
    state: ExtendsChatState,
    conversationSid: string,
): ChannelType | undefined =>
    selectConversationParticipants(state, conversationSid).find(
        (participant) => participant.channelType !== ChannelType.Chat,
    )?.channelType || ChannelType.Chat;

export const selectConversationParticipant = (
    state: ExtendsChatState,
    conversationSid: string,
    identity: string,
): Participant | undefined =>
    selectConversationParticipants(state, conversationSid).find((p) => p.identity === identity);

export const selectUserParticipant = (
    state: ExtendsChatState,
    conversationSid: string,
): Participant | undefined =>
    selectConversationParticipants(state, conversationSid).find(
        (p) => p.identity && p.identity === state.chat.session.user?.identity,
    );

export const useParticipants = () => {
    return useSelector<ExtendsChatState, ParticipantsState>(selectParticipants);
};

export const useConversationParticipants = (conversationSid: string): Participant[] => {
    return useSelector<ExtendsChatState, Participant[]>((state) =>
        selectConversationParticipants(state, conversationSid),
    );
};

export const useUserParticipant = (conversationSid: string): Participant | undefined => {
    return useSelector<ExtendsChatState, Participant | undefined>((state) =>
        selectConversationParticipant(state, conversationSid, state.chat.session.user?.identity!),
    );
};

export const selectUnknownParticipant = (
    state: ExtendsChatState,
    conversationSid: string,
): Participant | undefined =>
    selectConversationParticipants(state, conversationSid).find(
        (p) => !p.identity && !p.customerId,
    );

export const useUnknownParticipant = (conversationSid?: string): Participant | undefined => {
    return useSelector<ExtendsChatState, Participant | undefined>((state) =>
        conversationSid ? selectUnknownParticipant(state, conversationSid) : undefined,
    );
};

export const useChannelType = (conversationSid: string): ChannelType | undefined => {
    return useSelector<ExtendsChatState, ChannelType | undefined>((state) =>
        selectChannelType(state, conversationSid),
    );
};

export const useConversationCustomer = (conversationSid: string): Participant | undefined => {
    return useSelector<ExtendsChatState, Participant | undefined>((state) =>
        selectConversationCustomer(state, conversationSid),
    );
};
