import { ModalBody } from '@twilio-paste/modal';
import { Button } from '@twilio-paste/button';
import { Flex } from '@twilio-paste/flex';
import { Heading } from '@twilio-paste/heading';
import { Paragraph } from '@twilio-paste/paragraph';
import { Alert, AlertRoles } from '@twilio-paste/alert';
import { Anchor } from '@twilio-paste/anchor';
import { Text } from '@twilio-paste/text';
import { Box } from '@twilio-paste/box';

export function TemplatesModalEmpty({ onReload }: { onReload: () => void }) {
    return (
        <ModalBody>
            <Flex vertical hAlignContent="center" vAlignContent="center" paddingTop="space120">
                <Heading as="h4" variant="heading40">
                    No templates available
                </Heading>
                <Paragraph>Contact your IT team to add templates to your workspace.</Paragraph>

                <Button variant="primary" onClick={onReload} autoFocus>
                    Reload
                </Button>
                <Box marginTop="space180">
                    <Alert role={AlertRoles.NEUTRAL} variant="neutral">
                        <Heading as="h5" variant="heading50">
                            Developer Guidance
                        </Heading>
                        <Text as="div">
                            To use templated messages in Frontline, add a templates callback URL in
                            the Twilio console.
                        </Text>
                        <Anchor
                            href="https://www.twilio.com/docs/frontline/templated-messages"
                            showExternal
                            marginLeft="space20">
                            Read the docs
                        </Anchor>
                    </Alert>
                </Box>
            </Flex>
        </ModalBody>
    );
}
