import { CustomParseShape, ParseShape } from './types';

/**
 * Class to encapsulate the business logic of converting text into matches & props
 */
class TextExtraction {
    text: string;

    patterns: CustomParseShape[];

    /**
     * @param {String} text - Text to be parsed
     * @param {CustomParseShape[]} patterns - Patterns to be used when parsed,
     *                                 any extra attributes, will be returned from parse()
     */
    constructor(text: string, patterns: CustomParseShape[]) {
        this.text = text;
        this.patterns = patterns || [];
    }

    /**
     * Returns parts of the text with their own props
     * @return {Object[]} - props for all the parts of the text
     */
    parse() {
        let parsedTexts = [{ children: this.text }];
        this.patterns.forEach((pattern) => {
            const newParts: any[] = [];

            const tmp = pattern.nonExhaustiveModeMaxMatchCount || 0;
            const numberOfMatchesPermitted = Math.min(
                Math.max(Number.isInteger(tmp) ? tmp : 0, 0) || Number.POSITIVE_INFINITY,
                Number.POSITIVE_INFINITY,
            );

            let currentMatches = 0;

            parsedTexts.forEach((parsedText) => {
                // Only allow for now one parsing
                // @ts-ignore
                if (parsedText._matched) {
                    newParts.push(parsedText);
                    return;
                }

                const parts = [];
                let textLeft = parsedText.children;
                let indexOfMatchedString = 0;

                let matches: RegExpExecArray;
                // Global RegExps are stateful, this makes it start at 0 if reused
                // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/exec
                // @ts-ignore
                pattern.pattern.lastIndex = 0;
                // @ts-ignore
                while (textLeft && (matches = pattern.pattern.exec(textLeft))) {
                    const previousText = textLeft.substr(0, matches.index);
                    indexOfMatchedString = matches.index;

                    if (++currentMatches > numberOfMatchesPermitted) {
                        // Abort if we've exhausted our number of matches
                        break;
                    }

                    parts.push({ children: previousText });

                    parts.push(
                        this.getMatchedPart(pattern, matches[0], matches, indexOfMatchedString),
                    );

                    textLeft = textLeft.substr(matches.index + matches[0].length);
                    indexOfMatchedString += matches[0].length - 1;
                    // Global RegExps are stateful, this makes it operate on the "remainder" of the string
                    // @ts-ignore
                    pattern.pattern.lastIndex = 0;
                }

                parts.push({ children: textLeft });

                newParts.push(...parts);
            });

            parsedTexts = newParts;
        });

        // Remove _matched key.
        // @ts-ignore
        parsedTexts.forEach((parsedText) => delete parsedText._matched);

        return parsedTexts.filter((t) => !!t.children);
    }

    /**
     * @param {ParseShape} matchedPattern - pattern configuration of the pattern used to match the text
     * @param {string} text - Text matching the pattern
     * @param {string[]} matches - Result of the RegExp.exec
     * @param {number} index - Index of the matched string in the whole string
     * @return {Object} props for the matched text
     */
    private getMatchedPart(
        matchedPattern: ParseShape,
        text: string,
        matches: string[],
        index: number,
    ) {
        const props = {} as any;

        Object.keys(matchedPattern).forEach((key) => {
            if (
                key === 'pattern' ||
                key === 'renderText' ||
                key === 'nonExhaustiveModeMaxMatchCount'
            ) {
                return;
            }
            // @ts-ignore
            if (typeof matchedPattern[key] === 'function') {
                // Support onPress / onLongPress functions
                // @ts-ignore
                props[key] = () => matchedPattern[key](text, index);
            } else {
                // Set a prop with an arbitrary name to the value in the match-config
                // @ts-ignore
                props[key] = matchedPattern[key];
            }
        });

        let children = text;
        if (matchedPattern.renderText && typeof matchedPattern.renderText === 'function') {
            children = matchedPattern.renderText(text, matches);
        }

        return {
            ...props,
            children,
            _matched: true,
        };
    }
}

export default TextExtraction;
