import { Flex } from '@twilio-paste/flex';
import { FrontlineLogo } from './FrontlineLogo';

export function LoadingScreen() {
    return (
        <Flex height="100vh" hAlignContent="center" vAlignContent="center">
            <FrontlineLogo size={64} />
        </Flex>
    );
}
