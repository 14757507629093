export function updateAnchorUrl(elementId: string, newUrl: string) {
    const anchorElement = document.getElementById(elementId) as HTMLAnchorElement | null;
    if (anchorElement != null) {
        anchorElement.href = newUrl;
    }
}

export function updateAppBadgeAndTitle(unreadMessagesCount: number) {
    if (unreadMessagesCount > 0) {
        updateAnchorUrl('js-favicon', '/unread-icons/favicon.ico');
        updateAnchorUrl('js-icon', '/unread-icons/icon.svg');
        const unreadCount = unreadMessagesCount > 99 ? '99+' : unreadMessagesCount;
        document.title = `(${unreadCount}) Twilio Frontline`;
    } else {
        updateAnchorUrl('js-favicon', '/favicon.ico');
        updateAnchorUrl('js-icon', '/icon.svg');
        document.title = 'Twilio Frontline';
    }
}
