import { differenceInCalendarDays, format } from 'date-fns';

export function formatDateToNow(
    date: Date | number,
    is24Hour: boolean,
    renderDefaultFormat: (date: Date) => string,
): string {
    const currentDate = !(date instanceof Date) ? new Date(date) : date;
    const dateNowInUTC = new Date().getTime();
    const differenceInDays = differenceInCalendarDays(dateNowInUTC, currentDate);

    switch (true) {
        case differenceInDays < 1:
            return format(currentDate, is24Hour ? 'HH:mm' : 'hh:mm a');
        case differenceInDays === 1:
            return 'Yesterday';
        case differenceInDays <= 7:
            return format(currentDate, 'iii');
        default:
            return renderDefaultFormat(currentDate);
    }
}
