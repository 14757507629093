import { ReactNode } from 'react';
import { styled } from '@twilio-paste/styling-library';
import {
    ModalDialogPrimitiveOverlay,
    ModalDialogPrimitiveContent,
} from '@twilio-paste/modal-dialog-primitive';
import { colorBackgroundBrandStronger } from '@twilio-paste/design-tokens';

const StyledModalDialogOverlay = styled(ModalDialogPrimitiveOverlay)(() => ({
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(0, 0, 0, 0.7)',
}));

const StyledModalDialogContent = styled(ModalDialogPrimitiveContent)({
    width: 'auto',
    maxWidth: '300px',
    maxHeight: 'calc(100% - 60px)',
    background: colorBackgroundBrandStronger,
    borderRadius: '4px',
    padding: '20px',
});

type PrimitiveModalDialogProps = {
    isOpen: boolean;
    children: ReactNode;
    handleClose?: () => void;
};

export function PrimitiveModalDialog({ isOpen, children, handleClose }: PrimitiveModalDialogProps) {
    return (
        <StyledModalDialogOverlay allowPinchZoom isOpen={isOpen} onDismiss={handleClose}>
            <StyledModalDialogContent>{children}</StyledModalDialogContent>
        </StyledModalDialogOverlay>
    );
}
