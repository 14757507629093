import { ModalBody } from '@twilio-paste/modal';
import { Spinner } from '@twilio-paste/spinner';
import { Text } from '@twilio-paste/text';
import { Box } from '@twilio-paste/box';

export function TemplatesModalSpinner() {
    return (
        <ModalBody>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
                height="size10"
                paddingLeft="space50"
                paddingRight="space50">
                <Spinner decorative={false} color="colorTextWeak" title="Loading templates" />
                <Text
                    as="span"
                    color="colorTextWeak"
                    fontSize="fontSize30"
                    lineHeight="lineHeight20"
                    paddingLeft="space30">
                    Loading templates
                </Text>
            </Box>
        </ModalBody>
    );
}
