import { createContext, useContext, ReactNode } from 'react';
import { UseToasterReturnedProps } from '@twilio-paste/toast/dist/types';
import { Toaster, useToaster } from '@twilio-paste/toast';

export const ToasterContext = createContext<UseToasterReturnedProps | null>(null);

export function ToasterProvider({ children }: { children: ReactNode }) {
    const toaster = useToaster();
    return (
        <ToasterContext.Provider value={toaster}>
            {children}
            <Toaster {...toaster} />
        </ToasterContext.Provider>
    );
}

export function useToasterContext() {
    const context = useContext(ToasterContext);
    if (!context) {
        throw new Error('useToasterContext must be used within a ToasterContext.Provider');
    }
    return context;
}
