import {
    useConversationCustomer,
    useChannelType,
} from '@twilio/frontline-shared/store/ParticipantsState';
import { useConversation } from '@twilio/frontline-shared/store/conversations/hooks';
import { useConversationsUser } from '@twilio/frontline-shared/store/users/ConversationsUsersState';
import { ConversationViewVariant } from '../Conversation/types';

import { ConversationState } from '../Conversation/Conversation';
import { ConversationHeader } from './ConversationHeader';

export type ConversationHeaderContainerProps = {
    conversationSid: string;
    userIdentity: string;
    variant: ConversationViewVariant;
    state: ConversationState;
    onCaptionClick?: () => void;
};

export function ConversationHeaderContainer({
    conversationSid,
    userIdentity,
    variant,
    state,
    onCaptionClick,
}: ConversationHeaderContainerProps) {
    const conversation = useConversation(conversationSid);
    const channelType = useChannelType(conversationSid);
    const userParticipant = useConversationsUser(userIdentity);

    const customerParticipant = useConversationCustomer(conversationSid);

    return (
        <ConversationHeader
            channelSid={conversationSid}
            channelName={conversation?.friendlyName}
            channelType={channelType}
            channelAvatar={conversation?.avatar}
            state={state}
            variant={variant}
            currentUserFriendlyName={userParticipant?.friendlyName}
            currentUserIdentity={userIdentity}
            customerParticipantDisplayName={customerParticipant?.displayName || 'Customer'}
            onCaptionClick={onCaptionClick}
        />
    );
}
