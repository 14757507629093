type RefreshTokenResponse = {
    token: string;
    expiration: string;
};

export enum SSOError {
    REQUEST_FAILED = 'REQUEST_FAILED',
    NOT_FOUND = 'NOT_FOUND',
}

export const SSOErrorToMessage = {
    [SSOError.REQUEST_FAILED]:
        'Failed to get SSO configuration. Please check and try again, or contact your IT support.',
    [SSOError.NOT_FOUND]:
        'We’re unable to find this workspace. Re-enter your workspace ID and try again.',
};

export class FASClient {
    static SSO_REDIRECT_URL: string;

    static FRONTLINE_API_BASE_URL: string;

    static IAM_BASE_URL: string;

    static initialize(config: {
        SSO_REDIRECT_URL: string;
        FRONTLINE_API_BASE_URL: string;
        IAM_BASE_URL: string;
    }) {
        FASClient.SSO_REDIRECT_URL = config.SSO_REDIRECT_URL;
        FASClient.FRONTLINE_API_BASE_URL = config.FRONTLINE_API_BASE_URL;
        FASClient.IAM_BASE_URL = config.IAM_BASE_URL;
    }

    static getSSOUrl(companyName: string): Promise<string> {
        return fetch(`${FASClient.FRONTLINE_API_BASE_URL}/v1/saml2/initiate/${companyName}`, {
            method: 'POST',
            body: JSON.stringify({
                resource: FASClient.SSO_REDIRECT_URL,
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((resp) => {
                if (resp.status === 404) {
                    return Promise.reject(SSOError.NOT_FOUND);
                }
                return resp.json();
            })
            .then((json) => {
                if (!json.location) {
                    return Promise.reject(new Error('Cannot initialize SSO'));
                }
                return json.location;
            });
    }

    static refreshToken(realmSid: string, token: string): Promise<RefreshTokenResponse> {
        return fetch(`${FASClient.IAM_BASE_URL}/v2/Tokens/refresh/${realmSid}`, {
            method: 'POST',
            body: JSON.stringify({
                token,
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((resp) => {
                if (resp.status >= 400) {
                    return Promise.reject(resp.json());
                }
                return resp.json();
            })
            .then((json) => {
                if (json.error) {
                    return Promise.reject(json.error);
                }
                return json;
            });
    }
}
