import {
    AppCallback,
    GetCustomerDetailsByCustomerIdPayload,
    GetCustomersListPayload,
    PostCustomerPayload,
    DeleteCustomerPayload,
} from '@twilio/frontline-shared/core/Callbacks/types';
import { FrontlineSDK } from '@twilio/frontline-shared/sdk/FrontlineSDK';
import { Customer, CustomersList } from '../../types/customer';
import { Channel } from '../../types/channel';

export type CRMCallbackResponse = {
    objects?: {
        customer?: Customer;
        customers?: Customer[];
        searchable?: boolean;
        next_page_token?: string;
    };
};

export type CreateCustomerRequest = {
    customerName: string;
    company: string;
    channels: Channel[];
};

export type PostCreateCustomerCallbackResponse = {
    channels: Channel[];
    customer_id: string;
    details: any;
};

export type GetCustomerDetailsByCustomerIdCallback = AppCallback<
    GetCustomerDetailsByCustomerIdPayload,
    Customer | undefined
>;
export const getCustomerDetailsByCustomerId: GetCustomerDetailsByCustomerIdCallback = ({
    payload,
}) =>
    FrontlineSDK.shared!.callCallbackUrl('GetCustomerDetailsByCustomerId', payload).then(
        (json) => json?.objects?.customer,
    );

export type GetCustomersListCallback = AppCallback<GetCustomersListPayload, CustomersList>;
export const getCustomersList: GetCustomersListCallback = ({ payload }) =>
    FrontlineSDK.shared!.callCallbackUrl('GetCustomersList', payload).then(
        (json: CRMCallbackResponse) => ({
            customers: json?.objects?.customers,
            searchable: !!json?.objects?.searchable,
            nextPageToken: json?.objects?.next_page_token,
        }),
    );

export type CreateCustomerCallback = AppCallback<
    PostCustomerPayload,
    PostCreateCustomerCallbackResponse | undefined
>;
export const postCreateCustomerRequest: CreateCustomerCallback = ({ payload }) =>
    FrontlineSDK.shared!.callCallbackUrl('CreateCustomer', payload).then(
        (json) => json?.objects?.customer,
    );

export type DeleteCustomerCallback = AppCallback<DeleteCustomerPayload, undefined>;
export const postDeleteCustomerRequest: DeleteCustomerCallback = ({ payload }) =>
    FrontlineSDK.shared!.callCallbackUrl('DeleteCustomer', payload);
