export type Token = JWEToken;

type IToken = { identity: string; token: string; expiration: string };

export class JWEToken {
    public readonly identity: string;

    public readonly expiration: Date;

    public readonly token: string;

    constructor(identity: string, token: string, expiration: string) {
        this.identity = identity;
        this.expiration = new Date(expiration);
        this.token = token;
    }

    static fromJSON(json: IToken) {
        return new JWEToken(json.identity, json.token, json.expiration);
    }

    isValid(): boolean {
        return this.expiration > new Date();
    }
}
