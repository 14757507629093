import { useSelector } from 'react-redux';

import { createReducer } from '@reduxjs/toolkit';
import { IUser } from '@twilio/frontline-shared/models/User';
import { ExtendsChatState } from '../ChatState';
import { removeConversationsUser, setConversationUsers, upsertConversationsUser } from './actions';

export type ConversationsUsersState = { [key: string]: IUser };

export const initialState: ConversationsUsersState = {};

export const reduce = createReducer<ConversationsUsersState>(initialState, (builder) => {
    builder
        .addCase(setConversationUsers, (state, action) => {
            const { users } = action.payload;
            users.forEach((user) => {
                state[user.identity] = user;
            });
        })
        .addCase(upsertConversationsUser, (state, action) => {
            const { user } = action.payload;
            state[user.identity] = user;
        })
        .addCase(removeConversationsUser, (state, action) => {
            const { identity } = action.payload;
            delete state[identity];
        });
});

export const selectUsers = (state: ExtendsChatState) => state.chat.users;

export const selectUser = (state: ExtendsChatState, identity: string) => state.chat.users[identity];

export const useConversationsUsers = () => {
    return useSelector<ExtendsChatState, ConversationsUsersState>(selectUsers);
};

export const useConversationsUser = (identity: string) => {
    return useSelector<ExtendsChatState, IUser>((state) => selectUser(state, identity));
};
