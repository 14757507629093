import { useState } from 'react';
import { Conversation } from '@twilio/frontline-shared/models/Conversation';
import {
    Modal,
    ModalBody,
    ModalHeader,
    ModalHeading,
    ModalFooter,
    ModalFooterActions,
} from '@twilio-paste/modal';
import { useUID } from '@twilio-paste/uid-library';
import { Button } from '@twilio-paste/button';
import { useHistory } from 'react-router';
import { Participant } from '@twilio/frontline-shared/models/Participant';
import { Analytics } from '@twilio/frontline-shared/analytics';
import { WaitingOverlay } from '../WaitingOverlay';
import { useSession } from '../../context/SessionContext';
import { useToasterContext } from '../../context/ToasterContext';
import { closeConversationErrorToast, closeConversationSuccessToast } from './toasts';

export const enum CloseState {
    Idle = 'idle',
    Closing = 'closing',
}

export type CloseConversationProps = {
    conversationSid: Conversation['sid'];
    customerParticipantDisplayName: Participant['displayName'];
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
};

export function CloseConversation({
    conversationSid,
    customerParticipantDisplayName,
    isOpen,
    setIsOpen,
}: CloseConversationProps) {
    const toaster = useToasterContext();
    const { session } = useSession();
    const [closeState, setCloseState] = useState<CloseState>(CloseState.Idle);
    const history = useHistory();

    const handleCancelClose = () => {
        Analytics.logEvent('click_close_conversation_alert_cancel');
        setIsOpen(false);
    };

    const handleConfirmClose = () => {
        Analytics.logEvent('click_close_conversation_alert_confirm');
        setIsOpen(false);
        closeConversation(conversationSid);
    };

    const closeConversation = (convoSid: Conversation['sid']) => {
        setCloseState(CloseState.Closing);
        session
            ?.closeConversation(convoSid)
            .then(() => {
                toaster.push(
                    closeConversationSuccessToast(convoSid, customerParticipantDisplayName),
                );
                history.replace(history.location.pathname);
            })
            .catch((e) => {
                setCloseState(CloseState.Idle);
                toaster.push(closeConversationErrorToast(convoSid, closeConversation));
                if (e?.body?.message) {
                    console.log('Failed to close conversation', e?.body?.message);
                }
            });
    };

    const modalHeadingID = useUID();
    return (
        <>
            <Modal
                ariaLabelledby={modalHeadingID}
                isOpen={isOpen}
                onDismiss={handleCancelClose}
                size="default">
                <ModalHeader>
                    <ModalHeading as="h3" id={modalHeadingID} data-testid="heading-title">
                        Close conversation with {customerParticipantDisplayName}?
                    </ModalHeading>
                </ModalHeader>
                <ModalBody data-testid="heading-body">
                    The conversation will be removed from your list and archived.
                </ModalBody>
                <ModalFooter>
                    <ModalFooterActions>
                        <Button
                            data-testid="cancel-button"
                            variant="secondary"
                            onClick={handleCancelClose}>
                            Cancel
                        </Button>
                        <Button
                            data-testid="close-button"
                            variant="destructive"
                            onClick={handleConfirmClose}>
                            Close conversation
                        </Button>
                    </ModalFooterActions>
                </ModalFooter>
            </Modal>
            <WaitingOverlay
                isOpen={closeState === CloseState.Closing}
                message="Closing conversation"
            />
        </>
    );
}
