import { call, put, select } from 'redux-saga/effects';

import { TwilioUser as User } from '@twilio/frontline-shared/types';
import { fromTwilioUser } from '@twilio/frontline-shared/models/User';

import { AddConversationsUsers, upsertConversationsUser } from './actions';
import { ConversationsUsersState, selectUsers } from './ConversationsUsersState';
import { FrontlineSDK } from '../../sdk/FrontlineSDK';

export function* addConversationsUsersSaga(
    frontlineSDK: typeof FrontlineSDK,
    action: AddConversationsUsers,
) {
    const { identities } = action.payload;

    for (const identity of identities) {
        const users: ConversationsUsersState = yield select(selectUsers);
        if (!users[identity]) {
            try {
                const user: User = yield call(frontlineSDK.shared!.getUser, identity);
                yield put(upsertConversationsUser({ user: fromTwilioUser(user) }));
            } catch (err) {
                console.error('Failed to fetch user ', identity, err);
            }
        }
    }
}
