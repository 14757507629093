import { Flex } from '@twilio-paste/flex';
import { Text } from '@twilio-paste/text';
import { ReactNode } from 'react';

type CustomToastMessageProps = {
    title?: string;
    children?: ReactNode;
};

export function CustomToastMessage({ title, children }: CustomToastMessageProps) {
    return (
        <Flex vertical>
            {title && (
                <Text as="span" fontWeight="fontWeightSemibold" data-testid="toast-title">
                    {title}
                </Text>
            )}
            {children}
        </Flex>
    );
}
