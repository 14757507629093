import { call, put, select } from '@redux-saga/core/effects';

import {
    loadedMedia,
    loadMedia,
    NewMediaAction,
} from '@twilio/frontline-shared/store/media/actions';
import { fromTwilioMedia, IMedia } from '@twilio/frontline-shared/models/Media';
import { AppState } from '../AppState';

export function* receiveMediaSaga(action: NewMediaAction) {
    const { media: twilioMedia, message } = action.payload;
    const media = fromTwilioMedia(twilioMedia, message.attributes);

    const existingMedia: IMedia | undefined = yield select(
        (state: AppState) => state.chat.media[media.sid],
    );
    // Media was already processed
    if (existingMedia) {
        return;
    }

    yield put(loadMedia({ media }));

    try {
        const getContentUrl = twilioMedia.getContentTemporaryUrl.bind(twilioMedia);
        const contentUrl: string = yield call(getContentUrl);
        yield put(loadedMedia({ mediaSid: media.sid, path: contentUrl, height: 200, width: 200 }));
    } catch (error) {
        console.log('😠 fetching media url failed');
    }
}
