import React from 'react';
import { Button, StyleSheet, Text as RNText, View } from 'react-native';
import { Alert } from '@twilio-paste/alert';
import { Spinner } from '@twilio-paste/spinner';
import { Text } from '@twilio-paste/text';
import { Box } from '@twilio-paste/box';
import { Flex } from '@twilio-paste/flex';

import { ConversationMessage } from '@twilio/frontline-shared/models/Message';
import {
    compareMessages,
    isWaitingForResponse,
    toGiftedChatMessages,
    unreadMessagesSystemMessage,
} from '@twilio/frontline-shared/components/Chat/utils';
import { ChatMessage } from '@twilio/frontline-shared/components/Chat/types';
import { useDispatch } from '@twilio/frontline-shared/store/redux';
import { Participant } from '@twilio/frontline-shared/models/Participant';
import { ChannelType } from '@twilio/frontline-shared/types/channel';
import { Conversation } from '@twilio/frontline-shared/models/Conversation';
import { SystemEvent } from '@twilio/frontline-shared/models/Event';
import { IUser } from '@twilio/frontline-shared/models/User';
import {
    sendMediaMessage,
    sendMessage as sendMessageAction,
} from '@twilio/frontline-shared/store/messages/actions';
import { loadPrevMessages } from '@twilio/frontline-shared/store/conversations/actions';
import { IDraftMedia } from '@twilio/frontline-shared/models/Media';
import { saveDraft } from '@twilio/frontline-shared/store/draft/actions';
import { Attributes } from '@twilio/frontline-shared/types';
import { isNumber } from '@twilio/frontline-shared/utils/isNumber';
import { ConversationsUsersState } from '@twilio/frontline-shared/store/users/ConversationsUsersState';

import { ConversationHeader } from '../ConversationHeader';
import { ConversationViewVariant } from './types';
import { ChatContainer } from '../ChatContainer';

export type ConversationState = 'ready' | 'loading' | 'error';

type ConversationProps = {
    user: IUser;
    channelName?: Conversation['friendlyName'];
    channelSid?: Conversation['sid'];
    avatar?: Conversation['avatar'];
    events: SystemEvent[];
    messages: ConversationMessage[];
    customerParticipant?: Participant;
    consumptionHorizon: number;
    newMessagesCount?: number;
    hasOlderMessages: boolean;
    state: ConversationState;
    fetchConversation: () => void;
    draftMessage: string;
    isOutOfWhatsAppWindow: boolean;
    hideSendAttachmentButton: boolean;
    channelType?: ChannelType;
    lastChatReadTime?: number;
    users: ConversationsUsersState;
    hideInputToolbar?: boolean;
    variant: ConversationViewVariant;
    participantIdentity: string;
    onConversationHeaderClick?: () => void;
};

function PureConversation({
    user,
    channelName,
    channelSid,
    avatar,
    messages,
    events,
    customerParticipant,
    consumptionHorizon,
    newMessagesCount,
    hasOlderMessages,
    state,
    fetchConversation,
    draftMessage,
    isOutOfWhatsAppWindow,
    hideSendAttachmentButton,
    hideInputToolbar = false,
    channelType,
    lastChatReadTime,
    users,
    variant,
    participantIdentity,
    onConversationHeaderClick,
}: ConversationProps) {
    const dispatch = useDispatch();
    const chatMessages: Array<ChatMessage> = toGiftedChatMessages(
        messages,
        users,
        events,
        hasOlderMessages,
        customerParticipant,
        lastChatReadTime,
        channelType,
    ).sort(compareMessages);
    if (newMessagesCount && newMessagesCount > 0 && variant === ConversationViewVariant.USER) {
        const consumptionHorizonIndex = chatMessages.findIndex(
            (message) => message.createdAt === consumptionHorizon,
        );
        // if consumptionHorizonIndex is 0, there is no new message
        if (consumptionHorizonIndex > 0) {
            chatMessages.splice(
                consumptionHorizonIndex,
                0,
                unreadMessagesSystemMessage(
                    newMessagesCount,
                    chatMessages[consumptionHorizonIndex].createdAt,
                ),
            );
        } else if (consumptionHorizonIndex === -1) {
            // consumptionHorizonIndex -1 means all messages are new
            chatMessages.push(
                unreadMessagesSystemMessage(newMessagesCount, chatMessages[0]?.createdAt),
            );
        }
    }

    const sendMessage = (message: string, attributes?: Attributes) => {
        if (channelSid) {
            dispatch(sendMessageAction({ channelSid, body: message, attributes }));
        }
    };

    const sendMedia = (media: IDraftMedia) => {
        if (channelSid) {
            dispatch(
                sendMediaMessage({
                    channelSid,
                    media,
                }),
            );
        }
    };
    const onSaveDraft = (text: string) => {
        if (channelSid) {
            dispatch(saveDraft({ channelSid, message: text }));
        }
    };

    const loadPrev = () => {
        const oldestMessageIndex = messages[0]?.index;
        if (
            !isNumber(oldestMessageIndex) ||
            oldestMessageIndex === 0 ||
            !channelSid ||
            !hasOlderMessages
        ) {
            return;
        }
        const oldestMessageCreatedAt = messages[0].createdAt;
        dispatch(
            loadPrevMessages({
                conversationSid: channelSid,
                messageIndex: oldestMessageIndex,
                messageCreatedAt: oldestMessageCreatedAt,
            }),
        );
    };
    return (
        <View style={{ flex: 1 }}>
            <ConversationHeader
                conversationSid={channelSid || ''}
                state={state}
                userIdentity={participantIdentity}
                variant={variant}
                onCaptionClick={onConversationHeaderClick}
            />
            {isOutOfWhatsAppWindow && isWaitingForResponse(messages) && (
                <Alert variant="neutral">
                    <Text as="span">Waiting for customer response</Text>
                </Alert>
            )}
            {state === 'error' && (
                <View style={styles.center}>
                    <RNText style={styles.failedFetchMessage}>Failed to fetch conversation</RNText>
                    <Button title="Retry" onPress={fetchConversation} />
                </View>
            )}
            {state === 'loading' && (
                <View style={styles.center}>
                    <Flex hAlignContent="center">
                        <Spinner size="sizeIcon20" decorative={false} title="Loading" />
                        <Box paddingLeft="space30">
                            <RNText>Loading conversation</RNText>
                        </Box>
                    </Flex>
                </View>
            )}
            {state === 'ready' && channelSid && user && (
                <ChatContainer
                    user={{ _id: user.identity }}
                    customerParticipant={customerParticipant}
                    messages={chatMessages}
                    conversationFriendlyName={channelName || ''}
                    conversationSid={channelSid}
                    channelType={channelType || ChannelType.Chat}
                    draftMessage={draftMessage}
                    isOutOfWhatsAppWindow={isOutOfWhatsAppWindow}
                    hideSendAttachmentButton={hideSendAttachmentButton}
                    hideInputToolbar={hideInputToolbar}
                    onSend={sendMessage}
                    onSendMedia={sendMedia}
                    onEndReached={loadPrev}
                    saveDraft={onSaveDraft}
                />
            )}
        </View>
    );
}

export const ConversationView = React.memo(PureConversation);

const styles = StyleSheet.create({
    keyboardAvoidingView: { flex: 1, height: '100%' },
    center: {
        flex: 1,
        justifyContent: 'center',
    },
    failedFetchMessage: {
        alignSelf: 'center',
    },
});
