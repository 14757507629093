import { IMedia } from '@twilio/frontline-shared/models/Media';
import { IMessage } from '@twilio/frontline-shared/models/Message';
import { TwilioMedia } from '@twilio/frontline-shared/types';
import { createAction } from '../Action';

export const newMedia =
    createAction('CHANNEL_NEW_MEDIA')<{
        media: TwilioMedia;
        message: IMessage;
    }>();

export type NewMediaAction = ReturnType<typeof newMedia>;

export const loadMedia = createAction('CHANNEL_MEDIA_LOAD')<{ media: IMedia }>();

export type LoadMediaAction = ReturnType<typeof loadMedia>;

export const loadedMedia =
    createAction('CHANNEL_MEDIA_LOADED')<{
        mediaSid: IMedia['sid'];
        path: string;
        height?: number;
        width?: number;
    }>();

export type LoadedMediaAction = ReturnType<typeof loadedMedia>;

export const sendMedia = createAction('CHANNEL_SEND_MEDIA')<{ media: IMedia }>();

export type SendMediaAction = ReturnType<typeof sendMedia>;

export const downloadMedia = createAction('CHANNEL_DOWNLOAD_MEDIA')<{ media: IMedia }>();

export type DownloadMediaAction = ReturnType<typeof downloadMedia>;

export const initializeMedia = createAction('CHANNEL_INITIALIZE_MEDIA')<{ media: IMedia }>();

export type InitializeMedia = ReturnType<typeof initializeMedia>;

export type ChannelMediaActions =
    | LoadMediaAction
    | LoadedMediaAction
    | SendMediaAction
    | InitializeMedia;
