import { Button } from '@twilio-paste/button';
import { Text } from '@twilio-paste/text';
import { CustomToastMessage } from './CustomToastMessage';

type ToastMessageProps = {
    title?: string;
    text: string;
    onRetry?: () => void;
};

export function ToastMessage({ title, text, onRetry }: ToastMessageProps) {
    return (
        <CustomToastMessage title={title}>
            <Text as="span" data-testid="toast-text">
                {`${text} `}
                {onRetry && (
                    <Button variant="link" onClick={onRetry} data-testid="retry-button">
                        Retry
                    </Button>
                )}
            </Text>
        </CustomToastMessage>
    );
}
