import React from 'react';
import { StyleSheet, View, Text, ActivityIndicator, Platform } from 'react-native';

import {
    MediaDocument,
    MediaStatus,
    MediaUnsupported,
    MediaVoice,
    isMediaNotDownloaded,
    isMediaReady,
    isMediaLoading,
} from '@twilio/frontline-shared/models/Media';
import { formatFilesize } from '@twilio/frontline-shared/utils/filesize';
import { DefaultTheme } from '@twilio/frontline-shared/theme';
import { TwilIcon } from '@twilio/frontline-shared/components/TwilIcon';

const iconSize = 24;

export function DocumentPreview({
    document,
}: {
    document: Omit<MediaDocument | MediaVoice | MediaUnsupported, 'state' | 'sid'> & {
        state?: MediaStatus;
    };
}) {
    return (
        <View style={styles.container}>
            {(typeof document.state === 'undefined' || isMediaReady(document)) && (
                <TwilIcon name="document" size={iconSize} />
            )}
            {isMediaNotDownloaded(document) && (
                <TwilIcon name="download" size={iconSize} style={styles.downloadIcon} />
            )}
            {isMediaLoading(document) && <ActivityIndicator style={styles.loading} />}
            <View style={styles.texts}>
                <Text style={styles.filename} numberOfLines={1} ellipsizeMode="middle">
                    {document.filename || document.contentType}
                    {Platform.OS === 'android' && ' ' /* fixes last letter */}
                </Text>
                <Text style={styles.size}>{formatFilesize(document.size)}</Text>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        margin: DefaultTheme.space.space10,
        marginBottom: 0,
        backgroundColor: DefaultTheme.backgroundColors.colorBackgroundBody,
        paddingHorizontal: DefaultTheme.space.space30,
        paddingVertical: DefaultTheme.space.space20,
        paddingRight: DefaultTheme.space.space40,
        borderRadius: DefaultTheme.radii.borderRadius4,
        borderStyle: 'solid',
        borderColor: DefaultTheme.borderColors.colorBorderLight,
        flexDirection: 'row',
        alignItems: 'center',
    },
    filename: {
        fontWeight: DefaultTheme.fontWeights.fontWeightSemibold,
        fontSize: DefaultTheme.fontSizes.fontSize16,
    },
    size: {
        fontSize: DefaultTheme.fontSizes.fontSize10,
        color: DefaultTheme.textColors.colorTextWeak,
        textTransform: 'uppercase',
    },
    texts: {
        flexDirection: 'column',
        marginLeft: DefaultTheme.space.space30,
        marginRight: DefaultTheme.space.space30,
    },
    downloadIcon: {
        width: iconSize,
        textAlign: 'center',
    },
    loading: {
        width: iconSize,
    },
});
