import { AggregatedDeliveryReceipt, DetailedDeliveryReceipt } from '@twilio/conversations';
import { ChannelType } from '@twilio/frontline-shared/types/channel';
import { Attributes, ConversationSid, MediaSid, MessageSid, TwilioMessage } from '../types';
import { isString } from '../utils/isString';
import { isNumber } from '../utils/isNumber';
import { Participant } from './Participant';
import { IUser } from './User';
import { SidFactory } from '../types/SidFactory';

const WHATSAPP_AUTHOR_PREFIX = 'whatsapp:';

export enum MessageType {
    Text,
    Media,
}

export enum MessageStatus {
    Sending,
    Sent,
    Failed,
    Delivered,
    Read,
    Undelivered,
}

export type IMessage = {
    sid: MessageSid;
    tempId?: string;
    participantSid: string;
    status: MessageStatus;
    messageType: MessageType;
    index: number;
    body?: string;
    mediaSid?: MediaSid;
    channelSid: ConversationSid;
    author: string;
    attributes: Attributes;
    createdAt: Date;
    errorCode?: number;
};

export type LocalMessage = Omit<IMessage, 'index' | 'sid'> & {
    sid: string; // fixme: this not a MessageSid. Rename it to "referenceId"
    index: null;
};

export type ConversationMessage = LocalMessage | IMessage;

export const fromTwilioMessages = (messages: TwilioMessage[]): IMessage[] =>
    messages.map(fromTwilioMessage);

export const fromTwilioMessage = (message: TwilioMessage): IMessage => {
    const attributes = message.attributes as Attributes;
    const idAttribute = isString(attributes.messageId) ? attributes.messageId : undefined;
    const mediaIdAttributes = isString(attributes.mediaId) ? attributes.mediaId : undefined;

    return {
        sid: SidFactory.messageSid(message.sid),
        tempId: idAttribute,
        status: message.aggregatedDeliveryReceipt
            ? getMessageStatus(message.aggregatedDeliveryReceipt)
            : MessageStatus.Sent,
        messageType: message.attachedMedia?.length ? MessageType.Media : MessageType.Text,
        index: message.index,
        body: message.body || undefined,
        mediaSid: mediaIdAttributes || message.attachedMedia?.[0]?.sid,
        channelSid: SidFactory.conversationSid(message.conversation.sid),
        author: message.author || '',
        attributes,
        createdAt: message.dateCreated!,
        participantSid: message.participantSid || '',
    };
};

export const isSending = ({ status }: { status?: MessageStatus }): boolean =>
    status === MessageStatus.Sending;

export const isSent = ({ status }: { status?: MessageStatus }): boolean =>
    status === MessageStatus.Sent;

export const isFailed = ({ status }: { status?: MessageStatus }): boolean =>
    status === MessageStatus.Failed;

export const isUndelivered = ({ status }: { status?: MessageStatus }): boolean =>
    status === MessageStatus.Undelivered;

export const isDelivered = ({ status }: { status?: MessageStatus }): boolean =>
    status === MessageStatus.Delivered;

export const isRead = ({ status }: { status?: MessageStatus }): boolean =>
    status === MessageStatus.Read;

export const isHiddenMessage = (message: IMessage | TwilioMessage): boolean =>
    (message.attributes as Attributes).isHidden === true;

export const isUnsuccessfulMessage = (message: { status?: MessageStatus }): boolean =>
    isFailed(message) || isUndelivered(message);

export const isMediaMessage = (message: { messageType: MessageType }): boolean =>
    message.messageType === MessageType.Media;

export const isLocalMessage = (message: ConversationMessage): message is LocalMessage =>
    !isNumber(message.index);

export const isPersistedMessage = (message: ConversationMessage): message is IMessage =>
    !isLocalMessage(message);

export const isMessageFromWhatsApp = (message: ConversationMessage): message is IMessage =>
    message.author.startsWith(WHATSAPP_AUTHOR_PREFIX);

export const getMessageStatus = (aggregatedDelivery: AggregatedDeliveryReceipt): MessageStatus => {
    if (aggregatedDelivery.failed !== 'none') {
        return MessageStatus.Failed;
    }
    if (aggregatedDelivery.undelivered !== 'none') {
        return MessageStatus.Undelivered;
    }
    if (aggregatedDelivery.read === 'all') {
        return MessageStatus.Read;
    }
    if (aggregatedDelivery.delivered === 'all') {
        return MessageStatus.Delivered;
    }
    if (aggregatedDelivery.sent === 'all') {
        return MessageStatus.Sent;
    }
    return MessageStatus.Sent;
};
export const getMessageStatusFromReceipt = (
    receipt: DetailedDeliveryReceipt['status'],
): MessageStatus => {
    switch (receipt) {
        case 'failed':
            return MessageStatus.Failed;
        case 'undelivered':
            return MessageStatus.Undelivered;
        case 'read':
            return MessageStatus.Read;
        case 'delivered':
            return MessageStatus.Delivered;
        case 'sent':
        default:
            return MessageStatus.Sent;
    }
};

export const getLastChatReadTime = (
    participants: Participant[],
    user: IUser,
    channelType?: ChannelType,
) => {
    if (channelType !== ChannelType.Chat) {
        return 0;
    }

    const otherParticipants = participants.filter((p) => p.identity !== user?.identity);
    let lastChatReadTime = 0;

    otherParticipants.forEach((participant) => {
        const last = Math.max(participant.lastReadTime, participant.lastMessageReadTime);
        if (lastChatReadTime > 0) {
            lastChatReadTime = Math.min(lastChatReadTime, last);
        } else {
            lastChatReadTime = last;
        }
    });

    return lastChatReadTime;
};

export const getChatMessageReadStatus = (
    message: ConversationMessage,
    lastChatReadTime = 0,
    channelType?: ChannelType,
) => {
    if (channelType === ChannelType.Chat && message.status === MessageStatus.Sent) {
        const createdAt = message.createdAt?.getTime() || 0;
        // lastChatReadTime is 0 when it's a first message in the conversation
        if (lastChatReadTime === 0 || createdAt > lastChatReadTime) {
            return MessageStatus.Delivered;
        }
        return MessageStatus.Read;
    }
    return message.status;
};
