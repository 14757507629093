import { isNumber } from './isNumber';

const filesizeSymbols = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

type OutputType = 'string' | 'object';

type Options = {
    exponent?: number;
    output?: OutputType;
};

type ObjectOutput = {
    value: number;
    symbol: string;
    exponent: number;
};

type Result<T extends Options> = T extends undefined
    ? string
    : T['output'] extends 'string'
    ? string
    : T['output'] extends 'object'
    ? ObjectOutput
    : never;

// Based on filesize.js
export function filesize<T extends Options>(bytes: number, opts?: T): Result<T> {
    const ceil = 1024;
    const round = 2;
    const options: Options = opts || {};
    let e = options.exponent;
    const output = options.output || 'string';

    let resultNum;
    let resultSymbol;

    // Determining the exponent
    if (e === -1 || !isNumber(e)) {
        e = Math.floor(Math.log(bytes) / Math.log(ceil));
    }

    if (e < 0) {
        e = 0;
    }

    // Exceeding supported length, time to reduce & multiply
    if (e > 8) {
        e = 8;
    }

    // Zero is now a special case because bytes divide by 1
    if (bytes === 0) {
        resultNum = 0;
        resultSymbol = filesizeSymbols[e];
    } else {
        const val = bytes / 2 ** (e * 10);
        resultNum = Number(val.toFixed(e > 0 ? round : 0));
        resultSymbol = filesizeSymbols[e];
    }

    if (output === 'object') {
        return { value: resultNum, symbol: resultSymbol, exponent: e } as Result<T>;
    }

    return `${resultNum}${resultSymbol}` as Result<T>;
}

export const formatFilesize = (bytes: number): string => filesize(bytes);

export const bytesToMb = (bytes: number): number =>
    filesize(bytes, { output: 'object', exponent: 2 }).value;
