export enum BrowserType {
    Chrome = 'chrome',
    Firefox = 'firefox',
    Safari = 'safari',
    Edge = 'edge',
    Unknown = 'unknown',
}

export function detectBrowser() {
    const userAgent = window.navigator?.userAgent;
    if (userAgent.match(/edg/i)) {
        return BrowserType.Edge;
    }
    if (userAgent.match(/chrome|chromium|crios/i)) {
        return BrowserType.Chrome;
    }
    if (userAgent.match(/firefox|fxios/i)) {
        return BrowserType.Firefox;
    }
    if (userAgent.match(/safari/i)) {
        return BrowserType.Safari;
    }
    return BrowserType.Unknown;
}

const browserVersion = (userAgent: string, regex: RegExp) => {
    return userAgent.match(regex) ? userAgent.match(regex)![2] : null;
};

export function detectBrowserVersion() {
    const userAgent = window.navigator?.userAgent;
    const browser = detectBrowser();

    switch (browser) {
        case BrowserType.Edge:
            return `${browserVersion(userAgent, /(edge|edga|edgios|edg)\/([\d\.]+)/i)}`;
        case BrowserType.Firefox:
            return `${browserVersion(userAgent, /(firefox|fxios)\/([\d\.]+)/i)}`;
        case BrowserType.Chrome:
            return `${browserVersion(userAgent, /(chrome|crios)\/([\d\.]+)/i)}`;
        case BrowserType.Safari:
            return `${browserVersion(userAgent, /(safari)\/([\d\.]+)/i)}`;
        default:
            return `${BrowserType.Unknown}`;
    }
}

export function isNotificationSupportedBrowser(browserType: BrowserType) {
    return browserType !== BrowserType.Safari && browserType !== BrowserType.Unknown;
}
