import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { TokenManager } from '@twilio/frontline-shared/core/TokenManager';
import { useDispatch } from '@twilio/frontline-shared/store/redux';

import { SessionError } from '@twilio/frontline-shared/core/SessionError';
import { CredentialsStorage } from '../core/CredentialsStorage';
import { Session } from '../core/Session';
import { useSession } from '../context/SessionContext';
import { PAGES } from '../paths';
import { useModal } from '../context/ModalContext';

export enum AuthState {
    Unknown = 'unknown',
    Authenticated = 'authenticated',
    NotAuthenticated = 'notAuthenticated',
}

export function useAuth() {
    const [state, setState] = useState<AuthState>(AuthState.Unknown);
    const history = useHistory();
    const dispatch = useDispatch();
    const { session, setSession } = useSession();
    const { showModal } = useModal();

    const bootstrap = async () => {
        if (session) {
            setState(AuthState.Authenticated);
            return;
        }
        const [token, realmSid, accountUniqueName] = await Promise.all([
            CredentialsStorage.getToken(),
            CredentialsStorage.getRealmSID(),
            CredentialsStorage.getAccountUniqueName(),
        ]);
        console.log(token);

        if (token && realmSid) {
            try {
                const newToken = await TokenManager.refreshToken(token, realmSid);
                await CredentialsStorage.setToken(newToken);
                const newSession = await Session.create({
                    token: newToken,
                    accountUniqueName,
                    dispatch,
                    history,
                });
                setSession(newSession);
                setState(AuthState.Authenticated);
            } catch (err) {
                if (err instanceof SessionError) {
                    const [title, message] = err.alertInfo;
                    showModal(title, message);
                    history.replace(PAGES.LOGIN);
                }
                console.log('💥 useAuth error:', err);
                setState(AuthState.NotAuthenticated);
            }
            return;
        }

        setState(AuthState.NotAuthenticated);
    };

    useEffect(() => {
        bootstrap();
    }, []);

    return { authState: state };
}
