import { Box } from '@twilio-paste/box';
import { Flex } from '@twilio-paste/flex';
import { Text } from '@twilio-paste/text';
import { Button } from '@twilio-paste/button';
import { getChannelName } from '@twilio/frontline-shared/models/Participant';
import { ChannelType } from '@twilio/frontline-shared/types/channel';
import { Conversation } from '@twilio/frontline-shared/models/Conversation';
import { ChevronRightIcon } from '@twilio-paste/icons/cjs/ChevronRightIcon';
import { SkeletonLoader } from '@twilio-paste/skeleton-loader';
import { ConversationState } from './Conversation';
import { Avatar } from '../Avatar';

type ConversationInfoBoxProps = {
    friendlyName?: Conversation['friendlyName'];
    avatar?: Conversation['avatar'];
    channelType?: ChannelType;
    state: ConversationState;
    onCaptionClick?: () => void;
};

export function ConversationInfoBox({
    friendlyName,
    avatar,
    channelType,
    state,
    onCaptionClick,
}: ConversationInfoBoxProps) {
    if (state === 'loading') {
        return (
            <Flex vAlignContent="center">
                <Box paddingX="space30">
                    <SkeletonLoader width={32} height={32} borderRadius="borderRadiusCircle" />
                </Box>
                <Flex vertical>
                    <Flex>
                        <SkeletonLoader width={160} height={20} borderRadius="borderRadius20" />{' '}
                        <ChevronRightIcon
                            decorative={false}
                            title="Conversation"
                            color="colorTextIcon"
                        />
                    </Flex>
                    <Box marginTop="space20">
                        <SkeletonLoader width={117} height={20} borderRadius="borderRadius20" />
                    </Box>
                </Flex>
            </Flex>
        );
    }

    return (
        <Flex vAlignContent="center">
            <Box paddingX="space30">
                <Avatar size="sizeIcon70" friendlyName={friendlyName} avatar={avatar} />
            </Box>
            <Button variant="reset" size="reset" onClick={onCaptionClick}>
                <Flex vertical>
                    <Flex>
                        <Text
                            as="span"
                            fontWeight="fontWeightBold"
                            data-testid="convo-customer-friendly-name">
                            {friendlyName}
                        </Text>
                        <ChevronRightIcon
                            decorative={false}
                            title="Conversation"
                            color="colorTextIcon"
                        />
                    </Flex>
                    <Text
                        as="label"
                        color="colorTextWeak"
                        marginTop="space20"
                        data-testid="convo-channel">
                        {getChannelName(channelType || ChannelType.Chat)}
                    </Text>
                </Flex>
            </Button>
        </Flex>
    );
}
