import { initializeApp } from 'firebase/app';
import { getToken, getMessaging, isSupported, deleteToken } from 'firebase/messaging';

import {
    VAPID_KEY,
    FIREBASE_API_KEY,
    FIREBASE_AUTH_DOMAIN,
    FIREBASE_DATABASE_URL,
    FIREBASE_PROJECT_ID,
    FIREBASE_STORAGE_BUCKET,
    FIREBASE_MESSAGING_SENDER_ID,
    FIREBASE_APP_ID,
    FIREBASE_MEASUREMENT_ID,
} from '../env';

const firebaseConfig = {
    apiKey: FIREBASE_API_KEY,
    authDomain: FIREBASE_AUTH_DOMAIN,
    databaseURL: FIREBASE_DATABASE_URL,
    projectId: FIREBASE_PROJECT_ID,
    storageBucket: FIREBASE_STORAGE_BUCKET,
    messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
    appId: FIREBASE_APP_ID,
    measurementId: FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);

export const getOrRegisterServiceWorker = () => {
    if ('serviceWorker' in navigator) {
        return window.navigator.serviceWorker
            .getRegistration('/frontline-push-notifications-scope')
            .then((serviceWorker) => {
                if (serviceWorker) return serviceWorker;
                return window.navigator.serviceWorker.register('/push-notifications-sw.js', {
                    scope: '/frontline-push-notifications-scope',
                });
            });
    }
    throw new Error('Service workers are not supported.');
};

export const unregisterServiceWorker = () =>
    window.navigator?.serviceWorker
        ?.getRegistration('/push-notifications-sw.js')
        .then((serviceWorker) => serviceWorker?.unregister());

export const getFirebaseMessaging = () =>
    isSupported().then((isSupportedBrowser) => {
        if (isSupportedBrowser) {
            return getMessaging(app);
        }
        throw new Error("This browser doesn't support the API's required to use the firebase SDK");
    });

export const getFirebaseToken = () =>
    getOrRegisterServiceWorker()
        .then((serviceWorkerRegistration) =>
            getFirebaseMessaging().then((messaging) => ({ messaging, serviceWorkerRegistration })),
        )
        .then(({ messaging, serviceWorkerRegistration }) =>
            getToken(messaging, { vapidKey: VAPID_KEY, serviceWorkerRegistration }),
        );

export const requestPermission = () =>
    getOrRegisterServiceWorker().then((serviceWorkerRegistration) =>
        Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
                console.log('Notification permission granted.');
                return getFirebaseMessaging().then((messaging) =>
                    getToken(messaging, { vapidKey: VAPID_KEY, serviceWorkerRegistration }),
                );
            }
            console.log('Notification permission denied.');
            return null;
        }),
    );

export const deleteFirebaseToken = () =>
    getFirebaseMessaging()
        .then((messaging) => deleteToken(messaging))
        .then(unregisterServiceWorker);

// We aren't forcing browsers to use the new service worker, some browsers are still using the old ones. So we will remove the old service worker.
export const unregisterOldServiceWorker = () => {
    window.navigator?.serviceWorker
        ?.getRegistration('/firebase-cloud-messaging-push-scope')
        .then((serviceWorker) => serviceWorker?.unregister())
        .catch((er) => console.log('An error occurred while unregister service worker. ', er));

    window.navigator?.serviceWorker
        ?.getRegistration('/')
        .then((serviceWorker) => serviceWorker?.unregister())
        .catch((er) => console.log('An error occurred while unregister service worker. ', er));
};
