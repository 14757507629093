import { Text } from '@twilio-paste/text';
import { Heading } from '@twilio-paste/heading';
import { Flex } from '@twilio-paste/flex';
import { Box } from '@twilio-paste/box';
import { Button } from '@twilio-paste/button';
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalFooterActions,
    ModalHeader,
} from '@twilio-paste/modal';
import { DownloadIcon } from '@twilio-paste/icons/cjs/DownloadIcon';
import { useUID } from '@twilio-paste/uid-library';
import { format } from 'date-fns';
import { saveAs } from 'file-saver';

import { IChatMessage } from '@twilio/frontline-shared/components/Chat/types';
import { MediaImage } from '@twilio/frontline-shared/models/Media';

import { Avatar } from '../../Avatar';
import { dateTimeFormatter } from '../../../utils/date';

const PREVIEW_MAX_HEIGHT = 652;
const PREVIEW_MAX_WIDTH = 574;

type MessageMediaPreviewModalProps = {
    image: MediaImage;
    message: IChatMessage;
    isOpen: boolean;
    onDismiss: () => void;
};

export function MessageMediaPreviewModal({
    isOpen,
    image,
    message,
    onDismiss,
}: MessageMediaPreviewModalProps) {
    const modalHeadingID = useUID();

    const messageCreatedAt = dateTimeFormatter(new Date(message.createdAt));

    const handleImageDownload = async () => {
        if (!image.path) return;
        const imageMessageCreatedAt = format(message.createdAt, 'yyyy-MM-dd_HH-mm-ss');
        saveAs(image.path, `Frontline_${imageMessageCreatedAt}`);
    };

    return (
        <Modal ariaLabelledby={modalHeadingID} isOpen={isOpen} onDismiss={onDismiss} size="default">
            <ModalHeader>
                <Flex vAlignContent="center">
                    <Avatar
                        size="sizeIcon70"
                        avatar={message.user.avatar}
                        friendlyName={message.user.name}
                    />
                    <Box marginLeft="space30">
                        <Heading variant="heading50" as="h5" marginBottom="space0">
                            {message.user.name}
                        </Heading>
                        <Text as="span">Sent {messageCreatedAt}</Text>
                    </Box>
                </Flex>
            </ModalHeader>
            <ModalBody>
                <Box
                    as="img"
                    src={image.path}
                    borderRadius="borderRadius20"
                    height="auto"
                    width="100%"
                    objectFit="contain"
                    maxHeight={PREVIEW_MAX_HEIGHT}
                    maxWidth={PREVIEW_MAX_WIDTH}
                />
            </ModalBody>
            <ModalFooter>
                <ModalFooterActions>
                    <Button variant="secondary" onClick={handleImageDownload}>
                        <DownloadIcon decorative={false} title="Download file" />
                        Download file
                    </Button>
                </ModalFooterActions>
            </ModalFooter>
        </Modal>
    );
}
