import { ToasterToast } from '@twilio-paste/toast';

import { Conversation } from '@twilio/frontline-shared/models/Conversation';
import { Participant } from '@twilio/frontline-shared/models/Participant';
import { ToastMessage } from '../ToastMessage';

const SUCCESS_TOAST_TIMEOUT = 5000;
const ERROR_TOAST_TIMEOUT = 14000;

export function closeConversationSuccessToast(
    conversationSid: Conversation['sid'],
    customerParticipantDisplayName: Participant['displayName'],
): ToasterToast {
    return {
        message: (
            <ToastMessage text={`Conversation with ${customerParticipantDisplayName} closed.`} />
        ),
        variant: 'success',
        dismissAfter: SUCCESS_TOAST_TIMEOUT,
        id: `close_conversation_success_${conversationSid}`,
    };
}

export function closeConversationErrorToast(
    conversationSid: Conversation['sid'],
    closeConversation: (conversationSid: string) => void,
): ToasterToast {
    const message = (
        <ToastMessage
            title="Unable to close conversation."
            text="Please try again or contact your support team if the problem continues."
            onRetry={() => closeConversation(conversationSid)}
        />
    );

    return {
        message,
        variant: 'error',
        dismissAfter: ERROR_TOAST_TIMEOUT,
        id: `close_conversation_error_${conversationSid}`,
    };
}
