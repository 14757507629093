import { ChannelType } from '@twilio/frontline-shared/types/channel';
import { Attributes, CustomerId, ParticipantSid, TwilioParticipant } from '../types';
import { isString } from '../utils/isString';
import { isNumber } from '../utils/isNumber';
import { SidFactory } from '../types/SidFactory';

// not importing the lib's type since
// at the moment it only has email bindings typed
type ParticipantBindings = {
    [ChannelType.Sms]?: {
        address?: string;
    };
    [ChannelType.Whatsapp]?: {
        address?: string;
    };
};

export type Participant = {
    sid: ParticipantSid;
    attributes: Attributes;
    channelType: ChannelType;
    lastReadTime: number;
    lastMessageReadTime: number;
    // Customer Participant Parameters
    avatar?: string;
    customerId?: CustomerId;
    displayName?: string;
    // User Participant Parameters
    identity: string | null;
    markedUnreadAt?: number;
    bindings?: ParticipantBindings;
};

export const ATTRIBUTE_LAST_READ_TIME = 'frontline.lastReadTime';
export const ATTRIBUTE_MARKED_UNREAD_AT = 'frontline.markedUnreadAt';

export type ChannelNameType = 'Chat' | 'Messenger' | 'SMS' | 'WhatsApp' | 'Unknown';

export const fromTwilioParticipant = (twilioParticipant: TwilioParticipant): Participant => {
    const sid = SidFactory.participantSid(twilioParticipant.sid);
    const attributes = twilioParticipant.attributes as Attributes;

    const attributesAvatar = attributes.avatar;
    const avatar = isString(attributesAvatar) ? attributesAvatar : undefined;

    const { bindings } = twilioParticipant;
    const attributesCustomerId = attributes.customer_id;
    const isAttributesCustomerIdValid =
        isNumber(attributesCustomerId) || isString(attributesCustomerId);
    const customerId = isAttributesCustomerIdValid
        ? (String(attributesCustomerId) as CustomerId)
        : undefined;

    const attributeLastReadTime = attributes[ATTRIBUTE_LAST_READ_TIME];
    const lastReadTime = isNumber(attributeLastReadTime) ? attributeLastReadTime : 0;

    const lastMessageReadTime =
        twilioParticipant.lastReadTimestamp instanceof Date
            ? twilioParticipant.lastReadTimestamp.getTime()
            : 0;

    const attributesMarkedUnreadAt = attributes[ATTRIBUTE_MARKED_UNREAD_AT];
    const markedUnreadAt = isNumber(attributesMarkedUnreadAt)
        ? attributesMarkedUnreadAt
        : undefined;

    const attributesDisplayName = attributes.display_name;
    const displayName = isString(attributesDisplayName) ? attributesDisplayName : undefined;

    return {
        sid,
        attributes,
        avatar,
        channelType: twilioParticipant.type as ChannelType,
        customerId,
        displayName,
        lastMessageReadTime,
        lastReadTime,
        identity: twilioParticipant.identity,
        markedUnreadAt,

        // ignoring the ts ParticipantBindings mismatch until it gets better types from lib.
        // @ts-ignore
        bindings,
    };
};

export const isCustomerParticipant = (participant: Participant): boolean => {
    const { customerId } = participant;
    return !!customerId && customerId.length > 0;
};

export const isSameCustomerParticipant = (
    participant: Participant,
    customerParticipantAttributes: { customerId: CustomerId; channelType: ChannelType },
): boolean => {
    return (
        participant.channelType === customerParticipantAttributes.channelType &&
        participant.customerId === customerParticipantAttributes.customerId
    );
};

export const isWhatsAppChannel = (channelType?: ChannelType) => {
    return channelType === ChannelType.Whatsapp;
};

export const getChannelName = (channelType: ChannelType): ChannelNameType => {
    switch (channelType) {
        case ChannelType.Chat: {
            return 'Chat';
        }
        case ChannelType.Facebook: {
            return 'Messenger';
        }
        case ChannelType.Sms: {
            return 'SMS';
        }
        case ChannelType.Whatsapp: {
            return 'WhatsApp';
        }
        default: {
            return 'Unknown';
        }
    }
};

export const getChannelDisplayName = (channelNameType: ChannelNameType, toLowerCase?: boolean) => {
    switch (channelNameType) {
        case 'Chat':
        case 'Messenger':
        case 'WhatsApp': {
            const articleA = toLowerCase ? 'a' : 'A';
            return `${articleA} ${channelNameType}`;
        }
        default: {
            const articleAn = toLowerCase ? 'an' : 'An';
            return `${articleAn} ${channelNameType}`;
        }
    }
};

export const isChannelType = (type: ChannelType | string) =>
    Object.values(ChannelType).includes(type as ChannelType);

export const getParticipantBindingAddress = (participant: Participant): string => {
    if (participant.bindings?.sms) {
        return participant.bindings?.sms?.address || '';
    }
    if (participant.bindings?.whatsapp) {
        return participant.bindings?.whatsapp?.address || '';
    }
    return '';
};
