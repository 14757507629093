import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';

import { JWEToken } from '@twilio/frontline-shared/core/Token';
import { useDispatch } from '@twilio/frontline-shared/store/redux';
import { SessionError } from '@twilio/frontline-shared/core/SessionError';
import { LoadingScreen } from '../components/LoadingScreen';
import { Session } from '../core/Session';
import { CredentialsStorage } from '../core/CredentialsStorage';
import { useSession } from '../context/SessionContext';
import { PAGES } from '../paths';
import { useModal } from '../context/ModalContext';

export function AuthSSO() {
    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { setSession } = useSession();
    const { showModal } = useModal();

    const bootstrap = async () => {
        const params = new URLSearchParams(location.search);
        const tokenString = params.get('Token');
        const identity = params.get('Identity');
        const expiration = params.get('Expiration');

        if (!tokenString || !identity || !expiration) {
            history.replace(PAGES.LOGIN);
            return;
        }

        const jweToken = new JWEToken(identity, tokenString, expiration);
        const accountUniqueName = await CredentialsStorage.getAccountUniqueName();

        Session.create({ accountUniqueName, token: jweToken, dispatch, history })
            .then((session) => {
                CredentialsStorage.setRealmSID(session.configuration?.ssoRealmSid ?? '');
                CredentialsStorage.setToken(jweToken);
                setSession(session);
                history.replace('/');
            })
            .catch((err) => {
                if (err instanceof SessionError) {
                    const [title, message] = err.alertInfo;
                    showModal(title, message);
                    history.replace(PAGES.LOGIN);
                }
            });
    };

    useEffect(() => {
        bootstrap();
    }, []);

    return <LoadingScreen />;
}
