export enum TemplatesModalStatus {
    Loading = 'loading',
    Ready = 'ready',
    Error = 'error',
    NoWhatsAppTemplate = 'noWhatsAppTemplate',
}

export type Category = {
    display_name: string;
    templates: Template[];
};

export type Template = {
    content: string;
};

export type TemplatesByCategory = Category[];
