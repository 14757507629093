import * as Sentry from '@sentry/react';
import { Event, EventHint } from '@sentry/types';
import { BrowserTracing } from '@sentry/tracing';
import { AnalyticsService, AnalyticsUser } from '@twilio/frontline-shared/analytics/type';
import { Severity, beforeSendSharedFilter } from '@twilio/frontline-shared/analytics/sentry';
import { IS_DEV_BUILD, SENTRY_DSN, TWILIO_ENV, FRONTLINE_VERSION } from '../env';

export class SentryService extends AnalyticsService {
    init() {
        Sentry.init({
            dsn: SENTRY_DSN,
            integrations: [new BrowserTracing()],
            enabled: !IS_DEV_BUILD,
            environment: (TWILIO_ENV as string) || 'prod',
            release: FRONTLINE_VERSION,
            beforeBreadcrumb: this.beforeBreadcrumb,
            beforeSend: this.beforeSend,
        });
    }

    sentry = Sentry;

    beforeBreadcrumb(breadcrumb: Sentry.Breadcrumb) {
        if (
            breadcrumb.level === Severity.Critical ||
            breadcrumb.level === Severity.Fatal ||
            breadcrumb.category === 'redux'
        ) {
            return breadcrumb;
        }
        return null;
    }

    beforeSend(event: Event, hint: EventHint) {
        return beforeSendSharedFilter(event, hint);
    }

    logEvent(event: string, extra?: { [key: string]: any }) {}

    // TODO (JG) when we revamp all errors we can get rid of the below swap
    logError(event: string, extra?: { [key: string]: any }) {
        if (extra && extra.message) {
            Sentry.captureEvent({
                message: extra.message,
                extra: { ...extra, message: event },
            });
        } else {
            Sentry.captureEvent({ message: event, extra });
        }
    }

    setUser(user: AnalyticsUser) {
        Sentry.setUser({ id: user.id });
        Sentry.setTag('accountSid', user.accountSid);
    }
}
