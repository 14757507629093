import { Breadcrumb, SeverityLevel } from '@sentry/types';
import { Analytics } from './index';
import { SentryService, SENTRY_ID, Severity } from './sentry';

function getSentry() {
    const { sentry } = (Analytics.getService(SENTRY_ID) || { sentry: {} }) as SentryService;
    return sentry;
}

let Sentry = getSentry();

let breadcumbBL: Breadcrumb[] = [];

export const sentryMiddleware = () => (next: any) => (action: { type: string }) => {
    const { type } = action;
    if (type) {
        const breadcrumb: Breadcrumb = {
            category: 'redux',
            message: type,
            level: Severity.Info as SeverityLevel,
        };
        if (Sentry && Sentry.addBreadcrumb) {
            Sentry.addBreadcrumb(breadcrumb);
        } else {
            Sentry = getSentry();
            breadcumbBL.push(breadcrumb);
            if (Sentry && Sentry.addBreadcrumb) {
                breadcumbBL.forEach((crumb) => Sentry.addBreadcrumb(crumb));
                breadcumbBL = [];
            }
        }
    }

    next(action);
};
