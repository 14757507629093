import { select, put, call } from '@redux-saga/core/effects';

import { selectMyConversations } from '@twilio/frontline-shared/store/conversations/selectors';
import { getUnreadMessagesCount, Conversation } from '@twilio/frontline-shared/models/Conversation';
import { setUnreadMessagesCount } from '@twilio/frontline-shared/store/conversations/actions';

import { updateAppBadgeAndTitle } from '../../core/TabManager';

export function* updateAppIconBadgeSaga() {
    const conversations: Conversation[] = yield select(selectMyConversations);

    const unreadMessagesCount = conversations.reduce((acc, conversation) => {
        if (getUnreadMessagesCount(conversation) > 0) {
            return acc + getUnreadMessagesCount(conversation);
        }
        if (conversation.markedUnreadAt) {
            return acc + 1;
        }
        return acc;
    }, 0);

    yield call(updateAppBadgeAndTitle, unreadMessagesCount);
    yield put(setUnreadMessagesCount({ unreadMessagesCount }));
}
