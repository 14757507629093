import React from 'react';
import { Text, TextProps } from 'react-native';

import TextExtraction from './TextExtraction';
import { ParseShape } from './types';

/**
 * This is a list of the known patterns that are provided by this library
 * @typedef {('url'|'phone'|'email')} KnownParsePattern
 */
/**
 * @type {Object.<string, RegExp>}
 * // The keys really should be KnownParsePattern -- but this is unsupported in jsdoc, sadly
 */
export const PATTERNS = {
    /**
     * Segments/Features:
     *  - http/https support https?
     *  - auto-detecting loose domains if preceded by `www.`
     *  - Localized & Long top-level domains \.(xn--)?[a-z0-9-]{2,20}\b
     *  - Allowed query parameters & values, it's two blocks of matchers
     *    ([-a-zA-Z0-9@:%_\+,.~#?&\/=]*[-a-zA-Z0-9@:%_\+~#?&\/=])*
     *    - First block is [-a-zA-Z0-9@:%_\+\[\],.~#?&\/=]* -- this matches parameter names & values (including commas, dots, opening & closing brackets)
     *    - The first block must be followed by a closing block [-a-zA-Z0-9@:%_\+\]~#?&\/=] -- this doesn't match commas, dots, and opening brackets
     */
    url: /(https?:\/\/|www\.)[-a-zA-Z0-9@:%._\+~#=]{1,256}\.(xn--)?[a-z0-9-]{2,20}\b([-a-zA-Z0-9@:%_\+\[\],.~#?&\/=]*[-a-zA-Z0-9@:%_\+\]~#?&\/=])*/i,
    phone: /[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,7}/,
    email: /\S+@\S+\.\S+/,
};

/**
 * The props added by this component
 * @typedef {DefaultParseShape|import('./TextExtraction').CustomParseShape} ParsedTextAddedProps
 * @property {ParseShape[]} parse
 * @property {import('react-native').TextProps} childrenProps -- the props set on each child Text component
 */
/** @typedef {ParsedTextAddedProps & import('react-native').TextProps} ParsedTextProps */

export interface ParsedTextProps extends TextProps {
    parse?: ParseShape[];
    childrenProps?: TextProps;
}
class ParsedText extends React.Component<ParsedTextProps> {
    static defaultProps = {
        parse: null,
        childrenProps: {},
    };

    /** @returns {import('./TextExtraction').CustomParseShape[]} */
    getPatterns() {
        return this.props.parse?.map((option) => {
            // @ts-ignore

            const { type, ...patternOption } = option;
            if (type) {
                // @ts-ignore

                if (!PATTERNS[type]) {
                    // @ts-ignore

                    throw new Error(`${option.type} is not a supported type`);
                }
                // @ts-ignore

                patternOption.pattern = PATTERNS[type];
            }

            return patternOption;
        });
    }

    getParsedText() {
        if (!this.props.parse) {
            return this.props.children;
        }
        if (typeof this.props.children !== 'string') {
            return this.props.children;
        }
        // @ts-ignore
        const textExtraction = new TextExtraction(this.props.children, this.getPatterns());

        return textExtraction.parse().map((props, index) => {
            const { style: parentStyle } = this.props;
            // @ts-ignore
            const { style, ...remainder } = props;
            return (
                <Text
                    key={`parsedText-${index}`}
                    style={[parentStyle, style]}
                    {...this.props.childrenProps}
                    {...remainder}
                />
            );
        });
    }

    render() {
        // Discard custom props before passing remainder to Text
        const { parse, childrenProps, ...remainder } = { ...this.props };

        return <Text {...remainder}>{this.getParsedText()}</Text>;
    }
}

export default ParsedText;
