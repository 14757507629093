import { AccountSid, ConversationSid, ParticipantSid, MessageSid, SsoRealmSid } from './index';

export class SidFactory {
    private static checkIfSidIsValid(sid: string, prefix: string, typeName: string) {
        if (!sid || !sid.startsWith(prefix)) {
            console.debug(`${sid} is not a valid ${typeName}`);
        }
    }

    static accountSid(sid: string): AccountSid {
        this.checkIfSidIsValid(sid, 'AC', 'AccountSid');
        return sid as AccountSid;
    }

    static conversationSid(sid: string): ConversationSid {
        this.checkIfSidIsValid(sid, 'CH', 'ConversationSid');
        return sid as ConversationSid;
    }

    static messageSid(sid: string): MessageSid {
        this.checkIfSidIsValid(sid, 'IM', 'MessageSid');
        return sid as MessageSid;
    }

    static participantSid(sid: string): ParticipantSid {
        this.checkIfSidIsValid(sid, 'MB', 'ParticipantSid');
        return sid as ParticipantSid;
    }

    static ssoRealmSid(sid: string): SsoRealmSid {
        this.checkIfSidIsValid(sid, 'JB', 'SsoRealmSid');
        return sid as SsoRealmSid;
    }
}
