export const PAGES = {
    LOGIN: '/login',
    LOGOUT: '/logout',
    SSO: '/auth/sso',
    USER_CONVERSATIONS: '/users/:identity/conversations',
    CONVERSATIONS: '/conversations',
    CUSTOMERS: '/customers',
    DEFAULT: '/',
};

export function userConversationsPath(id: string) {
    return PAGES.USER_CONVERSATIONS.replace(':identity', id);
}

export function getUserConversationPath(identity: string, conversationId: string) {
    const userIdentityConversations = userConversationsPath(identity);
    return `${userIdentityConversations}?conversation_id=${conversationId}`;
}

export function getMyConversationPath(conversationId: string) {
    return `${PAGES.CONVERSATIONS}?conversation_id=${conversationId}`;
}

export function getCustomerDetailsPath(customerId: string) {
    return `${PAGES.CUSTOMERS}?customer_id=${customerId}`;
}
