import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { format } from 'date-fns';

import { DefaultTheme } from '@twilio/frontline-shared/theme';
import { IChatBaseMessage } from './types';
import { useChatContext } from './ChatContext';

export type TimeProps = {
    currentMessage: IChatBaseMessage;
    position: 'left' | 'right';
};

export function Time(props: TimeProps) {
    const { position, currentMessage } = props;
    const { timeFormat } = useChatContext();

    return (
        <View style={styles[position].container}>
            <Text testID="time-text" style={styles[position].text}>
                {format(currentMessage.createdAt, timeFormat!)}
            </Text>
        </View>
    );
}

const containerStyle = {
    marginTop: DefaultTheme.space.space0,
    marginRight: DefaultTheme.space.space0,
    marginBottom: DefaultTheme.space.space0,
    marginLeft: DefaultTheme.space.space0,
};

const timeText = {
    fontSize: DefaultTheme.fontSizes.fontSize9,
    lineHeight: DefaultTheme.lineHeights.lineHeight9,
};

const styles = {
    left: StyleSheet.create({
        container: containerStyle,
        text: {
            ...timeText,
            color: DefaultTheme.textColors.colorTextPlaceholder,
        },
    }),
    right: StyleSheet.create({
        container: containerStyle,
        text: {
            ...timeText,
            color: DefaultTheme.textColors.colorTextInverse,
        },
    }),
};
