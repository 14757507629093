import { Box } from '@twilio-paste/box';
import { Text } from '@twilio-paste/text';
import { ChatIcon } from '@twilio-paste/icons/cjs/ChatIcon';
import { UserIcon } from '@twilio-paste/icons/cjs/UserIcon';
import { ShowIcon } from '@twilio-paste/icons/cjs/ShowIcon';
import { SupportIcon } from '@twilio-paste/icons/esm/SupportIcon';
import { LinkExternalIcon } from '@twilio-paste/icons/esm/LinkExternalIcon';

import { useIsManager } from '@twilio/frontline-shared/store/ChatSessionState';
import { useUnreadMessagesCount } from '@twilio/frontline-shared/store/conversations/SimpleConversation';

import { Anchor } from '@twilio-paste/anchor';
import { SupportAddressType } from '@twilio/frontline-shared/models/FrontlineConfiguration';
import { createMailLink } from '@twilio/frontline-shared/utils/createMailLink';
import { detectBrowser, detectBrowserVersion } from '../../utils/browser';
import { NavbarLink } from './NavbarLink';
import { PAGES } from '../../paths';
import { Badge } from '../Badge';
import { useSession } from '../../context/SessionContext';
import { FRONTLINE_VERSION } from '../../env';

type LeftNavbarProps = {
    className?: string;
};

export function LeftNavbar({ className }: LeftNavbarProps) {
    const isManager = useIsManager();
    const { session } = useSession();
    const supportAddressType = session?.configuration?.supportAddressType;
    const supportAddress = session?.configuration?.supportAddress;

    const unreadMessagesCount = useUnreadMessagesCount();
    const badgeValue = unreadMessagesCount > 99 ? '99+' : unreadMessagesCount;

    const renderMyConversationsIcon = () => {
        if (badgeValue) {
            return (
                <Box position="relative">
                    <ChatIcon decorative />
                    <Badge value={badgeValue} />
                </Box>
            );
        }

        return <ChatIcon decorative />;
    };

    const openSupportEmail = (emailAddress: string) => {
        const deviceInfo = {
            Platform: navigator.platform,
            Browser: `${detectBrowser()}/${detectBrowserVersion()}`,
            Version: FRONTLINE_VERSION,
            Account: session?.configuration?.accountSid || '',
            User: session?.userSid || '',
        };
        const mailUrl = createMailLink(
            emailAddress,
            'Twilio Frontline Support Request',
            deviceInfo,
        );
        window.open(mailUrl);
    };

    const onSupportClick = (supportLink: string) => {
        if (supportAddressType === SupportAddressType.Email) {
            openSupportEmail(supportLink);
        } else {
            window.open(supportLink);
        }
    };

    return (
        <Box
            className={className}
            style={{ minWidth: 'var(--sidebar-width)' }}
            display="flex"
            flexDirection="column"
            backgroundColor="colorBackground"
            borderRightWidth="borderWidth10"
            borderRightStyle="solid"
            borderRightColor="colorBorderWeak"
            overflow="auto">
            <Box
                display="flex"
                flexDirection="column"
                height="100%"
                paddingTop="space60"
                paddingBottom="space60">
                <NavbarLink path={PAGES.CONVERSATIONS}>
                    {renderMyConversationsIcon()}
                    <Text
                        as="span"
                        color="colorText"
                        fontSize="fontSize30"
                        marginLeft="space40"
                        data-testid="my-conversations-button">
                        My conversations
                    </Text>
                </NavbarLink>

                <NavbarLink path={PAGES.CUSTOMERS}>
                    <UserIcon decorative />
                    <Text
                        as="span"
                        color="colorText"
                        fontSize="fontSize30"
                        marginLeft="space40"
                        data-testid="my-customers-button">
                        My customers
                    </Text>
                </NavbarLink>

                {isManager && (
                    <>
                        <Text
                            as="span"
                            display="block"
                            color="colorTextWeak"
                            fontSize="fontSize20"
                            fontWeight="fontWeightMedium"
                            marginTop="space100"
                            marginBottom="space30"
                            marginLeft="space50">
                            Manager tools
                        </Text>

                        <NavbarLink path={PAGES.DEFAULT}>
                            <ShowIcon decorative />
                            <Text
                                as="span"
                                color="colorText"
                                fontSize="fontSize30"
                                marginLeft="space40">
                                Team activity
                            </Text>
                        </NavbarLink>
                    </>
                )}

                <Box height="100%" minHeight="sizeSquare160" />
                {supportAddress && (
                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        paddingLeft="space50"
                        _hover={{
                            cursor: 'pointer',
                            textDecoration: 'underline',
                        }}
                        onClick={() => {
                            onSupportClick(supportAddress);
                        }}>
                        <SupportIcon decorative size="sizeIcon40" color="colorTextIcon" />
                        <Text as="span" paddingX="space30" paddingY="space40">
                            Contact support
                        </Text>
                        <LinkExternalIcon decorative color="colorTextIcon" />
                    </Box>
                )}
                <Box paddingY="space30" display="flex" flexDirection="column">
                    <Anchor
                        href="https://www.twilio.com/legal/privacy"
                        marginBottom="space30"
                        paddingLeft="space50">
                        <Text
                            as="span"
                            fontSize="fontSize20"
                            textDecoration="underline #F4F4F6"
                            _hover={{
                                color: 'colorText',
                                cursor: 'pointer',
                                textDecoration: 'underline',
                            }}>
                            Privacy Notice
                        </Text>
                    </Anchor>
                    <Anchor
                        href="https://www.twilio.com/legal/frontline-terms"
                        paddingLeft="space50">
                        <Text
                            as="span"
                            fontSize="fontSize20"
                            textDecoration="underline #F4F4F6"
                            _hover={{
                                color: 'colorText',
                                cursor: 'pointer',
                                textDecoration: 'underline',
                            }}>
                            Terms of service
                        </Text>
                    </Anchor>
                </Box>
            </Box>
        </Box>
    );
}
