type ErrorDescription = { description: string; nonRecoverable?: boolean };
type ErrorDescriptionMap = { [key: number]: ErrorDescription };

export const CallErrors = {
    CURRENTLY_UNAVAILABLE: 31480,
};

export const FrontlineErrors = {
    CALLBACK_EMPTY_URL: 48001,
    CALLBACK_INVALID_URL: 48002,
    CALLBACK_INVALID_RESPONSE: 48003,
    CALLBACK_ERROR_RESPONSE: 48004,
    UPDATE_REQUIRED_ERROR: 48500,
    OUTGOING_CONVERSATION_CONVERSATION_ALREADY_EXISTS: 48031,
};

export const ConversationsErrors = {
    BINDING_ALREADY_EXISTS: 50416, // the old error code remove after updating mobile to new API
};

const WHATSAPP_WINDOW_ERROR = 63016;

const INVALID_ACCESS_TOKEN_ISSUER_ERROR = 20103;

export const SERVICE_NOT_FOUND_ERROR_ALERT = {
    title: 'Unable to access your workspace',
    message:
        'Please contact your IT team to resolve the issue.\n\nDeveloper guidance: In the Twilio console, restore the Frontline Conversations service.',
};

export const ACCOUNT_SUSPENDED_ERROR_ALERT = {
    title: 'Unable to access your workspace',
    message:
        'Your Twilio Frontline workspace is temporarily suspended. Please contact your administrator.',
};

export const DEFAULT_SESSION_ERROR_ALERT = {
    title: 'Error',
    message:
        'An unexpected error occured while logging in. Please contact your IT support or try again later.',
};

const ERROR_DESCRIPTIONS: ErrorDescriptionMap = {
    30001: { description: 'Queue overflow' },
    30002: { description: 'Account suspended' },
    30003: { description: 'Unreachable destination handset	' },
    30004: { description: 'Message blocked' },
    30005: { description: 'Unknown destination handset' },
    30006: { description: 'Landline or unreachable carrier' },
    30007: { description: 'Carrier violation' },
    30008: { description: 'Unknown error' },
    30009: { description: 'Missing segment' },
    30010: { description: 'Message price exceeds max price.' },
    30019: { description: 'Content size exceeds carrier limit.' },

    // Channel Errors
    63001: { description: 'Channel could not authenticate the request' },
    63002: { description: 'Channel could not find From address' },
    63003: { description: 'Channel could not find To address' },
    63005: { description: 'Channel did not accept given content' },
    63006: { description: 'Could not format given content for the channel' },
    63007: { description: 'Twilio could not find a Channel with the specified From address' },
    63008: {
        description: 'Could not execute the request because the channel module is misconfigured',
    },
    63009: { description: 'Channel returned an error when executing the request' },
    63010: { description: 'Channels - Twilio Internal error' },
    63012: {
        description:
            'Channel returned an internal error that prevented it from completing the request',
    },
    63013: {
        description: "This message send failed because it violates Channel provider's policy.",
    },
    63014: {
        description:
            'This message failed to be delivered to the user because it was blocked by a user action.',
    },
    63016: {
        description:
            'Failed to send freeform message because you are outside the allowed window. Please use a Template.',
    },

    // Media Errors
    63034: {
        description:
            'The specified message media exceeds the maximum size limit. You may re-attempt with media of size 5 MB or less.',
        nonRecoverable: true,
    },
    11751: {
        description:
            'The total size of the media message request exceeds the maximum size limit for the channel.',
        nonRecoverable: true,
    },
    50512: {
        description: 'The requested multimedia attachment size exceeds the allowed limit.',
        nonRecoverable: true,
    },
};

export function getErrorDescription(code: number): ErrorDescription {
    return ERROR_DESCRIPTIONS[code] || { description: 'Error' };
}

export function isWhatsAppWindowError(code?: number | null) {
    return code === WHATSAPP_WINDOW_ERROR;
}

export const isUpdateRequiredError = (err: any) => {
    return err?.body?.code === FrontlineErrors.UPDATE_REQUIRED_ERROR;
};

export const isNotFoundServiceByInstance = (err: any) => {
    return err?.message?.includes("Connection error: Can't find Service Instance by Id");
};

export const isInvalidAccessTokenIssuer = (err: any) => {
    return (
        err?.errorCode === INVALID_ACCESS_TOKEN_ISSUER_ERROR ||
        err?.cause?.errorCode === INVALID_ACCESS_TOKEN_ISSUER_ERROR
    );
};

export const isConversationAlreadyExists = (err: any) => {
    const errorCode = err?.code || err?.body?.code;
    if (
        errorCode === ConversationsErrors.BINDING_ALREADY_EXISTS ||
        errorCode === FrontlineErrors.OUTGOING_CONVERSATION_CONVERSATION_ALREADY_EXISTS
    ) {
        return true;
    }
    return false;
};
