import { useMemo } from 'react';
import { ChannelType } from '@twilio/frontline-shared/types/channel';
import { FrontlineConfiguration } from '../models/FrontlineConfiguration';

export const useChannelTypeSizeLimits = (config?: FrontlineConfiguration) => {
    const channelTypeSizeLimits: { [key in ChannelType]: number } = useMemo(
        () => ({
            [ChannelType.Whatsapp]: config?.whatsappUploadLimit || 16,
            [ChannelType.Chat]: config?.chatUploadLimit || 150,
            [ChannelType.Sms]: config?.smsUploadLimit || 5,
            [ChannelType.Facebook]: config?.facebookUploadLimit || 150,
        }),
        [config],
    );

    return channelTypeSizeLimits;
};

export type ChannelTypeSizeLimitsType = ReturnType<typeof useChannelTypeSizeLimits>;
