export const formatSeconds = (value: number) => {
    if (value < 1) {
        value = 0;
    }
    const minutes = Math.floor(value / 60)
        .toString()
        .padStart(2, '0');
    const seconds = Math.floor(value % 60)
        .toString()
        .padStart(2, '0');
    return `${minutes}:${seconds}`;
};
