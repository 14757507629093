import { LastMessage, JSONObject as ConversationsJsonObject } from '@twilio/conversations';

import { isString } from '../utils/isString';
import { Attributes, ConversationSid, TwilioConversation } from '../types';
import { ConversationMessage, isUnsuccessfulMessage } from './Message';
import { SidFactory } from '../types/SidFactory';
import { isCustomerParticipant, isSameCustomerParticipant } from './Participant';
import { ParticipantsState } from '../store/ParticipantsState';
import { FrontlineEventType, SystemEvent, isSystemEvent } from './Event';
import { ChannelType } from '../types/channel';

export type JSONObject = ConversationsJsonObject;

export type Conversation = {
    sid: ConversationSid;
    friendlyName: string;
    avatar?: string;
    lastMessage: LastMessage;
    lastSystemEvent: SystemEvent | undefined;
    lastMessageTime: number;
    markedUnreadAt?: number;
    lastConsumedMessageIndex: number | null;
    unreadMessagesCount?: number;
    unreadSystemMessagesCount?: number;
    createdAt: Date;
    events: SystemEvent[];
    isAllPreviousMessagesFetched?: boolean;
};

export const DEFAULT_CONVERSATION_NAME = 'Customer';
export const ATTRIBUTE_FRONTLINE_EVENTS = 'frontline.events';

export const fromTwilioConversation = (twilioConversation: TwilioConversation): Conversation => {
    const attributes = twilioConversation.attributes as Attributes;

    const attributesAvatar = attributes.avatar;
    const attributesFrontlineEvents = attributes[ATTRIBUTE_FRONTLINE_EVENTS];
    const avatar = isString(attributesAvatar) ? attributesAvatar : undefined;
    const events = Array.isArray(attributesFrontlineEvents)
        ? attributesFrontlineEvents.filter(isSystemEvent).sort(compareEventDates)
        : [];
    const lastMessage = { ...twilioConversation.lastMessage };
    const lastSystemEvent = events.length > 0 ? events[events.length - 1] : undefined;
    const lastMessageTime = Math.max(
        lastMessage?.dateCreated?.getTime() || 0,
        lastSystemEvent?.date || 0,
        twilioConversation.dateCreated?.getTime() || 0,
    );
    // FIXME: https://issues.corp.twilio.com/browse/RTDSDK-2325
    // FIXME: https://issues.corp.twilio.com/browse/RTDSDK-2324
    const friendlyName = twilioConversation.friendlyName || DEFAULT_CONVERSATION_NAME;

    return {
        sid: SidFactory.conversationSid(twilioConversation.sid),
        friendlyName,
        avatar,
        lastMessage,
        lastConsumedMessageIndex: twilioConversation.lastReadMessageIndex,
        createdAt: twilioConversation.dateCreated!,
        events,
        lastSystemEvent,
        lastMessageTime,
    };
};

export const getUnreadMessagesCount = (
    conversation?: Pick<Conversation, 'unreadMessagesCount' | 'unreadSystemMessagesCount'>,
) => {
    return (
        (conversation?.unreadMessagesCount || 0) + (conversation?.unreadSystemMessagesCount || 0)
    );
};

const compareEventDates = (a: SystemEvent, b: SystemEvent) => a.date - b.date;

export const compareByLastMessage = (
    conversationA?: Conversation,
    conversationB?: Conversation,
): number => {
    if (!conversationA) {
        return -1;
    }

    if (!conversationB) {
        return 1;
    }

    return conversationB.lastMessageTime - conversationA.lastMessageTime;
};

export const sortByLastMessage = (conversations: Conversation[]): Conversation[] =>
    conversations.sort(compareByLastMessage);

export const isLastMessageSystemEvent = (
    conversation: Pick<Conversation, 'lastSystemEvent' | 'lastMessageTime'>,
) =>
    conversation.lastSystemEvent &&
    conversation.lastSystemEvent.date === conversation.lastMessageTime;

export const getIdentitiesFromSystemEvents = (
    conversation: Conversation,
    oldestMessageTime: number,
    newestMessageTime: number,
): Set<string> => {
    const users = new Set<string>();
    conversation.events
        ?.filter((e) => e.date >= oldestMessageTime && e.date <= newestMessageTime)
        .forEach((e) => {
            if (e.type === FrontlineEventType.Transfer) {
                users.add(e.from);
                users.add(e.to);
                if (e.by) {
                    users.add(e.by);
                }
            } else if (e.type === FrontlineEventType.CallEnded) {
                if (e.initiatorIdentity) {
                    users.add(e.initiatorIdentity);
                }
            }
        });
    return users;
};

export const shouldRenderDraft = (
    draftMessage: string | undefined,
    unreadMessagesCount: number,
    lastMessage: ConversationMessage | SystemEvent | undefined,
) => {
    if (!draftMessage) {
        return false;
    }

    if (unreadMessagesCount > 0) {
        return false;
    }

    if (lastMessage && !isSystemEvent(lastMessage) && isUnsuccessfulMessage(lastMessage)) {
        return false;
    }

    return true;
};

export const findExistingConversationSid = (
    customerId: string | undefined,
    channelType: ChannelType,
    conversationSids: string[],
    participants: ParticipantsState,
): string | undefined => {
    if (!customerId) {
        return;
    }

    return conversationSids.find((conversationSid) => {
        const conversationParticipants = participants[conversationSid];
        const customerParticipantAttributes = { customerId, channelType };
        return conversationParticipants?.find(
            (participant) =>
                isCustomerParticipant(participant) &&
                isSameCustomerParticipant(participant, customerParticipantAttributes),
        );
    });
};
