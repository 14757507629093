export type FulfilledResult<T> = { status: 'fulfilled'; value: T };

type Result<T> = FulfilledResult<T> | { status: 'rejected'; reason?: any };

export function allSettled<T>(promises: Promise<T>[]): Promise<Result<T>[]> {
    return Promise.all(
        promises.map((item) => {
            const onFulfill = (value: T): Result<T> => {
                return { status: 'fulfilled', value };
            };
            const onReject = (reason: any): Result<T> => {
                return { status: 'rejected', reason };
            };
            const itemPromise = Promise.resolve(item);
            try {
                return itemPromise.then(onFulfill, onReject);
            } catch (e) {
                return Promise.reject(e);
            }
        }),
    );
}
