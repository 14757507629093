import { Box } from '@twilio-paste/box';
import { Badge } from '@twilio-paste/badge';
import { ErrorIcon } from '@twilio-paste/icons/esm/ErrorIcon';
import { useInternetConnection } from '@twilio/frontline-shared/store/InternetConnectionState';
import { useConnectionState } from '@twilio/frontline-shared/store/ChatSessionState';

export function ConnectionBadge() {
    const isConnectedToInternet = useInternetConnection();
    const clientConnectionState = useConnectionState();

    if (isConnectedToInternet && clientConnectionState !== 'connected') {
        return (
            <Box paddingLeft="space40">
                <Badge variant="warning" as="span">
                    Connecting...
                </Badge>
            </Box>
        );
    }

    if (!isConnectedToInternet) {
        return (
            <Box paddingLeft="space40">
                <Badge variant="error" as="span">
                    <ErrorIcon decorative />
                    No internet connection
                </Badge>
            </Box>
        );
    }

    return null;
}
