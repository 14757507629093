import { MediaDocument, MediaVoice, MediaUnsupported } from '@twilio/frontline-shared/models/Media';

import { DocumentPreview } from '@twilio/frontline-shared/components/Chat/Media/DocumentPreview';
import { Anchor } from '@twilio-paste/anchor';

export function MessageDocument({
    document,
}: {
    document: MediaDocument | MediaVoice | MediaUnsupported;
}) {
    return (
        <Anchor href={document?.path ?? ''} download variant="inverse" target="_blank">
            {document && <DocumentPreview document={document} />}
        </Anchor>
    );
}
