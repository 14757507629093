import React from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';

import { ScalingActivityIndicator } from '@twilio/frontline-shared/components/ScalingActivityIndicator';
import { TwilIcon } from '@twilio/frontline-shared/components/TwilIcon';
import { formatSeconds } from '@twilio/frontline-shared/utils/formatSeconds';
import { DefaultTheme } from '@twilio/frontline-shared/theme';

import { Slider } from './Slider';
import { THUMB_SIZE } from './Slider/SliderThumb';
import { MessagePosition } from '../types';

export enum InlinePlayerState {
    None,
    Ready,
    Loading,
    Playing,
    Paused,
}

interface InlinePlayerProps {
    onPlay: () => void;
    onPause: () => void;
    onSeek: (time: number) => void;
    position: number;
    duration: number;
    state: InlinePlayerState;
    messagePosition: MessagePosition;
}

export function InlinePlayer({
    onPlay,
    onPause,
    onSeek,
    position,
    duration,
    state,
    messagePosition,
}: InlinePlayerProps) {
    const isReady = state === InlinePlayerState.Ready;
    const isLoading = state === InlinePlayerState.Loading;
    const isPlaying = state === InlinePlayerState.Playing;
    const isPaused = state === InlinePlayerState.Paused;

    const getPlayButtonBackgroundColor = () => {
        if (isLoading) {
            return DefaultTheme.backgroundColors.colorBackgroundPrimaryLighter;
        }
        if (messagePosition === 'left') {
            return DefaultTheme.backgroundColors.colorBackgroundPrimary;
        }
        return DefaultTheme.backgroundColors.colorBackgroundBody;
    };

    const isActive = isReady || isPlaying || isPaused;
    const trackColor =
        messagePosition === 'left'
            ? DefaultTheme.backgroundColors.colorBackgroundDarker
            : DefaultTheme.backgroundColors.colorBackgroundPrimaryLight;
    const timeStampColor =
        messagePosition === 'left'
            ? DefaultTheme.textColors.colorTextPlaceholder
            : DefaultTheme.textColors.colorTextBrandInverse;
    const playButtonBackgroundColor = getPlayButtonBackgroundColor();
    const playButtonIconColor =
        messagePosition === 'left'
            ? DefaultTheme.backgroundColors.colorBackgroundBody
            : DefaultTheme.backgroundColors.colorBackgroundPrimary;

    return (
        <View style={styles.outerWrapper}>
            <View style={styles.innerWrapper}>
                <TouchableOpacity
                    onPress={onPlay}
                    style={[styles.playButton, { backgroundColor: playButtonBackgroundColor }]}>
                    {isLoading ? (
                        <ScalingActivityIndicator
                            size={20}
                            color={DefaultTheme.backgroundColors.colorBackgroundPrimaryDarker}
                            style={styles.loadingIndicator}
                        />
                    ) : (
                        <TwilIcon
                            name={isPlaying ? 'mediaPause' : 'mediaPlay'}
                            size={32}
                            color={playButtonIconColor}
                        />
                    )}
                </TouchableOpacity>
                <View style={styles.progressBarWrapper}>
                    <Slider
                        disabled={!isActive}
                        onSlidingStart={() => {
                            if (isPlaying) {
                                onPause();
                            }
                        }}
                        onSlidingComplete={onSeek}
                        maximumValue={duration}
                        value={isActive ? position : 0}
                        thumbStyle={styles.progressBarThumb}
                        trackStyle={styles.progressBarTrack}
                        maximumTrackTintColor={trackColor}
                        minimumTrackTintColor={trackColor}
                    />
                    <View style={styles.timeStampsWrapper}>
                        <Text style={[styles.timeStamp, { color: timeStampColor }]}>
                            {isActive ? formatSeconds(Math.ceil(position)) : '--:--'}
                        </Text>
                        <Text style={[styles.timeStamp, { color: timeStampColor }]}>
                            {isActive
                                ? `-${formatSeconds(duration - Math.ceil(position))}`
                                : '--:--'}
                        </Text>
                    </View>
                </View>
            </View>
        </View>
    );
}

const PROGRESS_BAR_TOP_MARGIN = DefaultTheme.space.space30;
const BAR_THUMB_HEIGHT = 13;
const TIMESTAMP_TEXT_LINEHEIGHT = DefaultTheme.lineHeights.lineHeight10;

const TIMESTAMP_CONTAINER_TOP_OFFSET = Math.floor((THUMB_SIZE - BAR_THUMB_HEIGHT) / 2);
const PROGRESS_BAR_HEIGHT =
    THUMB_SIZE +
    TIMESTAMP_TEXT_LINEHEIGHT +
    PROGRESS_BAR_TOP_MARGIN -
    TIMESTAMP_CONTAINER_TOP_OFFSET;

const styles = StyleSheet.create({
    outerWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    innerWrapper: {
        flexDirection: 'row',
        paddingLeft: DefaultTheme.space.space10,
    },
    playButton: {
        justifyContent: 'center',
        width: 40,
        height: 40,
        borderRadius: 20,
        padding: DefaultTheme.space.space5,
        marginTop: PROGRESS_BAR_TOP_MARGIN,
    },
    loadingIndicator: {
        justifyContent: 'center',
        cursor: 'progress',
    },
    progressBarWrapper: {
        width: 270,
        height: PROGRESS_BAR_HEIGHT,
        paddingTop: PROGRESS_BAR_TOP_MARGIN,
        paddingRight: DefaultTheme.space.space10,
        paddingLeft: DefaultTheme.space.space20,
    },
    progressBarThumb: {
        width: BAR_THUMB_HEIGHT,
        height: BAR_THUMB_HEIGHT,
        backgroundColor: 'white',
        borderColor: DefaultTheme.borderColors.colorBorderPrimary,
        borderWidth: 1,
    },
    progressBarTrack: {
        height: 1,
    },
    timeStampsWrapper: {
        width: '100%',
        top: -TIMESTAMP_CONTAINER_TOP_OFFSET,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    timeStamp: {
        fontSize: DefaultTheme.fontSizes.fontSize10,
        lineHeight: TIMESTAMP_TEXT_LINEHEIGHT,
    },
});
