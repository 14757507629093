import { useState, useEffect } from 'react';
import { Avatar as PasteAvatar } from '@twilio-paste/avatar';
import { Box } from '@twilio-paste/box';
import { UserIcon } from '@twilio-paste/icons/cjs/UserIcon';

type AvatarProps = {
    friendlyName?: string;
    avatar?: string;
    size?: 'sizeIcon70' | 'sizeIcon40' | 'sizeIcon50' | 'sizeIcon110';
};

export function Avatar({ size = 'sizeIcon70', friendlyName = '', avatar }: AvatarProps) {
    const [useAvatarImage, setUseAvatarImage] = useState<boolean>(!!avatar);
    useEffect(() => setUseAvatarImage(!!avatar), [avatar]);

    const ucName = friendlyName.toLocaleUpperCase();

    if (useAvatarImage) {
        return (
            <Box
                as="div"
                element="AVATAR"
                backgroundColor="colorBackgroundUser"
                borderRadius="borderRadiusCircle"
                overflow="hidden"
                size={size}
                color="colorText">
                <Box
                    as="img"
                    alt={ucName}
                    maxWidth="100%"
                    src={avatar}
                    size={size}
                    title={ucName}
                    data-testid="avatar"
                    onError={() => {
                        setUseAvatarImage(false);
                    }}
                />
            </Box>
        );
    }

    return (
        <PasteAvatar
            size={size}
            name={ucName}
            data-testid="avatar"
            icon={!ucName || ucName.startsWith('+') ? UserIcon : undefined}
        />
    );
}
